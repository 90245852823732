import React from 'react';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  default: {
    overflowX: 'auto',
    padding: '6px 0', // To account for horizontal scrollbar and prevent a vertical scrollbar
  },
  errored: {
    '& iframe': {
      border: `2px solid ${theme.palette.error.main}`,
      borderRadius: '8px',
    },
  },
}));

const ReCAPTCHAInput = ({ fieldName }) => {
  const classes = useStyles();
  const { touched, errors, setFieldValue } = useFormikContext();

  return (
    <ReCAPTCHA
      className={cx(classes.default, {
        [classes.errored]: touched[fieldName] && errors[fieldName] !== undefined,
      })}
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      onChange={(value) => setFieldValue(fieldName, value, true)}
    />
  );
};

export default ReCAPTCHAInput;
