import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Slide, ButtonBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';

import { submitAgentLandingEmailAgentForm } from 'utils/api';

import { modalCloseButton } from 'assets/jss/material-dashboard-pro-react';
import { isAgentLandingPageSample } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  modalCloseButton,
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.palette.darkGray.main,
    margin: '0 0 32px',
  },
  button: {
    margin: '32px 0 0',
    color: 'black',
    width: '100%',
  },
}));

const EmailAgentForm = ({ onClose, agentLandingPageToken }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const formRef = React.useRef();

  return (
    <Dialog
      open
      keepMounted
      maxWidth="xs"
      scroll="body"
      onClose={onClose}
    >
      <ButtonBase
        className={classes.modalCloseButton}
        aria-label="Close"
        onClick={onClose}
      >
        <CloseIcon />
      </ButtonBase>
      <Formik
        innerRef={formRef}
        initialValues={{
          email: '',
          subject: '',
          message: '',
          recaptchaValue: '',
        }}
        onSubmit={async ({
          email, subject, message, recaptchaValue,
        }) => {
          showLoadingIndicatorModal();

          try {
            if (!isAgentLandingPageSample(agentLandingPageToken)) {
              await submitAgentLandingEmailAgentForm(agentLandingPageToken, email, subject, message, recaptchaValue);
            }

            setCurrentAlert('success', 'Thank you for your request, I will be in touch!');

            onClose();
          } catch (error) {
            console.error('error', error);
          }

          hideLoadingIndicatorModal();
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
          subject: Yup.string().required(),
          message: Yup.string().required(),
          recaptchaValue: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          return (
            <Form>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.title}>Send an email message to your local neighborhood real estate agent</h3>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="email">
                    {({ field }) => (
                      <CustomInput
                        labelText="Email Address"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          autoComplete: 'email',
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="subject"
                  >
                    {({ field }) => (
                      <CustomInput
                        labelText="Subject"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="message">
                    {({ field }) => (
                      <CustomInput
                        labelText="Message"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          multiline: true,
                          rows: 3,
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="recaptchaValue">
                    {({ field }) => (
                      <ReCAPTCHAInput
                        fieldName={field.name}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    round
                    outlined
                    size="lg"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Send Message
                  </Button>
                </GridItem>
              </GridContainer>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EmailAgentForm;
