import classNames from 'classnames';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import {
  filterCategorySettings,
  getAllPropertyTypesFromEventType,
  useDataFilterContext,
} from 'components/DataMap/DataFilterProvider';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  settingGridItem: {
    textAlign: 'center',
    marginBottom: '50px',
  },
  settingTitle: {
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: theme.palette.black.main,
  },
  settingText: {
    color: theme.palette.grayScale9.main,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectInputs: {
    maxWidth: '300px',
    margin: '40px auto',
  },
}));

const filtersWithClampedValues = (filtersObj) => {
  const obj = { ...filtersObj };

  // Use our own defaults as opposed to copying 'appliedFilters' to show proper bounds instead of 0 and Number.MAX_SAFE_INTEGER
  // By default, all property types are selected. We omit the key here on purpose.
  Object.keys(filterCategorySettings).forEach((key) => {
    const { lowerBound, upperBound } = filterCategorySettings[key];
    obj[`${key}Range`] = [Math.max(obj[`${key}Range`][0], lowerBound), Math.min(obj[`${key}Range`][1], upperBound)];
  });

  return obj;
};

const SliderFilter = ({
  classes, title, unitSuffix = '', filterCategory, filters, setFilters, keyName,
}) => {
  const {
    lowerBound, upperBound, step, lowerBoundLessThan, upperBoundGreaterThan,
  } = filterCategorySettings[filterCategory];

  return (
    <>
      <div className={classes.settingTitle}>{title}</div>
      <Slider
        value={filters[keyName]}
        min={lowerBound}
        max={upperBound}
        valueLabelDisplay="off"
        step={step}
        onChange={(event, newValue) => setFilters({
          ...filters,
          [keyName]: newValue,
        })}
      />
      <span className={classes.settingText} style={{ float: 'left' }}>
        {filters[keyName][0] === lowerBound && lowerBoundLessThan ? '≤ ' : ''}
        {numberWithCommas(filters[keyName][0])}
        {unitSuffix && ` ${unitSuffix}`}
      </span>
      <span className={classes.settingText} style={{ float: 'right' }}>
        {filters[keyName][1] === upperBound && upperBoundGreaterThan ? '≥ ' : ''}
        {numberWithCommas(filters[keyName][1])}
        {unitSuffix && ` ${unitSuffix}`}
      </span>
    </>
  );
};

const DataFilterControls = ({ initPropertyFilters }) => {
  const classes = useStyles();

  const {
    setAppliedFilters, isFiltersModalOpen, setIsFiltersModalOpen,
    availableEventTypes, selectedEventType, setSelectedEventType,
    filtersModalOptions, setSelectedEventTypeByName, defaultFilters,
  } = useDataFilterContext();

  // Track slider values internally so we can update the context only when they press the apply filters button
  // Use our own defaults as opposed to copying 'appliedFilters' to show proper bounds instead of 0 and Number.MAX_SAFE_INTEGER
  const [filters, setFilters] = React.useState(null);

  const applyFilters = (f) => {
    const newFilters = { ...f };

    Object.keys(filterCategorySettings).forEach((key) => {
      const {
        lowerBound, upperBound, lowerBoundLessThan, upperBoundGreaterThan,
      } = filterCategorySettings[key];

      newFilters[`${key}Range`] = [
        f[`${key}Range`][0] === lowerBound && lowerBoundLessThan ? 0 : f[`${key}Range`][0],
        f[`${key}Range`][1] === upperBound && upperBoundGreaterThan ? Number.MAX_SAFE_INTEGER : f[`${key}Range`][1],
      ];
    });

    setAppliedFilters(newFilters);
  };

  React.useEffect(() => {
    if (availableEventTypes.length === 0) return;

    if (!selectedEventType) {
      // Just set the selected event type to ABS by default if its not set already
      if (availableEventTypes) setSelectedEventTypeByName('Absentee Owners');
    }
  }, [availableEventTypes]);

  React.useEffect(() => {
    (async () => {
      if (!selectedEventType) return;

      // Initialize the filters on here
      const obj = filtersWithClampedValues(initPropertyFilters || defaultFilters);

      // If there are set init property filters, we don't want to override them
      if (!initPropertyFilters) {
        if (filtersModalOptions.selectAllPropertyTypesByDefault || filtersModalOptions.selectPropertyTypesByDefault) {
          const propertyTypes = getAllPropertyTypesFromEventType(selectedEventType);

          if (filtersModalOptions.selectAllPropertyTypesByDefault) {
            obj.propertyTypes = propertyTypes;
          } else {
          // Only select specific property types
            obj.propertyTypes = propertyTypes.filter((propertyType) => filtersModalOptions.selectPropertyTypesByDefault.includes(propertyType));
          }
        }
      }

      // Update both the local filters and the applied filters here
      setFilters(obj);
      applyFilters(obj);
    })();
  }, [selectedEventType, filtersModalOptions]);

  if (!selectedEventType || !isFiltersModalOpen || !filters) {
    return null;
  }

  let sliderFilters = null;

  // We have different filters for commercial data
  if (selectedEventType.name === 'Commercial') {
    sliderFilters = (
      <>
        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            title="Number of Units"
            filterCategory="numberOfUnits"
            filters={filters}
            setFilters={setFilters}
            keyName="numberOfUnitsRange"
          />
        </GridItem>

        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            filters={filters}
            setFilters={setFilters}
            title="Years Ago Built"
            filterCategory="builtYearsAgo"
            keyName="builtYearsAgoRange"
          />
        </GridItem>
        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            filters={filters}
            setFilters={setFilters}
            title="Years Ago Last Sold"
            filterCategory="soldYearsAgo"
            keyName="soldYearsAgoRange"
          />
        </GridItem>
      </>
    );
  } else {
    sliderFilters = (
      <>
        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            title="Square Footage"
            unitSuffix="sq.ft"
            filterCategory="squareFootage"
            filters={filters}
            setFilters={setFilters}
            keyName="squareFootageRange"
          />
        </GridItem>

        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            filters={filters}
            setFilters={setFilters}
            title="# of Bedrooms"
            filterCategory="numBeds"
            keyName="numBedsRange"
          />
        </GridItem>

        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            filters={filters}
            setFilters={setFilters}
            classes={classes}
            title="# of Bathrooms"
            filterCategory="numBaths"
            keyName="numBathsRange"
          />
        </GridItem>

        <GridItem xs={10} md={8} className={classes.settingGridItem}>
          <SliderFilter
            classes={classes}
            filters={filters}
            setFilters={setFilters}
            title="Years Ago Last Sold"
            filterCategory="soldYearsAgo"
            keyName="soldYearsAgoRange"
          />
        </GridItem>
      </>
    );
  }

  const onClose = () => {
    applyFilters(filters);
    setIsFiltersModalOpen(false);
  };

  const onReset = () => {
    const obj = { ...defaultFilters };

    if (filtersModalOptions.selectAllPropertyTypesByDefault || filtersModalOptions.selectPropertyTypesByDefault) {
      const propertyTypes = getAllPropertyTypesFromEventType(selectedEventType);

      if (filtersModalOptions.selectAllPropertyTypesByDefault) {
        obj.propertyTypes = propertyTypes;
      } else {
        // Only select specific property types
        obj.propertyTypes = propertyTypes.filter((propertyType) => filtersModalOptions.selectPropertyTypesByDefault.includes(propertyType));
      }
    }

    setFilters(filtersWithClampedValues(obj));
  };

  const onApply = () => {
    applyFilters(filters);
    setIsFiltersModalOpen(false);
  };

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="sm"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Change Data Filters" />

      <div className={classes.selectInputs}>
        {!filtersModalOptions.disableEventTypeSelector && (
          <CustomSelect
            labelText="Data Type"
            // NOTE: Disabled Commercial campaigns on 10/10/2022
            items={availableEventTypes.filter((eventType) => eventType.name !== 'Commercial').map(({ _id, name }) => ({ value: _id, name }))}
            inputProps={{
              value: selectedEventType._id,
              onChange: (event) => {
                const newSelectedEventType = availableEventTypes.filter(({ _id }) => _id === event.target.value)[0];
                setSelectedEventType(newSelectedEventType);

                // Reset the property types selection too
                setFilters({
                  ...filters,
                  propertyTypes: [],
                });
              },
            }}
          />
        )}

        <CustomSelect
          labelText="Property Types"
          items={selectedEventType.propertyTypeFilters.map(({ propertyType }) => ({ value: propertyType, name: propertyType }))}
          inputProps={{
            multiple: true,
            value: filters.propertyTypes || [],
            onChange: (event) => setFilters({
              ...filters,
              propertyTypes: event.target.value,
            }),
          }}
        />
      </div>

      <GridContainer justifyContent="center" style={{ marginTop: '16px' }}>
        {sliderFilters}

        <GridItem xs={9} className={classes.buttonsContainer}>
          <Button
            color="dark"
            round
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            color="primary"
            round
            onClick={onApply}
          >
            Apply Filters
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
};

export default DataFilterControls;
