import styles from 'assets/jss/material-dashboard-pro-react/components/homeEstimationStyle';
import html2canvas from 'html2canvas';
import React from 'react';

import {
  ButtonBase, Hidden, Slider, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import ConsentForm from 'components/AgentLanding/HomeEstimation/ConsentForm';
import HomeEstimationMap from 'components/AgentLanding/HomeEstimation/HomeEstimationMap';
import PriceTooLowForm from 'components/AgentLanding/HomeEstimation/PriceTooLowForm';
import SellForThisPriceForm from 'components/AgentLanding/HomeEstimation/SellForThisPriceForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimilarHomesTable from 'components/SimilarHomesTable/SimilarHomesTable';

import { downloadReportAgentLanding } from 'utils/api';
import {
  numberAsAbbreviatedPrice, numberWithCommas, openFileInNewWindow, showAPIErrorAlert, stringifyAddressObject,
} from 'utils/lib';

const useStyles = makeStyles(styles);

const EstimationSlider = ({
  description, value, setValue, valueNames,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.slider}>
      <p>{description}</p>
      <Slider
        valueLabelDisplay="off"
        step={1}
        min={0}
        max={2}
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
      />
      <div className={classes.sliderDescriptions}>
        {valueNames.map((name) => <span key={name}>{name}</span>)}
      </div>
    </div>
  );
};

const HomeEstimation = ({
  agentLandingPageToken, isConsentGiven, setIsConsentGiven, setIsDownloadingReport, agentData, propertyData, similarHomes, eventId,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const { setCurrentAlert } = useAlertContext();

  const mapRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [propertyConditionSliderValue, setPropertyConditionSliderValue] = React.useState(1);
  const [hotMarketSliderValue, setHotMarketSliderValue] = React.useState(1);

  const [averagePrice, setAveragePrice] = React.useState(null);
  const [averagePricePerSqft, setAveragePricePerSqft] = React.useState(null);
  const [estimateRangeString, setEstimateRangeString] = React.useState('Calculating...');
  const [estimatePerSqftRangeString, setEstimatePerSqftRangeString] = React.useState('Calculating...');

  React.useEffect(() => {
    // Similar homes data is not loaded in yet
    if (!similarHomes) return;

    let totalPrice = 0;
    let totalSqft = 0;
    similarHomes.forEach(({ price, squareFootage }) => {
      totalPrice += price;
      totalSqft += squareFootage;
    });

    setAveragePrice(totalPrice / similarHomes.length);
    setAveragePricePerSqft(totalPrice / totalSqft);
  }, [similarHomes]);

  React.useEffect(() => {
    // Property data may not be loaded in yet
    if (!propertyData) return;

    // Similar homes data is not loaded in yet
    if (!averagePricePerSqft || !averagePrice) return;

    let rawPriceEstimate;

    // If the property has sqft, use $/sqft for the price estimate, otherwise
    // just average the sales prices for what is likely a vacant lot
    if (propertyData.squareFootage) {
      rawPriceEstimate = propertyData.squareFootage * averagePricePerSqft;
    } else {
      rawPriceEstimate = averagePrice;
    }

    const priceEstimateLow = rawPriceEstimate * 0.85 * (1 + 0.05 * propertyConditionSliderValue + 0.05 * hotMarketSliderValue); // Total adjustment of 0.95 when sliders in middle
    const priceEstimateHigh = rawPriceEstimate * 0.95 * (1 + 0.05 * propertyConditionSliderValue + 0.05 * hotMarketSliderValue); // Total adjustment of 1.05 when sliders in middle

    setEstimateRangeString(`${numberAsAbbreviatedPrice(priceEstimateLow)} - ${numberAsAbbreviatedPrice(priceEstimateHigh)}`);
    setEstimatePerSqftRangeString(`$${(priceEstimateLow / propertyData.squareFootage).toFixed(0)} - $${(priceEstimateHigh / propertyData.squareFootage).toFixed(0)}`);
  }, [propertyData, averagePrice, averagePricePerSqft, propertyConditionSliderValue, hotMarketSliderValue]);

  // const [consentFormModalOpen, setConsentFormModalOpen] = React.useState(!isConsentGiven);
  const [consentFormModalOpen, setConsentFormModalOpen] = React.useState(false); // Not used anymore
  const [priceTooLowModalOpen, setPriceTooLowModalOpen] = React.useState(false); // Not used anymore
  const [sellForThisPriceModalOpen, setSellForThisPriceModalOpen] = React.useState(false); // Not used anymore

  const onDownloadReport = async () => {
    try {
      setIsDownloadingReport(true);

      const mapExistingWidth = mapRef.current.style.width;
      const mapExistingHeight = mapRef.current.style.height;
      const tableExistingWidth = tableRef.current.style.width;
      const tableExistingHeight = tableRef.current.style.height;

      mapRef.current.style.width = '1340px';
      mapRef.current.style.height = '654px';
      tableRef.current.style.width = '1342px';
      tableRef.current.style.height = '440px';

      // To get the map to update to the new size if on mobile
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const homeEstimateMapImage = (await html2canvas(mapRef.current, {
        useCORS: true, windowWidth: 1340, windowHeight: 654,
      })).toDataURL('image/png');

      const similarHomesTableImage = (await html2canvas(tableRef.current, {
        // useCORS: true, windowWidth: 1342, windowHeight: 440, width: 1342, height: 440,
        useCORS: true, windowWidth: 1342, windowHeight: 440,
      })).toDataURL('image/png');

      mapRef.current.style.width = mapExistingWidth;
      mapRef.current.style.height = mapExistingHeight;
      tableRef.current.style.width = tableExistingWidth;
      tableRef.current.style.height = tableExistingHeight;

      const { pdf } = await downloadReportAgentLanding(agentLandingPageToken, homeEstimateMapImage, similarHomesTableImage, eventId);
      openFileInNewWindow(pdf, setCurrentAlert);
    } catch (err) {
      console.error('error', err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    setIsDownloadingReport(false);
  };

  // If any of the properties are missing # beds/bath info, then this will be set to true
  const hasAnyZeroBedsOrBaths = React.useMemo(() => {
    if (!propertyData || !similarHomes) return false;

    return [propertyData, ...similarHomes].reduce((acc, property) => acc || property.numBeds === 0 || property.numBaths === 0, false);
  },
  [propertyData, similarHomes]);

  return (
    <>
      {consentFormModalOpen && (
        <ConsentForm
          onClose={() => { setConsentFormModalOpen(false); setIsConsentGiven(true); }}
          agentLandingPageToken={agentLandingPageToken}
          comparablesLoaded={propertyData && similarHomes}
        />
      )}

      {priceTooLowModalOpen && (
        <PriceTooLowForm
          onClose={() => setPriceTooLowModalOpen(false)}
          agentLandingPageToken={agentLandingPageToken}
        />
      )}

      {sellForThisPriceModalOpen && (
        <SellForThisPriceForm
          onClose={() => setSellForThisPriceModalOpen(false)}
          agentLandingPageToken={agentLandingPageToken}
        />
      )}

      <div className={classes.estimateSection}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.estimateCard}>
            {!consentFormModalOpen && propertyData ? (
              <div ref={mapRef}>
                <HomeEstimationMap
                  center={propertyData.address.coordinates}
                  similarHomes={similarHomes}
                />
                <div className={classes.estimateCardBottom}>
                  <div className={classes.estimateCardValue}>
                    <div>Your Estimated Property Value</div>
                    <h1>{estimateRangeString}</h1>
                    <div>
                      {/* Hide # beds/baths if data is missing */}
                      {!hasAnyZeroBedsOrBaths ? `${propertyData.numBeds} Bedrooms, ${propertyData.numBaths} Baths, ` : ''}

                      {/* Hide square footage data if its missing */}
                      {propertyData.squareFootage ? `${numberWithCommas(propertyData.squareFootage)} Sq Ft, ` : ''}

                      {`${numberWithCommas(propertyData.lotSquareFootage)} Sq Ft Lot`}
                    </div>
                    <div>{stringifyAddressObject(propertyData.address)}</div>
                  </div>
                  <div className={classes.estimateCardBottomOptions}>
                    <EstimationSlider
                      description="How's your Property Condition?"
                      value={propertyConditionSliderValue}
                      setValue={setPropertyConditionSliderValue}
                      valueNames={['Poor', 'Average', 'Good']}
                    />
                    <div className={classes.estimateCardBottomOptionsSpacer} />
                    <EstimationSlider
                      description="Are You in a Hot Market?"
                      value={hotMarketSliderValue}
                      setValue={setHotMarketSliderValue}
                      valueNames={['No', 'Kind of', 'Yes!']}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.mapLoading}>
                <LoadingIndicator />
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
      {/* <ButtonBase onClick={async () => {
        // Used for debugging html2canvas
        // const img = await html2canvas(mapRef.current, { useCORS: true, windowWidth: 1340, windowHeight: 654 });
        const img = await html2canvas(tableRef.current, { useCORS: true, windowWidth: 1342, windowHeight: 440 });

        tableRef.current.parentNode.appendChild(img);
      }}
      >
        kekw
      </ButtonBase> */}
      <div className={classes.homeActivitySection}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.homeActivityContainer}>
            <>
              <div className={classes.homeActivityHeaderContainer}>
                <div className={classes.homeActivityHeader}>
                  <h3>See Other Activity in Your Neighborhood</h3>
                </div>
                <div className={classes.homeActivityComparisonInfo}>
                  {!consentFormModalOpen && propertyData && similarHomes && (
                    <>
                      <div className={classes.homeActivityComparisonInfoProperty}>
                        Properties Compared:
                      </div>
                      <div className={classes.homeActivityComparisonInfoValue}>
                        {similarHomes.length}
                      </div>

                      <div className={classes.homeActivityComparisonInfoProperty}>
                        Price Estimate:
                      </div>
                      <div className={classes.homeActivityComparisonInfoValue}>
                        {estimateRangeString}
                      </div>

                      {propertyData?.squareFootage ? (
                        <>
                          <div className={classes.homeActivityComparisonInfoProperty}>
                            $/SqFt Estimate:
                          </div>
                          <div className={classes.homeActivityComparisonInfoValue}>
                            {estimatePerSqftRangeString}
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
                {!consentFormModalOpen && propertyData && similarHomes && (
                  <div className={classes.homeActivityGetReport}>
                    Get the Report
                    <br />
                    <ButtonBase onClick={onDownloadReport} />
                  </div>
                )}
              </div>
              {!consentFormModalOpen && propertyData && similarHomes ? (
                <div className={classes.homeActivityTableContainer} ref={tableRef}>
                  <SimilarHomesTable
                    similarHomes={similarHomes}
                    hasAnyZeroBedsOrBaths={hasAnyZeroBedsOrBaths}
                    hasSqFt={!!propertyData.squareFootage}
                  />
                </div>
              ) : (
                <div className={classes.tableLoading}>
                  <LoadingIndicator />
                </div>
              )}
            </>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.mostLikelySection}>
        {/* <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.mostLikelyContainer}>
            <h2>At this price you are most likely to...</h2>
            <div>
              <Button
                color="primary"
                round
                size={!isMobileWidth ? 'lg' : null}
                fontSizeOverride={!isMobileWidth ? 21 : null}
                onClick={() => setSellForThisPriceModalOpen(true)}
              >
                Sell For This Price?
              </Button>
              <Hidden smDown>
                <span className={classes.mostLikelyButtonSpacer} />
              </Hidden>
              <Hidden mdUp>
                <br />
              </Hidden>
              <Button
                color="orange"
                round
                size={!isMobileWidth ? 'lg' : null}
                fontSizeOverride={!isMobileWidth ? 21 : null}
                onClick={() => setPriceTooLowModalOpen(true)}
              >
                Price Too Low?
              </Button>
            </div>
          </GridItem>
        </GridContainer> */}
      </div>
    </>
  );
};

export default HomeEstimation;
