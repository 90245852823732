import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordVisibility = ({ showPassword, toggleShowPassword, className }) => (
  <ButtonBase disableRipple disableTouchRipple onClick={toggleShowPassword} tabIndex="-1">
    { showPassword
      ? <VisibilityOff className={className} />
      : <Visibility className={className} />}
  </ButtonBase>
);

export default PasswordVisibility;
