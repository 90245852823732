import React from 'react';

import { SvgIcon } from '@material-ui/core';

const BillingIcon = ({ className }) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19 4.7522C20.6569 4.7522 22 6.09534 22 7.7522V17.7522C22 19.4091 20.6569 20.7522 19 20.7522H5C3.34315 20.7522 2 19.4091 2 17.7522V7.7522C2 6.09534 3.34315 4.7522 5 4.7522H19ZM4 12.7522V17.7522C4 18.3045 4.44772 18.7522 5 18.7522H19C19.5523 18.7522 20 18.3045 20 17.7522V12.7522H4ZM4 8.7522H20V7.7522C20 7.19991 19.5523 6.7522 19 6.7522H5C4.44772 6.7522 4 7.19991 4 7.7522V8.7522Z" fill="inherit" />
  </SvgIcon>
);

export default BillingIcon;
