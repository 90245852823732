import { useMyBrokerage } from 'data/brokerage';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { assignAgentBranches } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: 'center',
    margin: '30px 0 0',
  },
  text: {
    fontWeight: 400,
    marginBottom: '20px',
  },
}));

const AssignAgentBranchesModal = ({ agentIds, onClose }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useMyBrokerage();

  if (isLoadingBrokerage) return (<LoadingIndicator />);

  return (
    <Formik
      initialValues={{
        brokerageBranchId: '',
      }}
      onSubmit={async ({ brokerageBranchId }) => {
        try {
          const { user } = await assignAgentBranches(brokerageBranchId, { agentIds });

          mutateBrokerage();

          onClose();

          setCurrentAlert('success', 'The agents\' branch assignments have been updated!');
        } catch (err) {
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        brokerageBranchId: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue,
        } = props;

        return (
          <Form>
            <Dialog
              open
              scroll="body"
              keepMounted
              maxWidth="sm"
              onClose={onClose}
            >
              <SimpleModalHeader onClose={onClose} title="Assign Agent(s) Branches" />

              {isSubmitting ? <LoadingIndicator modal /> : null}

              <h4 className={classes.text}>Agents' marketing materials for their campaign touches will use the marketing profile of whichever branch they are assigned to in your brokerage.</h4>
              <Field
                name="brokerageBranchId"
              >
                {({ field }) => (
                  <CustomSelect
                    labelText="Choose branch"
                    labelBold
                    items={brokerage.branches.map(({ _id, name }) => ({ value: _id, name }))}
                    inputProps={{
                      ...field,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>

              <div className={classes.buttonContainer}>
                <Button
                  round
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  {`Assign ${agentIds.length} agent(s) branches`}
                </Button>
              </div>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignAgentBranchesModal;
