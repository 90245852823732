import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  footer: {
    bottom: '0',
    zIndex: 4,
    padding: '72px 0',

    color: theme.palette.white.main,
    backgroundColor: theme.palette.gray.main,

    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
    },
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',

    '& a': {
      color: '#F1FAEE',
      padding: 0,
      fontWeight: '400',
    },
    '& a:hover,a:focus': {
      color: theme.palette.orange.main,
    },
  },
  logo: {
    width: '173px',
    marginTop: '-11px',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  businessInfoContainer: {
    marginTop: '20px',

    [theme.breakpoints.up('lg')]: {
      marginLeft: '28px',
    },

    [theme.breakpoints.only('md')]: {
      marginBottom: '40px',
    },

    '& p': {
      margin: '0 0 26px',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
  },
  copyrightText: {
    marginTop: '25px',
    color: theme.palette.white.main,
    opacity: '0.5',
    fontSize: '14px',
    lineHeight: '20px',
  },
  navLink: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  listTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 0',
    },
  },
  list: {
    padding: 0,
    margin: '16px 0 0',
    listStyle: 'none',
    color: theme.palette.white.main,
  },
  listIcon: {
    color: theme.palette.primary.main,
  },
  listItem: {
    padding: 0,
    margin: 0,

    '&:not(:last-child)': {
      marginBottom: '6px',
    },
  },
  newsletterForm: {
    marginTop: '12px',

    [theme.breakpoints.only('md')]: {
      marginBottom: '100px',
    },
  },
});

export default style;
