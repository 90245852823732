import React from 'react';
import { Field, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { agentLandingPageTypeEnums, enumToReadableName, getAgentLandingPageSampleUrlFromType } from 'utils/lib';

import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const useStyles = makeStyles((theme) => ({
  ...customCheckboxRadioSwitchStyles(theme),
  root: {
    '& a': {
      color: theme.palette.orange.main,
      textDecoration: 'underline',
    },
    '& label': {
      marginRight: '40px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.darkGray.main,
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.gray.main,
    fontWeight: 500,
    margin: '6px 0 10px',
  },
}));

const ChooseAgentLandingPageType = () => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  return (
    <Field name="agentLandingPageType">
      {({ field }) => (
        <div className={classes.root}>
          <h4 className={classes.title}>
            Agent Landing Page Type
          </h4>
          <h5 className={classes.subTitle}>
            {'We print a QR code on letters and postcards that links to a landing page with your profile on it. There are two types of landing pages: '}
            <a href={getAgentLandingPageSampleUrlFromType('COMPARABLE_PROPERTIES', null)} target="_blank" rel="noreferrer">one with comparable properties</a>
            {' and '}
            <a href={getAgentLandingPageSampleUrlFromType('CONTACT_FORM', null)} target="_blank" rel="noreferrer">one with only a contact form</a>
            . You may choose between the two here.
          </h5>
          {agentLandingPageTypeEnums.map((type) => (
            <FormControlLabel
              key={type}
              control={(
                <Radio
                  {...field}
                  checked={field.value === type}
                  onChange={() => setFieldValue(field.name, type, true)}
                  icon={(
                    <FiberManualRecordIcon
                      className={classes.radioUnchecked}
                    />
                  )}
                  checkedIcon={(
                    <FiberManualRecordIcon
                      className={classes.radioChecked}
                    />
                  )}
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              )}
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={enumToReadableName(type)}
            />
          ))}
        </div>
      )}
    </Field>
  );
};

export default ChooseAgentLandingPageType;
