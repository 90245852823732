import React from 'react';
import cx from 'classnames';
import { useFormikContext } from 'formik';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.orange.main,
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  buttonSmall: {
    padding: '8px 12px',
  },
  buttonLarge: {
    padding: '12px 20px',
    fontSize: '16px',
    lineHeight: '20px',
  },
  hidden: {
    visibility: 'hidden',
  },
  saved: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.success.main,
    '& > svg': {
      marginLeft: '6px',
      width: '16px',
      height: '16px',
    },
  },
}));

enum EButtonState {
  Hidden = 0,
  Save = 1,
  Loading = 2,
  Saved = 3,
}

export enum ESaveButtonFormikStatus {
  None = 0,
  Error = 1,
}

type SaveButtonProps = {
  onSave?: () => {};
  actionText?: string;
  savedText?: string;
  size?: 'sm' | 'lg'
};

/**
 * Must be used in a Formik form.
 *
 * If an error is thrown in Formik's onSubmit, the Formik status must be set to ESaveButtonFormikStatus.Error
 */
const SaveButton = ({
  onSave, actionText = 'Save', savedText = 'Saved', size = 'lg',
}: SaveButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    dirty, isValid, isSubmitting, handleSubmit, status, setStatus,
  } = useFormikContext();

  const [buttonState, setButtonState] = React.useState(EButtonState.Hidden);

  React.useEffect(() => {
    if (dirty) {
      if (isValid) {
        setButtonState(EButtonState.Save);
      } else {
        // If it goes from valid -> invalid, hide the save button
        setButtonState(EButtonState.Hidden);
      }
    } else {
      // If it goes from dirty -> not dirty, hide the save button
      setButtonState(EButtonState.Hidden);
    }
  }, [dirty, isValid]);

  React.useEffect(() => {
    // Check here in case they dirty the form again while its saving
    if (!isSubmitting && buttonState === EButtonState.Loading && status !== ESaveButtonFormikStatus.Error) {
      setButtonState(EButtonState.Saved);

      if (onSave) onSave();
    } else if (status === ESaveButtonFormikStatus.Error) {
      // Show Save button again for the user to retry
      setButtonState(EButtonState.Save);
    }
  }, [isSubmitting, status]);

  const onClick = () => {
    setStatus(ESaveButtonFormikStatus.None);
    setButtonState(EButtonState.Loading);
    handleSubmit();
  };

  let buttonContent;
  if (buttonState === EButtonState.Hidden || buttonState === EButtonState.Save) {
    buttonContent = actionText;
  } else if (buttonState === EButtonState.Loading) {
    buttonContent = (<LoadingIndicator size={16} thickness={3.6} color={theme.palette.white.main} />);
  } else if (buttonState === EButtonState.Saved) {
    buttonContent = (
      <>
        {savedText}
        <CheckRoundedIcon htmlColor={theme.palette.white.main} />
      </>
    );
  }

  return (
    <ButtonBase
      onClick={onClick}
      className={cx(
        classes.button,
        size === 'sm' ? classes.buttonSmall : classes.buttonLarge,
        buttonState === EButtonState.Hidden && classes.hidden,
        buttonState === EButtonState.Saved && classes.saved,
      )}
      disabled={buttonState !== EButtonState.Save}
    >
      {buttonContent}
    </ButtonBase>
  );
};

export default SaveButton;
