import React from 'react';

import { getUserInfo } from 'utils/api';

interface UserInfoContextType {
  userInfo: any;
  reloadUserInfo: () => void;
}

export const UserInfoContext = React.createContext<UserInfoContextType | undefined>(undefined);

const UserInfoProvider = (props: any) => {
  const [refresh, setRefresh] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState<any>(null);

  React.useEffect(() => {
    (async () => {
      if (!refresh) return;

      try {
        const { user } = await getUserInfo();

        setUserInfo(user);
        setRefresh(false);
      } catch (err) {
        setUserInfo(null);
        setRefresh(false);

        console.error(err);
      }
    })();
  }, [refresh]);

  const userInfoProviderValue = React.useMemo(() => ({
    // TODO: Removed this check here to prevent flicker on marketing page when refreshing. Don't really need this as userInfo is null initially?
    //       We can add a loading state in the future if we find that we need it.
    // userInfo: refresh ? null : userInfo, // Show null while refreshing. Used for loading effects.

    userInfo,
    reloadUserInfo: () => setRefresh(true),
  }), [userInfo, refresh]);

  return <UserInfoContext.Provider value={userInfoProviderValue} {...props} />;
};

export const useUserInfoContext = () => {
  const context = React.useContext(UserInfoContext);
  if (!context) {
    throw new Error('useUser must be used within a UserInfoProvider');
  }
  return context;
};

export const withUserInfoProvider = (Component: React.FC) => ({ ...props }) => (
  <UserInfoProvider>
    <Component {...props} />
  </UserInfoProvider>
);

export default UserInfoProvider;
