import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

const useStyles = makeStyles(styles as any);

type Props = {
  title?: string;
  message: React.ReactNode | string;
  onClose: () => void;
  extraButtons?: React.ReactNode;
};

const AcknowledgeModal = ({
  title, message, onClose, extraButtons,
}: Props) => {
  const classes = useStyles();
  // Maintain internal error message to prevent flicker bug when closing
  const [m, setM] = React.useState<React.ReactNode | string>('');

  if (m !== message && message !== '') setM(message);

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={message !== ''}
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      {title && (
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
      )}
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h5>{m}</h5>
      </DialogContent>
      <DialogActions
        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
      >
        {extraButtons}
        <Button onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AcknowledgeModal.propTypes = {
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AcknowledgeModal;
