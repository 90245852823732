import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { modalCloseButton } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
  modalCloseButton,
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '48px',
    color: theme.palette.grayScale11.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  titleSmall: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  line: {
    margin: '13px auto 15px',
    width: '100%',
    maxWidth: '163px',
    border: '1px solid rgba(0, 0, 0, 0.44)',
  },
}));

const SimpleModalHeader = ({ onClose, title, small = false }) => {
  const classes = useStyles();

  return (
    <>
      <ButtonBase
        className={classes.modalCloseButton}
        aria-label="Close"
        onClick={onClose}
      >
        <CloseIcon />
      </ButtonBase>

      <div className={cx(classes.title, small && classes.titleSmall)}>{title}</div>
      <div className={classes.line} />
    </>
  );
};

export default SimpleModalHeader;
