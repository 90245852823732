import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import AddressForm, { addressYupValidation, initialAddress } from 'components/AddressAutocomplete/AddressForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    margin: '47px 0 0',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
  brokerageAgentInfo: {
    margin: '0 auto 40px',
  },
}));

const CompanyDataForm = (props, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();
  const { isBrokerageAdmin, isBrokerageAgent } = useAuthDataContext();

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        brokerageDreNumber: userInfo.agentInfo.brokerageDreNumber || '',
        teamName: userInfo.agentInfo.teamName || '',
        companyName: userInfo.agentInfo.companyName || '',
        companyAddress: userInfo.agentInfo.companyAddress || { ...initialAddress },
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const {
          brokerageDreNumber, teamName, companyName, companyAddress,
        } = values;

        try {
          await updateUser({
            agentInfo: {
              brokerageDreNumber: !isBrokerageAgent ? brokerageDreNumber : undefined,
              teamName,
              companyName: !isBrokerageAgent ? companyName : undefined,
              companyAddress: !isBrokerageAgent ? companyAddress : undefined,
            },
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        brokerageDreNumber: !isBrokerageAgent ? Yup.string().required() : null,
        teamName: Yup.string(),
        companyName: !isBrokerageAgent ? Yup.string().required() : null,
        companyAddress: !isBrokerageAgent ? addressYupValidation.required() : null,
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        return (
          <Form>
            <FormikOnError>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={10} lg={9} xl={7}>
                  <GridContainer justifyContent="center" className={classes.topMargin}>
                    {isBrokerageAgent && (
                      <DashboardBubble
                        rootClass={classes.brokerageAgentInfo}
                        backgroundColor={theme.palette.primary.light}
                        icon={(<BusinessRoundedIcon htmlColor={theme.palette.orange.main} />)}
                        content={(
                          <>
                            Your account is part of a brokerage. Some of the fields here can only be changed by your brokerage admin.
                          </>
                        )}
                      />
                    )}

                    <GridItem xs={12} md={6}>
                      <Field
                        name="brokerageDreNumber"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Your Brokerage License Number"
                            inputProps={{
                              ...field,
                              placeholder: '#',
                              disabled: isBrokerageAgent,
                            }}
                            error={errors[field.name] !== undefined} // error={touched[field.name] && errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Field
                        name="teamName"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Team Name (Optional)"
                            inputProps={{
                              ...field,
                            }}
                            error={errors[field.name] !== undefined} // error={touched[field.name] && errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12}>
                      <Field
                        name="companyName"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Company Name"
                            inputProps={{
                              ...field,
                              disabled: isBrokerageAgent,
                            }}
                            error={errors[field.name] !== undefined} // error={touched[field.name] && errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <AddressForm
                      classes={classes}
                      valueName="companyAddress"
                      addressDisplayName="Company Address"
                      initialValue={values.companyAddress.address1}
                      showErrorsWhen="always"
                      disabled={isBrokerageAgent}
                    />

                    <GridItem xs={12}>
                      <div className={classes.saveButtonContainer}>
                        <SaveButton onSave={reloadUserInfo} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(CompanyDataForm);
