import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import log from 'loglevel';
import randomColor from 'randomcolor';
import React from 'react';
import simplepolygon from 'simplepolygon';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import GestureIcon from '@material-ui/icons/Gesture';
import ReplayIcon from '@material-ui/icons/Replay';

import Button from 'creative-components/CustomButtons/Button';

import AcknowledgeModal from 'components/AcknowledgeModal/AcknowledgeModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import FilterIcon from 'components/CustomIcons/FilterIcon';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import { useMapInfoContext } from 'components/DataMap/MapInfoProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import MultiZipcodesInput from 'components/MultiZipcodesInput/MultiZipcodesInput';

import { getDynamicDataCount } from 'utils/api';
import { numberWithCommas, supportEmail } from 'utils/lib';

const getPolygonPathCoords = (polygon) => {
  const polygonBounds = polygon.getPath();
  const bounds = [];
  for (let i = 0; i < polygonBounds.length; i++) {
    const point = {
      lat: polygonBounds.getAt(i).lat(),
      lng: polygonBounds.getAt(i).lng(),
    };
    bounds.push(point);
  }
  return bounds;
};

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      maxWidth: '140px',
      marginLeft: '12px',
      '& svg': {
        marginRight: '12px',
      },
    },
  },
  inputRow: {
    margin: '0 0 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  drawButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white.main,
    borderRadius: '8px',
    padding: '12px',
    marginRight: '12px',
    marginBottom: '3px',

    '&:disabled': {
      backgroundColor: `rgb(${hexToRgb(theme.palette.success.main)}, 0.5)`,
      // backgroundColor: theme.palette.offWhite.main,
      // borderColor: theme.palette.primary.main,
      // border: '3px solid',
    },
  },
  generateCostsButton: {
    marginLeft: '12px',
    marginBottom: '3px',

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: '12px',
    },
  },
}));

const defaultPolygonOptions = {
  clickable: false,
  draggable: false,
  editable: true,
  zIndex: 100,
};

const addPolygonEditListeners = (polygon, setCanRefetchDataCounts) => {
  polygon.getPath().addListener('insert_at', () => setCanRefetchDataCounts(true));
  polygon.getPath().addListener('set_at', () => setCanRefetchDataCounts(true));
  polygon.getPath().addListener('remove_at', () => setCanRefetchDataCounts(true));
  polygon.getPath().addListener('dragend', () => setCanRefetchDataCounts(true));
};

const DataSelectionOverlay = React.forwardRef(({
  apnSelectedCounts,
  setApnSelectedCounts,
  setApnAvailableCounts,
  setApnsTotalSelectedCount,
  setApnsTotalInAreaCount,
  filtersModalOptions,
  disableFilters,
  setSelectedZipcodesCallback,
  isDrawingShape,
  setIsDrawingShape,
  initSelectionPolygonPaths,
  initSelectedZipcodes,
  inputLabelText,
  maxNumberOfZipcodes,
  enableShapeDrawing,
  generateCostsButtonText,
  onFetchDataCountCustom, // Override function for generate costs button
}, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setCurrentAlert } = useAlertContext();
  const {
    selectedEventType, appliedFilters, setIsFiltersModalOpen, setFiltersModalOptions,
  } = useDataFilterContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const { mapInstance } = useMapInfoContext();

  const [canRefetchDataCounts, setCanRefetchDataCounts] = React.useState(false); // Triggered by user, used to do the global search count

  // References to the google maps polygons and colors
  const [selectionPolygons, setSelectionPolygons] = React.useState([]);
  const [selectionPolygonColors, setSelectionPolygonColors] = React.useState([]);

  const [selectedZipcodes, setSelectedZipcodes] = React.useState([]);

  const [showFetchCountsErrorModal, setShowFetchCountsErrorModal] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    getSelectedZipcodes() {
      return selectedZipcodes;
    },
    getSelectionPolygonPaths() {
      return selectionPolygons.map((polygon) => getPolygonPathCoords(polygon));
    },
    areDataCountsUpToDate() {
      // In case a user changes their selection and does something like tries to
      // create a campaign with refetching
      return !canRefetchDataCounts;
    },
  }));

  React.useEffect(() => {
    setFiltersModalOptions(filtersModalOptions);
  }, []);

  React.useEffect(() => {
    if (selectionPolygons.length > 0 || selectedZipcodes.length > 0) {
      setCanRefetchDataCounts(true);
    } else {
      setCanRefetchDataCounts(false);
    }
  }, [appliedFilters, selectionPolygons, selectedZipcodes]);

  const onSelectZipcodes = (selectedItems) => {
    // selectedItems here contains both zip codes and polygons because the zip codes input
    // has chips for shapes too
    const zipcodes = selectedItems.filter((o) => !o.includes('Shape'));
    const shapes = selectedItems.filter((o) => o.includes('Shape'));

    log.debug('zipcodes is ', zipcodes);
    log.debug('shapes is ', shapes);

    setSelectedZipcodes(zipcodes);
    setSelectedZipcodesCallback(zipcodes);

    if (shapes.length < selectionPolygons.length) {
      // A shape was removed. Figure out which one and remove the polygon too
      const existingPolygons = [];
      const existingPolygonColors = [];

      for (let i = 0; i < selectionPolygons.length; i++) {
        if (shapes.includes(`Shape ${i + 1}`)) {
          existingPolygons.push(selectionPolygons[i]);
          existingPolygonColors.push(selectionPolygonColors[i]);
        } else {
          // Delete this polygon
          selectionPolygons[i].setMap(null);
        }
      }

      setSelectionPolygons(existingPolygons);
      setSelectionPolygonColors(existingPolygonColors);
    }
  };

  React.useEffect(() => {
    if (!mapInstance) return;

    // This "selects" the initial zipcodes correctly
    if (initSelectedZipcodes && initSelectedZipcodes.length > 0) {
      onSelectZipcodes(initSelectedZipcodes);
    }

    // Must do this after the onSelectZipcodes because of the removing shape logic in that function
    if (initSelectionPolygonPaths && initSelectionPolygonPaths.length > 0) {
      const polygons = [];
      const polygonPaths = [];
      const polygonColors = [];

      initSelectionPolygonPaths.forEach((coordinates) => {
        const polygonPath = coordinates[0].map(([lng, lat]) => ({ lat, lng }));

        const color = randomColor({ luminosity: 'dark' });
        polygonColors.push(color);

        const polygon = new window.google.maps.Polygon({
          ...defaultPolygonOptions,
          map: mapInstance,
          path: polygonPath,
          strokeColor: color,
          strokeOpacity: 1,
          fillColor: color,
          fillOpacity: 0.2,
        });

        // Listeners for detecting geometry changes
        addPolygonEditListeners(polygon, setCanRefetchDataCounts);

        polygons.push(polygon);
        polygonPaths.push(polygonPath);
      });

      setSelectionPolygons(polygons);
      setSelectionPolygonColors(polygonColors);

      // Pan to one of the polygons
      mapInstance.panTo({
        lat: polygonPaths[0][0].lat,
        lng: polygonPaths[0][0].lng,
      });
    }
  }, [mapInstance]);

  const onFetchDataCount = async () => {
    showLoadingIndicatorModal();

    try {
      // Make sure they have some sort of selection (zip code or polygon)
      if (!selectedEventType || (!selectionPolygons && selectedZipcodes.length === 0)) return;

      log.debug('selectedZipcodes', selectedZipcodes);
      log.debug('selectionPolygons', selectionPolygons);

      // Keep the add on properties count if it exists
      const newApnSelectedCounts = [...apnSelectedCounts.filter(({ name }) => name === 'Add On Properties')];
      const addOnPropertiesCount = newApnSelectedCounts.length > 0 ? newApnSelectedCounts[0].count : 0;
      let newApnsTotalSelectedCount = addOnPropertiesCount;
      let newApnsTotalInAreaCount = addOnPropertiesCount;

      if (selectionPolygons.length > 0) {
        const polygonPaths = selectionPolygons.map((polygon) => getPolygonPathCoords(polygon));

        const { availableCount, totalCount } = await getDynamicDataCount(selectedEventType._id, appliedFilters, polygonPaths, undefined);
        newApnSelectedCounts.push({ name: 'Custom Shapes', count: availableCount });
        newApnsTotalSelectedCount += availableCount;
        newApnsTotalInAreaCount += totalCount;
      }

      await Promise.all(selectedZipcodes.map(async (zipcode) => {
        const { availableCount, totalCount } = await getDynamicDataCount(selectedEventType._id, appliedFilters, undefined, zipcode);
        newApnSelectedCounts.push({ name: zipcode, count: availableCount });
        newApnsTotalSelectedCount += availableCount;
        newApnsTotalInAreaCount += totalCount;
      }));

      setApnAvailableCounts(newApnSelectedCounts);
      setApnSelectedCounts(newApnSelectedCounts);
      setApnsTotalSelectedCount(newApnsTotalSelectedCount);
      setApnsTotalInAreaCount(newApnsTotalInAreaCount);

      // Count here includes the add on properties, so subtract it for the alert
      if (newApnsTotalSelectedCount < newApnsTotalInAreaCount) {
        setCurrentAlert('success', `We found ${numberWithCommas(newApnsTotalSelectedCount - addOnPropertiesCount)} available records out of ${numberWithCommas(newApnsTotalInAreaCount - addOnPropertiesCount)} total records in your selected farm area, some of which may be farmed by other users and be unavailable to you.`, 15000);
      } else {
        setCurrentAlert('success', `We found ${numberWithCommas(newApnsTotalSelectedCount - addOnPropertiesCount)} available records in your selected farm area.`, 15000);
      }
    } catch (err) {
      console.error(err);
      // showAPIErrorAlert(setCurrentAlert, err);

      setShowFetchCountsErrorModal(true);
    }

    hideLoadingIndicatorModal();
  };

  const resetMapSelection = () => {
    // Remove all polygons from the map
    selectionPolygons.forEach((polygon) => {
      polygon.setMap(null);
    });

    // Setting this will automatically run the useEffect hook below to update the selection data
    setSelectionPolygons([]);
    setSelectionPolygonColors([]);
    setApnSelectedCounts([]);
    setApnAvailableCounts([]);
    setApnsTotalSelectedCount(0);
    setApnsTotalInAreaCount(0);
    setCanRefetchDataCounts(false);

    onSelectZipcodes([]);

    log.debug('Reset button clicked!');
  };

  const handleDrawShape = () => {
    setIsDrawingShape(true);

    mapInstance.setOptions({
      draggableCursor: 'crosshair',
      draggable: false,
      zoomControl: false,
      disableDoubleClickZoom: false,
    });

    // Disable polygon editing so that you can't click on a vertex and mess up the drawing logic
    selectionPolygons.forEach((p) => {
      p.setEditable(false);
    });

    window.google.maps.event.addDomListener(mapInstance.getDiv(), 'mousedown', (e2) => {
      const poly = new window.google.maps.Polyline({ map: mapInstance, clickable: false });

      const mouseDelta = 7; // Mouse has to move by at least 5 pixels to add a new vertex
      let lastMouseX = null;
      let lastMouseY = null;
      // let lastMouseLatLng = null;
      const move = window.google.maps.event.addListener(mapInstance, 'mousemove', (e) => {
        // Only save this vertex if the mouse moved on the actual map by a certain
        // delta. This reduces the vertex count by a lot.
        if (lastMouseY && Math.abs(lastMouseX - e.domEvent.screenX) + Math.abs(lastMouseY - e.domEvent.screenY) < mouseDelta) return;

        // if (lastMouseLatLng && e.latLng.lat() === lastMouseLatLng.lat && e.latLng.lng() === lastMouseLatLng.lng) return;

        lastMouseX = e.domEvent.screenX;
        lastMouseY = e.domEvent.screenY;
        // lastMouseLatLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };

        poly.getPath().push(e.latLng);
      });

      const resetDrawing = () => {
        window.google.maps.event.clearListeners(mapInstance.getDiv(), 'mousedown');

        mapInstance.setOptions({
          draggableCursor: 'default',
          draggable: true,
          zoomControl: true,
          disableDoubleClickZoom: true,
        });

        setIsDrawingShape(false);
      };

      window.google.maps.event.addListenerOnce(mapInstance, 'mouseup', (e) => {
        window.google.maps.event.removeListener(move);
        const path = poly.getPath();
        poly.setMap(null);

        // Make sure we have enough points for a polygon
        if (path.length < 3) {
          resetDrawing();
          return;
        }

        const polygonPaths = [];

        const s = new Set();
        path.forEach((vertex) => {
          const vertexObj = { lat: vertex.lat(), lng: vertex.lng() };
          const vertexObjJson = JSON.stringify(vertexObj);

          if (s.has(vertexObjJson)) return;
          s.add(vertexObjJson);

          polygonPaths.push(vertexObj);
        });

        const polyGis = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [polygonPaths.map(({ lat, lng }) => ([lat, lng]))],
          },
        };
        const result = simplepolygon(polyGis);

        if (result.features.length > 1) {
          // Polygon had intersecting edges and had to be split up
          setCurrentAlert('warning', 'Some of the edges in your shape intersect. Your custom shape has been split into multiple shapes.');
        }

        const cleanPolygons = [];
        const cleanPolygonPaths = [];
        const cleanPolygonColors = [];

        result.features.forEach(({ geometry: { coordinates } }) => {
          const cleanPolygonPath = coordinates[0].map(([lat, lng]) => ({ lat, lng }));

          const color = randomColor({ luminosity: 'dark' });
          cleanPolygonColors.push(color);

          const cleanPolygon = new window.google.maps.Polygon({
            ...defaultPolygonOptions,
            map: mapInstance,
            path: cleanPolygonPath,
            strokeColor: color,
            strokeOpacity: 1,
            fillColor: color,
            fillOpacity: 0.2,
          });

          // Listeners for detecting geometry changes
          addPolygonEditListeners(cleanPolygon, setCanRefetchDataCounts);

          cleanPolygons.push(cleanPolygon);
          cleanPolygonPaths.push(cleanPolygonPath);
        });

        // Re-enable editing
        selectionPolygons.forEach((p) => {
          p.setEditable(true);
        });

        setSelectionPolygons([...selectionPolygons, ...cleanPolygons]);
        setSelectionPolygonColors([...selectionPolygonColors, ...cleanPolygonColors]);

        log.debug('nice add new', ...cleanPolygonPaths);

        resetDrawing();
      });
    });
  };

  return (
    <>
      {showFetchCountsErrorModal && (
        <AcknowledgeModal
          title="Whoa! We're popular right now!"
          message={(
            <>
              Our system is now under heavy usage. Please retry your query again. You might need to retry it 3 or 4 times for it to work. Or come back in a few hours! If you have any issues, please contact us at:
              {' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </>
            )}
          onClose={() => setShowFetchCountsErrorModal(false)}
        />
      )}

      {!disableFilters && (
        <div className={classes.buttonsContainer}>
          {/* {(selectedZipcodes.length > 0 || selectionPolygons.length > 0) && (
          <Button
            round
            color="white"
            textColorOverride="darkGray"
            onClick={resetMapSelection}
          >
            Clear Selections
          </Button>
        )} */}
          <Button
            round
            color="primary"
            onClick={() => setIsFiltersModalOpen(true, filtersModalOptions)}
          >
            <FilterIcon />
            Filters
          </Button>
        </div>
      )}

      <div className={classes.inputRow}>
        {enableShapeDrawing && (
          <div>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              title="Draw a custom shape"
              enterTouchDelay={0} // For mobile
              leaveTouchDelay={0}
            >
              <ButtonBase
                className={classes.drawButton}
                onClick={handleDrawShape}
                disabled={isDrawingShape || selectedZipcodes.length + selectionPolygons.length >= 6}
              >
                <GestureIcon />
              </ButtonBase>
            </Tooltip>
          </div>
        )}

        <MultiZipcodesInput
          labelText={inputLabelText}
          maxNumberOfZipcodes={maxNumberOfZipcodes}
          selectedZipcodeColors={[
            ...selectedZipcodes.map((o) => theme.palette.secondary.main),
            ...selectionPolygonColors,
          ]}
          selectedZipcodes={[
            ...selectedZipcodes,
            ...selectionPolygons.map((v, i) => `Shape ${i + 1}`),
          ]}
          setSelectedZipcodes={onSelectZipcodes}
        />
        {canRefetchDataCounts && (
          <Button
            className={classes.generateCostsButton}
            round
            color="orange"
            onClick={() => {
              if (onFetchDataCountCustom) {
                onFetchDataCountCustom(selectedZipcodes, selectionPolygons);
              } else {
                onFetchDataCount();
              }

              // Hide the fetch button
              setCanRefetchDataCounts(false);
            }}
          >
            <ReplayIcon />
            {generateCostsButtonText ?? 'Generate Costs'}
          </Button>
        )}
      </div>
    </>
  );
});

export default DataSelectionOverlay;
