import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import zipcodes from 'zipcodes';

import { makeStyles } from '@material-ui/core/styles';

import DataSelectionOverlay from 'components/DataMap/DataSelectionOverlay';
import { getMousePositionFromEvent, useMapInfoContext, withMapInfoProvider } from 'components/DataMap/MapInfoProvider';
import ZipcodeAndDataOverlay from 'components/DataMap/ZipcodeAndDataOverlay';
import MultiZipcodesInput from 'components/MultiZipcodesInput/MultiZipcodesInput';

import { corporateOfficeLocation } from 'utils/lib';

const externalPolygonOptions = {
  fillColor: '#000000',
  fillOpacity: 0.15,
  strokeColor: 'black',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 10,
};

const useStyles = makeStyles((theme) => ({
  form: {
    // height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',

    '& > div:first-child': { // Zipcodes input
      marginBottom: '20px',
    },
  },
  mapContainer: {
    // height: '500px',
    // height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    overflow: 'hidden',
  },
}));

const mapContainerStyle = {
  // height: '100%',
  flex: 1,
};

const defaultMapOptions = {
  zoom: 12,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'cooperative', // Reference: https://developers.google.com/maps/documentation/javascript/interaction#controlling_gesture_handling
  draggableCursor: 'default',
  clickableIcons: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
  ],
  center: corporateOfficeLocation,
};

// TODO: When we convert to typescript, remove all these defaults to make this less confusing
const MapContainer = ({
  dataSelectionOverlayRef,
  highlightZipcodes,
  apnSelectedCounts = [],
  setApnSelectedCounts = () => {},
  setApnAvailableCounts = () => {},
  setApnsTotalSelectedCount = () => {},
  setApnsTotalInAreaCount = () => {},
  filtersModalOptions,
  disableFilters = false,
  clickToLoadZipcodeEventCounts = false,
  showOnlyHighlightedZipcodes = false,
  highlightEventData,
  highlightEventDataShowHoverPopup,
  externalPolygonsToRender,
  allowDataSelection = false,
  initSelectionPolygonPaths = [],
  initSelectedZipcodes = [],
  initPropertyFilters = null,
  inputLabelText = 'Draw shapes or enter zip codes for your farm area',
  maxNumberOfZipcodes = 6,
  enableShapeDrawing = true,
  generateCostsButtonText = undefined,
  onFetchDataCountCustom = undefined,
}) => {
  const classes = useStyles();
  const {
    mapInstance, setMapInstance, setMapBounds, overlayViews,
  } = useMapInfoContext();

  const onLoad = React.useCallback((map) => {
    setMapInstance(map);
  });

  const mapOptions = React.useMemo(() => {
    let { center } = defaultMapOptions;

    // Pan to zip code if one was provided
    if (highlightZipcodes?.length) {
      const zipcodeInfo = zipcodes.lookup(highlightZipcodes[0]);
      center = {
        lat: zipcodeInfo.latitude,
        lng: zipcodeInfo.longitude,
      };
    }

    return {
      ...defaultMapOptions,
      center,
    };
  }, [defaultMapOptions, highlightZipcodes]);

  const [mousePosition, setMousePosition] = React.useState(null);
  const [isDrawingShape, setIsDrawingShape] = React.useState(false);
  const [combinedHighlightedZipcodes, setCombinedHighlightedZipcodes] = React.useState(highlightZipcodes);

  React.useEffect(() => {
    setCombinedHighlightedZipcodes(highlightZipcodes);
  }, [highlightZipcodes]);

  // Render external polygons
  React.useEffect(() => {
    if (!externalPolygonsToRender) return;

    const arr = [];
    externalPolygonsToRender.coordinates.forEach((polygon) => {
      const mapPolygon = new window.google.maps.Polygon({
        paths: polygon[0].map((coordinate) => ({ lng: coordinate[0], lat: coordinate[1] })),
        ...externalPolygonOptions,
      });

      mapPolygon.setMap(mapInstance);
      arr.push(mapPolygon);
    });
  }, []);

  const onDataOverlaySetSelectedZipcodes = (selectedZipcodes) => {
    const newCombined = [
      ...highlightZipcodes || [],
      ...selectedZipcodes,
    ];

    setCombinedHighlightedZipcodes(newCombined);

    // Center the map at the last highlighted zipcode
    if (newCombined.length > 0) {
      const zipcodeInfo = zipcodes.lookup(newCombined[newCombined.length - 1]);

      mapInstance.panTo({
        lat: zipcodeInfo.latitude,
        lng: zipcodeInfo.longitude,
      });
    }
  };

  const zipcodeAndDataOverlay = React.useMemo(() => (
    <ZipcodeAndDataOverlay
      // highlightZipcodes={highlightZipcodes}
      highlightZipcodes={combinedHighlightedZipcodes}
      clickToLoadZipcodeEventCounts={clickToLoadZipcodeEventCounts}
      showOnlyHighlightedZipcodes={showOnlyHighlightedZipcodes}
      highlightEventData={highlightEventData}
      highlightEventDataShowHoverPopup={highlightEventDataShowHoverPopup}
      isDrawingShape={isDrawingShape}
      initPropertyFilters={initPropertyFilters}
    />
  ), [mapInstance, combinedHighlightedZipcodes, highlightEventData, isDrawingShape]);

  const onIdle = () => {
    if (!mapInstance) return;
    setMapBounds(mapInstance.getBounds());
  };

  const onMouseMove = (e) => {
    setMousePosition(getMousePositionFromEvent(e));
  };

  return (
    <>
      {allowDataSelection && (
        <DataSelectionOverlay
          ref={dataSelectionOverlayRef}
          apnSelectedCounts={apnSelectedCounts}
          setApnSelectedCounts={setApnSelectedCounts}
          setApnAvailableCounts={setApnAvailableCounts}
          setApnsTotalSelectedCount={setApnsTotalSelectedCount}
          setApnsTotalInAreaCount={setApnsTotalInAreaCount}
          filtersModalOptions={filtersModalOptions}
          disableFilters={disableFilters}
          setSelectedZipcodesCallback={onDataOverlaySetSelectedZipcodes}
          isDrawingShape={isDrawingShape}
          setIsDrawingShape={setIsDrawingShape}
          initSelectionPolygonPaths={initSelectionPolygonPaths}
          initSelectedZipcodes={initSelectedZipcodes}
          inputLabelText={inputLabelText}
          maxNumberOfZipcodes={maxNumberOfZipcodes}
          enableShapeDrawing={enableShapeDrawing}
          generateCostsButtonText={generateCostsButtonText}
          onFetchDataCountCustom={onFetchDataCountCustom}
        />
      )}
      <div className={classes.mapContainer}>
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={mapContainerStyle}
          options={mapOptions}
          onIdle={onIdle}
          onMouseMove={onMouseMove}
        >
          {zipcodeAndDataOverlay}
          {overlayViews.length > 0 && mousePosition && (
            <OverlayView
              position={{ ...mousePosition }}
              mapPaneName={OverlayView.MARKER_LAYER}
            >
              {overlayViews[overlayViews.length - 1].render}
            </OverlayView>
          )}
        </GoogleMap>
      </div>
    </>
  );
};

const DataMap = React.memo(({
  renderSearchZipcodesForm = false,
  zipcodesFormLabel,
  ...rest
}) => {
  const classes = useStyles();
  const { mapInstance } = useMapInfoContext();

  if (!renderSearchZipcodesForm) {
    return (
      <MapContainer
        {...rest}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        zipcodesToHighlight: [],
      }}
      onSubmit={() => {}}
    >
      {(props) => {
        const { touched, errors, values } = props;

        // Center the map at the last highlighted zipcode
        React.useEffect(() => {
          if (values.zipcodesToHighlight.length > 0) {
            const zipcodeInfo = zipcodes.lookup(values.zipcodesToHighlight[values.zipcodesToHighlight.length - 1]);

            mapInstance.panTo({
              lat: zipcodeInfo.latitude,
              lng: zipcodeInfo.longitude,
            });
          }
        }, [values.zipcodesToHighlight]);

        return (
          <Form className={classes.form}>
            <FieldArray
              name="zipcodesToHighlight"
              render={(arrayHelpers) => (
                <MultiZipcodesInput
                  fieldName="zipcodesToHighlight"
                  labelText={zipcodesFormLabel ?? 'Add Zip Codes to highlight on the map'}
                  arrayHelpers={arrayHelpers}
                  error={touched.zipcodesToHighlight && errors.zipcodesToHighlight !== undefined}
                />
              )}
            />
            <MapContainer
              {...rest}
              highlightZipcodes={values.zipcodesToHighlight}
            />
          </Form>
        );
      }}
    </Formik>
  );
});

export default withMapInfoProvider(DataMap);
