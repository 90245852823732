import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { Chip, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { createCustomTouchTemplate } from 'utils/api';
import { useMergeVariables } from 'data/touch-trigger';
import { showAPIErrorAlert } from 'utils/lib';
import { ETouchType } from 'types/touch-type';

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: '40px 0 0',
  },
  tabsHidden: {
    display: 'none',
  },
  instructions: {
    color: theme.palette.darkGray.main,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    margin: '30px 0 30px',

    '& > span': {
      color: theme.palette.lightGray.main,
    },
  },
  formControl: {
    '& > div:first-child': {
      fontWeight: 500,
    },
  },
  customBodyTextInput: {
    '& textarea > span': {
      color: 'red',
    },
  },
  availableMergeVariables: {
    '& > span': {
      margin: '30px 0 12px',
      display: 'flex',
      alignItems: 'center',
    },
    '& h4': { // Title
      display: 'inline-block',
      color: theme.palette.darkGray.main,
      fontFamily: 'Montserrat',
      fontWeight: 500,
      marginRight: '10px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  mergeVariableChip: {
    margin: '5px',
    fontWeight: 400,
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px',

    '& > div': {
      marginTop: '10px', // For better rendering when wrapping
    },
    '& > div > button:first-child': {
      marginRight: '20px',
    },
  },
}));

const EditBodyText = ({
  onClose, touchTrigger, onReceiveEditedTouchTrigger, setIsConfirmingReset,
}) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const { customTouchTemplate, touchType: { name: touchTypeName } } = touchTrigger;

  const existingCustomBodyText = customTouchTemplate && customTouchTemplate.extraMergeVariables.customBodyText;
  const existingCustomEmailSubject = customTouchTemplate && customTouchTemplate.extraMergeVariables.customEmailSubject;

  const { mergeVariables, isLoadingMergeVariables } = useMergeVariables(touchTrigger._id);

  const [defaultCustomBodyText, setDefaultCustomBodyText] = React.useState(undefined);

  React.useEffect(() => {
    (async () => {
      if (touchTrigger.metadata.defaultCustomBodyTextUrl) {
        const { data } = await axios.get(touchTrigger.metadata.defaultCustomBodyTextUrl);
        setDefaultCustomBodyText(data);
      } else {
        setDefaultCustomBodyText(null);
      }
    })();
  }, []);

  if (isLoadingMergeVariables || defaultCustomBodyText === undefined) {
    return (
      <div style={{ marginTop: '40px' }}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        customBodyText: existingCustomBodyText || defaultCustomBodyText || '',
        customEmailSubject: existingCustomEmailSubject || touchTrigger.metadata.defaultSubject,
      }}
      onSubmit={async ({ customBodyText, customEmailSubject }) => {
        // Show a loading indicator during the upload
        showLoadingIndicatorModal();

        try {
          const { touchTrigger: updatedTouchTrigger } = await createCustomTouchTemplate(
            touchTrigger._id,
            undefined,
            customBodyText,
            undefined,
            [ETouchType.Email, ETouchType.Voicemail].includes(touchTypeName) ? customEmailSubject : undefined,
          );

          // Callback to update the touch trigger locally
          onReceiveEditedTouchTrigger(updatedTouchTrigger);

          onClose();

          setCurrentAlert('success', 'Your edits have been saved and will be used when sending out your touch! Please preview your touch to make sure everything looks great!');
        } catch (err) {
          console.error(err);
          showAPIErrorAlert(setCurrentAlert, err);
        }

        hideLoadingIndicatorModal();
      }}
      validationSchema={Yup.object().shape({
        // customBodyText: Yup.string().required().max(1000),
        customBodyText: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form>
            <h4 className={classes.instructions}>
              Provide your own text
            </h4>
            {[ETouchType.Email, ETouchType.Voicemail].includes(touchTypeName) && (
              <Field
                name="customEmailSubject"
              >
                {({ field }) => (
                  <CustomInput
                    labelText="Email Subject"
                    formControlProps={{
                      classes: {
                        root: classes.formControl,
                      },
                    }}
                    inputProps={{
                      ...field,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
            )}
            <Field
              name="customBodyText"
            >
              {({ field }) => (
                <CustomInput
                  labelText="Body Text"
                  formControlProps={{
                    classes: {
                      root: classes.formControl,
                    },
                    className: classes.customBodyTextInput,
                    fullWidth: true,
                    style: { paddingTop: 0 },
                  }}
                  inputProps={{
                    ...field,
                    multiline: true,
                    rows: 8,
                  }}
                  error={touched[field.name] && errors[field.name] !== undefined}
                />
              )}
            </Field>

            <div className={classes.availableMergeVariables}>
              <span>
                <h4>Available Custom Variables</h4>
                <Tooltip
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title="These special merge tags will be substituted with information when your touch goes out. You can use them as many times as you want. Use them and preview your touch to see them in action!"
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </span>
              <div>
                {mergeVariables.map((v) => (
                  <Chip
                    key={v}
                    color={values.customEmailSubject?.match(new RegExp(`(\\b)${v}(\\b)`, 'g')) || values.customBodyText.match(new RegExp(`(\\b)${v}(\\b)`, 'g')) ? 'primary' : 'default'}
                    label={v}
                    className={classes.mergeVariableChip}
                  />
                ))}
              </div>
            </div>

            <div className={classes.actions}>
              {/* Span for buttons to go to the right */}
              <span />

              <div>
                {customTouchTemplate && (
                <Button
                  color="white"
                  round
                  onClick={() => setIsConfirmingReset(true)}
                >
                  Reset
                </Button>
                )}
                <Button
                  color="primary"
                  round
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditBodyText;
