/* eslint-disable no-param-reassign */
import styles from 'assets/jss/material-dashboard-pro-react/components/agentInfoModalStyle';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from 'creative-components/CustomButtons/Button';
import CustomImage from 'creative-components/CustomUpload/CustomImage';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import {
  updateUserById,
} from 'utils/api';
import {
  stringifyAddressObject,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  ...customCheckboxRadioSwitch,
  ...styles(theme),
}));

const updateAgentInfo = async (userId, data, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert) => {
  showLoadingIndicatorModal();

  try {
    await updateUserById(userId, {
      agentInfo: {
        ...data,
      },
    });

    hideLoadingIndicatorModal();

    setCurrentAlert('success', 'User has been updated successfully!');

    return true;
  } catch (error) {
    console.error('error', error);

    hideLoadingIndicatorModal();

    setCurrentAlert('error', 'Something went wrong, please try again.');

    return false;
  }
};

const AgentInfoModal = ({ user, agentCampaigns, setOpenModal }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  // Used to rerender the component when the user object is updated after aa updateUserById call
  const [forceRefresh, setForceRefresh] = React.useState(null);

  const [imageOpen, setImageOpen] = React.useState(null);
  const [marketingColorPickerOpen, setMarketingColorPickerOpen] = React.useState(false);
  const [confirmCancelCrmPro, setConfirmCancelCrmPro] = React.useState(false);

  // const [zipcodeAvailablyCounts, setZipcodeAvailablyCounts] = React.useState(null);

  /* const interestedEventType = user.agentInfo.eventInterestedIn;

 React.useEffect(() => {
    (async () => {
      if (isCrmProEventType(interestedEventType)) return;

      const { zipcodesInterestedIn } = user.agentInfo;
      const counts = await getAvailabilityCountsForZipcodes(interestedEventType._id, zipcodesInterestedIn);

      setZipcodeAvailablyCounts(counts);
    })();
  }, []);

  if (!zipcodeAvailablyCounts && !isCrmProEventType(interestedEventType)) {
    return (<LoadingIndicator modal />);
  } */

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      scroll="body"
      maxWidth="md"
      keepMounted
      onClose={() => setOpenModal(null)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setOpenModal(null)}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{`${user.firstName} ${user.lastName ?? ''}`}</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        {imageOpen && (
          <Backdrop className={classes.backdropContainer} open onClick={() => setImageOpen(null)}>
            <img src={imageOpen} alt="" className={classes.backdropImage} />
          </Backdrop>
        )}
        <GridContainer className={classes.infoContainer}>
          <GridItem xs={12} className={classes.info}>
            <strong>Admin Settings:</strong>

            <Box mt={2}>
              <Checkbox
                checked={user.agentInfo.canBypassCampaignAPNLimits ?? false}
                tabIndex={-1}
                onChange={async (event) => {
                  const newValue = event.target.checked;
                  if (await updateAgentInfo(user._id, { canBypassCampaignAPNLimits: newValue }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                    user.agentInfo.canBypassCampaignAPNLimits = newValue;
                    setForceRefresh(Date.now());
                  }
                }}
                style={{ marginRight: '10px' }}
              />
              Allow min/max APN limits bypass for campaigns
            </Box>

            <Box mt={3}>
              <Checkbox
                checked={user.agentInfo.applyDiscountedAPNRate ?? false}
                tabIndex={-1}
                onChange={async (event) => {
                  const newValue = event.target.checked;
                  if (await updateAgentInfo(user._id, { applyDiscountedAPNRate: newValue }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                    user.agentInfo.applyDiscountedAPNRate = newValue;
                    setForceRefresh(Date.now());
                  }
                }}
                style={{ marginRight: '10px' }}
              />
              Apply discounted APN rates
            </Box>

            <Box mt={3} style={{ maxWidth: '300px' }}>
              <CustomSelect
                labelText="Set Referral"
                items={_.uniqBy([
                  { value: 'none', name: 'None' },
                  { value: 'agentadvice-direct', name: 'AgentAdvice Direct' },
                  { value: 'agentadvice-indirect', name: 'AgentAdvice Indirect' },
                  ...(user.agentInfo.interestApplication.referral ? [{ value: user.agentInfo.interestApplication.referral, name: user.agentInfo.interestApplication.referral }] : []),
                ], 'value')}
                inputProps={{
                  value: user.agentInfo.interestApplication.referral ?? 'none',
                  onChange: async (e) => {
                    const newValue = e.target.value;
                    if (await updateAgentInfo(user._id, { referral: newValue }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                      user.agentInfo.interestApplication.referral = newValue;
                      setForceRefresh(Date.now());
                    }
                  },
                }}
              />
            </Box>
          </GridItem>

          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Agent Info ID:</strong>
            <br />
            {user.agentInfo._id}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>User ID:</strong>
            <br />
            {user._id}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Created:</strong>
            <br />
            {user.createdAt}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Account Email:</strong>
            <br />
            {user.email}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Phone Number:</strong>
            <br />
            {user.phoneNumber}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Marketing Email:</strong>
            <br />
            {user.agentInfo.marketingEmail}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Touch Email:</strong>
            <br />
            {user.agentInfo.touchEmail}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Agent License Number:</strong>
            <br />
            {user.agentInfo.agentDreNumber}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Agent Brokerage's License Number:</strong>
            <br />
            {user.agentInfo.brokerageDreNumber}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Slogan (Optional):</strong>
            <br />
            {user.agentInfo.slogan}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Website:</strong>
            <br />
            <a href={user.agentInfo.website} target="_blank" rel="noreferrer">{user.agentInfo.website}</a>
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Team Name (Optional):</strong>
            <br />
            {user.agentInfo.teamName}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Postcard Testimonial (Optional):</strong>
            <br />
            {user.agentInfo.postcardTestimonial}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Company Name:</strong>
            <br />
            {user.agentInfo.companyName}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Company Address:</strong>
            <br />
            {stringifyAddressObject(user.agentInfo.companyAddress)}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <CustomImage
              required
              label="Profile Image"
              initialImage={user.agentInfo.agentPictureUrl}
              croppable
              onImageUpload={async (imageData) => {
                if (await updateAgentInfo(user._id, { agentPicture: imageData }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                  user.agentInfo.agentPictureUrl = imageData;
                  setForceRefresh(Date.now());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <CustomImage
              label="Team Logo (Dark)"
              initialImage={user.agentInfo.teamLogoUrl}
              croppable
              onImageUpload={async (imageData) => {
                if (await updateAgentInfo(user._id, { teamLogo: imageData }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                  user.agentInfo.teamLogoUrl = imageData;
                  setForceRefresh(Date.now());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <CustomImage
              darkBackground
              label="Team Logo (Light)"
              initialImage={user.agentInfo.teamLogoLightUrl}
              croppable
              onImageUpload={async (imageData) => {
                if (await updateAgentInfo(user._id, { teamLogoLight: imageData }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                  user.agentInfo.teamLogoLightUrl = imageData;
                  setForceRefresh(Date.now());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <CustomImage
              disabled={user.role.name === 'Brokerage Agent'} // Don't allow brokerage logo updating for brokerage agent types
              required
              label="Brokerage Logo (Dark)"
              initialImage={user.agentInfo.companyLogoUrl}
              croppable
              onImageUpload={async (imageData) => {
                if (await updateAgentInfo(user._id, { companyLogo: imageData }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                  user.agentInfo.companyLogoUrl = imageData;
                  setForceRefresh(Date.now());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <CustomImage
              darkBackground
              disabled={user.role.name === 'Brokerage Agent'} // Don't allow brokerage logo updating for brokerage agent types
              label="Brokerage Logo (Light)"
              initialImage={user.agentInfo.companyLogoLightUrl}
              croppable
              onImageUpload={async (imageData) => {
                if (await updateAgentInfo(user._id, { companyLogoLight: imageData }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                  user.agentInfo.companyLogoLightUrl = imageData;
                  setForceRefresh(Date.now());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Marketing Signature:</strong>
            <br />
            {user.agentInfo.marketingSignature && user.agentInfo.marketingSignature.signatureImageUrl && (
              <>
                <h5>{`Style: ${user.agentInfo.marketingSignature.signatureStyle}`}</h5>
                <ButtonBase
                  onClick={() => setImageOpen(user.agentInfo.marketingSignature.signatureImageUrl)}
                >
                  <img src={user.agentInfo.marketingSignature.signatureImageUrl} alt="Marketing Signature" className={classes.imageSmall} />
                </ButtonBase>
              </>
            )}
          </GridItem>
          {/* NOTE: Not in use anymore */}
          {/* <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Print Marketing Accent Color:</strong>
            <br />
            <PopupColorPicker
              initialColor={user.agentInfo.marketingColor}
              isOpen={marketingColorPickerOpen}
              onOpen={() => setMarketingColorPickerOpen(true)}
              onClose={async (newColor) => {
                setMarketingColorPickerOpen(false);

                if (user.agentInfo.marketingColor !== newColor) {
                  if (await updateAgentInfo(user._id, { marketingColor: newColor }, showLoadingIndicatorModal, hideLoadingIndicatorModal, setCurrentAlert)) {
                    user.agentInfo.marketingColor = newColor;
                    setForceRefresh(Date.now());
                  }
                }
              }}
              />
          </GridItem> */}
          {/* <GridItem xs={12} sm={6} className={classes.info}>
            {confirmCancelCrmPro && (
            <ConfirmModal
            message={`Are you sure you want to cancel ${user.firstName}'s CRM Pro subscription? They will be refunded a prorated amount.`}
              onConfirm={async () => {
                showLoadingIndicatorModal();

                await unsubscribeCrmPro(user._id, {});

                setCurrentAlert('success', 'CRM Pro subscription cancelled successfully!');

                hideLoadingIndicatorModal();

                // Reload the page
                window.location.reload();
              }}
              onClose={() => setConfirmCancelCrmPro(false)}
              />
            )}
            <strong>CRM Pro Subscription:</strong>
            <br />
            <span style={{ color: user.agentInfo.isCrmProEnabled ? theme.palette.success.main : theme.palette.warning.main }}>
            <strong>
                {user.agentInfo.isCrmProEnabled ? `Active (${user.agentInfo.stripe.crmProSubscriptionId})` : 'Inactive'}
              </strong>
            </span>
            <br />
            {user.agentInfo.isCrmProEnabled && (
            <Button
              color="warning"
              onClick={() => setConfirmCancelCrmPro(true)}
            >
              Cancel CRM Pro
            </Button>
            )}
            </GridItem>
            <GridItem xs={12} sm={6} className={classes.info}>
            <strong>CRM Pro Customer Testimonial:</strong>
            <br />
            {user.agentInfo.crmProCustomerTestimonial || 'Default'}
          </GridItem> */}
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Billing Details:</strong>
            <br />
            <ul>
              <li>
                Stripe Customer ID:
                {' '}
                {user.agentInfo.stripe.customerId}
              </li>
              <li>
                Payment Method ID:
                {' '}
                {user.agentInfo.stripe.paymentMethodId}
              </li>
              <li>
                Active Campaign Subscription IDs:
                {' '}
                {agentCampaigns && agentCampaigns.filter((campaign) => campaign.status === 'ACTIVE').map((c) => c.stripe.subscriptionId).join(', ')}
              </li>
            </ul>
          </GridItem>

          {/* {user.agentInfo.voicemailRecordings.map(({ eventType: { name }, voicemails }) => (
            voicemails.map((voicemail, i) => (
              <GridItem xs={12} sm={6} className={classes.info} key={`${name}-${i}`}>
                <strong>
                  {`${name} Voicemail #${i + 1}:`}
                </strong>
                <br />
                <audio src={voicemail} type="audio/mpeg" controls preload="auto" />
              </GridItem>
            ))))} */}
          {/* <GridItem xs={12} sm={6}>
            <strong>{`${interestedEventType.name} Property Types Interested In:`}</strong>
            <ul>
            {user.agentInfo.propertyTypesInterestedIn.map((propertyType) => (
              <li key={propertyType}>
                  {propertyType}
                </li>
              ))}
            </ul>
          </GridItem> */}
          {/* {!isCrmProEventType(interestedEventType) ? (
            <GridItem xs={12} md={6}>
              <strong className={classes.blueText}>{`Interested Zipcode ${interestedEventType.name} Availability:`}</strong>
              <ul className={classes.zipcodeCountsList}>
                {user.agentInfo.zipcodesInterestedIn.map((zipcode) => (
                  <li key={zipcode}>
                    <strong>{zipcode}</strong>
                    {` - ${zipcodeAvailablyCounts[zipcode].availableCount} Available, ${zipcodeAvailablyCounts[zipcode].unavailableCount} Unavailable`}
                  </li>
                ))}
              </ul>
            </GridItem>
          ) : null} */}
          {agentCampaigns && agentCampaigns.length > 0 && (
            <GridItem xs={12} md={6}>
              <strong>Active Campaigns</strong>
              <br />
              {agentCampaigns.filter((campaign) => campaign.status === 'ACTIVE').map((campaign) => (
                <div key={campaign._id}>
                  {`${campaign.name} (${campaign.eventType.name})`}
                  <Tooltip
                    title="View Info"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => history.push(`/dashboard/campaigns/${campaign._id}`)}
                      // onClick={() => setOpenModal((
                      //   <CampaignAdminInfoModal
                      //     campaign={campaign}
                      //     onClose={() => { setOpenModal(null); }}
                      //   />
                      // ))}
                      color="dark"
                    >
                      <OpenInNewIcon />
                    </Button>
                  </Tooltip>
                </div>
              ))}
            </GridItem>
          )}
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

AgentInfoModal.propTypes = {
  user: PropTypes.object.isRequired,
  agentCampaigns: PropTypes.array.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default AgentInfoModal;
