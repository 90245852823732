import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip, Card, CardHeader, CardContent,
} from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    height: '100%',
  },
}));

const InvoicesTable = ({ invoices }) => {
  const classes = useStyles();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!invoices) return;

    setData(invoices.map(({
      number, status, total, hostedInvoiceUrl, created,
    }) => {
      let statusText;

      if (status === 'open') {
        statusText = (<div style={{ color: 'red' }}>UNPAID</div>);
      } else if (status === 'paid') {
        statusText = (<div style={{ color: 'green' }}>PAID</div>);
      }

      return {
        number: (<b>{number}</b>),
        status: statusText,
        total: `$${(total / 100).toFixed(2)}`,
        created: moment(created * 1000).toDate(), // Created is in seconds, not ms
        actions: (
          <div>
            <Tooltip
              title="View Invoice"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                justIcon
                round
                simple
                href={hostedInvoiceUrl}
                color="dark"
                target="_blank"
              >
                <OpenInNewIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      };
    }));
  }, [invoices]);

  return (
    invoices ? (
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <ReactTable
            normalRightColumn
            columns={[
              {
                Header: 'Invoice Number',
                accessor: 'number',
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Total',
                accessor: 'total',
              },
              {
                Header: 'Created',
                accessor: 'created',
                Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
                sortType: 'datetime',
              },
              {
                Header: 'Actions',
                accessor: 'actions',
              },
            ]}
            data={data}
          />
        </div>
      </div>
    ) : (<LoadingIndicator />)
  );
};

export default InvoicesTable;
