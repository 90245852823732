import React from 'react';
import { Marker } from '@react-google-maps/api';

import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';

import { useMapInfoContext } from 'components/DataMap/MapInfoProvider';

import { getEventDataOwnerInformation, stringifyEventSiteAddressObject } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/eventMarkersStyle';

import eventMarkerWhiteIcon from 'assets/img/event-marker-white.png';
import eventMarkerGrayIcon from 'assets/img/event-marker-gray.png';
import eventMarkerBlueIcon from 'assets/img/event-marker-blue.png';
import eventMarkerGreenIcon from 'assets/img/event-marker-green.png';

import miniMarkerIcon from 'assets/img/mini-marker.png';

// const eventMarkerAvailableIcon = eventMarkerBlueIcon;
// const eventMarkerUnavailableIcon = eventMarkerGrayIcon;
// const eventMarkerSelectedIcon = eventMarkerGreenIcon;

const miniMarkerWidth = 11;
const miniMarkerHeight = 12;

const eventMarkerAvailableIcon = miniMarkerIcon;
const eventMarkerUnavailableIcon = miniMarkerIcon;
const eventMarkerSelectedIcon = miniMarkerIcon;

const useStyles = makeStyles(styles);

const zoomThresholdToShowEventDataMarkers = 15;

/**
 * Requires existingEventOverride to be set in the eventData object, if it exists
 */
const EventDataHoverPopup = ({ eventData }) => {
  const classes = useStyles();

  const siteAddressString = stringifyEventSiteAddressObject(eventData.siteAddress);

  const {
    name: ownerName, phoneNumber: ownerPhoneNumber, email: ownerEmail, ownerAddress,
  } = getEventDataOwnerInformation(eventData.existingEventOverride, eventData);

  return (
    <div className={classes.hoverContainer}>
      <h4 className={classes.hoverSiteAddress}>{siteAddressString}</h4>
      <h4 className={classes.hoverSiteApn}>{`APN: ${eventData.apn}`}</h4>
      <br />
      <h5 className={classes.hoverOwnerHeading}>Owner Information</h5>
      <span className={classes.hoverOwnerInfoSpan}>
        <PersonIcon className={classes.hoverOwnerInfoIcon} color="primary" fontSize="small" />
        <h5 className={classes.hoverOwnerInfoText}>{ownerName}</h5>
      </span>
      <br />
      <span className={classes.hoverOwnerInfoSpan}>
        <PhoneIcon className={classes.hoverOwnerInfoIcon} color="primary" fontSize="small" />
        <h5 className={classes.hoverOwnerInfoText}>{ownerPhoneNumber}</h5>
      </span>
      <br />
      <span className={classes.hoverOwnerInfoSpan}>
        <EmailIcon className={classes.hoverOwnerInfoIcon} color="primary" fontSize="small" />
        <h5 className={classes.hoverOwnerInfoText}>{ownerEmail}</h5>
      </span>
      <br />
      <span className={classes.hoverOwnerInfoSpan}>
        <HomeIcon className={classes.hoverOwnerInfoIcon} color="primary" fontSize="small" />
        <h5 className={classes.hoverOwnerInfoText}>{ownerAddress}</h5>
      </span>
    </div>
  );
};

const markerIconOptions = (markerIcon, zoom) => {
  let markerSizeMultiplier = 1;
  if (zoom >= 18) {
    markerSizeMultiplier = 3;
  } else if (zoom >= 15) {
    markerSizeMultiplier = 2;
  }

  return {
    url: markerIcon,
    scaledSize: {
      width: markerSizeMultiplier * miniMarkerWidth,
      height: markerSizeMultiplier * miniMarkerHeight,
    },
    anchor: new window.google.maps.Point(markerSizeMultiplier * 7.5, markerSizeMultiplier * 7.5),
  };
};

const EventMarkers = ({
  eventData, highlightEventDataApns, ignoreZoomThresholdCulling, showEventDataHoverPopup,
}) => {
  const {
    mapInstance, mapBounds, addOverlayView, removeOverlayView, overlayViews,
  } = useMapInfoContext();

  const onMarkerMouseOver = (eventData2) => (e) => {
    if (showEventDataHoverPopup) {
      addOverlayView('EventMarker', 2, (
        <EventDataHoverPopup eventData={eventData2} />
      ));
    }
  };

  const onMarkerMouseOut = () => {
    removeOverlayView('EventMarker');
  };

  const [markersToRender, setMarkersToRender] = React.useState([]);

  React.useEffect(() => {
    if (!eventData) return;

    const arr = [];

    eventData.forEach((o) => {
      const {
        _id, apn, siteLocation, available,
      } = o;

      if (!mapInstance || !mapInstance.getBounds() || !mapInstance.getBounds().contains(new window.google.maps.LatLng(siteLocation.coordinates[1], siteLocation.coordinates[0]))) return;

      let markerIcon;

      if (!available) {
        markerIcon = eventMarkerUnavailableIcon;
      } else if (highlightEventDataApns.includes(apn)) {
        markerIcon = eventMarkerSelectedIcon;
      } else {
        markerIcon = eventMarkerAvailableIcon;
      }

      const position = { lat: siteLocation.coordinates[1], lng: siteLocation.coordinates[0] };

      arr.push((
        <Marker
          key={_id}
          position={position}
          icon={markerIconOptions(markerIcon, mapInstance.zoom)}
          onMouseOver={onMarkerMouseOver(o)}
          onMouseOut={onMarkerMouseOut}
        />
      ));

      setMarkersToRender(arr);
    });
  }, [eventData, mapBounds, highlightEventDataApns]);

  if (!ignoreZoomThresholdCulling && mapInstance?.zoom < zoomThresholdToShowEventDataMarkers) {
    return null;
  }

  return (
    <>
      {markersToRender}
    </>
  );
};

export default EventMarkers;
