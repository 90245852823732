import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default () => (
  <SvgIcon style={{ fontSize: 44 }} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#018EDE" />
    <path d="M26.5 24H26.51M13 15V29C13 30.1046 13.8954 31 15 31H29C30.1046 31 31 30.1046 31 29V19C31 17.8954 30.1046 17 29 17L15 17C13.8954 17 13 16.1046 13 15ZM13 15C13 13.8954 13.8954 13 15 13H27M27 24C27 24.2761 26.7761 24.5 26.5 24.5C26.2239 24.5 26 24.2761 26 24C26 23.7239 26.2239 23.5 26.5 23.5C26.7761 23.5 27 23.7239 27 24Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="#018EDE" />
  </SvgIcon>
);
