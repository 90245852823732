import { containerFluid } from 'assets/jss/material-dashboard-pro-react';

const footerStyle = (theme) => ({
  left: {
    float: 'left!important',
    display: 'block',

    [theme.breakpoints.down('sm')]: {
      float: 'none!important',
      textAlign: 'center',
    },
  },
  right: {
    fontSize: '14px',
    float: 'right!important',

    [theme.breakpoints.down('sm')]: {
      float: 'none!important',
      textAlign: 'center',
      margin: '12px 0 0',
    },
  },
  footer: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#777777',
    zIndex: 3, // TODO: Was 4 before. Will cause problems?
    // padding: '34px 100px 34px 0',
    padding: '34px',

    // TODO: ???
    [theme.breakpoints.down('sm')]: {
      // padding: '5px 0',
      // padding: '34px 0',
    },
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& a': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      '&:not(:last-child)': {
        margin: '0 0 12px',
      },
    },
  },
});

export default footerStyle;
