import useSWR from 'swr';

export const useUsers = () => {
  const { data, error, mutate } = useSWR('/users/all');

  return {
    users: data?.users,
    isLoadingUsers: !data,
    isError: error,
    mutateUsers: mutate,
  };
};
