import { container } from 'assets/jss/material-dashboard-pro-react';

const styles = (theme) => ({
  root: {
    flex: 1,
    height: '100%',
    padding: '100px 0',
    textAlign: 'center',
  },
  container,
  title: {
    color: theme.palette.darkGray.main,
    fontSize: '80px',
    lineHeight: '100px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '72px',
      lineHeight: '80px',
    },
  },
  subTitle: {
    color: theme.palette.grayScale4.main,
    fontSize: '32px',
    lineHeight: '36px',
  },
});

export default styles;
