import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ButtonBase } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';

import ActionModal from 'components/ActionModal/ActionModal';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
}));

const CampaignCancelModal = ({
  campaign, cancellationFee, onConfirm, onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Formik
      initialValues={{
        waiveCancellationFee: false,
      }}
      onSubmit={async ({ waiveCancellationFee }) => onConfirm(waiveCancellationFee)}
      validationSchema={Yup.object().shape({
        waiveCancellationFee: Yup.boolean().required(),
      })}
    >
      {(props) => {
        const {
          isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form>
            <ActionModal
              backgroundColor={theme.palette.offWhite.main}
              textColor={theme.palette.darkGray.main}
              icon={<LibraryAddCheckRoundedIcon />}
              onClose={onClose}
              title="Are You Sure?"
              message={(

                <>
                  <h5>
                    {`Are you sure you want to cancel ${campaign.agent.firstName} ${campaign.agent.lastName}'s ${campaign.eventType.name} campaign?`}
                    <br />
                    <br />
                    <strong>
                      {`The agent will have to pay a $${!values.waiveCancellationFee ? numberWithCommas((cancellationFee / 100).toFixed(2)) : (0).toFixed(2)} cancellation fee.`}
                    </strong>
                  </h5>
                  <br />
                  <Field
                    name="waiveCancellationFee"
                  >
                    {({ field }) => (
                      <>
                        <Checkbox
                          checked={field.value}
                          tabIndex={-1}
                          onClick={() => setFieldValue(field.name, !field.value, true)}
                          style={{ marginRight: '10px' }}
                        />
                        Waive Cancellation Fee
                      </>
                    )}
                  </Field>
                </>
                )}
              buttons={[
                <ButtonBase onClick={onClose} className={classes.cancelButton}>No</ButtonBase>,
                <ButtonBase
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                >
                  Yes
                </ButtonBase>,
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CampaignCancelModal;
