import React from 'react';

import { Box, ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({

}));

// TODO: ADD VARIANTS FOR BOTTOM RIGHT ACTION?
export interface Props {
  title: string | React.ReactNode;
  stat?: number;
  Icon: React.FC;
  onViewAllClick: () => void;
}

export default ({
  title, stat, Icon, onViewAllClick,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box sx={{
      width: 260,
      height: 170,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 8,
      bgcolor: theme.palette.offWhite.main,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'start',
      }}
      >
        <Icon />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 14,
          fontWeight: 500,
          color: theme.palette.purple.dark,
        }}
        >
          <div style={{ fontSize: 14 }}>
            {title}

          </div>
          <div style={{ marginTop: 5, fontSize: 24 }}>
            {stat !== undefined ? numberWithCommas(stat) : <LoadingIndicator />}
          </div>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <ButtonBase onClick={onViewAllClick} style={{ color: theme.palette.primary.main }}>
          View All
          <ChevronRightIcon />
        </ButtonBase>

        {/* TODO: IMPLEMENT */}
        {/* <div>TODO</div> */}
      </Box>
    </Box>
  );
};
