import React from 'react';
import cx from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/agentLandingNavbarStyle';

const useStyles = makeStyles(styles);

const AgentLandingNavbar = ({
  agentLandingPageType, agentData, openAssessmentModal,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));

  let headerLogo;

  // Fallback to brokerage logo if no team logo exists for this agent
  if (agentData) {
    if (agentData.teamLogoUrl) {
      headerLogo = agentData.teamLogoUrl;
    } else {
      headerLogo = agentData.brokerageLogoUrl;
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => (routeName === '/' ? window.location.pathname === '/' : window.location.pathname.indexOf(routeName) > -1);

  let list;

  // Don't show any navbar options on the error page
  if (!activeRoute('/error')) {
    list = (
      <List className={classes.list}>
        <ListItem
          className={cx(classes.listItem, {
            [classes.listItemActive]: activeRoute('/'),
          })}
        >
          <NavLink to={{ pathname: '/', search: history.location.search }} className={classes.navLink} onClick={handleDrawerToggle}>
            <ListItemText
              primary="Home"
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>

        {agentLandingPageType === 'COMPARABLE_PROPERTIES' && (
          <ListItem className={classes.listItem}>
            <ButtonBase
              className={classes.navLink}
              onClick={() => {
                handleDrawerToggle();
                openAssessmentModal();
              }}
            >
              <ListItemText
                primary="Get Your Assessment"
                className={classes.listItemText}
              />
            </ButtonBase>
          </ListItem>
        )}

        <ListItem
          className={cx(classes.listItem, {
            [classes.listItemActive]: activeRoute('/bio'),
          })}
        >
          <NavLink to={{ pathname: '/bio', search: history.location.search }} className={classes.navLink} onClick={handleDrawerToggle}>
            <ListItemText
              primary="Agent Bio"
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>

        {/* <ListItem className={classes.listItem}>
          <ButtonBase
            className={classes.navLink}
            onClick={() => {
              handleDrawerToggle();
              onDownloadReport();
            }}
          >
            <ListItemText
              primary="Download Report"
              className={classes.listItemText}
            />
          </ButtonBase>
        </ListItem> */}
      </List>
    );
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.container} disableGutters>
        <GridContainer justifyContent="space-between" alignItems="center">
          <GridItem>
            <img src={headerLogo} alt="" className={classes.headerLogo} />
          </GridItem>
          {isMobileWidth ? (
            <>
              <Button
                className={classes.sidebarButtonOpen}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                size={!isXsWidth ? 'lg' : null}
              >
                <MenuIcon />
              </Button>

              <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
                TransitionComponent={Fade}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <div className={classes.drawerHeaderContainer}>
                  <a
                    href="/"
                  >
                    <img src={headerLogo} alt="" className={classes.drawerLogo} />
                  </a>
                  <Button
                    className={classes.sidebarButtonClose}
                    color="transparent"
                    justIcon
                    aria-label="close drawer"
                    onClick={handleDrawerToggle}
                    size={!isXsWidth ? 'lg' : null}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                {list}
                <div className={classes.drawerFooterContainer} />
              </Drawer>
            </>
          ) : (
            <GridItem>
              {list}
            </GridItem>
          )}
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
};

export default AgentLandingNavbar;
