import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { capitalize } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { FormLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { createBrokerage } from 'utils/api';
import { nameRegExp, phoneRegExp, showAPIErrorAlert } from 'utils/lib';
import analyticsClient from 'utils/analytics';
import { useBrokerageLeadTypes } from 'data/brokerage';

import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const useStyles = makeStyles((theme) => ({
  ...customCheckboxRadioSwitchStyles(theme),
  buttonContainer: {
    textAlign: 'center',
    margin: '30px 0 0',
  },
}));

const CreateBrokerageModal = ({ onClose, addBrokerage }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  const { brokerageLeadTypes, isLoadingLeadTypes } = useBrokerageLeadTypes();

  if (isLoadingLeadTypes) return <LoadingIndicator modal />;

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        leadType: '',
      }}
      onSubmit={async ({
        name, email, firstName, lastName, phoneNumber, leadType,
      }) => {
        try {
          const { brokerage, user } = await createBrokerage(name, email, firstName, lastName, phoneNumber, leadType);

          addBrokerage(brokerage);

          onClose();

          setCurrentAlert('success', 'The brokerage has been created successfully!');

          // Track GTM events
          analyticsClient.track('brokerage_registration_started', {
            brokerage_id: brokerage._id,
          });

          analyticsClient.track('user_registration_started', {
            agentdata_user_id: user._id,
            email: user.email,
            role: user.role.name,
          });
        } catch (err) {
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        firstName: Yup.string().required().matches(nameRegExp),
        lastName: Yup.string().required().matches(nameRegExp),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
        leadType: Yup.string(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue,
        } = props;

        return (
          <Form>
            <Dialog
              open
              scroll="body"
              keepMounted
              maxWidth="sm"
              onClose={onClose}
            >
              <SimpleModalHeader onClose={onClose} title="Create New Brokerage" />

              {isSubmitting ? <LoadingIndicator modal /> : null}

              <Field
                name="name"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Brokerage Name"
                    inputProps={{
                      ...field,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="firstName"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="First Name"
                    inputProps={{
                      ...field,
                      autoComplete: 'given-name',
                      maxLength: 50,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="lastName"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Last Name"
                    inputProps={{
                      ...field,
                      autoComplete: 'family-name',
                      maxLength: 50,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="email"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Email"
                    inputProps={{
                      ...field,
                      autoComplete: 'email',
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="phoneNumber"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Phone Number"
                    inputProps={{
                      ...field,
                      type: 'tel',
                      autoComplete: 'tel',
                    }}
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="leadType"
              >
                {({ field }) => (
                  <>
                    <FormLabel>Lead Type (Optional)</FormLabel>
                    <br />
                    {brokerageLeadTypes.map((type) => (
                      <FormControlLabel
                        key={type}
                        control={(
                          <Radio
                            {...field}
                            checked={field.value === type}
                            onChange={() => setFieldValue(field.name, type, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                            )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                            )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                          )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label={capitalize(type)}
                      />
                    ))}
                  </>
                )}
              </Field>

              <div className={classes.buttonContainer}>
                <Button
                  round
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  Create
                </Button>
              </div>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateBrokerageModal;
