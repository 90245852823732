import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle';
import classnames from 'classnames';
// A great library for fuzzy filtering/sorting items
import matchSorter from 'match-sorter';
import React from 'react';
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckIcon from '@material-ui/icons/Check';

import CustomInput from 'creative-components/CustomInput/CustomInput';

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...customCheckboxRadioSwitch,
  root: {
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0px',
  },
  tableHeader: {
    background: '#F2F2F3',
  },
  tableBody: {

  },
  tableRow: {

  },
  tableHeaderCell: {
    textAlign: 'left',
    padding: '12px 16px',
    color: theme.palette.grayScale9.main,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  tableHeaderCellInputBox: {
    margin: '5px 0 !important',
  },
  tableHeaderCellActions: {
    '& > div': {
      alignItems: 'flex-end',
    },
  },
  tableDataCell: {
    boxShadow: '0 -1px 0 #BEBEC5', // Use box shadow as small border
    textAlign: 'left',
    padding: '12px 14px',
    color: theme.palette.grayScale9.main,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tableDataCellActions: {
    '& > div': {
      justifyContent: 'flex-end',
    },
  },

  filterFormControl: {
    margin: '0 !important',
  },
  filterInput: {
    '& > input': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      margin: '10px 12px',
    },
  },
}));

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const classes = useStyles();
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
        className: classes.filterFormControl,
      }}
      inputProps={{
        className: classes.filterInput,
        value: filterValue || '',
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: 'Search',
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const rowsPerPageOptions = [5, 10, 25, 50, 100];

// Our table component
// NOTE: rightColumnComponent is only used if normalRightColumn is false
function Table({
  columns,
  data,
  showFilters = true,
  normalRightColumn = false,
  rightColumnComponent = null,
  renderRightColumnComponentOnlyWhenRowsSelected = false,
  selectableRows = false,
  numRowsPerPage = 10,
  initialSelectedPage = 0,
}, ref) {
  const classes = useStyles();

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    state: { selectedRowIds },
    selectedFlatRows,
    toggleAllRowsSelected,
    visibleColumns,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pagination: {
          pageIndex: initialSelectedPage, // Custom initial page index
          pageSize: numRowsPerPage, // Custom default page size
        },
      },
      autoResetPageIndex: true, // Turn off page index reset when sorting or filtering
    },
    showFilters ? useFilters : false,
    useSortBy,
    usePagination,
    selectableRows && useRowSelect, // AFTER usePagination
    (hooks) => {
      if (selectableRows) {
        hooks.visibleColumns.push((columns2) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <Checkbox
                  {...getToggleAllPageRowsSelectedProps()}
                  checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                  icon={<CheckIcon className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.root,
                  }}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                  icon={<CheckIcon className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.root,
                  }}
                />
              </div>
            ),
          },
          ...columns2,
        ]);
      }
    },
  );

  React.useImperativeHandle(ref, () => ({
    getSelectedFlatRows() {
      return selectedFlatRows;
    },
    deselectAllRows() {
      toggleAllRowsSelected(false);
    },
  }));

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <div className={classes.root}>
      <table {...getTableProps()} className={classes.table}>
        <thead className={classes.tableHeader}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className={classes.tableRow}>
              {headerGroup.headers.map((column, key) => (
                <th
                  key={key}
                  className={classnames(
                    classes.tableHeaderCell,
                    column.id === 'actions' ? classes.tableHeaderCellActions : null,
                  )}
                >
                  <div>
                    <div
                      {...(column.id !== 'actions' ? column.getHeaderProps(column.getSortByToggleProps()) : {})}
                      style={{
                        display: 'flex',
                        cursor: column.id !== 'actions' ? 'pointer' : 'unset',
                      }}
                    >
                      {column.render('Header')}

                      {/* Sort ascending icon */}
                      {column.isSorted && !column.isSortedDesc && (
                        <ArrowDropUpIcon />
                      )}

                      {/* Sort descending icon */}
                      {column.isSorted && column.isSortedDesc && (
                        <ArrowDropDownIcon />
                      )}
                    </div>

                    {/* Render the columns filter UI */}
                    {!normalRightColumn && headerGroup.headers.length - 1 === key
                      ? (rightColumnComponent ? (!renderRightColumnComponentOnlyWhenRowsSelected || selectedFlatRows.length > 0)
                      && (
                      <div className={classes.tableHeaderCellInputBox}>
                        {rightColumnComponent}
                      </div>
                      )
                        : null)
                      : column.canFilter
                        ? (
                          <div className={classes.tableHeaderCellInputBox}>
                            {column.render('Filter')}
                          </div>
                        )
                        : null}
                  </div>
                </th>
              ))}
              {normalRightColumn ? (
                <th
                  className={classes.tableHeaderCell}
                  style={{ display: 'none' }}
                >
                  r
                </th>
              ) : null}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={classes.tableBody}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classes.tableRow}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={classnames(classes.tableDataCell, cell.column.id === 'actions' ? classes.tableDataCellActions : null)}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}

                {normalRightColumn ? (
                  <td
                    className={classes.tableDataCell}
                    style={{ display: 'none' }}
                  />
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>

      <TablePagination
        component="div"
        count={rows.length}
        page={pageSize >= rows.length ? 0 : pageIndex} // Fixes an issue when # pages changes due to filters, despite autoResetPageIndex = true
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        style={{ boxShadow: '0 -1px 0 #BEBEC5' }}
      />
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

export default React.forwardRef(Table);
