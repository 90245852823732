import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import AllBrokeragesTable from 'components/AllBrokeragesTable/AllBrokeragesTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import CreateBrokerageModal from 'components/CreateBrokerageModal/CreateBrokerageModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { useAllBrokerages } from 'data/brokerage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer1: {
    marginTop: '60px',
  },
}));

const AllBrokeragesPage = () => {
  const classes = useStyles();

  const { brokerages, isLoadingBrokerages, mutateBrokerages } = useAllBrokerages();

  const [showCreatingModal, setShowCreatingModal] = React.useState(null);

  if (isLoadingBrokerages) return (<LoadingIndicator />);

  const createBrokerageButton = (
    <Button
      color="primary"
      round
      onClick={() => setShowCreatingModal(true)}
    >
      + Create New Brokerage
    </Button>
  );

  const onAddBrokerage = (newBrokerage) => {
    mutateBrokerages({
      brokerages: [...brokerages, newBrokerage],
    }, { revalidate: false });
  };

  return (
    <div className={classes.root}>
      {showCreatingModal && (
        <CreateBrokerageModal
          onClose={() => setShowCreatingModal(false)}
          addBrokerage={onAddBrokerage}
        />
      )}

      <DashboardSection
        sectionName="Brokerages"
        buttons={[createBrokerageButton]}
      />

      <div className={classes.spacer1} />

      <AllBrokeragesTable brokerages={brokerages} />
    </div>
  );
};

export default AllBrokeragesPage;
