import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import { useCampaignNumApnsBeingFarmed } from 'data/campaign';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import * as Yup from 'yup';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import ActionModal from 'components/ActionModal/ActionModal';
import AddOnPropertiesInput from 'components/AddOnPropertiesInput/AddOnPropertiesInput';
import { addressYupValidation, initialAddress } from 'components/AddressAutocomplete/AddressForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignInfoCards from 'components/CampaignInfoCards/CampaignInfoCards';
import ChooseAgentLandingPageType from 'components/ChooseAgentLandingPageType/ChooseAgentLandingPageType';
import ChooseTouchTemplateDesign from 'components/ChooseTouchTemplateDesign/ChooseTouchTemplateDesign';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import DataMap from 'components/DataMap/DataMap';
import ExtraMailOutAddressesInput from 'components/ExtraMailOutAddressesInput/ExtraMailOutAddressesInput';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import analyticsClient from 'utils/analytics';
import { saveCampaignDraft } from 'utils/api';
import {
  calculateCampaignDates, calculateCampaignPrices, nameRegExp, numberWithCommas,
  phoneRegExp, showAPIErrorAlert,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  campaignDatesMessage: {
    maxWidth: '700px',
    margin: '30px auto 40px',
  },
  map: {
    minHeight: '95vh',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px',
  },
  infoCards: {
    margin: '0 0 50px',
  },
  container: {
    margin: '20px 0 40px',
  },
  settingInput: {
    maxWidth: '400px',
  },
  spacerSmall: {
    marginTop: '70px',
  },
  spacerBig: {
    marginTop: '140px',
  },
  deleteDraftButton: {
    marginRight: '20px',
  },
  nextButton: {
    float: 'right',
  },
  confirmDisclaimerButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));

const CreateCampaignDataSelection = ({
  draftCampaign, setDraftCampaign, confirmDraftDeletion, setStep,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { mutate } = useSWRConfig();
  const { userInfo, reloadUserInfo } = useUserInfoContext();
  const { DASHBOARD_ROUTE, isBrokerageAgent, user } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const {
    availableEventTypes, selectedEventType, setSelectedEventType, setSelectedEventTypeByName, appliedFilters,
  } = useDataFilterContext();

  const { numApnsBeingFarmed, isLoadingNumApnsBeingFarmed } = useCampaignNumApnsBeingFarmed();

  const dataSelectionOverlayRef = React.useRef(null);

  // For Formik extraMailOutAddresses so we can access its values from the outside
  const [extraMailOutsCount, setExtraMailOutsCount] = React.useState(draftCampaign?.extraMailOutRecipients.length ?? 0);

  const [apnsTotalSelectedCount, setApnsTotalSelectedCount] = React.useState(0);
  const [apnsTotalInAreaCount, setApnsTotalInAreaCount] = React.useState(0);
  const [apnSelectedCounts, setApnSelectedCounts] = React.useState([]); // Keys are 'Custom Shapes', 'Add On Properties', and `${zipcode}`
  const [campaignDates, setCampaignDates] = React.useState(null);
  const [apnAvailableCounts, setApnAvailableCounts] = React.useState([]); // Keys are 'Custom Shapes', 'Add On Properties', and `${zipcode}`
  const [estimatedCosts, setEstimatedCosts] = React.useState(null);
  const [isSavingAsDraft, setIsSavingAsDraft] = React.useState(undefined);

  // Whether or not they put a limit with the reduce modal
  const hasFarmLimit = Boolean(apnAvailableCounts.find(({ name, count }) => apnSelectedCounts.find((o) => o.name === name)?.count !== count));

  const [initSelectedZipcodes, setInitSelectedZipcodes] = React.useState([]);

  const [showFarmAreaDisclaimer, setShowFarmAreaDisclaimer] = React.useState(false);

  React.useEffect(() => {
    if (!draftCampaign) return;

    // Initialize map settings if there's an existing campaign
    const zipcodesArr = [];
    draftCampaign.apnSelectedCounts.forEach(async ({ name }) => {
      if (name !== 'Custom Shapes' && name !== 'Add On Properties') zipcodesArr.push(name);
    });
    setInitSelectedZipcodes(zipcodesArr);
  }, []);

  React.useEffect(() => {
    if (!selectedEventType || isLoadingNumApnsBeingFarmed) return;

    const { campaignStartDate, campaignEndDate } = calculateCampaignDates(selectedEventType);
    setCampaignDates([campaignStartDate, campaignEndDate]);

    // Use the estimated number of APNs for the APN count for auto-renewal campaigns
    const {
      discountedMonthlyRate,
      monthlyRatePerApn,
      apnsMonthlyCost,
      extraRecipientsMonthlyCost,
      totalMonthlyCost,
    } = calculateCampaignPrices(selectedEventType, draftCampaign?.autoRenewalForCampaign ? draftCampaign.estimatedNumberOfApns : apnsTotalSelectedCount, extraMailOutsCount, numApnsBeingFarmed, userInfo.agentInfo.applyDiscountedAPNRate);

    setEstimatedCosts({
      discountedMonthlyRate,
      monthlyRatePerApn,
      apnsMonthlyCost,
      extraRecipientsMonthlyCost,
      totalMonthlyCost,
    });
  }, [selectedEventType, apnsTotalSelectedCount, extraMailOutsCount, isLoadingNumApnsBeingFarmed]);

  if (!selectedEventType) return (<LoadingIndicator />);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          extraMailOutAddresses: draftCampaign?.extraMailOutRecipients ?? [],
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: { ...initialAddress },
          farmAreaName: draftCampaign?.farmAreaName || '',
          agentLandingPageType: draftCampaign?.agentLandingPageType || 'COMPARABLE_PROPERTIES',
          addOnPropertiesJsonString: draftCampaign?.addOnPropertiesJsonString || null,
          touchTemplateDesignId: draftCampaign?.touchTemplateDesign || null,
        }}
        onSubmit={async (values) => {
          const {
            extraMailOutAddresses, farmAreaName, agentLandingPageType, addOnPropertiesJsonString, touchTemplateDesignId,
          } = values;
          showLoadingIndicatorModal();

          try {
            const { campaign } = await saveCampaignDraft({
              campaignId: draftCampaign ? draftCampaign._id : undefined,
              extraMailOutAddresses,
              farmAreaName,
              agentLandingPageType: selectedEventType.name === 'Commercial' ? 'CONTACT_FORM' : agentLandingPageType, // Restrict agent landing page type for commercial properties
              addOnPropertiesJsonString,
              touchTemplateDesignId,

              // Farm selection data for non-auto-renewal campaigns
              ...(!draftCampaign || !draftCampaign.autoRenewalForCampaign ? {
                eventTypeId: !draftCampaign ? selectedEventType._id : undefined, // Only send for new campaigns
                propertyFilters: appliedFilters,
                polygonPaths: dataSelectionOverlayRef.current.getSelectionPolygonPaths(),
                zipcodes: dataSelectionOverlayRef.current.getSelectedZipcodes(),
                apnSelectedCounts,
              } : {}),
            });

            // Track GTM event
            await analyticsClient.track('campaign_creation_completed', {
              agentdata_user_id: user.id,
              role: user.role,
              brokerage_id: userInfo.agentInfo.brokerage?._id ?? null,
              campaign_event_type: selectedEventType.name,
            });

            if (isSavingAsDraft) {
              if (draftCampaign) {
                // Mark as stale so cache updates next time
                mutate(`/campaigns/${campaign._id}`);
              }

              // Go to the dashboard
              history.push(DASHBOARD_ROUTE);

              setCurrentAlert('success', 'Your campaign draft has been saved. You can return to this campaign to pick up where you left off in the future.');
            } else {
              setDraftCampaign(campaign);

              // Go to the next page for editing touches
              setStep(2);
            }
          } catch (err) {
            console.error('error', err);
            showAPIErrorAlert(setCurrentAlert, err);
          }

          hideLoadingIndicatorModal();
        }}
        validationSchema={Yup.object().shape({
          extraMailOutAddresses: Yup.array().of(Yup.object().shape({
            firstName: Yup.string().required().matches(nameRegExp),
            lastName: Yup.string().required().matches(nameRegExp),
            email: Yup.string().email().optional(),
            phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').optional(),
            address: addressYupValidation.required(),
          })),
          firstName: Yup.string().matches(nameRegExp).optional(),
          lastName: Yup.string().matches(nameRegExp).optional(),
          email: Yup.string().email().optional(),
          phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').optional(),
          address: addressYupValidation.optional(),
          farmAreaName: Yup.string().required(),
          agentLandingPageType: Yup.string().required(),
          addOnPropertiesJsonString: Yup.string().nullable(),
          touchTemplateDesignId: Yup.string().required(),
        })}
      >
        {({
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, setTouched,
        }) => {
          const handleOnSave = (isNext) => {
            const {
              firstName, lastName, email, phoneNumber, address,
            } = values;

            if (!draftCampaign || !draftCampaign.autoRenewalForCampaign) {
              // Make sure they refetched since last change
              if (!dataSelectionOverlayRef.current.areDataCountsUpToDate()) {
                setCurrentAlert('warning', 'Please click on the Generate Costs button to update the number of APNs in your latest farm area selection!');
                return;
              }

              // Make sure they have > 0 selected parcels
              if (apnsTotalSelectedCount === 0) {
                setCurrentAlert('warning', 'There are no parcels in your selection area. Please select an area or expand your selection!');
                return;
              }
            }

            if (firstName || lastName || email || phoneNumber || address.address1) {
              setCurrentAlert('warning', 'Did you forget to add an extra recipient to your campaign?');
              return;
            }

            if (!isNext) {
              setIsSavingAsDraft(true);
              handleSubmit();
              return;
            }

            // Don't show the farm area disclaimer for auto-renewal campaigns or if
            // there is no difference in the available and total count
            if (draftCampaign?.autoRenewalForCampaign || apnsTotalSelectedCount === apnsTotalInAreaCount) {
              setIsSavingAsDraft(false);
              handleSubmit();
            } else {
              setShowFarmAreaDisclaimer(true);
            }
          };

          return (
            <Form>
              <FormikOnError>
                {showFarmAreaDisclaimer && (
                  <ActionModal
                    maxWidth="sm"
                    backgroundColor={theme.palette.offWhite.main}
                    textColor={theme.palette.darkGray.main}
                    icon={<LibraryAddCheckRoundedIcon />}
                    onClose={() => setShowFarmAreaDisclaimer(false)}
                    title="Farm Area Disclaimer"
                    message={(
                      <div>
                        {`I agree that my campaign will only be sent to ${numberWithCommas(apnsTotalSelectedCount)} of the ${numberWithCommas(apnsTotalInAreaCount)} APNs in the target Farm Area.`}

                        {hasFarmLimit ? ` I set the limit of ${numberWithCommas(apnSelectedCounts.reduce((sum, { count }) => sum + count, 0))} in the Farm Area selector above.` : ''}
                        <br />
                        <br />
                        Of the APNs in the target farm area, which the campaign is NOT targeting, some of them may be used exclusively by other agents.
                      </div>
                    )}
                    buttons={[
                      <ButtonBase onClick={() => setShowFarmAreaDisclaimer(false)}>Go Back</ButtonBase>,
                      <ButtonBase
                        onClick={() => {
                          setShowFarmAreaDisclaimer(false);
                          setIsSavingAsDraft(false);
                          handleSubmit();
                        }}
                        className={classes.confirmDisclaimerButton}
                      >
                        Continue
                      </ButtonBase>,
                    ]}
                  />
                )}

                {/* Don't show farm selection for auto-renewals */}
                {!draftCampaign?.autoRenewalForCampaign && (
                  <div className={classes.map}>
                    <DataMap
                      dataSelectionOverlayRef={dataSelectionOverlayRef}
                      apnSelectedCounts={apnSelectedCounts}
                      setApnSelectedCounts={setApnSelectedCounts}
                      setApnAvailableCounts={setApnAvailableCounts}
                      setApnsTotalSelectedCount={setApnsTotalSelectedCount}
                      setApnsTotalInAreaCount={setApnsTotalInAreaCount}
                      allowDataSelection
                      filtersModalOptions={{
                        selectPropertyTypesByDefault: ['SFR', 'Condominium', 'Townhouse', 'Multi Family Dwelling', 'Apartment Building'],
                        disableEventTypeSelector: true,
                      }}
                      initPropertyFilters={draftCampaign?.propertyFilters ?? null}
                      initSelectionPolygonPaths={draftCampaign?.selectionPolygons.coordinates ?? []}
                      initSelectedZipcodes={initSelectedZipcodes}
                    />
                  </div>
                )}

                <div className={classes.infoCards}>
                  <CampaignInfoCards
                    startDate={campaignDates ? campaignDates[0] : null}
                    endDate={campaignDates ? campaignDates[1] : null}
                    campaignCosts={estimatedCosts}
                    valuesAreEstimates
                    apnAvailableCounts={apnAvailableCounts}
                    apnSelectedCounts={apnSelectedCounts}
                    setApnSelectedCounts={setApnSelectedCounts}
                    apnsTotalSelectedCount={draftCampaign?.autoRenewalForCampaign ? draftCampaign.estimatedNumberOfApns : apnsTotalSelectedCount}
                    apnsTotalInAreaCount={apnsTotalInAreaCount}
                    setApnsTotalSelectedCount={setApnsTotalSelectedCount}
                    canReduceCosts
                  />
                </div>

                {/* NOTE: We took out DocuSign 06/21/2022 */}
                {/* <DashboardBubble
                  rootClass={classes.campaignDatesMessage}
                  backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
                  icon={<WarningRoundedIcon htmlColor={theme.palette.warning.main} />}
                  content="The campaign start and end dates may shift to a later date depending on when the DocuSign contract is completed by the agent."
                /> */}

                {/* Let the user know about possible overlapping properties, i.e. there aren't only zip codes selected */}
                {apnAvailableCounts.length > 1 && apnAvailableCounts.filter(({ name }) => name !== 'Custom Shapes' && name !== 'Add On Properties').length !== apnAvailableCounts.length && (
                  <DashboardBubble
                    rootClass={classes.campaignDatesMessage}
                    backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
                    icon={<WarningRoundedIcon htmlColor={theme.palette.warning.main} />}
                    content="You may have overlapping properties in your campaign's farm area. We will make sure to remove any duplicate properties, and if we do, the actual number of APNs in your campaign may be lower than the estimate shown here."
                  />
                )}

                <DashboardSection sectionName="Customization" />
                <div className={classes.container}>
                  <Field name="farmAreaName">
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Farm Area Name"
                        inputProps={{
                          ...field,
                          placeholder: 'Enter name',
                          endAdornment: <InfoOutlinedIcon />,
                          className: classes.settingInput,
                        }}
                        error={errors[field.name] !== undefined}
                        tooltip="This will be your farm area name merged onto certain marketing materials and refers to you being an expert in that community, neighborhood, zipcode and/or city, i.e. Hollywood Hills, The Westside, Crescent Heights"
                      />
                    )}
                  </Field>
                  {/* Only show the agent landing page selector for non-commercial properties since we don't do comparables for commercial properties */}
                  {selectedEventType.name !== 'Commercial' && (
                  <div style={{ marginTop: '40px' }}>
                    <ChooseAgentLandingPageType />
                  </div>
                  )}
                  <div style={{ marginTop: '40px' }}>
                    <ChooseTouchTemplateDesign eventType={selectedEventType} />
                  </div>
                </div>

                {(!draftCampaign || !draftCampaign.autoRenewalForCampaign) && (
                <>
                  <DashboardSection sectionName="Add More Properties to Your Campaign" />
                  <div className={classes.container}>
                    <AddOnPropertiesInput
                      apnAvailableCounts={apnAvailableCounts}
                      setApnAvailableCounts={setApnAvailableCounts}
                      apnSelectedCounts={apnSelectedCounts}
                      setApnSelectedCounts={setApnSelectedCounts}
                      setApnsTotalSelectedCount={setApnsTotalSelectedCount}
                      apnsTotalInAreaCount={apnsTotalInAreaCount}
                      setApnsTotalInAreaCount={setApnsTotalInAreaCount}
                    />
                  </div>
                </>
                )}

                <div className={classes.spacerSmall} />

                <div className={classes.spacerSmall} />

                <DashboardSection sectionName="Add Extra Recipients" />
                <div className={classes.container}>
                  <ExtraMailOutAddressesInput setExtraMailOutsCount={setExtraMailOutsCount} />
                </div>

                <div className={classes.spacerBig} />

                {draftCampaign !== null && (
                <Button
                  round
                  color="danger"
                  onClick={confirmDraftDeletion}
                  className={classes.deleteDraftButton}
                >
                  <DeleteRoundedIcon />
                  {draftCampaign.autoRenewalForCampaign ? 'Cancel Renewal' : 'Delete Draft'}
                </Button>
                )}

                <Button
                  round
                  color="dark"
                  disabled={isSubmitting}
                  onClick={() => handleOnSave(false)}
                >
                  <ScheduleRoundedIcon />
                  Save Draft
                </Button>

                <Button
                  round
                  color="primary"
                  className={classes.nextButton}
                  disabled={isSubmitting}
                  onClick={() => handleOnSave(true)}
                >
                  Next
                </Button>
              </FormikOnError>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateCampaignDataSelection;
