import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/homeNavbarStyle';

import logo from 'assets/img/logo-dark.png';

const useStyles = makeStyles(styles);

const HomeNavbar = ({ color }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));

  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [productsOpen, setProductsOpen] = React.useState(false);

  // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => (window.location.href.indexOf(routeName) > -1);
  const activeRoute = (routeName) => (routeName === '/' ? window.location.pathname === '/' : window.location.pathname.indexOf(routeName) > -1);

  const handleProductDropdownClick = () => {
    setProductsOpen(false);
    handleDrawerToggle();
  };

  const productsList = (
    <List className={classes.dropdownList} component="div" disablePadding>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/absentee-owners'),
      })}
      >
        <NavLink
          to="/products/absentee-owners"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="Absentee Owner" />
        </NavLink>
      </ListItem>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/nod'),
      })}
      >
        <NavLink
          to="/products/nod"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="NODs" />
        </NavLink>
      </ListItem>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/full-farm'),
      })}
      >
        <NavLink
          to="/products/full-farm"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="Full Farm" />
        </NavLink>
      </ListItem>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/commercial'),
      })}
      >
        <NavLink
          to="/products/commercial"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="Commercial" />
        </NavLink>
      </ListItem>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/crm-pro'),
      })}
      >
        <NavLink
          to="/products/crm-pro"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="CRM Pro" />
        </NavLink>
      </ListItem>
      <ListItem className={cx(classes.dropdownListItem, {
        [classes.dropdownListItemActive]: activeRoute('products/brokerage-accounts'),
      })}
      >
        <NavLink
          to="/products/brokerage-accounts"
          className={classes.navLink}
          onClick={handleProductDropdownClick}
        >
          <ListItemText primary="Brokerage Accounts" />
        </NavLink>
      </ListItem>
    </List>
  );

  const list = (
    <List className={classes.list}>
      {/* TODO: Disabled for now since we took out the marketing website */}
      {/* <ListItem
        className={cx(classes.listItem, classes.listItemDropdownHeader, {
          [classes.listItemActive]: activeRoute('/products'),
        })}
        button
        onClick={isMobileWidth ? () => setProductsOpen(!productsOpen) : () => setProductsOpen(true)}
        disableRipple
        disableTouchRipple
      >
        <ListItemText
          primary="Products"
          className={classes.listItemText}
        />
        {productsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Hidden smDown>
        <Collapse in={productsOpen} className={classes.dropdownCollapse} unmountOnExit timeout={0}>
          <ClickAwayListener onClickAway={() => setProductsOpen(false)}>
            {productsList}
          </ClickAwayListener>
        </Collapse>
      </Hidden>
      <Hidden mdUp>
        {productsOpen && productsList}
      </Hidden>

      <ListItem
        className={cx(classes.listItem, {
          [classes.listItemActive]: activeRoute('/pricing-page'),
        })}
      >
        <NavLink to="/pricing-page" className={classes.navLink} onClick={handleDrawerToggle}>
          <ListItemText
            primary="Pricing"
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>

      <ListItem
        className={cx(classes.listItem, {
          [classes.listItemActive]: activeRoute('/case-studies'),
        })}
      >
        <NavLink to="/case-studies" className={classes.navLink} onClick={handleDrawerToggle}>
          <ListItemText
            primary="Case Studies"
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>

      <ListItem
        className={cx(classes.listItem, {
          [classes.listItemActive]: activeRoute('/close-like-a-pro'),
        })}
      >
        <NavLink to="/close-like-a-pro" className={classes.navLink} onClick={handleDrawerToggle}>
          <ListItemText
            primary="Close Like A Pro!"
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem> */}

      <Hidden smDown>
        <ListItem
          className={cx(classes.listItem, classes.listItemNoHoverShadow, {
            [classes.listItemLeftMargin]: !isMobileWidth,
          })}
        >
          <NavLink
            to="/login"
            className={cx(classes.navLink, classes.navLinkUnderlined)}
            onClick={handleDrawerToggle}
          >
            <ListItemText
              primary="Login"
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>

        <ListItem className={cx(classes.listItem, classes.listItemNoHoverShadow, classes.listItemButton)}>
          <a href="https://harvist.com/sign-up/" className={classes.navLink}>
            <Button color="primary" round outlined className={classes.getItButton}>
              Sign Up
            </Button>
          </a>
        </ListItem>
      </Hidden>

    </List>
  );

  return (
    <AppBar position="static" className={classes.appBar} style={{ backgroundColor: color }}>
      <Toolbar className={classes.container} disableGutters>
        <GridContainer justifyContent="space-between" alignItems="center">
          <GridItem>
            <a href="https://harvist.com">
              <img src={logo} alt="Harvist" className={classes.logo} />
            </a>
          </GridItem>
          {isMobileWidth ? (
            <>
              <Button
                className={classes.sidebarButtonOpen}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                size={!isXsWidth ? 'lg' : null}
              >
                <MenuIcon />
              </Button>

              <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
                TransitionComponent={Fade}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <div className={classes.drawerHeaderContainer}>
                  <a
                    href="/"
                  >
                    <img src={logo} alt="Harvist" className={classes.drawerLogo} />
                  </a>
                  <Button
                    className={classes.sidebarButtonClose}
                    color="transparent"
                    justIcon
                    aria-label="close drawer"
                    onClick={handleDrawerToggle}
                    size={!isXsWidth ? 'lg' : null}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                {/* TODO: Disabled for now since we took out the marketing website */}
                {/* {list} */}
                <div className={classes.drawerFooterContainer}>
                  <div className={classes.drawerGetItButton}>
                    <a
                      href="https://harvist.com/sign-up/"
                      className={cx(classes.navLink, classes.navLinkDrawerButton)}
                    >
                      <Button color="primary" round outlined className={classes.getItButton}>
                        Sign Up
                      </Button>
                    </a>
                  </div>
                  <div>
                    <NavLink
                      to="/login"
                      className={cx(classes.navLink, classes.navLinkUnderlined, classes.navLinkDrawerButton)}
                      onClick={handleDrawerToggle}
                    >
                      <ListItemText
                        primary="Log-in"
                        className={classes.listItemText}
                      />
                    </NavLink>
                  </div>
                </div>
              </Drawer>
            </>
          ) : (
            <GridItem>
              {list}
            </GridItem>
          )}
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
};

export default HomeNavbar;
