import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Dialog, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import TermsCheckbox from 'components/AgentLanding/TermsCheckbox';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';

import { submitAgentLandingConsentForm } from 'utils/api';
import { isAgentLandingPageSample, phoneRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    backdropFilter: 'blur(6px)',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.black.main,
  },
  line: {
    margin: '13px auto 32px',
    width: '100%',
    maxWidth: '163px',
    border: '1px solid rgba(0, 0, 0, 0.44)',
  },
  button: {
    margin: '32px 0 0',
    width: '100%',
  },
}));

const ConsentForm = ({ onClose, agentLandingPageToken, comparablesLoaded }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const formRef = React.useRef();

  return (
    <Dialog
      open
      keepMounted
      maxWidth="xs"
      scroll="body"
      classes={{
        root: classes.dialogRoot,
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          phoneNumber: '',
          email: '',
          recaptchaValue: '',
          agreesToTerms: true,
        }}
        onSubmit={async ({
          phoneNumber, email, recaptchaValue,
        }) => {
          showLoadingIndicatorModal();

          try {
            if (!isAgentLandingPageSample(agentLandingPageToken)) {
              await submitAgentLandingConsentForm(agentLandingPageToken, phoneNumber, email, recaptchaValue);
            }

            onClose();
          } catch (error) {
            console.error('error', error);
          }

          hideLoadingIndicatorModal();
        }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          email: Yup.string().email().required(),
          recaptchaValue: Yup.string().required(),
          agreesToTerms: Yup.boolean().isTrue().required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          if (!comparablesLoaded) return <LoadingIndicator />;

          return (
            <Form>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.title}>Your Personal Estimate Awaits</h3>
                  <div className={classes.line} />
                </GridItem>
                <GridItem xs={12}>
                  <Field name="phoneNumber">
                    {({ field }) => (
                      <CustomInput
                        labelText="Phone Number"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          type: 'tel',
                          autoComplete: 'tel',
                        }}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="email">
                    {({ field }) => (
                      <CustomInput
                        labelText="Email Address"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          autoComplete: 'email',
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="recaptchaValue">
                    {({ field }) => (
                      <ReCAPTCHAInput
                        fieldName={field.name}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="agreesToTerms">
                    {({ field }) => (
                      <TermsCheckbox fieldName={field.name} />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    color="orange"
                    round
                    size="lg"
                    className={classes.button}
                    disabled={isSubmitting || !values.agreesToTerms}
                    onClick={handleSubmit}
                  >
                    Get Your Estimate Today!
                  </Button>
                </GridItem>
              </GridContainer>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ConsentForm;
