import React from 'react';

export const MapInfoContext = React.createContext(null);

export const getMousePositionFromEvent = (e) => ({
  lat: e.latLng.lat(),
  lng: e.latLng.lng(),
});

const MapInfoProvider = (props) => {
  const [mapInstance, setMapInstance] = React.useState(null);
  const [mapBounds, setMapBounds] = React.useState(null);

  const overlayViewsRef = React.useRef(null); // Use a ref to get around the memoized stuff
  const [overlayViews, setOverlayViews] = React.useState([]);

  React.useEffect(() => {
    overlayViewsRef.current = overlayViews;
  }, [overlayViews]);

  const addOverlayView = (key, priority, render) => {
    // Remove overlay view if exists for same key
    const newOverlayViews = [...overlayViewsRef.current].filter((o) => o.key !== key);

    newOverlayViews.push({ key, priority, render });

    // Sort by priority
    newOverlayViews.sort((a, b) => {
      if (a.priority < b.priority) return -1;
      return 1;
    });

    setOverlayViews(newOverlayViews);
  };

  const removeOverlayView = (key) => {
    const newOverlayViews = [...overlayViewsRef.current].filter((o) => o.key !== key);
    setOverlayViews(newOverlayViews);
  };

  const MapInfoProviderValue = React.useMemo(() => ({
    mapInstance,
    setMapInstance,
    mapBounds,
    setMapBounds,
    overlayViews,
    addOverlayView,
    removeOverlayView,
  }), [mapInstance, mapBounds, overlayViews]);

  return <MapInfoContext.Provider value={MapInfoProviderValue} {...props} />;
};

export const useMapInfoContext = () => React.useContext(MapInfoContext);

export const withMapInfoProvider = (Component) => ({ ...props }) => (
  <MapInfoProvider>
    <Component {...props} />
  </MapInfoProvider>
);

export default MapInfoProvider;
