import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { updateTouchVoicemail } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  voicemailsList: {
    backgroundColor: 'rgba(196, 196, 196, 0.11)',
    borderRadius: '22px',
    padding: '30px 0 30px 30px',
    margin: '40px 0 0',
    width: '100%',
    overflowX: 'auto',

    '& > div': {
      paddingRight: '30px',
      maxHeight: '467px',
      overflowY: 'auto',
    },
  },
  voicemailRowContainer: {
    width: '100%',
    display: 'inline-block',
  },
  voicemailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > h4': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',
      color: theme.palette.grayScale11.main,
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      marginRight: '40px',

      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
  },
  voicemailRowRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  voicemailRowDivider: {
    borderBottom: '1px solid #D3D3D3',
    margin: '15px 0',
  },

  button: {
    minWidth: '100px', // So buttons stay the same width when changing text
    color: theme.palette.grayScale9.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '8px',
    marginLeft: '18px',
    padding: '12px 24px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  selected: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.success.main,
    '& > svg': {
      marginLeft: '6px',
      width: '16px',
      height: '16px',
    },
  },

  recordNewContainer: {
    textAlign: 'center',
    margin: '30px 0 0',
  },
}));

const ChooseVoicemail = ({
  eventTypeVoicemails, touchTrigger, onReceiveEditedTouchTrigger,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [selectedVoicemailId, setSelectedVoicemailId] = React.useState(touchTrigger.metadata.voicemail?._id || null);

  const onSelectVoicemail = async (voicemailId) => {
    showLoadingIndicatorModal();

    try {
      const { touchTrigger: newTouchTrigger } = await updateTouchVoicemail(touchTrigger._id, { voicemailId });

      setSelectedVoicemailId(voicemailId);

      // Callback to update the touch trigger locally
      onReceiveEditedTouchTrigger(newTouchTrigger);
    } catch (err) {
      console.error('error', err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onRecordNewVoicemail = () => {
    history.push({ pathname: '/dashboard/marketing-profile', state: { activeTab: 'Voicemails' } });
  };

  if (!eventTypeVoicemails) return <LoadingIndicator />;

  return (
    <div className={classes.voicemailsList}>
      <div>
        {eventTypeVoicemails.map(({ _id, name, recordingUrl }, i) => (
          <div key={_id} className={classes.voicemailRowContainer}>
            <div className={classes.voicemailRow}>
              <h4>{name}</h4>
              <div className={classes.voicemailRowRight}>
                <audio
                  src={recordingUrl}
                  type="audio/mpeg"
                  controls
                  preload="auto"
                />
                <ButtonBase
                  className={cx(classes.button, _id === selectedVoicemailId && classes.selected)}
                  disabled={_id === selectedVoicemailId}
                  onClick={() => onSelectVoicemail(_id)}
                >
                  {_id === selectedVoicemailId ? 'Selected' : 'Select'}
                </ButtonBase>
              </div>
            </div>
            <div className={classes.voicemailRowDivider} />
          </div>
        ))}

        <div className={classes.recordNewContainer}>
          <Button
            round
            color="primary"
            onClick={onRecordNewVoicemail}
          >
            Record New Voicemail
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseVoicemail;
