const style = (theme) => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  content: {
    flex: 1,
    display: 'flex!important',
    margin: '0',
    border: '0',
    backgroundColor: theme.palette.white.main,
    alignItems: 'flex-start',
    height: '100%',
  },
});

export default style;
