import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles((theme) => ({

}));

export default function BrokerageBranchesTable({ brokerage }) {
  const classes = useStyles();
  const { isBrokerageAdmin } = useAuthDataContext();

  const [openModal, setOpenModal] = React.useState(null);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!brokerage) return;

      setData(brokerage.branches.map((branch) => ({
        _id: branch._id,
        name: branch.name,
        numAgents: branch.agentIds.length,
        createdAt: moment(branch.createdAt).toDate(),
      })));
    })();
  }, [brokerage]);

  if (!brokerage) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {openModal}

      <ReactTable
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row, value }) => (isBrokerageAdmin ? (
              <Link to={{
                pathname: '/dashboard/brokerage-branch-marketing-profile',
                state: { brokerageBranchId: row.original._id },
              }}
              >
                {value}
              </Link>
            ) : value),
          },
          {
            Header: '# of Agents',
            accessor: 'numAgents',
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
            sortType: 'datetime',
          },
        ]}
        data={data}
        normalRightColumn
      />
    </>
  );
}
