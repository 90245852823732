import React from 'react';
import { Redirect } from 'react-router-dom';

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';

import PrivateRoute from 'components/AuthDataProvider/PrivateRoute';
import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import AdvertisingIcon from 'components/CustomIcons/AdvertisingIcon';
import BillingIcon from 'components/CustomIcons/BillingIcon';
import FAQIcon from 'components/CustomIcons/FAQIcon';
import FourSquareIcon from 'components/CustomIcons/FourSquareIcon';
import HomeIcon from 'components/CustomIcons/HomeIcon';
import ProfileIcon from 'components/CustomIcons/ProfileIcon';

import AgentsAdminPage from 'views/Admin/AgentsAdminPage';
import AllBrokeragesPage from 'views/Admin/AllBrokeragesPage';
import BrokerageDetailPage from 'views/Admin/BrokerageDetailPage';
import CampaignsAdminPage from 'views/Admin/CampaignsAdminPage';
// import InterestApplicationsAdminPage from 'views/Admin/InterestApplicationsAdminPage';
import UsersAdminPage from 'views/Admin/UsersAdminPage';
import APNCountsMapPage from 'views/APNCountsMapPage';
import BrokerageBranchMarketingProfilePage from 'views/BrokerageAdmin/BrokerageBranchMarketingProfilePage';
import MyBrokeragePage from 'views/BrokerageAdmin/MyBrokeragePage';
import AdCampaignDetailPage from 'views/Dashboard/ad-campaigns/AdCampaignDetailPage';
import AdCampaignsPage from 'views/Dashboard/ad-campaigns/AdCampaignsPage';
import CreateAdCampaignPage from 'views/Dashboard/ad-campaigns/CreateAdCampaignPage';
import CampaignDetailPage from 'views/Dashboard/CampaignDetailPage';
import CampaignsPage from 'views/Dashboard/CampaignsPage';
import CreateCampaignPage from 'views/Dashboard/CreateCampaignPage';
import DashboardPageWrapper from 'views/Dashboard/DashboardPageWrapper';
// import BrokerageBillingPage from 'views/Dashboard/BrokerageBillingPage';
import FAQPage from 'views/Dashboard/FAQPage';
import MarketingProfilePage from 'views/Dashboard/MarketingProfilePage';
import SettingsPage from 'views/Dashboard/SettingsPage';
import UserBillingPage from 'views/Dashboard/UserBillingPage';
import LoginPage from 'views/Pages/LoginPage';
import ResetPasswordPage from 'views/Pages/ResetPasswordPage';
import SimpleCreateCampaignPage from 'views/Pages/SimpleCreateCampaignPage';
import UnsubscribePage from 'views/Unsubscribe/UnsubscribePage';

import { agentAdvicePageUrl } from 'utils/lib';

const routes = [
  {
    exact: true,
    path: '',
    name: 'APN Counts Map',
    icon: MapRoundedIcon,
    allowedRoles: ['Title Rep'],
    render: () => (<PrivateRoute component={APNCountsMapPage} requiredRoles={['Title Rep']} />),
    layout: '/dashboard',
  },

  {
    exact: true,
    path: '',
    name: 'Dashboard',
    icon: HomeIcon,
    allowedRoles: ['Admin', 'Support', 'Account Manager', 'Brokerage Admin', 'Brokerage Agent', 'Agent'],
    render: () => (
      <PrivateRoute
        component={DashboardPageWrapper}
        allowedRoles={['Admin', 'Support', 'Account Manager', 'Brokerage Admin', 'Brokerage Agent', 'Agent']}
      />
    ),
    layout: '/dashboard',
  },

  {
    collapse: true,
    collapseAlwaysOpen: true,
    name: 'Admin',
    state: 'adminCollapse',
    allowedRoles: ['Admin', 'Support', 'Account Manager'],
    views: [
      {
        path: '/admin/data-map',
        name: 'APN Counts Map',
        icon: MapRoundedIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support'],
        render: () => (<PrivateRoute component={APNCountsMapPage} requiredRoles={['Admin', 'Support']} />),
      },
      /* {
        path: '/admin/interest-applications',
        name: 'Interest Applications',
        icon: AssignmentIndRoundedIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support'],
        render: () => (<PrivateRoute component={InterestApplicationsAdminPage} requiredRoles={['Admin', 'Support']} />),
      }, */
      {
        path: '/admin/campaigns',
        name: 'Campaigns',
        icon: FourSquareIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support'],
        render: () => (<PrivateRoute component={CampaignsAdminPage} requiredRoles={['Admin', 'Support']} />),
      },
      {
        path: '/admin/ad-campaigns',
        name: 'Digital Ads',
        icon: AdvertisingIcon,
        allowedRoles: ['Admin', 'Support'],
        render: () => (<PrivateRoute component={AdCampaignsPage} requiredRoles={['Admin', 'Support']} />),
        layout: '/dashboard',
      },
      {
        // Must be above the route below to match first
        path: '/admin/brokerages/:brokerageId',
        hidden: true, // Hide in the sidebar
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support', 'Account Manager'],
        render: () => (<PrivateRoute component={BrokerageDetailPage} requiredRoles={['Admin', 'Support', 'Account Manager']} />),
      },
      {
        path: '/admin/brokerages',
        name: 'Brokerages',
        icon: BusinessRoundedIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support', 'Account Manager'],
        render: () => (<PrivateRoute component={AllBrokeragesPage} requiredRoles={['Admin', 'Support', 'Account Manager']} />),
      },
      {
        path: '/admin/agents',
        name: 'Agents',
        icon: SupervisorAccountRoundedIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support'],
        render: () => (<PrivateRoute component={AgentsAdminPage} requiredRoles={['Admin', 'Support']} />),
      },
      {
        path: '/admin/users',
        name: 'Users',
        icon: PersonRoundedIcon,
        layout: '/dashboard',
        allowedRoles: ['Admin'],
        render: () => (<PrivateRoute component={UsersAdminPage} requiredRoles={['Admin']} />),
      },
    ],
  },

  {
    path: '/brokerage-branch-marketing-profile',
    name: 'Brokerage Branch Marketing Profile',
    hidden: true, // Hide in the sidebar
    allowedRoles: ['Brokerage Admin'],
    render: () => (<PrivateRoute component={BrokerageBranchMarketingProfilePage} requiredRoles={['Brokerage Admin']} />),
    layout: '/dashboard',
  },
  {
    path: '/my-brokerage',
    name: 'My Brokerage',
    icon: BusinessRoundedIcon,
    allowedRoles: ['Brokerage Admin'],
    render: () => (<PrivateRoute component={MyBrokeragePage} requiredRoles={['Brokerage Admin']} />),
    layout: '/dashboard',
  },

  {
    path: '/marketing-profile',
    name: 'Marketing Profile',
    icon: ProfileIcon,
    allowedRoles: ['Brokerage Agent', 'Agent'],
    render: () => (<PrivateRoute component={MarketingProfilePage} requiredRoles={['Brokerage Agent', 'Agent']} />),
    layout: '/dashboard',
  },

  {
    collapse: true,
    collapseAlwaysOpen: true,
    name: 'Products',
    state: 'productsCollapse',
    allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
    views: [
      {
        path: '/ad-campaigns/create',
        hidden: true, // Hide in the sidebar
        layout: '/dashboard',
        allowedRoles: ['Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CreateAdCampaignPage} requiredRoles={['Brokerage Agent', 'Agent']} />),
      },
      {
        path: '/ad-campaigns/:adCampaignId',
        hidden: true, // Hide in the sidebar
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support', 'Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={AdCampaignDetailPage} requiredRoles={['Admin', 'Support', 'Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
      },
      {
        path: '/ad-campaigns',
        name: 'Digital Ads',
        layout: '/dashboard',
        icon: AdvertisingIcon,
        allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={AdCampaignsPage} requiredRoles={['Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
      },

      // NOTE: Lowercase the event type name and replace spaces with hyphens since we link to these pages from elsewhere! i.e. 'Absentee Owners' -> 'absentee-owners'
      {
        // Available for admins too
        path: '/campaigns/:campaignId',
        hidden: true, // Hide in the sidebar
        layout: '/dashboard',
        allowedRoles: ['Admin', 'Support', 'Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CampaignDetailPage} requiredRoles={['Admin', 'Support', 'Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
      },
      {
        path: '/absentee-owners-campaigns',
        name: 'Absentee Owners',
        icon: FourSquareIcon,
        allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CampaignsPage} componentProps={{ eventTypeName: 'Absentee Owners' }} requiredRoles={['Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
        layout: '/dashboard',
      },
      /* {
        path: '/nod-campaigns',
        name: 'NOD',
        icon: FourSquareIcon,
        allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CampaignsPage} componentProps={{ eventTypeName: 'NOD' }} requiredRoles={['Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
        layout: '/dashboard',
      }, */
      /* {
        path: '/commercial-campaigns',
        name: 'Commercial',
        icon: FourSquareIcon,
        allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CampaignsPage} componentProps={{ eventTypeName: 'Commercial' }} requiredRoles={['Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
        layout: '/dashboard',
      }, */
      {
        path: '/full-farm-campaigns',
        name: 'Full Farm',
        icon: FourSquareIcon,
        allowedRoles: ['Brokerage Admin', 'Brokerage Agent', 'Agent'],
        render: () => (<PrivateRoute component={CampaignsPage} componentProps={{ eventTypeName: 'Full Farm' }} requiredRoles={['Brokerage Admin', 'Brokerage Agent', 'Agent']} />),
        layout: '/dashboard',
      },
    ],
  },
  {
    path: '/create-campaign',
    hidden: true, // Hide in the sidebar
    name: 'Create Campaign',
    allowedRoles: ['Brokerage Agent', 'Agent'],
    render: () => (
      <PrivateRoute component={CreateCampaignPage} requiredRoles={['Brokerage Agent', 'Agent']} />
    ),
    layout: '/dashboard',
  },

  // {
  //   path: '/crm-pro',
  //   name: 'CRM Pro',
  //   icon: GroupIcon,
  //   allowedRoles: ['Agent'],
  //   render: () => (<PrivateRoute component={CRMProDashboardPage} requiredRoles={['Agent']} />),
  //   layout: '/dashboard',
  // },
  {
    path: '/faq',
    name: 'FAQ',
    icon: FAQIcon,
    render: () => (<PrivateRoute component={FAQPage} />),
    layout: '/dashboard',
  },
  {
    path: '/billing',
    name: 'Billing',
    icon: BillingIcon,
    allowedRoles: ['Brokerage Agent', 'Agent'],
    render: () => (<PrivateRoute component={UserBillingPage} requiredRoles={['Brokerage Agent', 'Agent']} />),
    layout: '/dashboard',
  },
  /* {
    path: '/brokerage-billing',
    name: 'Billing',
    icon: BillingIcon,
    allowedRoles: ['Brokerage Admin'],
    render: () => (<PrivateRoute component={BrokerageBillingPage} requiredRoles={['Brokerage Admin']} />),
    layout: '/dashboard',
  }, */

  // TODO: Re-enable at some point?
  // {
  //   path: '/feedback',
  //   name: 'Feedback',
  //   icon: FeedbackIcon,
  //   allowedRoles: ['Agent'],
  //   render: () => (<PrivateRoute component={FeedbackPage} requiredRoles={['Agent']} />),
  //   layout: '/dashboard',
  // },

  {
    path: '/settings',
    name: 'Settings',
    hidden: true, // Hide in the sidebar
    allowedRoles: ['Brokerage Agent', 'Agent'],
    render: () => (<PrivateRoute component={SettingsPage} requiredRoles={['Brokerage Agent', 'Agent']} />),
    layout: '/dashboard',
  },

  { // AgentAdvice link set up with a Rewardful campaign
    path: `/launch/${agentAdvicePageUrl}`,
    name: 'Launch Campaign',
    short: 'Launch Campaign',
    layout: '/simple',
    // We don't restrict this public route since they register during it
    render: () => (<PublicRoute component={SimpleCreateCampaignPage} />),
  },
  { // Harvist internal link
    path: '/launch/hp2s',
    name: 'Launch Campaign',
    short: 'Launch Campaign',
    layout: '/simple',
    render: () => (<PublicRoute component={SimpleCreateCampaignPage} />),
  },

  { // Same page as reset-password, but we just change the heading
    path: '/register',
    name: 'Register',
    short: 'Register',
    layout: '',
    render: () => (<PublicRoute restricted component={ResetPasswordPage} heading="Create Your Password" subheading="You will be able to log into your Harvist dashboard with this password moving forward." />),
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    short: 'Reset Password',
    layout: '',
    render: () => (<PublicRoute restricted component={ResetPasswordPage} heading="Reset Your Password" />),
  },
  {
    path: '/login',
    name: 'Login',
    short: 'Login',
    layout: '',
    render: () => (<PublicRoute restricted component={LoginPage} />),
  },
  {
    exact: true,
    path: '/',
    name: 'Home',
    layout: '',
    render: () => (<Redirect to="/login" />),
  },

  /* Unsubscribe Layout */
  {
    exact: true,
    path: '',
    layout: '/unsubscribe',
    name: 'Unsubscribe',
    render: (props) => (<PublicRoute component={UnsubscribePage} {...props} />),
  },
];

export default routes;
