import {
  grayColor,
  roseColor,
  infoColor,
  whiteColor,
  blackColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';

const buttonGrayColor = grayColor[3];

const buttonStyle = (theme) => ({
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(grayColor[0])
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(grayColor[0])
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(grayColor[0])
      }, 0.12)`,
    border: 'none',
    borderRadius: '3px',
    position: 'relative',
    padding: '14px 32px',
    // margin: '.3125rem 1px',
    margin: 0,
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: grayColor[0],
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(grayColor[0])
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(grayColor[0])
        }, 0.2)`,
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginTop: '-1em',
      marginBottom: '-1em',
      fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      width: '18px',
      height: '18px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        marginTop: '0px',
        position: 'absolute',
        width: '100%',
        transform: 'none',
        left: '0px',
        top: '0px',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  info: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: infoColor[0],
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(infoColor[0])
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(infoColor[0])
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(infoColor[0])
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(infoColor[0])
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(infoColor[0])
        }, 0.2)`,
    },
  },
  warning: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.warning.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.warning.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.warning.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.warning.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.warning.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.warning.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.warning.main)
        }, 0.2)`,
    },
  },
  danger: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.error.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.error.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.error.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.error.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.error.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.error.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.error.main)
        }, 0.2)`,
    },
  },
  rose: {
    backgroundColor: roseColor[0],
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(roseColor[0])
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(roseColor[0])
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(roseColor[0])
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: roseColor[0],
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(roseColor[0])
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(roseColor[0])
        }, 0.2)`,
    },
  },
  gray: {
    backgroundColor: buttonGrayColor,
    color: whiteColor,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(buttonGrayColor)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(buttonGrayColor)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(buttonGrayColor)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: buttonGrayColor,
      color: whiteColor,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(buttonGrayColor)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(buttonGrayColor)
        }, 0.2)`,
    },
  },
  primary: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    color: theme.palette.black.main,
    backgroundColor: theme.palette.primary.light,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.primary.light)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.primary.light)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.primary.light)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.primary.light,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.primary.light)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.primary.light)
        }, 0.2)`,
    },
  },
  orange: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.orange.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.orange.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.orange.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.orange.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.orange.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.orange.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.orange.main)
        }, 0.2)`,
    },
  },
  light: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.white.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.white.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.white.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.white.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.white.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.white.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.white.main)
        }, 0.2)`,
    },
  },
  dark: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.gray.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.gray.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.gray.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.gray.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.gray.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.gray.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.gray.main)
        }, 0.2)`,
    },
  },
  success: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: theme.palette.success.main,
    boxShadow:
      `0 2px 2px 0 rgba(${
        hexToRgb(theme.palette.success.main)
      }, 0.14), 0 3px 1px -2px rgba(${
        hexToRgb(theme.palette.success.main)
      }, 0.2), 0 1px 5px 0 rgba(${
        hexToRgb(theme.palette.success.main)
      }, 0.12)`,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.success.main,
      boxShadow:
        `0 14px 26px -12px rgba(${
          hexToRgb(theme.palette.success.main)
        }, 0.42), 0 4px 23px 0px rgba(${
          hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
          hexToRgb(theme.palette.success.main)
        }, 0.2)`,
    },
  },
  white: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    boxShadow: 'none',

    '&,&:focus,&:hover': {
      backgroundColor: whiteColor,
      color: grayColor[0],
      boxShadow: 'none',
    },
  },
  simple: {
    '&,&:focus,&:hover': {
      color: whiteColor,
      background: 'transparent',
      boxShadow: 'none',
    },
    '&$info': {
      '&,&:focus,&:hover,&:visited': {
        color: infoColor[0],
      },
    },
    '&$success': {
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.success.main,
      },
    },
    '&$warning': {
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.warning.main,
      },
    },
    '&$rose': {
      '&,&:focus,&:hover,&:visited': {
        color: roseColor[0],
      },
    },
    '&$danger': {
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.error.main,
      },
    },
    '&$primary': { // New button
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.primary.light,
      },
    },
    '&$orange': { // New button
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.orange.main,
      },
    },
    '&$light': { // New button
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.white.main,
      },
    },
    '&$dark': { // New button
      '&,&:focus,&:hover,&:visited': {
        color: theme.palette.gray.main,
      },
    },
  },
  underlined: {
    '&,&:focus,&:hover': {
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: '2px', // Originally 12 on top and bottom
      marginBottom: '14px', // Original 5 + 9 (to make up for 12 original bottom padding)
      boxShadow: `0px -3px ${whiteColor} inset`,
    },
    '&$info': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px -3px ${infoColor[0]} inset`,
      },
    },
    '&$success': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px -3px ${theme.palette.success.main} inset`,
      },
    },
    '&$warning': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px -3px ${theme.palette.warning.main} inset`,
      },
    },
    '&$rose': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px -3px ${roseColor[0]} inset`,
      },
    },
    '&$danger': {
      transition: 'none',

      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px -3px ${theme.palette.error.main} inset`,
      },
    },
    '&$primary': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px -3px ${theme.palette.primary.light} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: `0px -3px ${theme.palette.orange.main} inset`,
        color: theme.palette.orange.main,
      },
    },
    '&$orange': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px -3px ${theme.palette.orange.main} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: `0px -3px ${theme.palette.primary.main} inset`,
        color: theme.palette.primary.main,
      },
    },
    '&$light': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px -3px ${theme.palette.white.main} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: `0px -3px ${theme.palette.orange.main} inset`,
        color: theme.palette.orange.main,
      },
    },
    '&$dark': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px -3px ${theme.palette.gray.main} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: `0px -3px ${theme.palette.orange.main} inset`,
        color: theme.palette.orange.main,
      },
    },
  },
  outlined: {
    '&,&:focus,&:hover': {
      color: whiteColor,
      background: 'transparent',
      boxShadow: `0px 0px 0px 2px ${whiteColor} inset`,
    },
    '&$info': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px 0px 0px 2px ${infoColor[0]} inset`,
      },
    },
    '&$success': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.success.main} inset`,
      },
    },
    '&$warning': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.warning.main} inset`,
      },
    },
    '&$rose': {
      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px 0px 0px 2px ${roseColor[0]} inset`,
      },
    },
    '&$danger': {
      transition: 'none',

      '&,&:focus,&:hover,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.error.main} inset`,
      },
    },
    '&$primary': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.primary.light} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.orange.main,
      },
    },
    '&$orange': { // New button
      transition: 'none', // TODO: good?
      color: theme.palette.darkGray.main,

      '&,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.orange.main} inset`,
      },
      '&:focus,&:hover': {
        color: 'white',
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&$light': { // New button
      transition: 'none', // TODO: good?

      '&,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.white.main} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&$dark': { // New button
      transition: 'none', // TODO: good?
      color: theme.palette.gray.main,

      '&,&:visited': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.gray.main} inset`,
      },
      '&:focus,&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.white.main,
      },
    },
  },
  transparent: { // New button
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',

    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  disabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  lg: {
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        marginTop: '-4px',
      },
    },
    height: '60px',
    // padding: '1.125rem 2.25rem',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  sm: {
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        marginTop: '1px',
      },
    },
    padding: '0.40625rem 1.25rem',
    fontSize: '0.6875rem',
    lineHeight: '1.5',
    borderRadius: '0.2rem',
  },
  round: {
    borderRadius: '45px',
  },
  block: {
    width: '100% !important',
  },
  link: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: grayColor[0],
      boxShadow: 'none',
    },
  },
  justIcon: {
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
    height: '41px',
    minWidth: '41px',
    width: '41px',
    '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
      marginRight: '0px',
    },
    '&$lg': {
      height: '57px',
      minWidth: '57px',
      width: '57px',
      lineHeight: '56px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '32px',
        lineHeight: '56px',
      },
      '& svg': {
        width: '32px',
        height: '32px',
      },
    },
    '&$sm': {
      height: '30px',
      minWidth: '30px',
      width: '30px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '17px',
        lineHeight: '29px',
      },
      '& svg': {
        width: '17px',
        height: '17px',
      },
    },
  },
  primaryTextColor: {
    color: theme.palette.primary.main,
  },
  orangeTextColor: {
    color: theme.palette.orange.main,
  },
  grayTextColor: {
    color: theme.palette.gray.main,
  },
  whiteTextColor: {
    color: theme.palette.white.main,
  },
  blackTextColor: {
    color: theme.palette.black.main,
  },
  darkGrayTextColor: {
    color: theme.palette.grayScale9.main,
  },
});

export default buttonStyle;
