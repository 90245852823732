import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import StatsPropertiesIcon from 'components/CustomIcons/StatsPropertiesIcon';
import StatsTouchesSentIcon from 'components/CustomIcons/StatsTouchesSentIcon';
import StatsEngagementsIcon from 'components/CustomIcons/StatsEngagementsIcon';
import StatsHotProspectsIcon from 'components/CustomIcons/StatsHotProspectsIcon';
import { Box, Container } from '@material-ui/core';
import { engagementLeadTypes, hotProspectLeadTypes } from 'types/stats';
import { useCampaignDeliveredTouchStats, useCampaignLeadResponseStats } from 'data/campaign-stats';
import StatsCard from './StatsCard';
import TouchAnalyticsGraph from './TouchAnalyticsGraph';
import CampaignProgressGraph from './CampaignProgressGraph';
import TouchesSentBarGraph from './TouchesSentBarGraph';
import EmailDeliverabilityStats from './EmailDeliverabilityStats';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  infoBubble: {
    maxWidth: '700px',
    margin: '40px auto 50px',
  },
}));

export interface Props {
  campaign: any;
  setActiveTab: (tab: string) => void;
}

export default ({ campaign, setActiveTab }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { deliveredTouchStats } = useCampaignDeliveredTouchStats(campaign._id);
  const { leadResponseStats } = useCampaignLeadResponseStats(campaign._id);

  const onViewProspects = () => setActiveTab('Prospects');
  const onViewHotProspects = () => setActiveTab('Stats - Hot Prospects');
  const onViewEngagements = () => setActiveTab('Stats - Engagements');
  const onViewTouchTimeline = () => setActiveTab('Touches');

  const touchesSentStat = React.useMemo<number | undefined>(() => {
    if (!deliveredTouchStats) return undefined;

    return deliveredTouchStats!.reduce((sum, current) => sum + current.count, 0);
  }, [deliveredTouchStats]);

  const hotProspectsStat = React.useMemo<number | undefined>(() => {
    if (!leadResponseStats) return undefined;

    // We only want to show the # of unique hot prospects, not just the # of events unlike the engagements
    // stat. We therefore group these by the apn and show that #
    // return hotProspectLeadTypes.reduce((sum, leadResponseType) => (sum + leadResponseStats[leadResponseType].length), 0);
    const hotProspectApns = hotProspectLeadTypes.reduce<Set<string>>((set, leadResponseType) => {
      leadResponseStats[leadResponseType].forEach((leadResponse) => {
        if (leadResponse.eventData) set.add(leadResponse.eventData?.apn);
      });

      return set;
    },
    new Set());

    return hotProspectApns.size;
  }, [leadResponseStats]);

  const engagementsStat = React.useMemo<number | undefined>(() => {
    if (!leadResponseStats) return undefined;

    return engagementLeadTypes.reduce((sum, leadResponseType) => (sum + leadResponseStats[leadResponseType].length), 0);
  }, [leadResponseStats]);

  // TODO: RESPONSIVENESS
  return (
    <Container maxWidth="xl">
      {/* // <GridContainer justifyContent="center" alignContent="center">
    //   <GridItem xs={12} sm={6} lg={3}> */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gridGap: 20,
        marginTop: 30,
        marginBottom: 26,
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
      >
        <StatsCard
          title="Properties"
          stat={campaign.subscribedEvents.length}
          Icon={StatsPropertiesIcon}
          onViewAllClick={onViewProspects}
        />
        {/* </GridItem>
      <GridItem xs={12} sm={6} lg={3}> */}
        <StatsCard
          title={(
            <>
              Hot Prospects
              {' '}
              <span style={{ fontSize: 24 }}> 🔥</span>
            </>
          )}
          stat={hotProspectsStat}
          Icon={StatsHotProspectsIcon}
          onViewAllClick={onViewHotProspects}
        />
        {/* </GridItem>
      <GridItem xs={12} sm={6} lg={3}> */}
        <StatsCard
          title="Engagements"
          stat={engagementsStat}
          Icon={StatsEngagementsIcon}
          onViewAllClick={onViewEngagements}
        />
        {/* </GridItem>
      <GridItem xs={12} sm={6} lg={3}> */}
        <StatsCard
          title="Touches Sent"
          stat={touchesSentStat}
          Icon={StatsTouchesSentIcon}
          onViewAllClick={onViewTouchTimeline}
        />
      </Box>
      {/* //   </GridItem>
  // </GridContainer> */}

      <Box sx={{ marginBottom: 26 }}>
        <TouchAnalyticsGraph campaignId={campaign._id} />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gridGap: 20,
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
      >
        <CampaignProgressGraph
          campaignStartDate={campaign.campaignStartDate}
          campaignEndDate={campaign.campaignEndDate}
          onViewTouchTimeline={onViewTouchTimeline}
        />

        <TouchesSentBarGraph
          campaignId={campaign._id}
          onViewTouchTimeline={onViewTouchTimeline}
        />

        <EmailDeliverabilityStats
          campaignId={campaign._id}
          onViewTouchTimeline={onViewTouchTimeline}
        />
      </Box>
    </Container>
  );
};
