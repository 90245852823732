import useSWR from 'swr';
import { IAdCampaign } from 'types/ad-campaign';

export const useAdCampaigns = () => {
  const { data, error } = useSWR('/digital-ads');

  return {
    adCampaigns: data?.data as IAdCampaign[],
    isLoadingAdCampaigns: !data,
    isErrorAdCampaigns: !!error,
  };
};

export const useAdCampaignDetail = (adCampaignId: string) => {
  const { data, error, mutate } = useSWR(`/digital-ads/${adCampaignId}`);

  return {
    adCampaign: data?.data as IAdCampaign,
    isLoadingAdCampaign: !data,
    isErrorAdCampaign: !!error,
    mutateAdCampaign: mutate,
  };
};

export const useAdCampaignAdPreview = () => {
  const { data, error } = useSWR('/digital-ads/ad-preview');

  return {
    adPreview: data?.data as string,
    isLoadingAdPreview: !data,
    isErrorAdPreview: !!error,
  };
};
