import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, CardContent, ButtonBase,
} from '@material-ui/core';

import AcknowledgeModal from 'components/AcknowledgeModal/AcknowledgeModal';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import Button from 'creative-components/CustomButtons/Button';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import PasswordVisibility from 'components/PasswordVisibility/PasswordVisibility';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import { authenticate } from 'utils/api';
import { maxPasswordLength } from 'utils/lib';

const useStyles = makeStyles(styles);

const LoginForm = ({ setStartingPasswordReset }) => {
  const classes = useStyles();
  const { onLogin } = useAuthDataContext();

  const passwordInputRef = React.createRef();

  const [modalMessage, setModalMessage] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <AcknowledgeModal
        message={modalMessage}
        onClose={() => setModalMessage('')}
      />
      <Formik
        initialValues={{
          email: '', password: '',
        }}
        onSubmit={async ({ email, password }) => {
          try {
            const { token, expiresInDays } = await authenticate(email, password);

            // Call the login function on the auth context
            onLogin(token, expiresInDays);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
              setModalMessage('Invalid email or password, please try again.');
            } else {
              setModalMessage(`${error}`);
              console.error('error', error);
            }
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
          password: Yup.string().max(maxPasswordLength).required('Password is required'),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue,
          } = props;

          return (
            <Form>
              {isSubmitting ? <LoadingIndicator modal /> : null}
              <Card>
                <CardHeader
                  title="Login"
                  classes={{
                    root: classes.cardHeaderRoot,
                    title: classes.cardHeaderTitle,
                  }}
                />
                <div className={classes.cardHeaderLine} />
                <CardContent>
                  <Field
                    name="email"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Email"
                        inputProps={{
                          ...field,
                          autoComplete: 'email',
                          onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                              passwordInputRef.current.focus();
                            }
                          },
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                        formControlProps={{
                          classes: {
                            root: classes.customInputDashboard,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    name="password"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Password"
                        inputProps={{
                          ...field,
                          inputRef: passwordInputRef,
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <PasswordVisibility
                              showPassword={showPassword}
                              toggleShowPassword={toggleShowPassword}
                              className={classes.inputAdornmentIcon}
                            />
                          ),
                          onChange: (e) => setFieldValue(field.name, e.target.value.substring(0, 16), true),
                          onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                              // Same checks as submit button below
                              if (isValid && dirty && !isSubmitting) {
                                handleSubmit();
                              }
                            }
                          },
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                        formControlProps={{
                          classes: {
                            root: classes.customInputDashboard,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Button
                    className={classes.primaryAction}
                    type="submit"
                    color="primary"
                    round
                    block
                    disabled={!isValid || !dirty || isSubmitting}
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                  <div className={classes.center}>
                    <ButtonBase
                      disableRipple
                      disableTouchRipple
                      onClick={() => setStartingPasswordReset(true)}
                      className={classes.secondaryAction}
                    >
                      Forgot your password?
                    </ButtonBase>
                  </div>
                </CardContent>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginForm;
