import styles from 'assets/jss/material-dashboard-pro-react/creative-components/buttonStyle';
import classNames from 'classnames';
import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles as any);

// @ts-ignore
interface Props extends ButtonProps {
  color?: ButtonProps['color'] | 'dark';
  textColorOverride?: 'primary' |
  'orange' |
  'gray' |
  'darkGray' |
  'white' |
  'black';
  fontSizeOverride?: number;
  simple?: boolean;
  round?: boolean;
  outlined?: boolean;
  underlined?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string;
  muiClasses?: any;
  children?: React.ReactNode
}

const RegularButton = React.forwardRef<HTMLButtonElement, Props>(({
  color = 'primary',
  textColorOverride,
  fontSizeOverride,
  round,
  children,
  fullWidth,
  disabled,
  simple,
  outlined,
  underlined,
  size = 'md',
  block,
  link,
  justIcon,
  className,
  muiClasses,
  ...rest
}, ref) => {
  const classes = useStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.outlined]: outlined,
    [classes.underlined]: underlined,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className ?? '']: className,
    [classes[`${textColorOverride}TextColor`]]: true,
  });

  return (
    <Button
      disableRipple
      disableTouchRipple
      {...rest}
      ref={ref}
      classes={muiClasses}
      className={btnClasses}
      style={fontSizeOverride ? { fontSize: `${fontSizeOverride}px` } : undefined}
    >
      {children}
    </Button>
  );
});

export default RegularButton;
