/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Polygon } from '@react-google-maps/api';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useMapInfoContext } from 'components/DataMap/MapInfoProvider';

import { getDynamicDataCount } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  zipcodeTooltip: {
    marginLeft: '14px',
    backgroundColor: theme.palette.white.main,
    border: '2px solid #FFF',
    borderRadius: '6px',
    padding: '6px 10px',
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '18px',
    color: theme.palette.gray.main,
  },
  highlightedZipcodeTooltip: {
    backgroundColor: theme.palette.orange.main,
    border: 'none',

    color: theme.palette.white.main,
  },
}));

const highlightZipcodePolygonOptions = {
  fillColor: '#96d6b3',
  fillOpacity: 0.4,
  strokeColor: '#534b52',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const zipcodePolygonOptions = {
  fillColor: '#555555',
  fillOpacity: 0.1,
  strokeColor: 'black',
  strokeOpacity: 0.4,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const overlayViewKey = 'ZipcodePolygon';

// Had to separate this out into a component cuz if we had state in
// the main map component, it would reset the zoom/position on rerenders
const ZipcodePolygon = ({
  zipcode, zipcodePolygons, highlight = false,
  clickToLoadZipcodeEventCounts, isDrawingShape,
}) => {
  const classes = useStyles();
  const { selectedEventType, appliedFilters } = useDataFilterContext();
  const {
    mapInstance, overlayViews, addOverlayView, removeOverlayView,
  } = useMapInfoContext();

  const [canRefetchDataCounts, setCanRefetchDataCounts] = React.useState(false);
  const [tooltipToShow, setTooltipToShow] = React.useState((
    <div className={cx(classes.zipcodeTooltip, highlight && classes.highlightedZipcodeTooltip)}>
      {zipcode}
    </div>
  ));

  React.useEffect(() => {
    if (!clickToLoadZipcodeEventCounts || !selectedEventType || !appliedFilters) return;

    setCanRefetchDataCounts(true);

    setTooltipToShow((
      <div className={classes.zipcodeTooltip}>
        <h5>
          <b>{zipcode}</b>
          <br />
          {`Click to load ${selectedEventType.name} APN counts`}
        </h5>
      </div>
    ));
  }, [selectedEventType, appliedFilters]);

  /* const zipcodeCenter = React.useMemo(() => {
    // Calculate the center coordinates of this zipcode

    let minLat;
    let minLng;
    let maxLat;
    let maxLng;

    zipcodePolygons.coordinates.forEach((polygon, index) => {
      polygon[0].forEach((coordinate) => {
        const lng = coordinate[0];
        const lat = coordinate[1];

        if (minLng === undefined || lng < minLng) minLng = lng;
        if (maxLng === undefined || lng > maxLng) maxLng = lng;

        if (minLat === undefined || lat < minLat) minLat = lat;
        if (maxLat === undefined || lat > maxLat) maxLat = lat;
      });
    });

    // Calculate the center
    return { lat: minLat + (maxLat - minLat) / 2, lng: minLng + (maxLng - minLng) / 2 };
  }, [zipcodePolygons]); */

  React.useEffect(() => {
    // Refresh overlay view
    if (overlayViews.filter((o) => o.key === overlayViewKey).length > 0) addOverlayView(overlayViewKey, 1, tooltipToShow);
  }, [tooltipToShow]);

  const onPolygonMouseClick = async () => {
    if (!clickToLoadZipcodeEventCounts) return; // Click to load is disabled
    if (!canRefetchDataCounts) return; // Data is already loaded

    setCanRefetchDataCounts(false);
    setTooltipToShow((
      <div className={classes.zipcodeTooltip}>
        <LoadingIndicator />
      </div>
    ));

    const { availableCount, totalCount } = await getDynamicDataCount(selectedEventType._id, appliedFilters, undefined, zipcode);

    setTooltipToShow((
      <div className={classes.zipcodeTooltip}>
        <h5><b>{`${zipcode} - ${selectedEventType.name}`}</b></h5>
        <h5>{`${availableCount} Available`}</h5>
      </div>
    ));
  };

  // Propagate all these polygon events to the map instance too for
  // the create campaign drawing custom shapes to work
  const onMouseMove = (e) => {
    window.google.maps.event.trigger(mapInstance, 'mousemove', e);
  };

  const onMouseDown = (e) => {
    window.google.maps.event.trigger(mapInstance, 'mousedown', e);
  };

  const onMouseUp = (e) => {
    window.google.maps.event.trigger(mapInstance, 'mouseup', e);
  };

  const onMouseOver = (e) => {
    window.google.maps.event.trigger(mapInstance, 'mouseover', e);

    addOverlayView(overlayViewKey, 1, tooltipToShow);
  };

  const onMouseOut = (e) => {
    removeOverlayView(overlayViewKey);
    window.google.maps.event.trigger(mapInstance, 'mouseout', e);
  };

  return (
    <>
      {zipcodePolygons.coordinates.map((polygon, index) => (
        <React.Fragment key={`${zipcode}-${index}`}>
          <Polygon
            paths={polygon[0].map((coordinate) => ({ lng: coordinate[0], lat: coordinate[1] }))}
            options={{
              ...(highlight ? highlightZipcodePolygonOptions : zipcodePolygonOptions),
              clickable: !isDrawingShape,
            }}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onPolygonMouseClick}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default ZipcodePolygon;
