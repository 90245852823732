import getReportIcon from 'assets/img/agent-landing/home-estimation/get-report-icon.svg';
import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  container: {
    ...container,
    position: 'relative',
  },

  estimateSection: {
    margin: '-235px 20px 0',
  },
  estimateCard: {
    padding: '0 !important',
    background: theme.palette.white.main,
    border: `2px solid ${theme.palette.primary.main}`,
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',
    borderRadius: '8px',
  },
  estimateCardBottom: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  estimateCardValue: {
    flex: 1,
    textAlign: 'center',
    padding: '25px 12px 33px',

    '& > div:nth-child(1)': { // "Your Estimated Property Value"
      color: theme.palette.darkGray.main,
      fontSize: '20px',
      lineHeight: '30px',
    },
    '& > h1': {
      margin: '8px 0 0',
      color: theme.palette.orange.main,
    },
    '& > div:nth-last-child(2)': { // Property info
      margin: '31px 0 0',
      color: theme.palette.darkGray.main,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& > div:nth-last-child(1)': { // Address
      margin: '12px 0 0',
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
  },
  estimateCardBottomOptions: {
    padding: '32px 40px 27px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      borderTop: `2px solid ${theme.palette.grayScale6.main}`,
    },
    [theme.breakpoints.up('md')]: {
      borderLeft: `2px solid ${theme.palette.grayScale6.main}`,
    },
  },
  estimateCardBottomOptionsSpacer: {
    height: '32px',
  },
  slider: {
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      width: '365px',
    },

    '& > p': {
      margin: '0 12px 12px',
      color: theme.palette.gray.main,
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
  },
  sliderDescriptions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  homeActivitySection: {
    margin: '45px 20px 0',
  },
  homeActivityContainer: {
    padding: '0 !important',
    background: theme.palette.white.main,
    border: `1px solid ${theme.palette.grayScale6.main}`,
    borderRadius: '8px',
  },
  homeActivityHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '22px 22px 10px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  homeActivityHeader: {
    flex: 2,

    '& > h3': {
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    '& > p': {
      margin: '5px 0 0',
      color: theme.palette.darkGray.main,
      fontSize: '16px',
      lineHeight: '24px',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  homeActivityComparisonInfo: {
    flex: 1,
    paddingRight: '20px',
    lineHeight: '24px',
    fontSize: '16px',

    [theme.breakpoints.down('sm')]: {
      padding: '30px 0',
    },

    '& dt': {
      float: 'left',
      width: '200px',
      clear: 'left',
      textAlign: 'right',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& dd': {
      marginLeft: '210px',
      fontWeight: 'bold',
    },
  },
  homeActivityComparisonInfoProperty: {
    width: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'right',
    color: theme.palette.darkGray.main,
  },
  homeActivityComparisonInfoValue: {
    width: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'left',
    paddingLeft: '12px',
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },

    color: theme.palette.orange.main,
  },
  homeActivityGetReport: {
    flexShrink: 0,
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'bold',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px',
    },

    '& > button': {
      margin: '8px 0 0',
      background: `url(${getReportIcon})`,
      width: '40px',
      height: '40px',
    },
  },
  homeActivityTableContainer: {
    overflowX: 'auto',
  },

  mostLikelySection: {
    margin: '57px 0 108px',

    [theme.breakpoints.down('sm')]: {
      margin: '60px 20px 80px',
    },
  },
  mostLikelyContainer: {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > h2': {
      color: theme.palette.darkGray.main,
      textAlign: 'center',
    },
    '& > div': {
      textAlign: 'center',
    },
    '& > div > button': {
      margin: '26px 0 0',
    },
  },
  mostLikelyButtonSpacer: {
    width: '28px',
    display: 'inline-block',
  },

  mapLoading: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
  },
  tableLoading: {
    margin: '20px 0',
  },
});

export default style;
