/* eslint-disable func-names */
import _ from 'lodash';
import * as Yup from 'yup';

Yup.addMethod(Yup.object, 'optional', function (
  isOptional = true,
  defaultValue = undefined,
) {
  return this.transform((value) => {
    // If false is passed, skip the transform
    if (!isOptional) return value;

    // If any child property has a value, skip the transform
    if (
      value
      && (Object.values(value).some((v) => !(v === null || v === undefined || v === '' || typeof (v) === 'object'))
      || Object.values(value).forEach((v) => (typeof (v) === 'object' && Object.values(v).some((v2) => !(v2 === null || v2 === undefined || v2 === ''))))) // Nested objects 1 level deep
    ) {
      return value;
    }

    return defaultValue;
  })
  // Remember, since we're dealing with the `object`
  // type, we have to change the default value
    .default(defaultValue);
});

Yup.addMethod(Yup.object, 'atLeastOneRequired', function (list, message) {
  return this.shape(list.reduce((acc, field) => ({
    ...acc,
    [field]: this.fields[field].when(_.without(list, field), {
      is: (...values) => !values.some((item) => item),
      then: this.fields[field].required(message),
    }),
  }), {}), list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map((i) => [item, i])], []));
});
