import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import ReactTable from 'creative-components/ReactTable/ReactTable';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({

}));

export default function AllBrokeragesTable({ brokerages }) {
  const classes = useStyles();
  const location = useLocation();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!brokerages) return;

    // Sort by createdAt desc
    brokerages.sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1));

    setData(brokerages.map((brokerage) => ({
      _id: brokerage._id,
      name: brokerage.name,
      numBranches: brokerage.branches.length,
      numAgents: numberWithCommas(brokerage.agents.length),
      numApnsBeingFarmed: numberWithCommas(brokerage.numApnsBeingFarmed),
      createdAt: moment(brokerage.createdAt).toDate(),
    })));
  }, [brokerages]);

  if (!brokerages) {
    return <LoadingIndicator />;
  }

  return (
    <ReactTable
      columns={[
        {
          Header: 'Brokerage Name',
          accessor: 'name',
          Cell: ({ row, value }) => (
            <Link to={{
              pathname: `/dashboard/admin/brokerages/${row.original._id}`,
              state: { previousPathname: location.pathname },
            }}
            >
              {value}
            </Link>
          ),
        },
        {
          Header: '# of Branches',
          accessor: 'numBranches',
        },
        {
          Header: '# of Agents',
          accessor: 'numAgents',
        },
        {
          Header: '# of APNs Being Farmed',
          accessor: 'numApnsBeingFarmed',
        },
        {
          Header: 'Date Created',
          accessor: 'createdAt',
          Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
          sortType: 'datetime',
        },
      ]}
      data={data}
      normalRightColumn
    />
  );
}
