import heroImage from 'assets/img/agent-landing/hero.png';
import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  container: {
    ...container,
    position: 'relative',
  },
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.darkBlueGray.main,
    mixBlendMode: 'hard-light',
  },

  heroContainer: {
    padding: '44px 0 314px',

    [theme.breakpoints.down('sm')]: {
      padding: '44px 0 280px',
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    '& > img': {
      width: '200px',
      height: '200px',
      background: 'rgba(1,1,1,0.2)',
      objectFit: 'cover',
      borderRadius: '100%',
      marginRight: '26px',

      [theme.breakpoints.only('xs')]: {
        width: '80px',
        height: '80px',
      },
      [theme.breakpoints.only('sm')]: {
        width: '120px',
        height: '120px',
      },
    },
    '& > div > h1': {
      color: theme.palette.white.main,
    },
    '& > div > p': {
      margin: '8px 0 0',
      color: theme.palette.grayScale7.main,
      fontSize: '16px',
      lineHeight: '24px',
      whiteSpace: 'pre',
    },

    '& a': {
      color: theme.palette.white.main,
    },
  },
  right: {
    fontSize: '24px',
    lineHeight: '36px',
    color: theme.palette.white.main,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 0',
    },
    [theme.breakpoints.up('md')]: {
      float: 'right',
    },

    '& > button': {
      margin: '12px 0 0',

      [theme.breakpoints.up('md')]: {
        minWidth: '207px',
      },
    },
  },
});

export default style;
