import React from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import PopupColorPicker from 'components/PopupColorPicker/PopupColorPicker';
// import PostcardSamplesModal from 'components/PostcardSamplesModal/PostcardSamplesModal';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';

import { updateUser } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const PrintColorsForm = (props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  const [isPostcardSamplesModalOpen, setIsPostcardSamplesModalOpen] = React.useState(false);
  const [marketingColorPickerOpen, setMarketingColorPickerOpen] = React.useState(false);

  // TODO: Change model to have an object of colors and add {primary, secondary}. Also figure out with zach the primary/secondary recolor parts on letters/postcards

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        marketingColor: userInfo.agentInfo.marketingColor,
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const { marketingColor } = values;

        try {
          await updateUser({
            agentInfo: {
              marketingColor,
            },
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        marketingColor: Yup.string().required().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, 'Your marketing color is not valid'),
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        return (
          <Form>
            <FormikOnError>
              {/* TODO: Create new modal here */}
              {/* {isPostcardSamplesModalOpen && (<BrokerageLogosModal onClose={() => setIsPostcardSamplesModalOpen(false)} />)} */}

              <GridContainer justifyContent="center">
                <GridItem xs={12} style={{ marginTop: '40px', textAlign: 'left' }}>
                  <h6>Print Marketing Accent Color</h6>
                  <Field
                    name="marketingColor"
                  >
                    {({ field }) => (
                      <>
                        <PopupColorPicker
                          initialColor={values[field.name]}
                          onChange={(newColor) => setFieldValue(field.name, newColor, true)}
                          isOpen={marketingColorPickerOpen}
                          onOpen={() => setMarketingColorPickerOpen(true)}
                          onClose={() => setMarketingColorPickerOpen(false)}
                        />
                      </>
                    )}
                  </Field>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.saveButtonContainer}>
                    <SaveButton onSave={reloadUserInfo} />
                  </div>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(PrintColorsForm);
