import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { unsubscribeEmailTouch } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  container: {
    textAlign: 'center',
    '& > h3': {
      color: theme.palette.darkGray.main,
      margin: '100px 0 10px',
    },
    '& > p': {
      color: theme.palette.gray.main,
    },
  },
}));

const UnsubscribePage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { token: unsubscribeToken } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [finishedUnsubscribing, setFinishedUnsubscribing] = React.useState(false);

  // Fetch home estimation data
  React.useEffect(() => {
    (async () => {
      try {
        // No token provided?
        if (!unsubscribeToken) {
          history.push('/unsubscribe/error');
          return;
        }

        await unsubscribeEmailTouch(unsubscribeToken);

        setFinishedUnsubscribing(true);
      } catch (err) {
        console.error(err);
        history.push('/unsubscribe/error');
      }
    })();
  }, []);

  let eventTypeName;
  if (location.state) eventTypeName = location.state.eventTypeName;

  return (
    <GridContainer className={classes.root}>
      <GridItem xs={12}>
        {finishedUnsubscribing ? (
          <div className={classes.container}>
            <h3>
              You have been unsubscribed
            </h3>
            <p>
              I'm sorry to see you go. You will no longer receive these emails from me.
            </p>
          </div>
        ) : <LoadingIndicator />}
      </GridItem>
    </GridContainer>
  );
};

export default UnsubscribePage;
