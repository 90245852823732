import React from 'react';

import { SvgIcon } from '@material-ui/core';

const FourSquareIcon = ({ className }) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19 13.7522C20.6569 13.7522 22 15.0953 22 16.7522V19.7522C22 21.4091 20.6569 22.7522 19 22.7522H16C14.3431 22.7522 13 21.4091 13 19.7522V16.7522C13 15.0953 14.3431 13.7522 16 13.7522H19ZM8 13.7522C9.65685 13.7522 11 15.0953 11 16.7522V19.7522C11 21.4091 9.65685 22.7522 8 22.7522H5C3.34315 22.7522 2 21.4091 2 19.7522V16.7522C2 15.0953 3.34315 13.7522 5 13.7522H8ZM19 15.7522H16C15.4477 15.7522 15 16.1999 15 16.7522V19.7522C15 20.3045 15.4477 20.7522 16 20.7522H19C19.5523 20.7522 20 20.3045 20 19.7522V16.7522C20 16.1999 19.5523 15.7522 19 15.7522ZM8 15.7522H5C4.44772 15.7522 4 16.1999 4 16.7522V19.7522C4 20.3045 4.44772 20.7522 5 20.7522H8C8.55228 20.7522 9 20.3045 9 19.7522V16.7522C9 16.1999 8.55228 15.7522 8 15.7522ZM8 2.7522C9.65685 2.7522 11 4.09534 11 5.7522V8.7522C11 10.4091 9.65685 11.7522 8 11.7522H5C3.34315 11.7522 2 10.4091 2 8.7522V5.7522C2 4.09534 3.34315 2.7522 5 2.7522H8ZM19 2.7522C20.6569 2.7522 22 4.09534 22 5.7522V8.7522C22 10.4091 20.6569 11.7522 19 11.7522H16C14.3431 11.7522 13 10.4091 13 8.7522V5.7522C13 4.09534 14.3431 2.7522 16 2.7522H19ZM8 4.7522H5C4.44772 4.7522 4 5.19991 4 5.7522V8.7522C4 9.30448 4.44772 9.7522 5 9.7522H8C8.55228 9.7522 9 9.30448 9 8.7522V5.7522C9 5.19991 8.55228 4.7522 8 4.7522ZM19 4.7522H16C15.4477 4.7522 15 5.19991 15 5.7522V8.7522C15 9.30448 15.4477 9.7522 16 9.7522H19C19.5523 9.7522 20 9.30448 20 8.7522V5.7522C20 5.19991 19.5523 4.7522 19 4.7522Z" fill="inherit" />
  </SvgIcon>
);

export default FourSquareIcon;
