import React from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import BrokerageBranchDataForm from 'components/BrokerageMarketingProfile/BrokerageBranchDataForm';
import UnsavedChangesWarning from 'components/UnsavedChangesWarning/UnsavedChangesWarning';

import { useMyBrokerage } from 'data/brokerage';

import { backdropContainer, backdropImage } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
  backdropContainer,
  backdropImage,
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const BrokerageBranchMarketingProfilePage = () => {
  const classes = useStyles();
  const location = useLocation();

  const formRef = React.createRef();

  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useMyBrokerage();
  const [brokerageBranch, setBrokerageBranch] = React.useState(undefined);

  const [imageOpen, setImageOpen] = React.useState(null);

  React.useEffect(() => {
    if (isLoadingBrokerage) return;

    if (location.state && location.state.brokerageBranchId) {
      const arr = brokerage.branches.filter(({ _id }) => _id === location.state.brokerageBranchId);

      if (arr.length > 0) {
        setBrokerageBranch(arr[0]);
        return;
      }
    }

    // User is creating a new branch or they came to this page with an invalid branch id
    setBrokerageBranch(null);
  }, [isLoadingBrokerage, brokerage]);

  if (brokerageBranch === undefined) return (<LoadingIndicator />);

  return (
    <div className={classes.root}>
      {imageOpen ? (
        <Backdrop className={classes.backdropContainer} open onClick={() => setImageOpen(null)}>
          <img src={imageOpen} alt="" className={classes.backdropImage} />
        </Backdrop>
      ) : null}

      <UnsavedChangesWarning formRefs={[formRef]} />

      <DashboardSection
        breadcrumbs={[{ name: 'Branches', to: '/dashboard/my-brokerage' }]}
        sectionName={brokerageBranch ? `${brokerageBranch.name} - Marketing Profile` : 'Create Branch Marketing Profile'}
      />

      <BrokerageBranchDataForm ref={formRef} brokerageBranch={brokerageBranch} />
    </div>
  );
};

export default BrokerageBranchMarketingProfilePage;
