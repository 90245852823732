import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DashboardSection from 'components/DashboardSection/DashboardSection';
import InvoicesTable from 'components/InvoicesTable/InvoicesTable';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import PaymentMethodCard from 'components/PaymentMethodCard/PaymentMethodCard';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { getUserInvoices } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    marginTop: '40px',
  },
}));

const UserBillingPage = () => {
  const classes = useStyles();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  const [invoices, setInvoices] = React.useState(undefined);

  React.useEffect(() => {
    (async () => {
      const { invoices: invoicesReturned } = await getUserInvoices();

      setInvoices(invoicesReturned);
    })();
  }, []);

  if (!userInfo) {
    return (<LoadingIndicator />);
  }

  return (
    <div className={classes.root}>
      <DashboardSection sectionName="Payment Method" />
      <PaymentMethodCard
        billingName={`${userInfo.firstName} ${userInfo.lastName}`}
        paymentMethodInfo={userInfo.agentInfo.stripe.paymentMethodInfo}
        reloadCallback={reloadUserInfo}
      />

      <div className={classes.spacer} />

      <DashboardSection sectionName="Invoices" />
      <InvoicesTable invoices={invoices} />
    </div>
  );
};

export default UserBillingPage;
