import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

const styles = (theme: any) => ({
  grid: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 3px !important', // 6px gutter
    },

    [theme.breakpoints.only('sm')]: {
      padding: '0 7px !important', // 14px gutter
    },

    [theme.breakpoints.only('md')]: {
      padding: '0 12px !important', // 24px gutter
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0 16px !important', // 32px gutter
    },
  },
});

const useStyles = makeStyles(styles);

export default function GridItem(props: GridProps) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <Grid item {...rest} className={className ? `${classes.grid} ${className}` : classes.grid}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
