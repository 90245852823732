import cx from 'classnames';
import { useAdCampaigns } from 'data/ad-campaign';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import AdCampaignsTable from 'components/AdCampaignsTable';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { isMarketingProfileCompleted } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    overflowX: 'auto',
    overflowY: 'auto',
  },
  instructionsText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '36px',
    color: theme.palette.black.main,
  },
  marketingProfileInstructions: {
    marginBottom: '60px',

    '& > a': {
      whiteSpace: 'nowrap',
      font: 'inherit',
      padding: '6px 10px',
      color: theme.palette.orange.main,
      backgroundColor: '#FDE8E4',
      borderRadius: '8px',
      textDecoration: 'underline',
    },

    '& > span': {
      color: theme.palette.error.main,
    },
  },
  createAdInstructions: {
    '& > button': {
      font: 'inherit',
      padding: '6px 10px',
      color: '#116942',
      backgroundColor: '#DEF2E9',
      borderRadius: '8px',
      textDecoration: 'underline',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const { userInfo } = useUserInfoContext();
  const history = useHistory();

  const { isAnyAgent } = useAuthDataContext();
  const { adCampaigns, isLoadingAdCampaigns, isErrorAdCampaigns } = useAdCampaigns();

  const onCreateAdCampaign = async () => {
    history.push('/dashboard/ad-campaigns/create');
  };

  const createButton = (
    <Button
      color="primary"
      round
      onClick={onCreateAdCampaign}
    >
      + Create New Ad
    </Button>
  );

  let render;

  if (isLoadingAdCampaigns || !userInfo) {
    render = <LoadingIndicator />;
  } else if (!adCampaigns.length && isAnyAgent) {
    if (isMarketingProfileCompleted(userInfo.agentInfo)) {
      render = (
        <p className={cx(classes.instructionsText, classes.createAdInstructions)}>
          Get started by creating your first
          {' '}
          <ButtonBase onClick={onCreateAdCampaign}>
            Digital Ad!
          </ButtonBase>
        </p>
      );
    } else {
      render = (
        <p className={cx(classes.instructionsText, classes.marketingProfileInstructions)}>
          Hi There 👋 - Let's get started with your
          {' '}
          <Link to="/dashboard/marketing-profile">
            Marketing Profile.
          </Link>
        </p>
      );
    }
  } else {
    render = (
      <AdCampaignsTable adCampaigns={adCampaigns} tableProps={{ showFilters: false }} />
    );
  }

  return (
    <div className={classes.root}>
      <DashboardSection
        sectionName={isAnyAgent ? 'Your Digital Ads' : 'Digital Ads'}
        buttons={userInfo && isAnyAgent ? [createButton] : []}
      />
      <div className={classes.table}>
        {render}
      </div>
    </div>
  );
};
