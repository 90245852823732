import React from 'react';
import { Prompt } from 'react-router-dom';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';

const UnsavedChangesWarning = ({ formRefs }) => {
  const { setCurrentAlert } = useAlertContext();
  const [unsavedChangesWarningShown, setUnsavedChangesWarningShown] = React.useState(false);

  return (
    <Prompt
      when
      message={() => {
        // If the warning was already shown, let them leave
        if (unsavedChangesWarningShown) return true;

        // Check if there are unsaved changes
        let hasDirtyForm = false;
        for (let i = 0; i < formRefs.length; i++) {
          const formRef = formRefs[i];
          if (formRef.current.dirty) {
            hasDirtyForm = true;
            break;
          }
        }

        // No dirty forms (unsaved changes), let them leave
        if (!hasDirtyForm) return true;

        setUnsavedChangesWarningShown(true);

        // Show an alert
        setCurrentAlert('warning', 'You have unsaved changes! Click the "Save" buttons on the page to save your changes!');

        // Don't allow them to leave
        return false;
      }}
    />
  );
};

export default UnsavedChangesWarning;
