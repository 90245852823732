import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import ActionModal from 'components/ActionModal/ActionModal';

const useStyles = makeStyles((theme) => ({
}));

const MissingVoicemailsModal = ({ onClose }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ActionModal
      backgroundColor={theme.palette.offWhite.main}
      textColor={theme.palette.darkGray.main}
      icon={<WarningRoundedIcon />}
      onClose={onClose}
      title="Missing Voicemail Recording!"
      message={(
        <div>
          Your upcoming Voicemail touch doesn't have a recording assigned.
          Make sure to record and assign a voicemail recording by clicking the pencil icon next to the voicemail touch.
          <br />
          <br />
          If you don't do this quick step, we won't be able to send out any voicemails for you!
        </div>
      )}
      buttons={[
        <ButtonBase onClick={onClose}>Okay</ButtonBase>,
      ]}
    />
  );
};

export default MissingVoicemailsModal;
