const style = (theme) => ({
  formControl: {
    position: 'relative',
    margin: '0 0 22px',
    padding: 0,
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: theme.palette.gray.main,
    },
  },
  // TODO: Once we get rid of main page, remove these and normalize this
  formControlDashboard: {
    '& *': {
    },
  },

  label: {
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '6px',
  },
  labelDashboard: {
    fontWeight: 600,
  },
  labelDisabled: {
    color: theme.palette.grayScale5.main,
  },
  labelBold: {
    fontWeight: 'bold',
  },
  labelRequired: {
    '&:after': {
      content: '" *"',
      color: theme.palette.error.main,
    },
  },

  helperText: {
    marginTop: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.lightGray.main,
  },
  helperTextError: {
    color: theme.palette.error.main,
  },

  inputRoot: {
    position: 'relative',
    background: theme.palette.white.main,
    border: `2px solid ${theme.palette.grayScale5.main}`,
    borderRadius: '8px',
  },
  input: {
    margin: '14px 16px',
    padding: 0,
    color: theme.palette.darkGray.main,
    caretColor: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',

    // Autofill colors
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      '-webkit-text-fill-color': 'inherit !important',
    },

    '&::placeholder': {
      color: theme.palette.lightGray.main,
    },
  },
  inputDashboard: {
    fontWeight: 500,
  },
  inputFocused: {
    background: '#F9FDFE',
    borderColor: theme.palette.primary.main,
  },
  inputErrored: {
    background: '#FEFAFA',
    borderColor: theme.palette.error.main,
  },
  inputComplete: {
    background: 'rgba(150,214,179, 0.15)',
    borderColor: '#F0F0F0',
  },
  inputDisabled: {
    background: theme.palette.grayScale7.main,
    border: 'none',

    '&::placeholder': {
      color: theme.palette.grayScale5.main,
    },
  },

  inputAdornedStart: {
    paddingLeft: '20px',
  },
  inputAdornedEnd: {
    paddingRight: '20px',
  },

  feedbackError: {
    color: `${theme.palette.error.main} !important`,
  },
  feedbackSuccess: {
    color: `${theme.palette.success.main} !important`,
  },

  endAdornment: {
    '& svg:not(:last-child)': {
      marginRight: '8px',
    },
  },
});

export default style;
