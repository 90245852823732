import DOMPurify from 'dompurify';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: 'center',
    margin: '30px 0 0',
  },
  emailSubject: {
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: '50px',
  },
  emailText: {
    fontWeight: 400,
    '& *': {
      whiteSpace: 'normal',
    },
  },
}));

interface Props {
  title: string;
  emailSubject: string | undefined;
  emailHtml: string | undefined;
  onClose: () => void;
}

class ShadowDomElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const content = this.getAttribute('content');
    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = `${content}`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'shadow-dom-element': ShadowDomElement;
  }
}

// Register the custom element
customElements.define('shadow-dom-element', ShadowDomElement);

const SafeHtmlContainer: React.FC<{ html: string }> = ({ html }) => {
  React.useEffect(() => {
    // Ensure the custom element is defined before using it
    if (typeof window !== 'undefined' && !window.customElements.get('shadow-dom-element')) {
      customElements.define('shadow-dom-element', ShadowDomElement);
    }
  }, []);

  return (
    <div>
      {/* @ts-ignore */}
      <shadow-dom-element content={html} />
    </div>
  );
};

const ViewEmailModal: React.FC<Props> = ({
  title, emailSubject, emailHtml, onClose,
}: Props) => {
  const classes = useStyles();

  const cleanHtml = React.useMemo(() => {
    if (!emailHtml) return null;
    return DOMPurify.sanitize(emailHtml, { USE_PROFILES: { html: true } });
  }, [emailHtml]);

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="lg"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title={title} />

      {emailSubject && cleanHtml
        ? (
          <>
            <div className={classes.emailSubject}>{`Subject: ${emailSubject}`}</div>
            {/* <div className={classes.emailText} dangerouslySetInnerHTML={{ __html: cleanHtml }} /> */}
            <SafeHtmlContainer html={cleanHtml} />
          </>
        )
        : <LoadingIndicator /> }

      {/* <div className={classes.buttonContainer}>
        <Button
          round
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </div> */}
    </Dialog>
  );
};

export default ViewEmailModal;
