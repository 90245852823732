import axios from 'axios';
import { saveAs } from 'file-saver';
import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import BadgedContacts from 'components/BadgedContacts';
import EditCampaignEventOverrideModal from 'components/CampaignLeadsTable/EditCampaignEventOverrideModal';

import { exportCampaign } from 'utils/api';
import {
  getAgentLandingPageSampleUrlFromType, getEventDataOwnerInformation, stringifyEventSiteAddressObject, tableFilterPhoneNumbers,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  exportButton: {
    float: 'right',
    marginTop: '20px',
  },
}));

const CampaignLeadsTable = ({ campaign, setEventOverrides, width }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setCurrentAlert } = useAlertContext();

  const [infoModal, setInfoModal] = React.useState(null);

  const [data, setData] = React.useState([]);
  const [columnsToRender, setColumnsToRender] = React.useState([]);

  React.useEffect(() => {
    // The # of columns we render depends on the screen width
    let columns = [
      {
        Header: 'Farming Enabled',
        accessor: 'farmingEnabled',
        Cell: ({ value }) => (value ? <CheckIcon htmlColor={theme.palette.success.main} /> : <CloseIcon htmlColor={theme.palette.error.main} />),
        disableFilters: true,
        sortType: (rowA, rowB) => {
          const a = rowA.values.farmingEnabled ? 1 : 0; // Convert true/false to 1/0
          const b = rowB.values.farmingEnabled ? 1 : 0;
          return a - b; // Sort in ascending order (false -> true)
        },
      },
      {
        Header: 'APN',
        accessor: 'apn',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone Numbers',
        accessor: 'phoneNumbers',
        filter: tableFilterPhoneNumbers,
        Cell: ({ value }) => <BadgedContacts main={value[0]} alternates={value.length > 1 ? value.slice(1) : []} />,
      },
      {
        Header: 'Emails',
        accessor: 'emails',
        Cell: ({ value }) => <BadgedContacts main={value[0]} alternates={value.length > 1 ? value.slice(1) : []} />,
      },
      {
        Header: 'Owner Address',
        accessor: 'ownerAddress',
      },
      {
        Header: 'Site Address',
        accessor: 'siteAddress',
      },
    ];

    const persistentColumns = [
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ];

    /* switch (width) {
      case 'xs':
      case 'sm':
        // First 2 columns
        columns = [...columns.splice(0, 2), ...persistentColumns];
        break;
      case 'md':
        // First 4 columns
        columns = [...columns.splice(0, 4), ...persistentColumns];
        break;
      default:
        // All columns
        columns = [...columns, ...persistentColumns];
        break;
    } */

    columns = [...columns, ...persistentColumns];

    setColumnsToRender(columns);
  }, [width]);

  React.useEffect(() => {
    if (campaign === null) return;

    setData(campaign.subscribedEvents.map((eventData) => {
      const {
        _id, apn, ownerContact, siteAddress,
      } = eventData;
      const [existingEventOverride] = campaign.eventOverrides.filter((o) => o.event === _id);

      const {
        name, phoneNumber, email, ownerAddress, alternateEmails, alternatePhoneNumbers,
      } = getEventDataOwnerInformation(existingEventOverride, eventData);

      return ({
        farmingEnabled: !(existingEventOverride && existingEventOverride.farmingStopped),
        apn,
        name,
        phoneNumbers: [phoneNumber, ...(alternatePhoneNumbers ?? [])],
        emails: [email, ...(alternateEmails ?? [])],
        ownerAddress,
        siteAddress: stringifyEventSiteAddressObject(siteAddress),
        actions: (
          <div>
            <Tooltip
              title={(
                <>
                  View QR Code
                  <br />
                  Landing Page
                </>
              )}
              placement="top"
            >
              <Button
                justIcon
                round
                simple
                onClick={() => window.open(getAgentLandingPageSampleUrlFromType(campaign.agentLandingPageType, _id), '_blank', 'noopener,noreferrer')}
                color="dark"
              >
                <OpenInNewIcon />
              </Button>
            </Tooltip>
            <Tooltip
              title="Edit"
              placement="top"
            >
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setInfoModal(() => (
                    <EditCampaignEventOverrideModal
                      campaignId={campaign._id}
                      eventId={_id}
                      defaultOwnerContactInfo={ownerContact}
                      existingEventOverride={existingEventOverride}
                      updateEventOverride={(updatedEventOverride) => setEventOverrides([...campaign.eventOverrides.filter((obj) => obj.event !== _id), updatedEventOverride])}
                      onCancel={() => setInfoModal(null)}
                    />
                  ));
                }}
                color={existingEventOverride ? 'primary' : 'dark'}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      });
    }));
  }, [campaign]);

  const handleExportToCsv = async () => {
    setCurrentAlert('success', 'We are gathering your data to export. Your download will begin shortly.');

    const { csvFileUrl } = await exportCampaign(campaign._id);

    const { data: csvData } = await axios.get(csvFileUrl, { responseType: 'blob' });
    saveAs(csvData, `campaign-${campaign._id}-prospects-export.csv`);
  };

  return (
    <>
      {infoModal}
      <div className={classes.tableContainer}>
        <ReactTable
          columns={columnsToRender}
          data={data}
        />
      </div>
      <div>
        <Button
          round
          color="primary"
          className={classes.exportButton}
          onClick={handleExportToCsv}
        >
          <GetAppIcon />
          Export
        </Button>
      </div>
    </>
  );
};

export default withWidth()(CampaignLeadsTable);
