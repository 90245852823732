import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Slide, ButtonBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';

import { submitAgentLandingPriceTooLowForm } from 'utils/api';
import { isAgentLandingPageSample, phoneRegExp } from 'utils/lib';

import { modalCloseButton } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
  modalCloseButton,
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.palette.darkGray.main,
    margin: '0 0 16px',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.darkGray.main,
    margin: '0 0 32px',
  },
  button: {
    margin: '32px 0 0',
    color: 'black',
    width: '100%',
  },
}));

const estimateOffAmounts = ['100k', '250k', '500k+'];

const PriceTooLowForm = ({ onClose, agentLandingPageToken }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const formRef = React.useRef();

  return (
    <Dialog
      open
      keepMounted
      maxWidth="xs"
      scroll="body"
      onClose={onClose}
    >
      <ButtonBase
        className={classes.modalCloseButton}
        aria-label="Close"
        onClick={onClose}
      >
        <CloseIcon />
      </ButtonBase>
      <Formik
        innerRef={formRef}
        initialValues={{
          phoneNumber: '',
          email: '',
          estimateOffAmount: '',
          wantProfessionalOpinion: '',
          recaptchaValue: '',
        }}
        onSubmit={async ({
          phoneNumber, email, estimateOffAmount, wantProfessionalOpinion, recaptchaValue,
        }) => {
          showLoadingIndicatorModal();

          try {
            if (!isAgentLandingPageSample(agentLandingPageToken)) {
              await submitAgentLandingPriceTooLowForm(agentLandingPageToken, phoneNumber, email, estimateOffAmount, wantProfessionalOpinion, recaptchaValue);
            }

            setCurrentAlert('success', 'Thank you for your request, I will be in touch!');

            onClose();
          } catch (error) {
            console.error('error', error);
          }

          hideLoadingIndicatorModal();
        }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          email: Yup.string().email().required(),
          estimateOffAmount: Yup.string().required(),
          wantProfessionalOpinion: Yup.boolean().required(),
          recaptchaValue: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          return (
            <Form>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.title}>Price too low?</h3>
                  <p className={classes.subtitle}>If you think our estimate is off, then let us know, and we're happy to help get you a more formal estimate to value your home.</p>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="phoneNumber">
                    {({ field }) => (
                      <CustomInput
                        labelText="Phone Number"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          type: 'tel',
                          autoComplete: 'tel',
                        }}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="email">
                    {({ field }) => (
                      <CustomInput
                        labelText="Email Address"
                        labelBold
                        labelRequired
                        inputProps={{
                          ...field,
                          autoComplete: 'email',
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="estimateOffAmount">
                    {({ field }) => (
                      <CustomSelect
                        labelText="My estimate is off by..."
                        labelBold
                        labelRequired
                        items={estimateOffAmounts.map((amount) => ({ value: amount, name: amount }))}
                        inputProps={{
                          ...field,
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="wantProfessionalOpinion">
                    {({ field }) => (
                      <CustomSelect
                        labelText="I want your professional opinion"
                        labelBold
                        labelRequired
                        items={[{ value: true, name: 'Yes' }, { value: false, name: 'No' }]}
                        inputProps={{
                          ...field,
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Field name="recaptchaValue">
                    {({ field }) => (
                      <ReCAPTCHAInput
                        fieldName={field.name}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    round
                    outlined
                    size="lg"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Let us know
                  </Button>
                </GridItem>
              </GridContainer>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default PriceTooLowForm;
