import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: 2000,
  },
  inline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
}));

export default function LoadingIndicator({ modal = false, color = '', ...props }) {
  const classes = useStyles();
  const theme = useTheme();

  if (!modal) {
    return (
      <div
        className={classes.inline}
        style={{ color: color || theme.palette.gray.main }}
      >
        <CircularProgress color="inherit" {...props} />
      </div>
    );
  }

  return (
    <Backdrop open className={classes.modal} style={{ color: color || theme.palette.gray.main }}>
      <CircularProgress color="inherit" {...props} />
    </Backdrop>
  );
}
