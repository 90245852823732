import _ from 'lodash';
import React from 'react';
import { FastField, useFormikContext, Field } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import AddressAutocomplete from 'components/AddressAutocomplete/AddressAutocomplete';

// Nasty code required since fields at this point can be something
// like primaryContact.address.address1
const errorCheck = (name, touched, errors, dirty, showErrorsWhen) => {
  let check = true; // showErrorsWhen = always

  if (showErrorsWhen === 'touched') {
    check = Boolean(name.split('.').reduce((o, i) => o[i] || false, touched));
  } else if (showErrorsWhen === 'formDirty') {
    check = dirty;
  }

  return check && Boolean(name.split('.').reduce((o, i) => o[i] || false, errors));
};

// showErrorsWhen = always | formDirty | touched
// customDirty is a dirty function to use for the dirty check
const AddressForm = ({
  classes, valueName, addressDisplayName, initialValue, showErrorsWhen = 'touched', disabled = false, customDirty = null,
}) => {
  const {
    dirty, setFieldValue, touched, errors,
  } = useFormikContext();

  const dirtyCheck = customDirty !== null ? customDirty : dirty;

  return (
    <GridContainer>
      <GridItem xs={12} md={8}>
        {/* Normal field here so that it updates on the dirty checks, if applicable */}
        <Field
          name={`${valueName}.address1`}
        >
          {({ field }) => (
            <AddressAutocomplete
              hasMultipleAddressFields
              valueName={valueName}
              setFieldValue={setFieldValue}
              labelText={addressDisplayName}
              initialValue={initialValue}
              inputProps={{
                ...field,
                autoComplete: 'forceoff',
              }}
              disabled={disabled}
              error={errorCheck(field.name, touched, errors, dirtyCheck, showErrorsWhen)}
            />
          )}
        </Field>
      </GridItem>
      <GridItem xs={12} md={4}>
        <FastField
          name={`${valueName}.address2`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="Apt, Suite"
              inputProps={{
                ...field,
                disabled,
              }}
            />
          )}
        </FastField>
      </GridItem>
      <GridItem xs={12} md={4}>
        <FastField
          name={`${valueName}.city`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="City"
              inputProps={{
                ...field,
                disabled: true,
              }}
              labelDisabled={disabled || false}
              error={errorCheck(field.name, touched, errors, dirtyCheck, showErrorsWhen)}
            />
          )}
        </FastField>
      </GridItem>
      <GridItem xs={12} md={4}>
        <FastField
          name={`${valueName}.state`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="State"
              inputProps={{
                ...field,
                disabled: true,
              }}
              labelDisabled={disabled || false}
              error={errorCheck(field.name, touched, errors, dirtyCheck, showErrorsWhen)}
            />
          )}
        </FastField>
      </GridItem>
      <GridItem xs={12} md={4}>
        <FastField
          name={`${valueName}.zipcode`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="Zipcode"
              inputProps={{
                ...field,
                disabled: true,
              }}
              labelDisabled={disabled || false}
              error={errorCheck(field.name, touched, errors, dirtyCheck, showErrorsWhen)}
            />
          )}
        </FastField>
      </GridItem>
      <FastField
        type="hidden"
        name={`${valueName}.location`}
      />
    </GridContainer>
  );
};

export const initialAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  location: {},
};

/**
 * Extracts key from usually a MongoDB Address document to work with the AddressForm component
 *
 * @param {*} addressObj
 * @returns An address object compatible with the AddressForm component
 */
export const pickAddress = (addressObj) => ({
  ..._.pick(addressObj, ['address1', 'address2', 'city', 'state', 'zipcode', 'location']),
});

export const addressYupValidation = Yup.object({
  address1: Yup.string().required(),
  address2: Yup.string(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zipcode: Yup.string().required(),
  location: Yup.object({
    lat: Yup.number().required(),
    lng: Yup.number().required(),
  }).required(),
});

export const addressOptionalLatLngYupValidation = Yup.object({
  address1: Yup.string().required(),
  address2: Yup.string(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zipcode: Yup.string().required(),
  location: Yup.object({
    lat: Yup.number().optional(),
    lng: Yup.number().optional(),
  }).required(),
});

export const addressYupValidationWithoutLocation = Yup.object({
  address1: Yup.string().required(),
  address2: Yup.string(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zipcode: Yup.string().required(),
});

export default AddressForm;
