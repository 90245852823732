import {
  container,
  boxShadow,
  drawerWidth,
  transition,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.darkGray.main,
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    width: '100%',
    zIndex: '1029',
    border: '0',
    transition: 'all 150ms ease 0s',
    display: 'block',

    height: '96px',
    // padding: '18px 0 0',
  },
  container: {
    ...container,
    height: '100%',
  },
  list: {
    padding: 0,
    listStyle: 'none',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: '22px',
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  listItem: {
    float: 'left',
    position: 'relative',
    display: 'block',
    padding: 0,

    '&:not(:last-child)': {
      [theme.breakpoints.only('md')]: {
        marginRight: '20px',
      },

      [theme.breakpoints.up('lg')]: {
        marginRight: '32px',
      },
    },
    // '&:hover,&:focus': {
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        boxShadow: `0px -4px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical
      },
    },
    '& span': {
      color: theme.palette.white.main,
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      margin: '4px 0',
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 'auto',
    },
  },
  navLink: {
    color: theme.palette.white.main,
    borderRadius: '0',
    lineHeight: '18px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    transition: 'box-shadow 0.3s',

    [theme.breakpoints.down('sm')]: {
      padding: '14px 16px',
      width: '100%',
      justifyContent: 'flex-start',

      '&:hover': {
        backgroundColor: '#5A5F68',
        borderRadius: '6px 0 0 6px',
      },
    },
  },
  listItemText: {
    flex: 'none',
    padding: '0',
    minWidth: '0',
    margin: 0,
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  listItemActive: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#5A5F68',
      borderRadius: '6px 0 0 6px',

      '& span': {
        color: theme.palette.primary.main,
      },
    },

    [theme.breakpoints.up('md')]: {
      boxShadow: `0px -4px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical
    },
  },

  headerLogo: {
    maxHeight: '50px',

    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '80px',
      marginRight: '20px',
      marginLeft: '12px',
      padding: '12px 0',
    },
  },

  // MOBILE DRAWER STYLES
  sidebarButtonOpen: {
    '&,&:hover,&:focus': {
      color: theme.palette.primary.main,
    },
  },
  sidebarButtonClose: {
    padding: '20px',

    '&,&:hover,&:focus': {
      color: theme.palette.orange.main,
    },
  },
  drawerPaper: {
    position: 'relative',
    border: 'none',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: '100%',
    textAlign: 'left',
    paddingRight: 0,
    paddingLeft: 0,
    ...transition,
    background: theme.palette.gray.main,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerHeaderContainer: {
    position: 'sticky',
    top: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.gray.main,
    zIndex: 5,
  },
  drawerLogo: {
    [theme.breakpoints.only('xs')]: {
      margin: '14px 16px',
      maxHeight: '32px',
    },
    [theme.breakpoints.only('sm')]: {
      margin: '22px 26px',
      maxHeight: '64px',
    },
  },
  drawerFooterContainer: {
    position: 'sticky',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.gray.main,
    zIndex: 5,
    padding: '0 40px 30px',
  },
});

export default style;
