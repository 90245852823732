import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/components/loadingDotsStyle';

const useStyles = makeStyles(styles);

const LoadingDots = () => {
  const classes = useStyles();

  return (
    <ul className={classes.root} role="status">
      <li className={classes.dot} />
      <li className={classes.dot} />
      <li className={classes.dot} />
    </ul>
  );
};

export default LoadingDots;
