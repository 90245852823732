/* eslint-disable no-shadow */
import styles from 'assets/jss/material-dashboard-pro-react/layouts/homeStyle';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
// import { intercomAppId } from 'utils/lib';
import routes from 'routes';

// import { IntercomProvider } from 'react-use-intercom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Footer from 'creative-components/Footer/Footer';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import HomeFooter from 'components/HomeFooter/HomeFooter';
import HomeNavbar from 'components/HomeNavbar/HomeNavbar';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import ErrorPage from 'views/Pages/ErrorPage';

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const { currentAlert, currentModal } = useAlertContext();
  const { isLoadingIndicatorModalShowing } = useLoadingIndicatorContext();

  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = (routes) => routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '') {
      return (
        <Route
          exact={prop.exact}
          path={prop.layout + prop.path}
          component={prop.component}
          render={prop.render}
          key={key}
        />
      );
    }
    return null;
  });

  /* const getActiveRoute = (routes) => {
    const activeRoute = 'Default';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  }; */

  const navbarColor = theme.palette.darkGray.main;

  // TODO: Disabled for now since we took out the marketing website
  /* let navbarColor = theme.palette.darkGray.main;

  // Only use a transparent navbar for specific pages
  if (
    location.pathname === '/'
    || (location.pathname.startsWith('/products') && location.pathname !== '/products/brokerage-accounts')
  ) {
    navbarColor = 'transparent';
  } */

  return (
  // <IntercomProvider appId={intercomAppId} autoBoot>
    <div className={classes.wrapper}>
      <HomeNavbar color={navbarColor} />
      {currentAlert}
      {currentModal}
      {isLoadingIndicatorModalShowing ? (<LoadingIndicator modal />) : null}
      <div
        className={classes.content}
      >
        <Switch>
          {getRoutes(routes)}
          <Route path="*" component={() => (<PublicRoute component={ErrorPage} />)} />
        </Switch>
      </div>
      {/* <HomeFooter /> */}
      <Footer />
    </div>
  // </IntercomProvider>
  );
}
