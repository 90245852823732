import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Slider } from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';

import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  countsList: {
    margin: '40px 0 0',

    '& > h2': {
      margin: '0 0 24px',
      fontFamily: 'Overpass Mono',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.grayScale9.main,
      '& > span': {
        float: 'right',
      },
    },

    '& > div:not(:last-child)': {
      margin: '0 0 30px',
    },
  },
  countsListName: {
    padding: '8px 16px',
    borderRadius: '10px',
    backgroundColor: theme.palette.orange.main,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    color: theme.palette.white.main,
    textAlign: 'center',
  },
  countsListCount: {
    float: 'right',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    color: theme.palette.black.main,
  },
  lineSeparator: {
    margin: '20px 0',
    width: '100%',
    border: '1px solid #CCCCCC',
  },
  totalCount: {
    backgroundColor: '#DEF2E9',
    borderRadius: '10px',
    padding: '13px 16px',
    margin: '0 0 30px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.black.main,

    '& > span': {
      float: 'right',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.orange.main,
    },
  },
  slider: {
    margin: '15px 8px 60px',
  },
  sliderText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grayScale9.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {

  },
  applyButton: {

  },
}));

function CampaignAPNsReduceModal({
  onClose, apnAvailableCounts, apnSelectedCounts, setApnSelectedCounts, setApnsTotalSelectedCount,
}) {
  const classes = useStyles();

  const [sliderValues, setSliderValues] = React.useState(null);

  React.useEffect(() => {
    const a = {};
    apnAvailableCounts.forEach(({ name, count }) => {
      a[name] = apnSelectedCounts.filter((o) => name === o.name)[0].count; // Initialize sliders current selected count for this key
    });

    setSliderValues(a);
  }, [apnAvailableCounts]);

  const onSliderChange = (name, newValue) => {
    setSliderValues({
      ...sliderValues,
      [name]: newValue,
    });
  };

  if (!sliderValues) return null;

  const onApply = () => {
    const newCounts = Object.keys(sliderValues).map((name) => ({ name, count: sliderValues[name] }));
    setApnSelectedCounts(newCounts);

    const newTotal = Object.values(sliderValues).reduce((sum, newCount) => sum + newCount, 0);
    setApnsTotalSelectedCount(newTotal);

    onClose();
  };

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="xs"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Reduce # of APNs" small />

      <div className={classes.countsList}>
        <h2>
          Selection
          <span>APNs Available</span>
        </h2>

        {apnAvailableCounts.map(({ name, count }) => (
          <div key={name}>
            <span className={classes.countsListName}>{name}</span>
            <span className={classes.countsListCount}>{numberWithCommas(sliderValues[name])}</span>

            <div className={classes.slider}>
              <Slider
                value={sliderValues[name]}
                min={0}
                max={count}
                valueLabelDisplay="off"
                step={1}
                onChange={(event, newValue) => onSliderChange(name, newValue)}
                disabled={name === 'Add On Properties'}
              />
              <span className={classes.sliderText} style={{ float: 'left' }}>
                0
              </span>
              <span className={classes.sliderText} style={{ float: 'right' }}>
                {numberWithCommas(count)}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.lineSeparator} />

      <div className={classes.totalCount}>
        Total # of APNs Selected
        <span>{numberWithCommas(Object.values(sliderValues).reduce((sum, newCount) => sum + newCount, 0))}</span>
      </div>

      <div className={classes.buttons}>
        <Button
          round
          color="white"
          textColorOverride="darkGray"
          onClick={onClose}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          round
          color="primary"
          onClick={onApply}
          className={classes.applyButton}
        >
          Apply
        </Button>
      </div>
    </Dialog>
  );
}

export default CampaignAPNsReduceModal;
