import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/userInfoModalStyle';

const useStyles = makeStyles(styles);

const UserInfoModal = ({ user, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      scroll="body"
      maxWidth="md"
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>User Details</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer className={classes.infoContainer}>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>ID:</strong>
            <br />
            {user._id}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Status:</strong>
            <br />
            {user.status}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Role:</strong>
            <br />
            {user.role.name}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Name:</strong>
            <br />
            {user.firstName}
            {' '}
            {user.lastName}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Email:</strong>
            <br />
            {user.email}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Phone Number:</strong>
            <br />
            {user.phoneNumber}
          </GridItem>
          {/* TODO: Remove this? Don't need to show this after changing to password reset tokens */}
          {/* <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Registration URL:</strong>
            <br />
            {user.registrationUrl}
          </GridItem> */}
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Created At:</strong>
            <br />
            {user.createdAt}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Updated At:</strong>
            <br />
            {user.createdAt}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

UserInfoModal.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserInfoModal;
