import useSWR from 'swr';

export const useAllBrokerages = () => {
  const { data, error, mutate } = useSWR('/brokerages/all');

  return {
    brokerages: data?.brokerages,
    isLoadingBrokerages: !data,
    isError: error,
    mutateBrokerages: mutate,
  };
};

/**
 * Accessible by brokerage admins only
 */
export const useMyBrokerage = (shouldFetch = true) => {
  const { data, error, mutate } = useSWR(shouldFetch ? '/brokerages/me' : null);

  return {
    brokerage: data?.brokerage,
    isLoadingBrokerage: !data,
    isError: error,
    mutateBrokerage: mutate,
  };
};

export const useBrokerageInvoices = () => {
  const { data, error, mutate } = useSWR('/brokerages/invoices');

  return {
    invoices: data?.invoices,
    isLoadingInvoices: !data,
    isError: error,
    reloadBrokerageInvoices: mutate,
  };
};

export const useBrokerageQuota = () => {
  const { data, error, mutate } = useSWR('/brokerages/quota');

  return {
    quota: data,
    isLoadingQuota: !data,
    isError: error,
    reloadQuota: mutate,
  };
};

export const useBrokerageById = (brokerageId) => {
  const { data, error, mutate } = useSWR(`/brokerages/${brokerageId}`);

  return {
    brokerage: data?.brokerage,
    isLoadingBrokerage: !data,
    isError: error,
    mutateBrokerage: mutate,
  };
};

export const useBrokerageLeadTypes = () => {
  const { data, error } = useSWR('/brokerages/lead-types');

  return {
    brokerageLeadTypes: data?.brokerageLeadTypes,
    isLoadingLeadTypes: !data,
    isError: error,
  };
};
