/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ChromePicker } from 'react-color';

import ButtonBase from '@material-ui/core/ButtonBase';
import Backdrop from '@material-ui/core/Backdrop';

import { makeStyles } from '@material-ui/core/styles';

import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import styles from 'assets/jss/material-dashboard-pro-react/components/popupColorPickerStyle';

const useStyles = makeStyles(styles);

const PopupColorPicker = ({
  initialColor, onChange, isOpen, onOpen, onClose,
}) => {
  const classes = useStyles();

  const [color, setColor] = React.useState(initialColor);

  return (
    <>
      <ButtonBase className={classes.marketingColorSwatch} onClick={onOpen} style={{ background: `rgba(${hexToRgb(color)})` }} />
      {isOpen && (
      <Backdrop
        className={classes.backdropContainer}
        open
        onClick={() => onClose(color)}
      >
        {/* NOTE: Stop propagation so clicking on the chrome picker doesn't close the backdrop */}
        <div onClick={(e) => e.stopPropagation()} onDrag={(e) => e.stopPropagation()}>
          <ChromePicker
            style={{ zIndex: 10000 }}
            color={color}
            disableAlpha
            onChange={(newColor) => {
              setColor(newColor.hex);
              if (onChange) onChange(newColor.hex);
            }}
          />
        </div>
      </Backdrop>
      )}
    </>
  );
};

export default PopupColorPicker;
