import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { resendDocuSignEmail } from 'utils/api';
import { stringifyAddressObject } from 'utils/lib';

const useStyles = makeStyles((theme) => (
  {
    ...modalStyle(theme),
    info: {
      marginBottom: '18px',
    },
    sectionHeader: {
      color: theme.palette.darkGray.main,
      fontSize: '18px',
      fontWeight: 700,
      padding: '12px 0 8px',
    },
  }
));

const CampaignAdminInfoModal = ({ campaign, onClose }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const handleResendClick = async () => {
    showLoadingIndicatorModal();

    try {
      await resendDocuSignEmail(campaign._id);

      setCurrentAlert('success', 'We have re-sent the DocuSign email');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setCurrentAlert('error', error.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', error);
    }

    hideLoadingIndicatorModal();
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      scroll="body"
      maxWidth="md"
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          {`${campaign.name} - ${campaign._id}`}
        </h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer className={classes.infoContainer}>
          <GridItem xs={12}>
            <h5 className={classes.sectionHeader}>General</h5>
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Agent:</strong>
            <br />
            {`${campaign.agent.firstName} ${campaign.agent.lastName} (${campaign.agent._id})`}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Event Type:</strong>
            <br />
            {`${campaign.eventType.name}`}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Status:</strong>
            <br />
            {campaign.status}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Campaign Start Date:</strong>
            <br />
            {campaign.campaignStartDate ? moment(campaign.campaignStartDate).format('MMMM D, YYYY, h:mm A') : 'N/A'}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Campaign End Date:</strong>
            <br />
            {campaign.campaignEndDate ? moment(campaign.campaignEndDate).format('MMMM D, YYYY, h:mm A') : 'N/A'}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Created On:</strong>
            <br />
            {moment(campaign.createdAt).format('MMMM D, YYYY, h:mm A')}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Last Updated On:</strong>
            <br />
            {moment(campaign.updatedAt).format('MMMM D, YYYY, h:mm A')}
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Subscription ID:</strong>
            <br />
            {campaign.stripe ? campaign.stripe.subscriptionId : 'N/A'}
          </GridItem>

          <GridItem xs={12}>
            <h5 className={classes.sectionHeader}>{`${campaign.eventType.name} Data`}</h5>
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.info}>
            <strong>Number of APNs</strong>
            <br />
            {campaign.subscribedEvents.length}
          </GridItem>
          <GridItem xs={12} className={classes.info}>
            <strong>Extra Addresses to Send Mail Outs to:</strong>
            <br />
            {campaign.extraMailOutRecipients.length > 0 ? (
              <ul>
                {campaign.extraMailOutRecipients.map(({ firstName, lastName, address }) => (
                  <li key={address._id}>
                    {`${firstName} ${lastName} @ ${stringifyAddressObject(address)}`}
                  </li>
                ))}
              </ul>
            ) : 'None'}
          </GridItem>
          {/* {campaign.status === 'PENDING_DOCUSIGN' && (
            <GridItem xs={12} style={{ textAlign: 'center' }}>
              <Button
                round
                color="primary"
                onClick={handleResendClick}
              >
                Resend DocuSign Email
              </Button>
            </GridItem>
          )} */}
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

CampaignAdminInfoModal.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CampaignAdminInfoModal;
