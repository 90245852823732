import { useCampaignDetail } from 'data/campaign';
import log from 'loglevel';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ButtonBase, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CreateCampaignDataSelection from 'components/CreateCampaign/CreateCampaignDataSelection';
import CreateCampaignTouchEditing from 'components/CreateCampaign/CreateCampaignTouchEditing';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import analyticsClient from 'utils/analytics';
import { deleteCampaign } from 'utils/api';
import { getNumDaysAutoRenewalCampaignStartsIn, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cancelButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  autoRenewalCampaignMessage: {
    maxWidth: '1000px',
    margin: '30px auto 40px',
    '& a': {
      color: theme.palette.orange.main,
    },
  },
}));

const CreateCampaignPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo } = useUserInfoContext();
  const { DASHBOARD_ROUTE, user } = useAuthDataContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const {
    availableEventTypes, selectedEventType, setSelectedEventType, setSelectedEventTypeByName, appliedFilters,
  } = useDataFilterContext();

  // If they're editing an existing draft, draftCampaignId will be set here
  const { campaign: draftCampaignId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { campaign, isLoadingCampaign, isLoadingCampaignError } = useCampaignDetail(draftCampaignId);

  /**
   *  Step 1 - Data selection
   *  Step 2 - Touch editing
   */
  const [step, setStep] = React.useState(1);

  const [draftCampaign, setDraftCampaign] = React.useState(undefined);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = React.useState(false);

  React.useEffect(() => {
    if (draftCampaignId && isLoadingCampaign) return; // Supposed to load a campaign and its still loading

    if (isLoadingCampaignError) {
      // Something went wrong getting their campaign
      history.replace(DASHBOARD_ROUTE);

      // TODO: Remove this when we add error pop-ups to react-swr
      setCurrentAlert('error', 'Something went wrong retrieving your campaign!');
      return;
    }

    if (campaign) {
      // Sanity check to make sure the campaign is a draft
      if (campaign.status !== 'DRAFT') {
        history.replace(`/dashboard/campaigns/${campaign._id}`);
        return;
      }

      setDraftCampaign(campaign);
    } else {
      setDraftCampaign(null);
    }
  }, [isLoadingCampaign, isLoadingCampaignError, campaign]);

  React.useEffect(() => {
    if (availableEventTypes.length === 0) return;
    if (!userInfo) return;
    if (draftCampaign === undefined) return; // Not loaded yet

    let analyticsCampaignEventType;

    if (location.state && location.state.eventType) {
      setSelectedEventType(location.state.eventType);

      analyticsCampaignEventType = location.state.eventType.name;
    } else if (!draftCampaign) {
      // User came to this page directly and is editing an existing campaign draft
      setSelectedEventTypeByName('Absentee Owners');
      analyticsCampaignEventType = 'Absentee Owners';
    } else {
      // User came to this page directly
      setSelectedEventTypeByName(draftCampaign.eventType.name);
      analyticsCampaignEventType = draftCampaign.eventType.name;
    }

    // User isn't editing a draft campaign, send an analytics event
    if (!draftCampaign) {
      // Track GTM event
      analyticsClient.track('campaign_creation_started', {
        agentdata_user_id: user.id,
        role: user.role,
        brokerage_id: userInfo.agentInfo.brokerage?._id ?? null,
        campaign_event_type: analyticsCampaignEventType,
      });
    }
  }, [availableEventTypes, userInfo, draftCampaign]);

  if (!selectedEventType || draftCampaign === undefined) return (<LoadingIndicator />);

  log.debug('draftCampaign', draftCampaign);

  const confirmDraftDeletion = () => setIsConfirmDeleteOpen(true);

  const onDeleteDraft = async () => {
    showLoadingIndicatorModal();

    try {
      if (!draftCampaign) return;

      await deleteCampaign(draftCampaign._id);

      setCurrentAlert('success', 'Your campaign draft has been deleted.');

      // Go to the dashboard
      history.push(DASHBOARD_ROUTE);
    } catch (err) {
      console.error('error', err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  let stepContent;

  if (step === 1) {
    stepContent = (
      <CreateCampaignDataSelection
        draftCampaign={draftCampaign}
        setDraftCampaign={setDraftCampaign}
        confirmDraftDeletion={confirmDraftDeletion}
        setStep={setStep}
      />
    );
  } else if (step === 2) {
    stepContent = (
      <CreateCampaignTouchEditing
        draftCampaign={draftCampaign}
        confirmDraftDeletion={confirmDraftDeletion}
        setStep={setStep}
      />
    );
  }

  const numDaysCampaignStartsIn = draftCampaign?.autoRenewalForCampaign ? getNumDaysAutoRenewalCampaignStartsIn(draftCampaign.createdAt) : undefined;

  return (
    <div className={classes.root}>
      <DashboardSection sectionName={`Create Your ${selectedEventType.name} Campaign`} />

      {isConfirmDeleteOpen && (
        <ActionModal
          icon={<WarningRoundedIcon />}
          onClose={() => setIsConfirmDeleteOpen(false)}
          title="Are You Sure?"
          message="You are about to delete this campaign draft. Your farm area selections and any marketing material customizations created for this campaign will be lost."
          buttons={[
            <ButtonBase onClick={() => setIsConfirmDeleteOpen(false)} className={classes.cancelButton}>Cancel</ButtonBase>,
            <ButtonBase onClick={onDeleteDraft}>Confirm</ButtonBase>,
          ]}
        />
      )}

      {draftCampaign?.autoRenewalForCampaign && (
        <DashboardBubble
          rootClass={classes.autoRenewalCampaignMessage}
        // backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
          icon={<AutorenewIcon htmlColor={theme.palette.warning.main} />}
          content={(
            <>
              {'This campaign is an auto-renewal for this '}
              <Link to={`/dashboard/campaigns/${draftCampaign?.autoRenewalForCampaign}`} target="_blank" rel="noopener noreferrer">
                {`${draftCampaign.eventType.name} campaign`}
              </Link>
              .
              <br />
              <br />
              {`This campaign will automatically start in ${numDaysCampaignStartsIn} day(s). If you wish to cancel the auto-renewal, you may do so by clicking the Cancel Renewal button below.`}
            </>
        )}
        />
      )}

      {stepContent}
    </div>
  );
};

export default CreateCampaignPage;
