import {
  containerFluid,
  drawerMiniWidth,
  drawerWidth,
  transition,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    // '&:after': {
    //   display: 'table',
    //   clear: 'both',
    //   content: '" "',
    // },
  },
  mainPanel: {
    background: theme.palette.white.main,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    minHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    // marginTop: '70px',
    padding: '24px 30px 12px',
    // padding: '30px 15px',
    // minHeight: 'calc(100vh - 152px)',
    position: 'relative',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px 12px',
    },
  },
  container: { ...containerFluid },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

export default style;
