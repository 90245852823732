/* eslint-disable no-shadow */
import { infoColor } from 'assets/jss/material-dashboard-pro-react';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';
import cx from 'classnames';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
import PerfectScrollbar from 'perfect-scrollbar';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';

import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Footer from 'creative-components/Footer/Footer';
import AdminNavbar from 'creative-components/Navbars/AdminNavbar';
import Sidebar from 'creative-components/Sidebar/Sidebar';

import AcknowledgeModal from 'components/AcknowledgeModal/AcknowledgeModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import PrivateRoute from 'components/AuthDataProvider/PrivateRoute';
import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import { withDataFilterProvider } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { withUserInfoProvider } from 'components/UserInfoProvider/UserInfoProvider';

import ErrorPage from 'views/Pages/ErrorPage';

import { getFeedbackTopics, getIntercomUserHash } from 'utils/api';
import { intercomAppId } from 'utils/lib';

let ps;

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const { ...rest } = props;
  const { user } = useAuthDataContext();
  const {
    currentAlert, currentModal, getModalLastAlert, setCurrentModal, closeCurrentModal,
  } = useAlertContext();
  const { isLoadingIndicatorModalShowing } = useLoadingIndicatorContext();

  /* const intercom = useIntercom();

  React.useEffect(() => {
    (async () => {
      if (!intercom || !user) return;

      const intercomProps = {
        user: `${user.firstName} ${user.lastName ?? ''}`,
        userId: user.id,
      };

      // Get a signed user hash from our backend for increased security w/ intercom
      const { userHash } = await getIntercomUserHash();

      intercomProps.userHash = userHash;

      intercom.boot(intercomProps);
    })();
  }, [intercom, user]); */

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  const classes = useStyles();
  const mainPanelClasses = `${classes.mainPanel
  } ${
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;

  // ref for main panel div
  const mainPanel = React.createRef();

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });

      // TODO: Causing the weird scrolling issues for zach???
      // document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  // TODO: Disabled feedback surveys for now
  /*
  // Effect for showing feedback alerts
  React.useEffect(() => {
    (async () => {
      const modalKey = 'campaignSurvey';

      const lastModalTime = getModalLastAlert(modalKey);

      // Only show a reminder once every week
      if (lastModalTime && Date.now() < lastModalTime + (1000 * 60 * 60 * 24 * 7)) {
        // We showed a reminder within the last week, skip this one.
        return;
      }

      const { topics } = await getFeedbackTopics();

      // Do they have pending campaign surveys?
      if (topics.filter(({ name }) => name.toLowerCase().includes('campaign')).length > 0) {
        setCurrentModal({
          component: AcknowledgeModal,
          props: (close) => ({
            message: (
              <>
                We'd love to hear about your experience with Harvist, please take a moment to fill out a short survey by
                {' '}
                <a href="/dashboard/feedback" style={{ color: infoColor[0] }}>clicking here</a>
                .
              </>
            ),
            onClose: close,
          }),
        }, modalKey);
      }
    })();
  }, []); */

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // eslint-disable-next-line no-shadow
  /* const getActiveRoute = (routes) => {
    const activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].exact) {
        // If the route is supposed to be exact then do a strict endsWidth check instead of the
        // indexOf check below. Handles cases like the billing (/billing) page being after the dashboard (/) page
        // in the routes
        if (window.location.href.endsWith(routes[i].layout + routes[i].path)) {
          const routeName = routes[i].name;

          return routeName === 'Dashboard' && user ? `Welcome, ${user.firstName}!` : routeName;
        }
      } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) { // Handles cases like dynamic urls
        return routes[i].name;
      }
    }

    return activeRoute;
  }; */

  const getRoutes = (routes) => routes.map((prop, key) => {
    // Permission check. Check for prop.collapse since we ignore collapses here since a page route
    // under a collapse could be available for users who don't have permission to see the collapse object
    // the page is a part of.
    if (!prop.collapse && prop.allowedRoles && !prop.allowedRoles.includes(user.role)) {
      return null;
    }

    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/dashboard') {
      return (
        <Route
          exact={prop.exact}
          path={prop.layout + prop.path}
          component={prop.component}
          render={prop.render}
          key={key}
        />
      );
    }
    return null;
  });

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  return (
    <div className={classes.wrapper}>
      {currentAlert}
      {currentModal && (
        <currentModal.component
          {...currentModal.props(closeCurrentModal)}
        />
      )}
      {isLoadingIndicatorModalShowing ? (<LoadingIndicator modal />) : null}
      <Sidebar
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="primary"
        miniActive={miniActive}
        userName={user ? `${user.firstName} ${user.lastName ?? ''}` : ''}
        userRole={user ? user.role : ''}
        isAssumedUser={user.isAssumedUser}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        {/* TODO: TEST mobile NAVBAR WITH NEW SECTION STUFF */}
        <Hidden mdUp>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize}
            miniActive={miniActive}
            // brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        </Hidden>
        <div className={classes.content}>
          {/* <div className={classes.container}> */}
          <Switch>
            {getRoutes(routes)}
            <Route path="*" component={() => (<PublicRoute component={ErrorPage} />)} />
          </Switch>
          {/* </div> */}
        </div>
        <Footer />
      </div>
    </div>
  );
};

// Wrap in private route to enforce authentication when rendering anything in the dashboard
export default withUserInfoProvider(withDataFilterProvider(() => (
  // <IntercomProvider appId={intercomAppId} autoBoot={false}>
  <PrivateRoute component={Dashboard} />
  // </IntercomProvider>
)));
