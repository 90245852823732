import {
  primaryColor,
  infoColor,
  backdropContainer,
  backdropImage,
} from 'assets/jss/material-dashboard-pro-react';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

const style = (theme) => {
  const modalStylesWithTheme = modalStyle(theme);

  return ({
    backdropContainer,
    backdropImage,
    ...modalStylesWithTheme,
    // TODO: Remove later. Commented out the maxWidth in the parent modalStyle.js
    // modal: {
    //   ...modalStylesWithTheme.modal,
    //   [theme.breakpoints.up('sm')]: {
    //     // Override the one from the parent
    //   },
    // },
    info: {
      marginBottom: '18px',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
    },
    blueText: {
      color: infoColor[0],
    },
    zipcodeCountsList: {
      color: primaryColor[0],
      listStyle: 'none',
    },
    imageSmall: {
      maxHeight: '100px',
      maxWidth: '100%',
    },
  });
};

export default style;
