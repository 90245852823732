import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';

import { updateUser } from 'utils/api';
import { showAPIErrorAlert, urlRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    margin: '47px 0 0',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const SocialLinksForm = (props, ref) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  return (
    <Formik
      innerRef={ref}
      validateOnChange={false}
      initialValues={{
        socialLinks: {
          facebook: userInfo.agentInfo.socialLinks.facebook || '',
          yelp: userInfo.agentInfo.socialLinks.yelp || '',
          linkedIn: userInfo.agentInfo.socialLinks.linkedIn || '',
          instagram: userInfo.agentInfo.socialLinks.instagram || '',
          twitter: userInfo.agentInfo.socialLinks.twitter || '',
          tikTok: userInfo.agentInfo.socialLinks.tikTok || '',
        },
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const { socialLinks } = values;

        try {
          await updateUser({
            agentInfo: {
              socialLinks,
            },
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        socialLinks: Yup.object({
          facebook: Yup.string().url().matches(urlRegExp),
          yelp: Yup.string().url().matches(urlRegExp),
          linkedIn: Yup.string().url().matches(urlRegExp),
          instagram: Yup.string().url().matches(urlRegExp),
          twitter: Yup.string().url().matches(urlRegExp),
          tikTok: Yup.string().url().matches(urlRegExp),
        }).required(),
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        const errorForNestedFieldObj = (fieldName) => {
          const split = fieldName.split('.');
          return touched[split[0]] && touched[split[0]][split[1]] && errors[split[0]] && errors[split[0]][split[1]] !== undefined;
        };

        return (
          <Form>
            <FormikOnError>
              <GridContainer justifyContent="center">
                <GridItem xs={12} lg={10} xl={7}>
                  <GridContainer justifyContent="center" className={classes.topMargin}>

                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.facebook"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Facebook"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.yelp"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Yelp"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.linkedIn"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="LinkedIn"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.instagram"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Instagram"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.twitter"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Twitter"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <Field
                        name="socialLinks.tikTok"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="TikTok"
                            inputProps={{
                              ...field,
                            }}
                            error={errorForNestedFieldObj(field.name)}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12}>
                      <div className={classes.saveButtonContainer}>
                        <SaveButton onSave={reloadUserInfo} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(SocialLinksForm);
