import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import capitalize from 'capitalize';
import React from 'react';

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';
import StripeCardElement from 'components/StripeCardElement/StripeCardElement';

import {
  getBrokeragePaymentMethodSetupIntent, getUserPaymentMethodSetupIntent, updateBrokeragePaymentMethod,
  updateUserPaymentMethod,
} from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  content: {
    '& > h5': {
      fontSize: '15px',
      fontWeight: 400,
    },
  },
  spacer: {
    height: '20px',
  },
  stripeCardElement: {
    margin: '50px 0',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    borderRadius: '8px',
    padding: '8px 12px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
}));

const PaymentMethodCard = ({ billingName, paymentMethodInfo, reloadCallback }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { setCurrentAlert } = useAlertContext();
  const { isBrokerageAdmin } = useAuthDataContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = React.useState(false);
  const [setupIntentClientSecret, setSetupIntentClientSecret] = React.useState(null);

  const getSetupIntentClientSecret = async () => {
    showLoadingIndicatorModal();
    try {
      const getSetupIntentCall = isBrokerageAdmin ? getBrokeragePaymentMethodSetupIntent : getUserPaymentMethodSetupIntent;
      const { setupIntentClientSecret: setupIntentClientSecretReturned } = await getSetupIntentCall();

      setSetupIntentClientSecret(setupIntentClientSecretReturned);
      setIsUpdatingPaymentMethod(true);
    } catch (err) {
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const updatePaymentMethod = async () => {
    showLoadingIndicatorModal();

    const data = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: { },
      },
    };

    if (billingName) {
      data.payment_method.billing_details.name = billingName;
    }

    const cardSetupResult = await stripe.confirmCardSetup(setupIntentClientSecret, data);

    if (cardSetupResult.error) {
      // Don't do the API request, have the Stripe CC field go red
      hideLoadingIndicatorModal();

      setCurrentAlert('error', cardSetupResult.error.message);

      return;
    }

    const newPaymentMethodId = cardSetupResult.setupIntent.payment_method;

    const updatePaymentMethodCall = isBrokerageAdmin ? updateBrokeragePaymentMethod : updateUserPaymentMethod;
    await updatePaymentMethodCall(newPaymentMethodId);

    setCurrentAlert('success', 'Your payment method has been updated.');

    setSetupIntentClientSecret(null);
    hideLoadingIndicatorModal();
    setIsUpdatingPaymentMethod(false);

    reloadCallback();
  };

  const onModalClose = () => setIsUpdatingPaymentMethod(false);

  return (
    <>
      {isUpdatingPaymentMethod && (
        <Dialog
          open
          scroll="body"
          keepMounted
          maxWidth="sm"
          onClose={onModalClose}
          className={classes.updateContainer}
        >
          <SimpleModalHeader onClose={onModalClose} title="Update Payment Method" />

          <div className={classes.stripeCardElement}>
            <StripeCardElement />
          </div>

          <div className={classes.buttonContainer}>
            <Button
              round
              color="primary"
              onClick={updatePaymentMethod}
            >
              Update
            </Button>
          </div>
        </Dialog>
      )}

      <div className={classes.content}>
        {paymentMethodInfo && paymentMethodInfo.last4 ? (
          <h5>
            {`${capitalize(paymentMethodInfo.brand)} card ending in ${paymentMethodInfo.last4}`}
            <br />
            <b>Expires:</b>
            {` ${paymentMethodInfo.expMonth}/${paymentMethodInfo.expYear}`}
          </h5>
        ) : (
          <h5>No payment method on file</h5>
        )}
        <div className={classes.spacer} />
        <Button
          color="warning"
          className={classes.button}
          onClick={getSetupIntentClientSecret}
        >
          {paymentMethodInfo && paymentMethodInfo.last4 ? 'Change Payment Method' : 'Provide Payment Method'}
        </Button>
      </div>
    </>
  );
};

export default PaymentMethodCard;
