import {
  backdropContainer,
} from 'assets/jss/material-dashboard-pro-react';

const style = {
  backdropContainer,
  marketingColorSwatch: {
    padding: '12px 30px',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
};

export default style;
