export enum ECampaignStatus {
  Draft = 'DRAFT',
  PendingDocusign = 'PENDING_DOCUSIGN', // We no longer require DocuSign 06/21/2022, the PENDING_DOCUSIGN state isn't used anymore
  PendingData = 'PENDING_DATA',
  PendingBilling = 'PENDING_BILLING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}
