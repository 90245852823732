import React from 'react';
import {
  Link, useHistory, useParams, useLocation,
} from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import BrokerageAgentsTable from 'components/BrokerageTables/BrokerageAgentsTable';
import BrokerageAdminsTable from 'components/BrokerageTables/BrokerageAdminsTable';
import BrokerageBranchesTable from 'components/BrokerageTables/BrokerageBranchesTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';

import { useBrokerageById } from 'data/brokerage';

import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    marginTop: '20px',
  },
  dashboardBubble: {
    margin: '10px auto',
    '& a': {
      color: theme.palette.warning.main,
    },
  },
  quotaText: {
    '& > span': {
      fontWeight: 600,
    },
  },
}));

const tabs = ['Branches', 'Agents', 'Admins'];

const BrokerageDetailPage = () => {
  const { brokerageId } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { DASHBOARD_ROUTE, isAdmin } = useAuthDataContext();
  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useBrokerageById(brokerageId);

  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  if (!brokerageId) {
    // Invalid url params. Redirect to dashboard
    history.replace(DASHBOARD_ROUTE);
  }

  if (isLoadingBrokerage) return (<LoadingIndicator />);

  let tabContent;
  if (activeTab === tabs[0]) {
    tabContent = (
      <>
        <BrokerageBranchesTable brokerage={brokerage} />
      </>
    );
  } else if (activeTab === tabs[1]) {
    tabContent = (
      <>
        <BrokerageAgentsTable brokerage={brokerage} mutateBrokerage={mutateBrokerage} />
      </>
    );
  } else if (activeTab === tabs[2]) {
    tabContent = (
      <>
        <BrokerageAdminsTable brokerage={brokerage} />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <DashboardSection
        breadcrumbs={[{ name: 'Brokerages', to: location.state ? location.state.previousPathname : '/dashboard' }]}
        sectionName={brokerage.name}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {/* TODO: IMPROVE JUST LIKE MYBROKERAGEPAGE */}
      {/* <DashboardBubble
        rootClass={classes.dashboardBubble}
        backgroundColor={theme.palette.primary.light}
        icon={<InfoIcon htmlColor={theme.palette.success.main} />}
        content={(
          <span className={classes.quotaText}>
            <span>Stats:</span>
            <br />
            {`${numberWithCommas(brokerage.agents.length)} Agents`}
            <br />
            {`${numberWithCommas(brokerage.numApnsBeingFarmed)} APNs`}
          </span>
          )}
      /> */}

      <div className={classes.spacer} />

      {tabContent}
    </div>
  );
};

export default BrokerageDetailPage;
