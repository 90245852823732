import useSWR from 'swr';

export const useMergeVariables = (touchTriggerId) => {
  const { data, error } = useSWR(`/touch-triggers/${touchTriggerId}/merge-variables`);

  return {
    mergeVariables: data?.mergeVariables,
    isLoadingMergeVariables: !data,
    isError: error,
  };
};
