import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DataMap from 'components/DataMap/DataMap';
import DashboardSection from 'components/DashboardSection/DashboardSection';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  adminMap: {
    flex: 1,
    minHeight: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AdminDashboardPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DashboardSection
        sectionName="Zip Codes Map"
      />
      <div className={classes.adminMap}>
        <DataMap
          renderSearchZipcodesForm
        />
      </div>
    </div>
  );
};

export default AdminDashboardPage;
