import React from 'react';
import cx from 'classnames';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { compressImageUpload } from 'utils/lib';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

const useStyles = makeStyles((theme) => ({
  imageHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.darkGray.main,
    position: 'relative',
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: '6px',
    },
  },
  requiredText: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    float: 'right',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.orange.main,
    textTransform: 'none',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '6px',
    backgroundColor: '#F2F2F3',
    border: `1px dashed ${theme.palette.primary.main}`,

    width: '100%',
    height: '256px',

    '& > img': {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      margin: 'auto',
    },

    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },
  imageContainerDark: {
    backgroundColor: '#121213',
  },
  imageContainerErrored: {
    border: `2px solid ${theme.palette.orange.main}`,
  },
  fileInput: {
    display: 'none',
  },
  cropContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '12px',
  },
  button: {
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.white.main,
  },
  selectFileButton: {
    backgroundColor: theme.palette.primary.main,
  },
  removeButton: {
    margin: '14px 0 0',
    backgroundColor: theme.palette.error.main,
  },
  finishCropping: {
    textAlign: 'center',

    '& > button': {
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      padding: '10px 20px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.white.main,
    },
  },
  uploadHover: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    visibility: 'hidden',
  },
}));

const cropAspectRatio = 1;

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
const getCroppedImage = async (
  image,
  pixelCrop,
  scale,
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = pixelCrop.x * scaleX;
  const cropY = pixelCrop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 4) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 3) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  return canvas.toDataURL('image/png');
};

/**
 * Creates an alert if cropping hasn't finished
 */
export const croppingValidation = (ref, setCurrentAlert) => () => {
  // Make sure they finished the croppie cropping
  if (ref && ref.current && ref.current.isInMiddleOfCropping()) {
    setCurrentAlert('warning', 'Did you forget to finish cropping your image?');
    return false;
  }

  return true;
};

const getCenterCrop = (width, height, aspect) => centerCrop(
  makeAspectCrop(
    {
      unit: 'px',
      width: 0.8 * width,
    },
    aspect,
    width,
    height,
  ),
  width,
  height,
);

// TODO: Maybe add aspect ratio prop here?
const CustomImage = ({
  initialImage, label, required, onImageUpload, onImageDelete, error, croppable, disabled = false, darkBackground = false, tooltipText = '',
}, ref) => {
  const classes = useStyles();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [imageData, setImageData] = React.useState(initialImage || null);

  const [crop, setCrop] = React.useState();

  const inputRef = React.useRef();
  const croppedImageRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    isInMiddleOfCropping() {
      return imageData && crop;
    },
  }));

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile) return;

    try {
      showLoadingIndicatorModal();
      const data = await compressImageUpload(imageFile);
      hideLoadingIndicatorModal();
      setImageData(data);

      if (croppable) {
        setCrop(getCenterCrop(imageFile.width, imageFile.height, cropAspectRatio));
      } else {
        onImageUpload(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onImageLoad = (e) => {
    setCrop(getCenterCrop(e.currentTarget.width, e.currentTarget.height, cropAspectRatio));
  };

  const handleFinishCropping = async () => {
    const croppedImage = await getCroppedImage(croppedImageRef.current, crop, 1);

    setImageData(croppedImage);
    onImageUpload(croppedImage);
    setCrop(null);
  };

  return (
    <div>
      <div className={classes.imageHeader}>
        {label}
        {tooltipText && (
          <Tooltip
            placement="top"
            disableFocusListener
            disableTouchListener
            title={tooltipText}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        )}
        {required && (<span className={classes.requiredText}>*required</span>)}
      </div>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        className={classes.fileInput}
        onChange={(e) => handleImageUpload(e)}
        onClick={(e) => { e.target.value = null; }}
      />
      {!crop && (
        <div className={cx(classes.imageContainer, darkBackground && classes.imageContainerDark, error && classes.imageContainerErrored)}>
          {imageData ? (
            <>
              <img src={imageData} alt="" />
              {!disabled && (
                <div className={classes.uploadHover}>
                  <ButtonBase
                    className={cx(classes.button, classes.selectFileButton)}
                    onClick={handleClick}
                  >
                    Upload
                  </ButtonBase>
                  {!required && onImageDelete && (
                    <ButtonBase
                      className={cx(classes.button, classes.removeButton)}
                      onClick={() => {
                        setImageData(null);
                        onImageDelete();
                      }}
                    >
                      Remove
                    </ButtonBase>
                  )}
                </div>
              )}
            </>
          ) : (
            !disabled && (
              <ButtonBase
                className={cx(classes.button, classes.selectFileButton)}
                onClick={handleClick}
              >
                Select file
              </ButtonBase>
            )
          )}
        </div>
      )}
      {crop && (
        <div className={classes.cropContainer}>
          <ReactCrop
            ruleOfThirds
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={cropAspectRatio}
          >
            <img
              ref={croppedImageRef}
              alt="Crop"
              src={imageData}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}
      <div className={classes.finishCropping}>
        {crop && (
          <ButtonBase onClick={handleFinishCropping}>
            Finish Cropping
          </ButtonBase>
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(CustomImage);
