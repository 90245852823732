import cx from 'classnames';
import React from 'react';

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { getTouchPreview } from 'utils/api';
import { openFileInNewWindow, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    marginTop: '20px',
    position: 'relative',
    display: 'block',
    margin: 'auto',
  },

  previewContainerLetter: {
    // 8.5 in. x 11 in. Add extra to remove scrollbars, etc.
    maxWidth: '8.75in',
    maxHeight: '11.1in',

    width: '100%',
    height: '100%',

    overflowY: 'auto',
    overflowX: 'auto',
  },
  previewContainerPostcard6x11: {
    // 11.25 in. x 6.25 in. Add extra to remove scrollbars, etc.
    maxWidth: '11.5in',
    maxHeight: '6.25in',

    width: '100%',
    height: '100%',

    overflowY: 'auto',
    overflowX: 'auto',
  },
  previewContainerPostcard6x9: {
    // 9.25 in. x 6.25 in. Add extra to remove scrollbars, etc.
    maxWidth: '9.5in',
    maxHeight: '6.25in',

    width: '100%',
    height: '100%',

    overflowY: 'auto',
    overflowX: 'auto',
  },

  previewFrame: {
    overflow: 'hidden',
  },

  previewFrameLetter: {
    height: 'calc(11in + 4px)', // Add 4px for border
    width: 'calc(8.5in + 4px)',
  },
  previewFramePostcard6x11: {
    height: 'calc(6.25in + 4px)', // Add 4px for border
    width: 'calc(11.25in + 4px)',
  },
  previewFramePostcard6x9: {
    height: 'calc(6.25in + 4px)', // Add 4px for border
    width: 'calc(9.25in + 4px)',
  },

  pdfPage: {
    margin: '0 auto 20px',
    width: 'fit-content',
  },

  lagTimeText: {
    fontWeight: 500,
    margin: '20px auto',
  },
}));

const PreviewPostcardLetterModal = ({ onClose, touchTrigger, touchName }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  const [previewData, setPreviewData] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const { pdf, html } = await getTouchPreview(touchTrigger._id);

        if (pdf) {
          // Preview PDFs by opening them in a new tab
          openFileInNewWindow(pdf, setCurrentAlert);

          onClose();
        } else {
          // Inject some styles to prevent text selection and to add a border around the body
          const htmlToFind = '<style>';
          const cssToInject = '* { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; } body { border: 2px solid #000; width: inherit !important; height: inherit !important; }';

          const data = [];

          if (touchTrigger.touchType.name === 'LETTER') { // One page for letters
            const htmlWithInjectedCss = html[0].replace(htmlToFind, `${htmlToFind}${cssToInject}`);
            data.push(`data:text/html,${encodeURIComponent(htmlWithInjectedCss)}`);
          } else { // Two pages for postcards, used for the old style postcards. New ones are all PDF.
            const htmlFrontWithInjectedCss = html[0].replace(htmlToFind, `${htmlToFind}${cssToInject}`);
            const htmlBackWithInjectedCss = html[1].replace(htmlToFind, `${htmlToFind}${cssToInject}`);
            data.push(`data:text/html,${encodeURIComponent(htmlFrontWithInjectedCss)}`);
            data.push(`data:text/html,${encodeURIComponent(htmlBackWithInjectedCss)}`);
          }

          setPreviewData({ touchTypeName: touchTrigger.touchType.name, data, type: 'html' });
        }
      } catch (err) {
        console.error('error', err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, []);

  let preview;

  if (previewData) {
    if (previewData.touchTypeName === 'LETTER') {
      preview = (
        <div className={cx(classes.previewContainer, classes.previewContainerLetter)}>
          <iframe
            src={previewData.data[0]}
            title="Letter Sample"
            className={cx(classes.previewFrame, classes.previewFrameLetter)}
            frameBorder="0"
            height="100%"
            width="100%"
          />
        </div>
      );
    } else if (previewData.touchTypeName === 'POSTCARD') {
      if (previewData.type === 'html') { // HTML templates
        preview = (
          <div className={cx(classes.previewContainer, touchTrigger.metadata.size === '6x11' ? classes.previewContainerPostcard6x11 : classes.previewContainerPostcard6x9)}>
            <iframe
              src={previewData.data[0]}
              title="Postcard Front Sample"
              className={cx(classes.previewFrame, touchTrigger.metadata.size === '6x11' ? classes.previewFramePostcard6x11 : classes.previewFramePostcard6x9)}
              frameBorder="0"
              height="100%"
              width="100%"
            />
            <iframe
              src={previewData.data[1]}
              title="Postcard Rear Sample"
              className={cx(classes.previewFrame, touchTrigger.metadata.size === '6x11' ? classes.previewFramePostcard6x11 : classes.previewFramePostcard6x9)}
              frameBorder="0"
              height="100%"
              width="100%"
            />
          </div>
        );
      }
    }
  }

  return (
    <Dialog
      id="preview-modal"
      open
      scroll="paper"
      keepMounted
      maxWidth="lg"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title={`${touchName} Preview`} />

      {previewData
        ? preview
        : (
          <>
            {touchTrigger.touchType.name === 'POSTCARD' && <div className={classes.lagTimeText}>We are preparing your custom postcard with all of your personalized info...this may take up to a minute!</div>}
            <LoadingIndicator />
          </>
        )}
    </Dialog>
  );
};

export default PreviewPostcardLetterModal;
