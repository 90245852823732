const style = (theme) => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  content: {
    flex: 1,
    display: 'flex!important',
    margin: '0',
    border: '0',
    backgroundColor: theme.palette.white.main,
    alignItems: 'center',
    height: '100%',
  },
  downloadingReportModalRoot: {
    // To fix dialog size when resizing map/table for html2canvas
    height: '100vh',
    width: '100vw',
    '& > div:first-child': {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
    },
  },
  downloadingReportModalPaper: {
    textAlign: 'center',

    '& > h3': {
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
    },
    '& > p': {
      margin: '16px 0 34px',
      color: theme.palette.darkGray.main,
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
});

export default style;
