import React from 'react';

import { SvgIcon } from '@material-ui/core';

const SubtractIcon = ({ className }) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4032 14.0128C19.4326 14.0128 20.4121 13.7979 21.2999 13.4104C22.0513 13.2359 22.834 13.1438 23.6381 13.1438C29.3608 13.1438 34 17.8126 34 23.5719C34 29.3312 29.3608 34 23.6381 34C17.9154 34 13.2762 29.3312 13.2762 23.5719C13.2762 22.7627 13.3678 21.9751 13.5411 21.2189C13.9262 20.3254 14.1397 19.3395 14.1397 18.3035C14.1397 14.254 10.8778 10.9712 6.85399 10.9712C6.4258 10.9712 6.00623 11.0084 5.5983 11.0797C5.58512 11.0798 5.57193 11.0799 5.55873 11.0799C2.48873 11.0799 0 8.59956 0 5.53993C0 2.48031 2.48873 0 5.55873 0C8.62873 0 11.1175 2.48031 11.1175 5.53993C11.1175 5.62839 11.1154 5.71636 11.1113 5.80381L11.1642 5.84555C11.1333 6.11957 11.1175 6.39817 11.1175 6.6805C11.1175 10.73 14.3794 14.0128 18.4032 14.0128Z" />
  </SvgIcon>
);

export default SubtractIcon;
