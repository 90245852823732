import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useCampaignEmailDeliverabilityStats } from 'data/campaign-stats';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  select: {
    '& > div': {
      marginBottom: 0,
    },
  },
}));

const totalIntervalName = 'Total';

export interface Props {
  campaignId: string;
  onViewTouchTimeline: () => void;
}

export default ({ campaignId, onViewTouchTimeline }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { emailDeliverabilityStats } = useCampaignEmailDeliverabilityStats(campaignId);

  const [selectedTime, setSelectedTime] = React.useState<string>(totalIntervalName);

  const selectItems = React.useMemo<{ value: string; name: string; }[] | undefined>(() => {
    if (!emailDeliverabilityStats) return undefined;

    // Convert object to array and parse dates. Arbitrarily use bouncedEmails here. Also
    // sort the array by parse dates.
    const dataArray = Object.entries(emailDeliverabilityStats.bouncedEmails).map(([key, value]) => {
      const parsedDate = moment(key, 'MM-YYYY');
      return { monthYear: key, events: value, parsedDate };
    }).sort((a, b) => a.parsedDate.diff(b.parsedDate));

    return dataArray.map(({ monthYear, parsedDate }) => ({
      value: monthYear,
      name: monthYear !== totalIntervalName ? parsedDate.format('MMM YYYY') : totalIntervalName,
    }));
  }, [emailDeliverabilityStats]);

  const data = React.useMemo<{ title: string; count: number; percentageChange: number; }[] | undefined>(() => {
    if (!emailDeliverabilityStats) return undefined;

    return [
      { title: 'Unsubscribes', ...emailDeliverabilityStats.unsubscribedEmails[selectedTime] },
      { title: 'Bounces', ...emailDeliverabilityStats.bouncedEmails[selectedTime] },
      { title: 'Clicks', ...emailDeliverabilityStats.clickedEmails[selectedTime] },
    ];
  }, [emailDeliverabilityStats, selectedTime]);

  return (
    <Box sx={{
      height: 317,
      width: '100%',
      maxWidth: 287,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 8,
      bgcolor: theme.palette.offWhite.main,
    }}
    >
      <Box sx={{
        fontWeight: 500,
        fontSize: 21,
        color: theme.palette.purple.dark,
        marginBottom: 15,
      }}
      >
        <Box sx={{
          fontWeight: 500,
          fontSize: 21,
          color: theme.palette.purple.dark,
          marginBottom: 15,
        }}
        >
          Email Deliverability
        </Box>

        {/* TODO: TEMP DISABLED */}
        {/* <ButtonBase onClick={onViewTouchTimeline} style={{ color: theme.palette.primary.main }}>
          View All
          <ChevronRightIcon />
        </ButtonBase> */}
      </Box>

      {data && selectItems ? (
        <>
          {data.map(({ title, count, percentageChange }) => (
            <Box
              key={title}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <Box>
                <Box sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: theme.palette.purple.dark,
                }}
                >
                  {title}
                </Box>
                <Box sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  color: theme.palette.primary.main,
                }}
                >
                  {`${count} Properties`}
                </Box>
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: 14,
                bgcolor: percentageChange >= 0 ? `rgba(${hexToRgb(theme.palette.primary.main)}, 0.15)` : `rgba(${hexToRgb(theme.palette.orange.main)}, 0.15)`,
                borderRadius: 100,
                padding: '3px 10px',
                fontWeight: 400,
                color: percentageChange >= 0 ? theme.palette.primary.main : theme.palette.orange.main,
              }}
              >
                {percentageChange >= 0 ? <TrendingUpIcon style={{ fontSize: 12, marginRight: 6 }} /> : <TrendingDownIcon style={{ fontSize: 12, marginRight: 6 }} />}
                {`${(Math.abs(percentageChange) * 100).toFixed(0)}%`}
              </Box>
            </Box>
          ))}

          <Box
            sx={{
              alignSelf: 'end',
            }}
            className={classes.select}
          >
            <CustomSelect
              labelText=""
              labelBold
              labelLight
              fontSize={14}
              items={selectItems.map(({ value, name }) => ({ value, name }))}
              inputProps={{
                value: selectedTime,
                onChange: (e: any) => setSelectedTime(e.target.value),
              }}
            />
          </Box>
        </>
      ) : <LoadingIndicator />}
    </Box>
  );
};
