const style = (theme) => ({
  formControl: {
    position: 'relative',
    margin: '0 0 22px',
    padding: 0,
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: theme.palette.darkGray.main,
    },
  },

  label: {
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '6px',
    fontWeight: 600,
  },
  labelDisabled: {
    color: theme.palette.grayScale5.main,
  },
  labelBold: {
    fontWeight: 'bold',
  },
  labelRequired: {
    '&:after': {
      content: '" *"',
      color: theme.palette.error.main,
    },
  },
  labelLight: {
    color: theme.palette.grayScale9.main,
  },

  helperText: {
    marginTop: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.lightGray.main,
  },
  helperTextError: {
    color: theme.palette.error.main,
  },

  selectMenuPaper: {
    backgroundColor: theme.palette.white.main,
    padding: '8px 0px',
    border: `2px solid ${theme.palette.grayScale5.main}`,
    borderRadius: '6px',
    boxShadow: '0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06)',

    // TODO: REMOVE?
    // '&:focus': {
    //   backgroundColor: theme.palette.white.main,
    // },
  },
  selectMenuItem: {
    padding: '10px 16px',
    color: theme.palette.darkGray.main,
    lineHeight: '26px',
  },
  selectMenuItemSelectedMultiple: {
    // backgroundColor: 'transparent !important',
    // '&:hover': {
    //   backgroundColor: `${theme.palette.darkGray.main} !important`,
    //   color: theme.palette.white.main,
    //   '&:after': {
    //     color: theme.palette.white.main,
    //   },
    // },
    '&:after': {
      top: '20px',
      right: '12px',
      width: '12px',
      height: '5px',
      borderLeft: '2px solid currentColor',
      transform: 'rotate(-45deg)',
      opacity: '1',
      color: theme.palette.darkGray.main,
      position: 'absolute',
      content: "''",
      borderBottom: '2px solid currentColor',
      transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)',
    },
  },
  input: {
    margin: 0,
    padding: '16px 16px',
  },
});

export default style;
