export enum ELeadResponseType {
  EmailOpen = 'EMAIL_OPEN',
  EmailClick = 'EMAIL_CLICK',
  EmailReply = 'EMAIL_REPLY',
  AgentLandingOpen = 'AGENT_LANDING_OPEN',

  // For 'COMPARABLE_PROPERTIES' agent landing page type
  AgentLandingConsentForm = 'AGENT_LANDING_CONSENT_FORM',
  AgentLandingDownloadReport = 'AGENT_LANDING_DOWNLOAD_REPORT',
  AgentLandingEmailAgentForm = 'AGENT_LANDING_EMAIL_AGENT_FORM',
  AgentLandingSellForThisPriceForm = 'AGENT_LANDING_SELL_FOR_THIS_PRICE_FORM',
  AgentLandingPriceTooLowForm = 'AGENT_LANDING_PRICE_TOO_LOW_FORM',
  AgentLandingAssessmentForm = 'AGENT_LANDING_ASSESSMENT_FORM',

  // For 'CONTACT_FORM' agent landing page type
  AgentLandingContactForm = 'AGENT_LANDING_CONTACT_FORM',
}
