import React from 'react';

import { SvgIcon } from '@material-ui/core';

const FAQIcon = ({ className }) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 5.24978L12 6.28327L14.5 5.24978C16.1049 4.58634 17.8951 4.58634 19.5 5.24978L21.3714 6.02341C21.751 6.18036 22 6.56038 22 6.98298V19.2257C22 19.7965 21.5523 20.2592 21 20.2592C20.8728 20.2592 20.7467 20.2341 20.6286 20.1853L19.5 19.7187C17.8951 19.0553 16.1049 19.0553 14.5 19.7187L12 20.7522L9.5 19.7187C7.89515 19.0553 6.10485 19.0553 4.5 19.7187L3.37139 20.1853C2.85861 20.3973 2.27664 20.1395 2.07152 19.6095C2.02428 19.4874 2 19.3572 2 19.2257V6.98298C2 6.56038 2.24895 6.18036 2.62861 6.02341L4.5 5.24978C6.10485 4.58634 7.89515 4.58634 9.5 5.24978ZM5.26408 7.09807L4 7.62064V17.7663C6.02542 17.0062 8.25753 17.0409 10.2641 17.8704L11 18.1742V8.0342L8.73592 7.09807C7.62037 6.63691 6.37963 6.63691 5.26408 7.09807ZM15.2641 7.09807L13 8.0332V18.1742L13.7359 17.8704C15.7425 17.0409 17.9746 17.0062 20 17.7663V7.62064L18.7359 7.09807C17.6204 6.63691 16.3796 6.63691 15.2641 7.09807Z" fill="inherit" />
  </SvgIcon>
);

export default FAQIcon;
