import React from 'react';

import {
  ButtonBase, Dialog, DialogProps,
  Theme,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface StyleProps {
  backgroundColor?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  dialog: (props) => ({
    '& > div > div': {
      width: 'fit-content',
      padding: '16px 16px 22px',
      backgroundColor: props.backgroundColor || theme.palette.secondary.main,
    },
  }),
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.white.main,
    marginRight: '50px',

    '& > svg': {
      marginRight: '18px',
      verticalAlign: 'middle',
    },
  },
  message: {
    padding: '0 20px 0',
    color: theme.palette.white.main,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '16px 0 20px',
    overflowWrap: 'break-word',
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    '& > button': {
      margin: '0 10px 0',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '8px',
      padding: '8px 12px',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.grayScale9.main,
      textTransform: 'uppercase',
    },
  },
}));

interface Props {
  icon?: React.ReactNode;
  title: string;
  message: React.ReactNode | string;
  onClose: () => void;
  buttons: React.ReactNode[];
  backgroundColor?: string;
  textColor?: string;
  maxWidth?: DialogProps['maxWidth'];
}

const ActionModal = ({
  icon, title, message, onClose, buttons = [], backgroundColor, textColor, maxWidth = 'xs',
}: Props) => {
  const classes = useStyles({ backgroundColor });
  const theme = useTheme();

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth={maxWidth}
      onClose={onClose}
      className={classes.dialog}
    >
      <div className={classes.header}>
        <div className={classes.title} style={{ color: textColor }}>
          {icon}
          {title}
        </div>
        {/* TODO: modalCloseButton??? */}
        {/* <ButtonBase className={classes.modalCloseButton} aria-label="Close" onClick={onClose}> */}
        <ButtonBase aria-label="Close" onClick={onClose}>
          <CloseIcon htmlColor={textColor || theme.palette.white.main} />
        </ButtonBase>
      </div>
      <div className={classes.message} style={{ color: textColor }}>{message}</div>
      <div className={classes.buttons}>
        {buttons.map((button, i) => <React.Fragment key={i}>{button}</React.Fragment>)}
      </div>
    </Dialog>
  );
};

export default ActionModal;
