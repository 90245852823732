import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';

/**
 * The `user_id` event parameter name is reserved. We use agentdata_user_id instead.
 *
 * When adding new event parameters, make sure to add them as variables in GTM too!
 */

const analyticsClient = Analytics({
  app: 'AgentData Dashboard',
  plugins: [
    process.env.REACT_APP_ENV === 'production'
      ? googleTagManager({
        debug: process.env.REACT_APP_LOGGER_LEVEL === 'debug',
        containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
      })
      : null,
  ].filter(Boolean), // Remove null values if not in production
});

export default analyticsClient;
