import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import AgentsTable from 'components/AgentsTable/AgentsTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';

import { getAgentUsers } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AgentsAdminPage = () => {
  const classes = useStyles();

  // const { campaigns, isLoadingCampaigns } = useAllCampaigns();

  const [agents, setAgents] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const { agents: agentsReturned } = await getAgentUsers();

      setAgents(agentsReturned);
    })();
  }, []);

  return (
    <div className={classes.root}>
      {openModal}

      {true && (
        <DashboardSection
          sectionName="Agents"
        />
      )}

      <AgentsTable
        agents={agents}
        // campaigns={campaigns}
        setAgents={setAgents}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default AgentsAdminPage;
