import React from 'react';

import { SvgIcon } from '@material-ui/core';

const ProfileIcon = ({ className }) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 16.7522H17C19.7614 16.7522 22 18.9908 22 21.7522C22 22.3045 21.5523 22.7522 21 22.7522C20.4872 22.7522 20.0645 22.3662 20.0067 21.8688L19.9949 21.5759C19.907 20.0594 18.6928 18.8452 17.1763 18.7573L17 18.7522H7C5.34315 18.7522 4 20.0953 4 21.7522C4 22.3045 3.55228 22.7522 3 22.7522C2.44772 22.7522 2 22.3045 2 21.7522C2 19.0634 4.12231 16.8704 6.78311 16.7568L7 16.7522H17H7ZM12 2.7522C15.3137 2.7522 18 5.43849 18 8.7522C18 12.0659 15.3137 14.7522 12 14.7522C8.68629 14.7522 6 12.0659 6 8.7522C6 5.43849 8.68629 2.7522 12 2.7522ZM12 4.7522C9.79086 4.7522 8 6.54306 8 8.7522C8 10.9613 9.79086 12.7522 12 12.7522C14.2091 12.7522 16 10.9613 16 8.7522C16 6.54306 14.2091 4.7522 12 4.7522Z" fill="inherit" />
  </SvgIcon>
);

export default ProfileIcon;
