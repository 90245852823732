import { GoogleMap, Marker, OverlayView } from '@react-google-maps/api';
import markerIcon from 'assets/img/agent-landing/home-estimation/home-estimation-map-marker.svg';
import styles from 'assets/jss/material-dashboard-pro-react/components/homeEstimationMapStyle';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useMapInfoContext, withMapInfoProvider } from 'components/DataMap/MapInfoProvider';

import { numberAsAbbreviatedPrice } from 'utils/lib';

const useStyles = makeStyles(styles);

const mapContainerStyle = {
  height: '389px',
};

const mapOptions = {
  zoom: 14,
  scrollwheel: false,
  disableDefaultUI: true,

  // TODO: Allow panning or not?
  // gestureHandling: 'none', // Reference: https://developers.google.com/maps/documentation/javascript/interaction#controlling_gesture_handling
  // keyboardShortcuts: false,
  zoomControl: true,
  gestureHandling: 'cooperative', // Reference: https://developers.google.com/maps/documentation/javascript/interaction#controlling_gesture_handling
  draggableCursor: 'default',

  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

const SimilarHomeView = ({ coordinates, price }) => {
  const classes = useStyles();

  return (
    <OverlayView
      position={{ ...coordinates }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className={classes.similarHomeContainer}>
        <p>
          Sold Price
          <span>
            {numberAsAbbreviatedPrice(price)}
          </span>
        </p>
        <div className={classes.similarHomeCaret} />
      </div>
    </OverlayView>
  );
};

// TODO: When we convert to typescript, remove all these defaults to make this less confusing
const MapContainer = ({
  center,
  similarHomes,
}) => {
  const classes = useStyles();
  const {
    mapInstance, setMapInstance, setMapBounds,
  } = useMapInfoContext();

  const onLoad = React.useCallback((map) => {
    setMapInstance(map);
  });

  const mapOptionsWithCenter = {
    ...mapOptions,
    center,
  };

  return React.useMemo(() => (
    <div className={classes.mapContainer}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={mapContainerStyle}
        options={mapOptionsWithCenter}
        onIdle={() => {
          if (!mapInstance) return;
          setMapBounds(mapInstance.getBounds());
        }}
      >
        <Marker
          position={center}
          icon={{
            url: markerIcon,
            scaledSize: { width: 112, height: 122 },
            anchor: new window.google.maps.Point(56, 95),
          }}
        />
        {similarHomes && similarHomes.map(({ coordinates, price }, i) => (
          <SimilarHomeView
            key={i}
            coordinates={coordinates}
            price={price}
          />
        ))}
      </GoogleMap>
    </div>
  ), [mapInstance, similarHomes]);
};

const HomeEstimationMap = React.memo((props) => (
  <MapContainer {...props} />
));

export default withMapInfoProvider(HomeEstimationMap);
