import { useAllCampaigns } from 'data/campaign';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CampaignsTable from 'components/CampaignsTable/CampaignsTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  campaignsSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  sectionSpacer: {
    height: '20px',
  },
  farmingAreaSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '500px',
  },
  campaignsTable: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    overflowX: 'auto',
    overflowY: 'auto',
  },
}));

const CampaignsAdminPage = () => {
  const classes = useStyles();
  const { userInfo } = useUserInfoContext();

  /* const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  // Handle changes in pagination
  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handlePageSizeChange = (newSize) => setPageSize(newSize);

  const { campaigns: allCampaigns, totalCampaignsCount, isLoadingCampaigns } = useAllCampaigns(undefined, undefined, true, currentPage, pageSize); */

  const { campaigns: allCampaigns, isLoadingCampaigns } = useAllCampaigns(undefined, undefined, true);

  /* const [allCampaignSubscribedEvents, setAllCampaignSubscribedEvents] = React.useState(null);
  const [outlineZipcodes, setOutlineZipcodes] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!userInfo || isLoadingCampaigns) return;

      // Only show farm area for pending, active, and paused campaigns
      const activeOrPendingCampaigns = allCampaigns.filter(({ status }) => ['PENDING_DOCUSIGN', 'PENDING_DATA', 'PENDING_BILLING', 'ACTIVE', 'PAUSED'].includes(status));

      if (activeOrPendingCampaigns && activeOrPendingCampaigns.length > 0) {
        // Find all the zipcodes there is event data in so we can highlight those
        const zipcodesToHighlight = [];
        const allSubscribedEvents = [];

        for (let i = 0; i < activeOrPendingCampaigns.length; i++) {
          const campaign = activeOrPendingCampaigns[i];

          allSubscribedEvents.push(...campaign.subscribedEvents);

          campaign.subscribedEvents.forEach((eventData) => {
            const { _id, siteAddress: { zipcode } } = eventData;

            if (!zipcodesToHighlight.includes(zipcode)) {
              zipcodesToHighlight.push(zipcode);
            }
          });
        }

        setAllCampaignSubscribedEvents(allSubscribedEvents);
        setOutlineZipcodes(zipcodesToHighlight);
      }
    })();
  }, [userInfo, isLoadingCampaigns]); */

  let campaignsTable;

  // if (isLoadingCampaigns || !userInfo || !allCampaignSubscribedEvents) {
  if (isLoadingCampaigns || !userInfo) {
    campaignsTable = <LoadingIndicator />;
  } else {
    campaignsTable = (
      <CampaignsTable
        campaigns={allCampaigns}
        showHotProspects={false}
        tableProps={{
          showFilters: true,
          numRowsPerPage: 25,
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.campaignsSection}>
        <DashboardSection
          sectionName="All Campaigns"
        />
        <div className={classes.campaignsTable}>
          {campaignsTable}
        </div>
      </div>
      <div className={classes.sectionSpacer} />

      {/* TODO: Disabled since we don't fetch detailed campaigns anymore due to slow queries */}
      {/* {allCampaigns && (
        <div className={classes.farmingAreaSection}>
          <DashboardSection
            sectionName="Farming Areas"
          />
          {userInfo ? (
            <DataMap
              highlightZipcodes={outlineZipcodes}
              highlightEventData={allCampaignSubscribedEvents || null}
              highlightEventDataShowHoverPopup
              showOnlyHighlightedZipcodes
            />
          ) : <LoadingIndicator />}
        </div>
      )} */}
    </div>
  );
};

export default CampaignsAdminPage;
