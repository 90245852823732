import {
  blackColor,
  hexToRgb,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    // height: '100%',
    position: 'relative',
    // top: '0',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  content: {
    flex: 1,
    // TODO: WHY DO WE NEED THIS??
    // padding: '96px 0', // Navbar height is 96px, footer height is 96px

    // TODO: ??? FOR MOBILE
    // [theme.breakpoints.down('lg')]: {
    //   padding: '100px 0', // Top bar height changes to 100px
    // },

    // position: 'relative',
    // minHeight: '100vh',
    display: 'flex!important',
    margin: '0',
    border: '0',
    // color: blackColor,
    backgroundColor: theme.palette.white.main,
    alignItems: 'center',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center center',
    height: '100%',
    // NOTE: Was making footer shift up
    // [theme.breakpoints.down('sm')]: {
    //   minHeight: '700px!important',
    // },

    // '& footer': {
    //   position: 'absolute',
    //   bottom: '0',
    //   width: '100%',
    //   border: 'none !important',
    // },

    // '&:before': {
    //   backgroundColor: `rgba(${hexToRgb(blackColor)}, 0.05)`,
    // },

    // TODO: WHAT IS THE BELOW FOR???? commented out during redesign. see if it causes any issues
    // '&:before,&:after': {
    //   display: 'block',
    //   content: '""',
    //   position: 'absolute',
    //   width: '100%',
    //   height: '100%',
    //   top: '0',
    //   left: '0',
    //   zIndex: '2',
    // },
  },
});

export default style;
