import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumbs, ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grayScale10.main}`,
    flexWrap: 'wrap',

    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column',
    // },
  },
  breadcrumbsContainer: {
    // flex: 1,
    // overflow: 'scroll',
    // flex: 2,
    // overflow: 'hidden',
    // '& ol li:last-child': {
    //   overflow: 'hidden',
    // },
  },
  breadcrumbs: {
    color: theme.palette.grayScale11.main,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '40px',

    // '& .MuiBreadcrumbs-ol': {
    //   flexWrap: 'nowrap', // TODO: Why did we need this on desktop again?

    //   [theme.breakpoints.down('sm')]: {
    //     flexWrap: 'wrap',
    //   },
    // },
    '& a': {
      font: 'inherit',
      color: 'inherit',
      textDecoration: 'underline',

      '&:hover': {
        color: theme.palette.orange.main,
      },
    },
  },
  sectionName: {
    // overflow: 'hidden',
    // display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
    },

    '& > span': {
      color: theme.palette.grayScale11.main,
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '40px', // To prevent text wrap
      maxHeight: '40px',
      alignSelf: 'flex-end',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& > button': {
      marginLeft: '16px',
      verticalAlign: 'sub',
    },
  },
  sectionNameInputForm: {
    '& > div': {
      margin: 0,
    },
    '& svg': {
      // Do here since htmlColor prop doesn't work on the end adornment
      color: `${theme.palette.success.main}`,
    },
  },

  actions: {
    marginLeft: 'auto',
    // flex: 1, // TODO: MAKE SURE THIS DOESN'T BREAK ANYTHING
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 12px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      // marginBottom: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      flexDirection: 'column',
      alignItems: 'flex-end',
      // margin: '20px 0',
      // marginLeft: '0',
    },

    '& > button': {
      // margin: '5px',

      [theme.breakpoints.down('xs')]: {
        margin: '20px 0',
      },
    },
  },
  buttonsRight: {
    marginLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  tabs: {
    // flex: 1,
    // marginLeft: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
    flexWrap: 'wrap',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  tabButton: {
    padding: '0 16px 16px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#838391',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  tabButtonActive: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
    boxShadow: `0px -3px ${theme.palette.primary.dark} inset`,
  },
}));

interface Props {
  breadcrumbs?: { name: string; to: string; }[];
  sectionName?: string;
  sectionNameEditable?: boolean;
  onSectionEditName?: (name: string) => void;
  subsectionName?: string;
  buttons?: React.ReactNode[];
  tabs?: string[];
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
  buttonLayout?: 'left' | 'right';
}

const DashboardSection: React.FC<Props> = ({
  breadcrumbs, sectionName, sectionNameEditable, onSectionEditName, subsectionName,
  buttons = [], tabs, activeTab, setActiveTab, buttonLayout = 'left',
}: Props) => {
  const classes = useStyles();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isEditingSectionName, setIsEditingSectionName] = React.useState(false);

  const onUpdateSectionName = async () => {
    const name = inputRef?.current?.value as string;
    await onSectionEditName?.(name);
    setIsEditingSectionName(false);
  };

  const renderButtons = (
    <div className={cx(classes.buttons, buttonLayout === 'right' && classes.buttonsRight)}>
      {buttons.map((button, i) => (
        <React.Fragment key={i}>{button}</React.Fragment>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <span className={classes.breadcrumbsContainer}>
        {!isEditingSectionName ? (
          <Breadcrumbs separator={<NavigateNextRoundedIcon />} className={classes.breadcrumbs}>
            {breadcrumbs && breadcrumbs.map(({ name, to }) => (
              <Link key={name} to={to}>{name}</Link>
            ))}
            <span className={classes.sectionName}>
              <span>
                {sectionName}
              </span>
              {sectionNameEditable && (
                <Tooltip
                  title="Edit Name"
                  placement="top"
                >
                  <ButtonBase onClick={() => setIsEditingSectionName(true)}><EditRoundedIcon htmlColor="#838391" /></ButtonBase>
                </Tooltip>
              )}
            </span>
            {subsectionName && (
              <span className={classes.sectionName}>
                  {subsectionName}
              </span>
            )}
          </Breadcrumbs>
        ) : (
          <div className={classes.sectionNameInputForm}>
            <CustomInputDashboard
              inputProps={{
                inputRef,
                autoFocus: true,
                defaultValue: sectionName,
                maxLength: 32,
                endAdornment: (
                  <ButtonBase onClick={onUpdateSectionName}>
                    <DoneRoundedIcon />
                  </ButtonBase>
                ),
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    onUpdateSectionName();
                  } else if (event.key === 'Escape') {
                    setIsEditingSectionName(false);
                  }
                },
              }}
            />
          </div>
        )}
      </span>
      <div className={classes.actions}>
        {buttonLayout === 'left' && renderButtons}
        {tabs && (
          <div className={classes.tabs}>
            {tabs.map((tab) => (
              <ButtonBase
                key={tab}
                disableRipple
                disableTouchRipple
                className={cx({
                  [classes.tabButton]: true,
                  [classes.tabButtonActive]: tab === activeTab,
                })}
                onClick={() => {
                  setActiveTab?.(tab);
                }}
              >
                {tab}
              </ButtonBase>
            ))}
          </div>
        )}
        {buttonLayout === 'right' && renderButtons}
      </div>
    </div>
  );
};

export default DashboardSection;
