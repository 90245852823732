import React from 'react';

import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: '8px',
    fontSize: '15px',
    fontWeight: 400,
    display: 'inline',
  },
  validIcon: {
    verticalAlign: 'top',
    color: theme.palette.primary.main,
  },
  invalidIcon: {
    verticalAlign: 'top',
    color: theme.palette.orange.main,
  },
}));

type Props = {
  description: string;
  valid: boolean;
};

export default ({ description, valid }: Props) => {
  const classes = useStyles();

  return (
    <div>
      {valid
        ? (
          <CheckCircleIcon
            fontSize="small"
            className={classes.validIcon}
          />
        )
        : (
          <RadioButtonUncheckedIcon
            fontSize="small"
            color="disabled"
            className={classes.invalidIcon}
          />
        )}
      <h4 className={classes.text}>
        {description}
      </h4>
    </div>
  );
};
