import React from 'react';
import 'chartjs-adapter-moment';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { addPaddingBelowLegendPlugin } from 'utils/chart-plugins';
import { ETouchType } from 'types/touch-type';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import { useCampaignDeliveredTouchStats } from 'data/campaign-stats';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const useStyles = makeStyles((theme) => ({

}));

const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  elements: {
    line: {
      backgroundColor: '#ff0000' as const,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month', // You can customize the time unit as needed (e.g., 'month', 'year')
        displayFormats: {
          month: 'MMM', // Format for displaying dates on the x-axis
        },
      },

      grid: {
        borderDash: [5, 5],
        tickBorderDash: [5, 5],
        drawBorder: false,
        drawTicks: false,
        color: '#DCE3EB',
      },
      ticks: {
        color: '#7D7D7D',
        padding: 10,
        crossAlign: 'near' as const,
        font: {
          size: 12,
          family: 'Montserrat',
        },
      },
    },
    y: {
      // stacked: true, // TODO: IDK WHAT THIS DOES
      beginAtZero: true,
      grace: 3,
      grid: {
        borderDash: [5, 5],
        tickBorderDash: [5, 5],
        drawBorder: false,
        drawTicks: false,
        color: '#DCE3EB',
      },
      ticks: {
        precision: 0,
        color: '#7D7D7D',
        padding: 10,
        crossAlign: 'far' as const,
        font: {
          size: 12,
          family: 'Montserrat',
        },
      },
    },
  },
  cubicInterpolationMode: 'monotone',
  plugins: {
    legend: {
      position: 'top' as const,
      align: 'start' as const,
      labels: {
        usePointStyle: true,
        textAlign: 'right' as const,
        boxWidth: 10,
        boxHeight: 10,
      },
    },
    tooltip: {
      yAlign: 'bottom' as const,
      displayColors: false,
      caretPadding: 8,
      cornerRadius: 8,
      bodyAlign: 'center',
      bodyFont: {
        size: 12,
        family: 'Montserrat',
        weight: '500',
      },
      // NOTE: STACKS DATA ON TOOLTIP
      // mode: 'index' as const,
      callbacks: {
        title: (tooltipItem: any) => moment(tooltipItem[0].label, 'MMM D, YYYY, hh:mm:ss a').format('MMM D, YYYY'),
        label: (context: any) => {
          // Use the dataset's color for the tooltip background
          context.chart.tooltip.options.backgroundColor = context.dataset.pointBackgroundColor;

          // Render only the value, no label
          return context.formattedValue;
        },
      },
    },
  },
};

export interface Props {
  campaignId: string;
}

export default ({ campaignId }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { deliveredTouchStats, isLoadingDeliveredTouchStats } = useCampaignDeliveredTouchStats(campaignId);

  const chartData = React.useMemo<any>(
    () => {
      if (isLoadingDeliveredTouchStats || !deliveredTouchStats) return null;

      let sortedStats = deliveredTouchStats.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

      // Only show touch delivery stats for 2024 and after
      sortedStats = sortedStats.filter(({ date }) => moment(date).isAfter(moment('2023-12-20T02:16:14.115Z')));

      // We have no data, so return an empty dataset
      if (!sortedStats.length) return { datasets: [] };

      const xAxisDateFormat = 'YYYY-MM-DD';

      const aggregatedData = sortedStats.reduce<{ 'Direct Mail': { x: string, y: number }[], 'Digital Marketing': { x: string, y: number }[] }>((result, current) => {
        const { touchType, date, count } = current;

        const dateString = moment(date).format(xAxisDateFormat);

        if (touchType === ETouchType.Letter || touchType === ETouchType.Postcard) {
          result['Direct Mail'].push({ x: dateString, y: count });
        } else {
          // eslint-disable-next-line no-param-reassign
          result['Digital Marketing'].push({ x: dateString, y: count });
        }

        return result;
      }, { 'Direct Mail': [], 'Digital Marketing': [] });

      // Make sure we have data at the global beginning and end to make both line
      // graphs "overlap" and not show holes
      const firstDateString = moment(sortedStats[0].date).format(xAxisDateFormat);
      const lastDateString = moment(sortedStats[sortedStats.length - 1].date).format(xAxisDateFormat);

      const fillEndpointData = (datasetKey: 'Direct Mail' | 'Digital Marketing') => {
        // Edge case if there's no data yet for this campaign
        if (!aggregatedData[datasetKey].length) return;

        if (aggregatedData[datasetKey][0].x !== firstDateString) {
          aggregatedData[datasetKey].unshift({ x: firstDateString, y: 0 });
        }
        if (aggregatedData[datasetKey][aggregatedData[datasetKey].length - 1].x !== lastDateString) {
          aggregatedData[datasetKey].push({ x: lastDateString, y: 0 });
        }
      };

      fillEndpointData('Direct Mail');
      fillEndpointData('Digital Marketing');

      return {
        // labels,
        datasets: [
          {
            fill: true,
            label: 'Direct Mail',
            data: aggregatedData['Direct Mail'],
            borderColor: '#018EDE',
            borderWidth: 1.5,
            pointBorderWidth: 0,
            pointBackgroundColor: '#018EDE',
            pointRadius: 2,
            backgroundColor: (context: any) => {
              const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, context.chart.canvas.height);
              gradient.addColorStop(0, '#018EDE'); // Start color with transparency
              gradient.addColorStop(0.5, '#FFFFFF00'); // End color with transparency
              return gradient;
            },
          },
          {
            fill: true,
            label: 'Digital Marketing',
            data: aggregatedData['Digital Marketing'],
            borderColor: '#56917F',
            borderWidth: 1.5,
            pointBorderWidth: 0,
            pointBackgroundColor: '#56917F',
            pointRadius: 2,
            backgroundColor: (context: any) => {
              const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, context.chart.canvas.height);
              gradient.addColorStop(0, '#56917F'); // Start color with transparency
              gradient.addColorStop(0.5, '#FFFFFF00'); // End color with transparency
              return gradient;
            },
          },
        ],
      };
    },
    [deliveredTouchStats],
  );

  return (
    <Box sx={{
      height: 450,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 8,
      bgcolor: theme.palette.offWhite.main,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
      }}
      >
        <Box sx={{
          fontWeight: 500,
          fontSize: 21,
          color: theme.palette.purple.dark,
        }}
        >
          Touch Analytics
        </Box>

        {/* TODO: IMPLEMENT DROPDOWN */}
        {/* DROPDOWN */}
      </Box>

      <Box sx={{
        flex: 1,
        display: 'flex',
      }}
      >
        {chartData ? (
          <Line options={options} data={chartData} plugins={[addPaddingBelowLegendPlugin]} />
        ) : <LoadingIndicator />}
      </Box>
    </Box>
  );
};
