import { useMyBrokerage } from 'data/brokerage';
import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import CustomImage from 'creative-components/CustomUpload/CustomImage';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import AddressForm, { addressYupValidation, initialAddress, pickAddress } from 'components/AddressAutocomplete/AddressForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';

import { updateBrokerageBranchMarketingInfo } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  imagesSpacer: {
    margin: '75px 0 0',
    width: '100%',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const BrokerageBranchDataForm = ({ brokerageBranch }, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const { setCurrentAlert } = useAlertContext();
  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useMyBrokerage();

  if (isLoadingBrokerage) {
    return (<LoadingIndicator />);
  }

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        name: brokerageBranch ? brokerageBranch.name : '',
        dreNumber: brokerageBranch ? brokerageBranch.dreNumber : '',
        logo: brokerageBranch ? brokerageBranch.logoUrl : '',
        logoLight: brokerageBranch ? brokerageBranch.logoLightUrl : '',
        address: brokerageBranch ? pickAddress(brokerageBranch.address) : { ...initialAddress },
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const {
          name, dreNumber, logo, logoLight, address,
        } = values;

        try {
          await updateBrokerageBranchMarketingInfo({
            brokerageBranchId: brokerageBranch ? brokerageBranch._id : null,
            name,
            dreNumber,
            logo,
            logoLight,
            address,
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });

          // If a new branch is being made, send them an alert and redirect to the branches page
          if (!brokerageBranch) {
            setCurrentAlert('success', 'Your new brokerage branch has been created!');

            history.push('/dashboard/my-brokerage');
          }
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        dreNumber: Yup.string().required(),
        logo: Yup.string().required(),
        logoLight: Yup.string().optional(),
        address: addressYupValidation.required(),
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        return (
          <Form>
            <FormikOnError>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={10} lg={9} xl={7}>
                  <GridContainer justifyContent="center" style={{ margin: '50px 0 18px' }}>

                    <GridItem xs={12} md={6}>
                      <Field
                        name="logo"
                      >
                        {({ field }) => (
                          <CustomImage
                            required
                            label="Branch Logo (Dark)"
                            initialImage={values[field.name]}
                            onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                            // error={touched[field.name] && errors[field.name] !== undefined}
                            error={errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12} md={6}>
                      <Field
                        name="logoLight"
                      >
                        {({ field }) => (
                          <CustomImage
                            required
                            darkBackground
                            label="Branch Logo (Light)"
                            initialImage={values[field.name]}
                            onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                            // error={touched[field.name] && errors[field.name] !== undefined}
                            error={errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <div className={classes.imagesSpacer} />

                    <GridItem xs={12} md={6}>
                      <Field
                        name="name"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Branch Name"
                            inputProps={{
                              ...field,
                            }}
                            error={errors[field.name] !== undefined} // error={touched[field.name] && errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12} md={6}>
                      <Field
                        name="dreNumber"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Branch License Number"
                            inputProps={{
                              ...field,
                              placeholder: '#',
                            }}
                            error={errors[field.name] !== undefined} // error={touched[field.name] && errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <AddressForm
                      classes={classes}
                      valueName="address"
                      addressDisplayName="Branch Address"
                      initialValue={values.address.address1}
                      showErrorsWhen="always"
                    />

                    <GridItem xs={12}>
                      <div className={classes.saveButtonContainer}>
                        <SaveButton onSave={mutateBrokerage} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(BrokerageBranchDataForm);
