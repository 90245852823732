import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

export default ({ component: Component, restricted, ...rest }) => {
  const { isAuthLoaded, user, DASHBOARD_ROUTE } = useAuthDataContext();

  // Scroll to top when navigating to page for the first time
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isAuthLoaded) {
    return (<LoadingIndicator />);
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        user && restricted
          ? <Redirect to={DASHBOARD_ROUTE} />
          : <Component {...props} {...rest} />
      )}
    />
  );
};
