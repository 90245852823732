import styles from 'assets/jss/material-dashboard-pro-react/components/similarHomesTableStyle';
import cx from 'classnames';
import React from 'react';
import { useTable } from 'react-table';

import { makeStyles } from '@material-ui/core/styles';

import { numberAsAbbreviatedPrice, numberWithCommas } from 'utils/lib';

const useStyles = makeStyles(styles);

const SimilarHomesTable = ({ similarHomes, hasAnyZeroBedsOrBaths, hasSqFt }, ref) => {
  const classes = useStyles();

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          const { status } = row.original;

          return (
            <div className={classes.tableDataCellBold}>
              {status}
            </div>
          );
        },
      },
      ...(hasSqFt ? [
        {
          Header: 'Price',
          accessor: ({ price }) => numberAsAbbreviatedPrice(price),
        },
      ] : []),
      {
        Header: 'Property Address',
        accessor: 'propertyAddress',
      },
      // Only show the # beds/baths columns if we have the data for all the properties
      ...(!hasAnyZeroBedsOrBaths ? [
        {
          Header: 'Beds',
          accessor: 'numBeds',
          Footer: () => {
            const total = similarHomes.reduce((sum, similarHome) => similarHome.numBeds + sum, 0);
            return (total / similarHomes.length).toFixed(1);
          },
        },
        {
          Header: 'Bath',
          accessor: 'numBaths',
          Footer: () => {
            const total = similarHomes.reduce((sum, similarHome) => similarHome.numBaths + sum, 0);
            return (total / similarHomes.length).toFixed(1);
          },
        },
      ] : []),
      ...(hasSqFt ? [
        {
          Header: 'Sq Ft',
          accessor: ({ squareFootage }) => `${numberWithCommas(squareFootage)} Sq Ft`,
          Footer: () => {
            const total = similarHomes.reduce((sum, similarHome) => similarHome.squareFootage + sum, 0);
            return `${numberWithCommas((total / similarHomes.length).toFixed(0))} Sq Ft`;
          },
        },
      ] : []),
      {
        Header: 'Lot Size',
        accessor: ({ lotSquareFootage }) => `${numberWithCommas(lotSquareFootage)} Sq Ft`,
        Footer: () => {
          const total = similarHomes.reduce((sum, similarHome) => similarHome.lotSquareFootage + sum, 0);
          return `${numberWithCommas((total / similarHomes.length).toFixed(0))} Sq Ft`;
        },
      },
      ...(hasSqFt ? [
        {
          Header: '$/Sq Ft',
          accessor: ({ price, squareFootage }) => `$${numberWithCommas((squareFootage !== 0 ? price / squareFootage : 0).toFixed(0))}`,
          Footer: () => {
            const totalSqft = similarHomes.reduce((sum, similarHome) => similarHome.squareFootage + sum, 0);
            const totalPrice = similarHomes.reduce((sum, similarHome) => similarHome.price + sum, 0);
            return `$${numberWithCommas((totalPrice / totalSqft).toFixed(0))}`;
          },
        },
      ] : [
        {
          // Make this a diff column name than the 'Price' one above, for simplicity
          Header: 'Sold Price',
          accessor: ({ price }) => numberAsAbbreviatedPrice(price),
          Footer: () => {
            const totalPrice = similarHomes.reduce((sum, similarHome) => similarHome.price + sum, 0);
            return numberAsAbbreviatedPrice((totalPrice / similarHomes.length));
          },
        },
      ]),
    ],
    [],
  );

  const tableData = React.useMemo(
    () => similarHomes.map(({
      price, propertyAddress, numBeds, numBaths, squareFootage, lotSquareFootage,
    }) => ({
      price,
      status: 'Sold', // Always 'Sold' here
      propertyAddress,
      numBeds,
      numBaths,
      squareFootage,
      lotSquareFootage,
    })),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
  );

  return (
    <table {...getTableProps()} className={classes.table}>
      <thead className={classes.tableHeader}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={cx(classes.tableCell, classes.tableHeaderCell)}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={classes.tableBody}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className={cx(classes.tableCell, classes.tableDataCell)}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
      <tfoot className={classes.tableFooter}>
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            <td className={cx(classes.footerCell, classes.footerCellLeft)} colSpan={hasSqFt ? 3 : 2}>
              {`TOTAL COMPARABLE PROPERTIES: ${similarHomes.length}`}
              <span style={{ float: 'right' }}>AVERAGE:</span>
            </td>

            {/* Only render footer cells for ones we want, so the label above can take up the max # of columns */}
            {group.headers.filter(({ Header }) => ['Beds', 'Bath', 'Sq Ft', 'Lot Size', '$/Sq Ft', 'Sold Price'].includes(Header)).map((column) => (
              <td {...column.getFooterProps()} className={cx(classes.footerCell, classes.footerCellRight)}>
                {column.render('Footer')}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};

export default React.forwardRef(SimilarHomesTable);
