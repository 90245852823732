import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import InfoIcon from '@material-ui/icons/Info';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import UserInfoModal from 'components/UsersTable/UserInfoModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { authenticateAssumeUser, resendSignUpEmail } from 'utils/api';
import { enumToReadableName, showAPIErrorAlert } from 'utils/lib';
import { useUsers } from 'data/user';

const styles = {

};

const useStyles = makeStyles(styles);

export default function UsersTable() {
  const classes = useStyles();
  const { onLogin } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const { users, isLoadingUsers } = useUsers();

  const [infoModal, setInfoModal] = React.useState(null);

  const [data, setData] = React.useState([]);

  const onAssumeUser = async (userId) => {
    showLoadingIndicatorModal();
    try {
      const { token, expiresInDays } = await authenticateAssumeUser(userId);

      // Call the login function on the auth context
      onLogin(token, expiresInDays);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onResendSignUpEmail = async (userId, firstName, lastName) => {
    showLoadingIndicatorModal();
    try {
      await resendSignUpEmail(userId);

      setCurrentAlert('success', `Re-sent a sign up email to ${firstName} ${lastName}`);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  React.useEffect(() => {
    if (isLoadingUsers) return;

    setData(users.map((user) => ({
      name: `${user.firstName} ${user.lastName ?? ''}`,
      email: user.email,
      role: user.role.name,
      status: enumToReadableName(user.status),
      createdAt: moment(user.createdAt).toDate(),
      actions: (
        <div>
          {user.status === 'ACTIVE' && (
            <Button
              onClick={() => onAssumeUser(user._id)}
              color="orange"
              round
              size="sm"
            >
              Impersonate
            </Button>
          )}
          {user.status === 'UNREGISTERED' && (
            <Tooltip
              title="Resend Invite Email"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                justIcon
                round
                simple
                onClick={() => onResendSignUpEmail(user._id, user.firstName, user.lastName)}
                color="primary"
              >
                <SendRoundedIcon />
              </Button>
            </Tooltip>
          )}
          <Tooltip
            title="Info"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setInfoModal(() => (
                  <UserInfoModal
                    user={user}
                    onClose={() => { setInfoModal(null); }}
                  />
                ));
              }}
              color="dark"
            >
              <InfoIcon />
            </Button>
          </Tooltip>
        </div>
      ),
    })));
  }, [isLoadingUsers, users]);

  if (isLoadingUsers) return <LoadingIndicator />;

  return (
    <>
      {infoModal}

      <ReactTable
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Role',
            accessor: 'role',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
            sortType: 'datetime',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          },
        ]}
        data={data}
      />
    </>
  );
}
