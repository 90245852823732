import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const FAQPage = () => {
  const history = useHistory();

  // Open FAQ page
  const newWindow = window.open('https://harvist.com/faq', '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;

  // Kind of hacky, but go back to the original page they were just on
  useEffect(() => {
    history.goBack();
  }, []);

  return null;
};

export default FAQPage;
