import log from 'loglevel';
import { CreateAdCampaignDto, UpdateAdCampaignDto } from 'types/ad-campaign';

import { apiClient } from '.';

export const createAdCampaign = async (createAdCampaignDto: CreateAdCampaignDto) => {
  const response = await apiClient.post('/digital-ads/create', createAdCampaignDto);

  log.debug('called createAdCampaign and got', response.data);

  return response.data;
};

export const updateAdCampaign = async (adCampaignId: string, updateAdCampaignDto: UpdateAdCampaignDto) => {
  const response = await apiClient.patch(`/digital-ads/${adCampaignId}`, updateAdCampaignDto);

  log.debug('called updateAdCampaign and got', response.data);

  return response.data;
};
