export enum EAdCampaignStatus {
  PendingBilling = 'PENDING_BILLING',
  PendingAdApproval = 'PENDING_AD_APPROVAL',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
}

export interface IAdCampaign {
  _id: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  name: string;
  callToActionLink: string;
  zipCodes: string[];
  customAudienceCampaigns: {
    _id: string;
    name: string;
    numSubscribedApns: number;
  }[];
  costs: {
    userMonthlyBudget: number; // User's cost for this ad campaign
    nextInvoiceDate?: Date;
  };
  latestImagePreviewUrl?: string;
  status: EAdCampaignStatus;
  createdAt: Date;

  stats: IAdCampaignStats;
}

export interface IAdCampaignStats {
  reach?: number;
  impressions?: number;
  inlineLinkClicks?: number;
}

export interface CreateAdCampaignDto {
  name: string;
  callToActionLink: string;
  budget: number;
  customAudienceCampaignIds: string[];
  zipCodes: string[];
}

export interface UpdateAdCampaignDto {
  name?: string;
  status?: EAdCampaignStatus.Paused | EAdCampaignStatus.Active;
}
