import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import ReactTable from 'creative-components/ReactTable/ReactTable';

import DashboardBubble from 'components/DashboardBubble/DashboardBubble';

import { stringifyAddressObject } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  infoBubble: {
    maxWidth: '700px',
    margin: '40px auto 50px',
  },
}));

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
];

const CampaignExtraRecipientsTable = ({ campaign }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (campaign === null) return;

    setData(campaign.extraMailOutRecipients.map((extraRecipient) => {
      const {
        firstName, lastName, phoneNumber, email, address,
      } = extraRecipient;

      return ({
        name: `${firstName} ${lastName}`,
        phoneNumber,
        email,
        address: stringifyAddressObject(address),
      });
    }));
  }, [campaign]);

  return (
    <>
      <DashboardBubble
        rootClass={classes.infoBubble}
        backgroundColor={theme.palette.primary.light}
        icon={<InfoIcon htmlColor={theme.palette.success.main} />}
        content="These are the extra recipients you added when creating your campaign. They will receive all marketing touches from this campaign."
      />
      <div className={classes.tableContainer}>
        <ReactTable
          columns={columns}
          data={data}
          normalRightColumn
        />
      </div>
    </>
  );
};

export default CampaignExtraRecipientsTable;
