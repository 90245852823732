import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

import Button from 'creative-components/CustomButtons/Button';

import CreateUserModal from 'components/CreateUserModal/CreateUserModal';
import UsersTable from 'components/UsersTable/UsersTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';

import { getRoles } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UsersAdminPage = () => {
  const classes = useStyles();

  const [roles, setRoles] = React.useState(null);

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const { roles: rolesReturned } = await getRoles();

      setRoles(rolesReturned);
    })();
  }, []);

  const createUserButton = (
    <Button
      color="primary"
      round
      onClick={() => setIsCreateUserModalOpen(true)}
    >
      <CreateIcon />
      {' '}
      Create User
    </Button>
  );

  return (
    <div className={classes.root}>
      <DashboardSection
        sectionName="Users"
        buttons={[createUserButton]}
      />
      {isCreateUserModalOpen && (
        <CreateUserModal
          roles={roles}
          onCancel={() => setIsCreateUserModalOpen(false)}
        />
      )}

      <UsersTable />
    </div>
  );
};

export default UsersAdminPage;
