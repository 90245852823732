import { Formik } from 'formik';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DashboardSection from 'components/DashboardSection/DashboardSection';
import DataMap from 'components/DataMap/DataMap';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  adminMap: {
    flex: 1,
    minHeight: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const APNCountsMapPage = () => {
  const classes = useStyles();

  const [apnsTotalSelectedCount, setApnsTotalSelectedCount] = React.useState(0);
  const [apnsTotalInAreaCount, setApnsTotalInAreaCount] = React.useState(0);
  const [apnSelectedCounts, setApnSelectedCounts] = React.useState([]); // Keys are 'Custom Shapes', 'Add On Properties', and `${zipcode}`
  const [apnAvailableCounts, setApnAvailableCounts] = React.useState([]); // Keys are 'Custom Shapes', 'Add On Properties', and `${zipcode}`

  return (
    <div className={classes.root}>
      <DashboardSection
        sectionName="APN Counts Map"
      />
      <div className={classes.adminMap}>
        {/* Need Formik for the DataMap zip codes input field */}
        <Formik
          initialValues={{}}
          onSubmit={null}
          validationSchema={{}}
        >
          {() => (
            <DataMap
              apnSelectedCounts={apnSelectedCounts}
              setApnSelectedCounts={setApnSelectedCounts}
              setApnAvailableCounts={setApnAvailableCounts}
              setApnsTotalSelectedCount={setApnsTotalSelectedCount}
              setApnsTotalInAreaCount={setApnsTotalInAreaCount}
              allowDataSelection
              filtersModalOptions={{
                selectPropertyTypesByDefault: ['SFR', 'Condominium', 'Townhouse', 'Multi Family Dwelling', 'Apartment Building'],
                disableEventTypeSelector: false,
              }}
            />
          )}
        </Formik>
      </div>
    </div>
  );
};

export default APNCountsMapPage;
