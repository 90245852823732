import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ButtonBase, ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LongArrowLeftIcon from 'components/CustomIcons/LongArrowLeftIcon';

import { supportEmail } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.palette.grayScale10.main}`,
    boxShadow: '0px 6px 20px -2px rgba(58, 57, 76, 0.14), 0px 4px 6px rgba(58, 57, 76, 0.06)',
    borderRadius: '8px',

    [theme.breakpoints.up('sm')]: {
      minWidth: '200px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '258px',
    },
  },
  button: {
    display: 'block',
    // margin: '10px 16px',
    padding: '10px 16px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grayScale9.main,

    '&:hover': {
      color: theme.palette.primary.main,
    },

    '& > svg': {
      width: '16px',
      height: '10px',
      marginRight: '10px',
    },
  },
}));

const UserSettingsPopUp = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user, onLogout, isAnyAgent } = useAuthDataContext();

  const onNavigateSettings = () => {
    const settingsRoute = '/dashboard/settings';

    if (location.pathname !== settingsRoute) history.push('/dashboard/settings');

    onClose();
  };

  const onContactSupport = () => {
    window.location.href = `mailto:${supportEmail}`;
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.root}>
        {/* <ButtonBase
          disableRipple
          disableTouchRipple
          className={classes.button}
          onClick={() => console.log('ok')}
        >
          Account Settings
        </ButtonBase> */}
        {/* <ButtonBase
          disableRipple
          disableTouchRipple
          className={classes.button}
          onClick={() => console.log('ok')}
        >
          Help Docs
        </ButtonBase> */}

        {/* Only show the contact support and settings button to agents */}
        {isAnyAgent && (
          <>
            <ButtonBase
              disableRipple
              disableTouchRipple
              component="a"
              className={classes.button}
              onClick={onNavigateSettings}
            >
              Notification Settings
            </ButtonBase>
            <ButtonBase
              disableRipple
              disableTouchRipple
              className={classes.button}
              onClick={onContactSupport}
            >
              Contact Support
            </ButtonBase>
          </>
        )}

        <ButtonBase
          disableRipple
          disableTouchRipple
          className={classes.button}
          onClick={onLogout}
        >
          <LongArrowLeftIcon />
          Logout
        </ButtonBase>
      </div>
    </ClickAwayListener>
  );
};

export default UserSettingsPopUp;
