import {
  cardTitle,
  center,
  container,
  grayColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react';

const loginPageStyle = (theme) => ({
  center,
  container: {
    ...container,
    zIndex: '4',
    margin: '100px 0',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: whiteColor,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  loginButton: {
  },
  passwordValidationInfosContainer: {
    maxWidth: '300px',
    textAlign: 'left',
    margin: '0 auto 26px',
  },

  formContainer: {
    textAlign: 'center',
    margin: '20px auto 60px',
    padding: '0 20px',
    maxWidth: '562px',
  },
  cardHeaderRoot: {
    marginTop: '48px',
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '48px',
    color: theme.palette.grayScale11.main,
  },
  cardHeaderLine: {
    margin: '10px auto 30px',
    width: '100%',
    maxWidth: '163px',
    border: '1px solid rgba(0, 0, 0, 0.44)',
  },
  customInputDashboard: {
    maxWidth: '300px',
    marginBottom: '30px',
    textAlign: 'initial',
  },
  primaryAction: {
    maxWidth: '300px',
    margin: '10px 0 44px',
  },
  secondaryAction: {
    margin: '0 0 90px',
    textDecoration: 'underline',
    fontFamily: 'Overpass Mono',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.black.main,
  },
});

export default loginPageStyle;
