const style = (theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    display: 'flex',
    backgroundColor: 'transparent',
  },
  inputField: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRight: 'none',
    boxSizing: 'border-box',
    borderRadius: '16px 0px 0px 16px',

    flex: 1,
    padding: '12px',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.white.main,

    '& input': {
      padding: 0,
    },

    '& input::placeholder': {
      color: theme.palette.offWhite.main,
    },
  },
  button: {
    width: '96px',
    color: theme.palette.white.main,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    border: `2px solid ${theme.palette.white.main}`,
    boxSizing: 'border-box',
    borderRadius: '0px 16px 16px 0px',

    '&:hover': {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  submittedText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
});

export default style;
