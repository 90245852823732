import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';
import CustomSelect from 'components/CustomSelect/CustomSelect';

import { addBrokerageAgent } from 'utils/api';
import { nameRegExp, phoneRegExp, showAPIErrorAlert } from 'utils/lib';
import analyticsClient from 'utils/analytics';

import { useMyBrokerage, useBrokerageQuota } from 'data/brokerage';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: 'center',
    margin: '30px 0 0',
  },
  text: {
    fontWeight: 400,
    marginBottom: '20px',
  },
  spacer: {
    marginBottom: '30px',
  },
}));

const AddBrokerageAgentModal = ({ onClose }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useMyBrokerage();
  const { reloadQuota } = useBrokerageQuota();

  if (isLoadingBrokerage) return (<LoadingIndicator />);

  return (
    <Formik
      initialValues={{
        brokerageBranchId: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      }}
      onSubmit={async ({
        brokerageBranchId, email, firstName, lastName, phoneNumber,
      }) => {
        try {
          const { user } = await addBrokerageAgent(brokerageBranchId, email, firstName, lastName, phoneNumber);

          mutateBrokerage();
          reloadQuota();

          onClose();

          setCurrentAlert('success', `${firstName} ${lastName} has been emailed an invite link!`);

          // Track GTM event
          analyticsClient.track('user_registration_started', {
            agentdata_user_id: user._id,
            email: user.email,
            role: user.role.name,
          });
        } catch (err) {
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        brokerageBranchId: Yup.string().required(),
        email: Yup.string().email().required(),
        firstName: Yup.string().required().matches(nameRegExp),
        lastName: Yup.string().required().matches(nameRegExp),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue,
        } = props;

        return (
          <Form>
            <Dialog
              open
              scroll="body"
              keepMounted
              maxWidth="sm"
              onClose={onClose}
            >
              <SimpleModalHeader onClose={onClose} title="Add New Agent" />

              {isSubmitting ? <LoadingIndicator modal /> : null}

              <h4 className={classes.text}>Agents' marketing materials for their campaign touches will use the marketing profile of whichever branch they are assigned to in your brokerage.</h4>
              <Field
                name="brokerageBranchId"
              >
                {({ field }) => (
                  <CustomSelect
                    labelText="Choose branch"
                    labelBold
                    items={brokerage.branches.map(({ _id, name }) => ({ value: _id, name }))}
                    inputProps={{
                      ...field,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>

              <div className={classes.spacer} />

              <Field
                name="firstName"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="First Name"
                    inputProps={{
                      ...field,
                      autoComplete: 'given-name',
                      maxLength: 50,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="lastName"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Last Name"
                    inputProps={{
                      ...field,
                      autoComplete: 'family-name',
                      maxLength: 50,
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="email"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Email"
                    inputProps={{
                      ...field,
                      autoComplete: 'email',
                    }}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>
              <Field
                name="phoneNumber"
              >
                {({ field }) => (
                  <CustomInputDashboard
                    labelText="Phone Number"
                    inputProps={{
                      ...field,
                      type: 'tel',
                      autoComplete: 'tel',
                    }}
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    error={touched[field.name] && errors[field.name] !== undefined}
                  />
                )}
              </Field>

              <div className={classes.buttonContainer}>
                <Button
                  round
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  Add & Send Registration Email
                </Button>
              </div>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddBrokerageAgentModal;
