import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';
import InstagramIcon from '@material-ui/icons/Instagram';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import EmailSignupField from 'components/EmailSignupField/EmailSignupField';

import { supportPhoneNumberFormatted } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/homeFooterStyle';

import logo from 'assets/img/logo-dark.png';

const useStyles = makeStyles(styles);

const HomeFooter = () => {
  const classes = useStyles();

  const newsletter = (
    <>
      <div className={classes.listTitle}>Newsletter</div>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Get Our Monthly Marketing Tips" />
        </ListItem>
        <ListItem className={`${classes.listItem} ${classes.newsletterForm}`}>
          <EmailSignupField />
        </ListItem>
      </List>
    </>
  );

  const socialLinksAndCopyright = (
    <>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <a href="https://www.instagram.com" className={classes.socialLink}>
            <ListItemIcon className={classes.listIcon}>
              <InstagramIcon />
            </ListItemIcon>
            <ListItemText primary="Instagram" />
          </a>
        </ListItem>
      </List>
      <div style={{ flexGrow: 1 }} />
      <div className={classes.copyrightText}>
        {`© 2020 - ${1900 + new Date().getYear()}`}
      </div>
    </>
  );

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} lg={3}>
            <a
              href="/"
            >
              <img src={logo} alt="Harvist" className={classes.logo} />
            </a>
            <div className={classes.businessInfoContainer}>
              <p>
                13400 Ventura Blvd.
                <br />
                Sherman Oaks, CA 91423
                <br />
                {supportPhoneNumberFormatted}
              </p>
              <Hidden smDown>
                {socialLinksAndCopyright}
              </Hidden>
            </div>
          </GridItem>
          <Hidden lgUp>
            <GridItem xs={12} sm={8} md={6}>
              {newsletter}
            </GridItem>
          </Hidden>
          <GridItem xs={12} md={4} lg={2}>
            <div className={classes.listTitle}>Products</div>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/absentee-owners" className={classes.navLink}>
                  <ListItemText primary="Absentee Owners" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/nod" className={classes.navLink}>
                  <ListItemText primary="NOD" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/commercial" className={classes.navLink}>
                  <ListItemText primary="Commercial" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/crm-pro" className={classes.navLink}>
                  <ListItemText primary="CRM Pro" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/full-farm" className={classes.navLink}>
                  <ListItemText primary="Full Farm" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/products/brokerage-accounts" className={classes.navLink}>
                  <ListItemText primary="Brokerage" />
                </NavLink>
              </ListItem>
            </List>
          </GridItem>
          <GridItem xs={12} md={4} lg={2}>
            <div className={classes.listTitle}>Resources</div>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <NavLink to="#" className={classes.navLink}>
                  <ListItemText primary="Blog" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                <NavLink to="/close-like-a-pro" className={classes.navLink}>
                  {/* Line break after the word 'to' */}
                  <ListItemText
                    primary={'Free Guide to\n Closing Like a Pro'}
                    primaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
                  />
                </NavLink>
              </ListItem>
            </List>
          </GridItem>
          <GridItem xs={12} md={4} lg={2}>
            <div className={classes.listTitle}>Company</div>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                {/* TODO: ABOUT PAGE??? */}
                <NavLink to="#" className={classes.navLink}>
                  <ListItemText primary="About" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                {/* TODO: PRIVACY PAGE??? */}
                <NavLink to="#" className={classes.navLink}>
                  <ListItemText primary="Privacy" />
                </NavLink>
              </ListItem>
              <ListItem className={classes.listItem}>
                {/* TODO: TERMS PAGE??? */}
                <NavLink to="#" className={classes.navLink}>
                  <ListItemText primary="Terms" />
                </NavLink>
              </ListItem>
            </List>
          </GridItem>
          <Hidden mdDown>
            <GridItem lg={3}>
              {newsletter}
            </GridItem>
          </Hidden>
          <Hidden mdUp>
            <GridItem xs={12}>
              {socialLinksAndCopyright}
            </GridItem>
          </Hidden>
        </GridContainer>
      </div>
    </footer>
  );
};

export default HomeFooter;
