const style = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  dot: {
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    animation: '$tp-loader-animation-1 1.3s infinite ease-in',
    opacity: 0,
    '-moz-transform': 'rotate(0.1deg)', // Fixes small rendering bug in FF;
    width: '16px',
    height: '16px',

    '&:nth-child(2)': {
      animationName: '$tp-loader-animation-2',
      margin: '0 10px',
    },

    '&:nth-child(3)': {
      animationName: '$tp-loader-animation-3',
    },
  },

  '@keyframes tp-loader-animation-1': {
    '0%, 70%, 100%': {
      opacity: 0.2,
    },
    '30%': {
      opacity: 1,
    },
  },

  '@keyframes tp-loader-animation-2': {
    '0%, 15%, 85%, 100%': {
      opacity: 0.2,
    },
    '45%': {
      opacity: 1,
    },
  },

  '@keyframes tp-loader-animation-3': {
    '0%, 30%, 100%': {
      opacity: 0.2,
    },
    '60%': {
      opacity: 1,
    },
  },
});

export default style;
