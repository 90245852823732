import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';

import Button from 'creative-components/CustomButtons/Button';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignSteps from 'components/CampaignSteps/CampaignSteps';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import PaymentMethodCard from 'components/PaymentMethodCard/PaymentMethodCard';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { createCampaign } from 'utils/api';
import {
  getNumDaysAutoRenewalCampaignStartsIn, numberWithCommas, showAPIErrorAlert, sortTouchesForCampaignSteps,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  touchPreviewMessage: {
    maxWidth: '700px',
    margin: '30px auto 40px',
  },
  spacer: {
    marginTop: '100px',
  },
  deleteDraftButton: {
    margin: '0 20px 0 80px',
  },
  createCampaignButton: {
    float: 'right',
  },
  confirmPopUp: {
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    },
  },
  paymentMethodContainer: {
    borderRadius: '12px',
    padding: '18px',
    backgroundColor: theme.palette.primary.light,
  },
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
}));

const CreateCampaignTouchEditing = ({ draftCampaign, confirmDraftDeletion, setStep }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { mutate } = useSWRConfig();
  const { userInfo, reloadUserInfo } = useUserInfoContext();
  const { DASHBOARD_ROUTE, isBrokerageAgent, user } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const isBillingInfoProvided = userInfo.agentInfo.stripe.paymentMethodInfo && userInfo.agentInfo.stripe.paymentMethodInfo.last4;

  const onSaveDraft = () => {
    // Go to the dashboard
    history.push(DASHBOARD_ROUTE);

    setCurrentAlert('success', 'Your campaign draft has been saved. You can return to this campaign to pick up where you left off in the future.');
  };

  const handleClickStart = () => {
    if (draftCampaign.autoRenewalForCampaign) {
    // Users can't start auto-renewing campaigns. They must wait for them to start.
      setCurrentAlert('warning', `This auto-renewal campaign will automatically start in ${getNumDaysAutoRenewalCampaignStartsIn(draftCampaign.createdAt)} day(s)`);
    } else {
      setShowConfirmation(true);
    }
  };

  const onStartCampaign = async () => {
    // Make sure they have a payment method on file
    if (!isBillingInfoProvided) {
      setCurrentAlert('warning', 'Please provide a payment method!');
      return;
    }

    showLoadingIndicatorModal();

    try {
      await createCampaign(draftCampaign._id);

      // Mark as stale so cache updates next time
      mutate(`/campaigns/${draftCampaign._id}`);

      setCurrentAlert('success', 'Your campaign has been created and will start shortly! Give us some time to crunch the data and prepare everything for you.');

      // Go to the dashboard
      history.push(DASHBOARD_ROUTE);
    } catch (err) {
      console.error('error', err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  if (!draftCampaign) return (<LoadingIndicator />);

  return (
    <div className={classes.root}>
      {showConfirmation && (
        <ActionModal
          maxWidth="sm"
          backgroundColor={theme.palette.offWhite.main}
          textColor={theme.palette.darkGray.main}
          icon={<LibraryAddCheckRoundedIcon />}
          onClose={() => setShowConfirmation(false)}
          title="Ready to Start Your Campaign?"
          message={(
            <div className={classes.confirmPopUp}>
              {`Your ${draftCampaign.eventType.name} campaign will have ${numberWithCommas(draftCampaign.estimatedNumberOfApns)} parcels.`}
              <br />
              <br />
              Confirm your payment method:
              <br />
              <br />
              <div className={classes.paymentMethodContainer}>
                <PaymentMethodCard
                  billingName={`${userInfo.firstName} ${userInfo.lastName}`}
                  paymentMethodInfo={userInfo.agentInfo.stripe.paymentMethodInfo}
                  reloadCallback={reloadUserInfo}
                />
              </div>
              <br />
              <br />
            </div>
          )}
          buttons={[
            <ButtonBase onClick={() => setShowConfirmation(false)} className={classes.cancelButton}>Go Back</ButtonBase>,
            <ButtonBase onClick={onStartCampaign}>Start Campaign</ButtonBase>,
          ]}
        />
      )}

      <DashboardBubble
        rootClass={classes.touchPreviewMessage}
        // backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
        icon={<InfoRoundedIcon htmlColor={theme.palette.warning.main} />}
        content={(
          <>
            You can preview and edit your marketing touches here before starting your campaign. A random property address will be used for the data on your touch previews until your campaign starts.
            <br />
            <br />
            The final order and number of touches will be assigned once your campaign starts. Click the Start Campaign button when you're ready to start your campaign!
          </>
        )}
      />

      <div>
        <Button
          round
          color="primary"
          onClick={() => setStep(1)}
        >
          <ArrowBackRoundedIcon />
          Back
        </Button>

        <Button
          round
          color="primary"
          onClick={handleClickStart}
          className={classes.createCampaignButton}
        >
          Start Campaign
        </Button>
      </div>

      <CampaignSteps
        agent={userInfo}
        eventType={draftCampaign.eventType}
        initialTouchTriggers={sortTouchesForCampaignSteps(draftCampaign)}
        campaignStartDate={null}
        campaignCreatedDate={draftCampaign.createdAt}
      />

      <div className={classes.spacer} />

      <div>
        <Button
          round
          color="primary"
          onClick={() => setStep(1)}
        >
          <ArrowBackRoundedIcon />
          Back
        </Button>

        <Button
          round
          color="danger"
          onClick={confirmDraftDeletion}
          className={classes.deleteDraftButton}
        >
          <DeleteRoundedIcon />
          {draftCampaign.autoRenewalForCampaign ? 'Cancel Renewal' : 'Delete Draft'}
        </Button>

        <Button
          round
          color="dark"
          onClick={onSaveDraft}
        >
          <ScheduleRoundedIcon />
          Save Draft
        </Button>

        <Button
          round
          color="primary"
          onClick={handleClickStart}
          className={classes.createCampaignButton}
        >
          Start Campaign
        </Button>
      </div>

    </div>
  );
};

export default CreateCampaignTouchEditing;
