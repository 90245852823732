import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import React from 'react';

import { createTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

// Create the colors separately so that we can use colors in the class overrides below
const colors = {
  palette: {
    /** Set the primary/secondary colors here to have MUI components use them */
    primary: {
      light: '#96d6b3',
      main: '#56917f',
      dark: '#3C5547',
    },
    secondary: {
      main: '#534b52',
    },

    success: {
      main: '#96d6b3',
    },
    warning: {
      main: '#ffbf2f',
    },
    error: {
      main: '#ff3b30',
    },
    orange: {
      main: '#F04528',
    },
    purple: {
      main: '#534b52',
      dark: '#2d232e',
    },
    darkBlueGray: {
      main: '#40454E',
    },
    lightGray: {
      main: '#696F79',
    },
    gray: {
      main: '#484D57',
    },
    darkGray: {
      main: '#171718',
    },
    grayScale4: {
      main: '#999BA1',
    },
    grayScale5: {
      main: '#C3C4C8',
    },
    grayScale6: {
      main: '#E4E4E6',
    },
    grayScale7: {
      main: '#F6F6F7',
    },
    grayScale8: {
      main: '#FBFBFC',
    },
    grayScale9: {
      main: '#3A394C',
    },
    grayScale10: {
      main: '#D9D9DD',
    },
    grayScale11: {
      main: '#50505E',
    },
    offWhite: {
      main: '#F2F2F2',
    },
    white: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },

    // Colors for different event types. Used for event type badges and other stuff.
    absenteeOwners: {
      main: '#E86825',
    },
    nod: {
      main: '#5D5FEF',
    },
    commercial: {
      main: '#2B9EB8',
    },
    fullFarm: {
      main: '#1BA769',
    },
  },
};

const theme = createTheme({
  ...colors,
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  props: {
    MuiCheckbox: {
      checkedIcon: <CheckIcon />,
      icon: <CheckIcon />,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
});

theme.overrides = {
  MuiListItemIcon: {
    root: {
      minWidth: 'auto',
      marginRight: '10px',
    },
  },
  MuiListItemText: {
    primary: {
      textTransform: 'none',
    },
  },
  MuiCollapse: {
    root: {

    },
  },
  MuiInputBase: {
    input: {
      height: '20px', // Prevent letters like 'g' from getting cut off at the bottom. MUI default is 19px.
    },
    multiline: {
      padding: 0,
    },
  },
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        border: '2px solid',
        borderRadius: '8px',
        borderColor: colors.palette.grayScale5.main,
      },
      '&:hover $notchedOutline': {
        border: '2px solid',
        borderColor: colors.palette.grayScale5.main,
      },
      '&$focused $notchedOutline': {
        border: '2px solid',
        borderColor: colors.palette.primary.main,
      },
      '&$error': {
        '& $notchedOutline, &:hover $notchedOutline, &$focused $notchedOutline': {
          border: '2px solid',
          borderColor: colors.palette.error.main,
        },
      },
    },
  },
  MuiTouchRipple: {
    root: {

    },
    ripple: {
      backgroundColor: 'transparent',
    },
    rippleVisible: {
      backgroundColor: 'transparent',
    },
    ripplePulsate: {
      backgroundColor: 'transparent',
    },
    child: {
      backgroundColor: 'transparent',
    },
    childPulsate: {
      backgroundColor: 'transparent',
    },
    childLeaving: {
      backgroundColor: 'transparent',
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: '0 0 12px',
    },
  },
  MuiSlider: {
    root: {
      color: colors.palette.primary.main,
    },
    rail: {
      height: '4px',
      backgroundColor: colors.palette.grayScale5.main,
      borderRadius: '10px',
    },
    track: {
      height: '4px',
      borderRadius: '10px',
    },
    mark: {
      height: '4px',
    },
    thumb: {
      width: '16px',
      height: '16px',
      marginTop: '-6px',

      '&:hover': {
        boxShadow: `0 0 0 8px rgba(${hexToRgb(colors.palette.primary.main)}, 10%)!important`,
      },
      '&.Mui-focusVisible': {
        boxShadow: `0 0 0 8px rgba(${hexToRgb(colors.palette.primary.main)}, 10%)!important`,
      },
      '&.Mui-disabled': {
        width: '16px',
        height: '16px',
        marginTop: '-6px',
      },
    },
    active: {
      '&:focus': {
        boxShadow: `0 0 0 12px rgba(${hexToRgb(colors.palette.primary.main)}, 16%)!important`,
      },
    },
  },
  MuiCheckbox: {
    root: {
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      boxShadow: '0px 2px 2px rgba(58, 57, 76, 0.08)',
      color: colors.palette.white.main,
      background: colors.palette.white.main,
      border: `1px solid ${colors.palette.grayScale5.main}`,
      boxSizing: 'border-box',

      '&:hover': {
        boxShadow: '0px 2px 8px 4px rgba(58, 57, 76, 0.08)',
        background: colors.palette.white.main,
      },

      '&.Mui-checked, &.MuiCheckbox-indeterminate': {
        border: 'none',

        // color: `${colors.palette.white.main} !important`,
        '&.MuiCheckbox-colorPrimary': {
          background: colors.palette.primary.main,

          '&:hover': {
            background: colors.palette.primary.main,
          },
        },
        '&.MuiCheckbox-colorSecondary': {
          background: colors.palette.orange.main,

          '&:hover': {
            background: colors.palette.orange.main,
          },
        },
        '&.Mui-disabled': {
          background: colors.palette.grayScale5.main,

          '&:hover': {
            background: colors.palette.lightGray.main,
          },
        },
      },

      '& svg': {
        color: `${colors.palette.white.main} !important`,
        padding: '2px',
      },

      '&.MuiCheckbox-indeterminate svg': {
        padding: 0,
      },
    },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: colors.palette.primary.main,
    },
    colorSecondary: {
      color: colors.palette.orange.main,
    },
  },
  MuiDialog: {
    paper: {
      width: '100%',
      padding: '42px 40px',
      borderRadius: '16px',
      background: colors.palette.white.main,

      [theme.breakpoints.down('sm')]: {
        padding: '21px 20px',
        margin: '18px',
      },
    },
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      margin: 'auto',
    },
  },
  MuiCard: {
    root: {
      margin: '17px 0',
      padding: '8px 24px',
      borderRadius: '12px',
      boxShadow: '0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03)',
      backgroundColor: theme.palette.white.main,
    },
  },
  MuiCardHeader: {
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.grayScale11.main,
    },
    subheader: {
      color: theme.palette.grayScale11.main,
    },
  },
  MuiCardContent: {
    root: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.black.main,
    },
  },
  MuiCardActions: {
    root: {
      justifyContent: 'flex-end',
    },
  },
  MuiChip: {
    label: {
    },
    deleteIconColorSecondary: {
      color: `rgba(${hexToRgb(colors.palette.white.main)}, 85%) !important`,
      '&:hover': {
        color: `${theme.palette.white.main} !important`,
      },
    },
    deletableColorPrimary: {
      '&:focus': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    deletableColorSecondary: {
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    colorPrimary: {
      color: colors.palette.black.main,
      backgroundColor: colors.palette.primary.light,
    },
    deleteIconColorPrimary: {
      color: colors.palette.black.main,
    },
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.darkGray.main,
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '6px',
      fontWeight: 600,
    },
  },
  MuiTooltip: {
    tooltip: {
      padding: '10px 15px',
      minWidth: '90px',
      color: colors.palette.white.main,
      lineHeight: '1.7em',
      backgroundColor: `rgba(${hexToRgb(colors.palette.grayScale9.main)}, 90%)`,
      border: 'none',
      borderRadius: '6px',
      opacity: '1!important',
      // boxShadow:
      // `0 8px 10px 1px rgba(${
      //   hexToRgb(colors.palette.black.main)
      // }, 0.14), 0 3px 14px 2px rgba(${
      //   hexToRgb(colors.palette.black.main)
      // }, 0.12), 0 5px 5px -3px rgba(${
      //   hexToRgb(colors.palette.black.main)
      // }, 0.2)`,
      textAlign: 'center',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      textShadow: 'none',
      textTransform: 'none',
      letterSpacing: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      wordWrap: 'normal',
      whiteSpace: 'normal',
      lineBreak: 'auto',
    },
    arrow: {
      color: `rgba(${hexToRgb(colors.palette.grayScale9.main)}, 90%)`,
    },
  },
};

export default theme;
