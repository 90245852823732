/* eslint-disable no-shadow */
import styles from 'assets/jss/material-dashboard-pro-react/layouts/simpleCreateStyle';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import routes from 'routes';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Footer from 'creative-components/Footer/Footer';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import { withDataFilterProvider } from 'components/DataMap/DataFilterProvider';
import HomeNavbar from 'components/HomeNavbar/HomeNavbar';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { withUserInfoProvider } from 'components/UserInfoProvider/UserInfoProvider';

import ErrorPage from 'views/Pages/ErrorPage';

const useStyles = makeStyles(styles);

const SimpleCreate = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const { currentAlert, currentModal } = useAlertContext();
  const { isLoadingIndicatorModalShowing } = useLoadingIndicatorContext();

  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = (routes) => routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/simple') {
      return (
        <Route
          exact={prop.exact}
          path={prop.layout + prop.path}
          component={prop.component}
          render={prop.render}
          key={key}
        />
      );
    }
    return null;
  });

  const navbarColor = theme.palette.darkGray.main;

  return (
    <div className={classes.wrapper}>
      <HomeNavbar color={navbarColor} />
      {currentAlert}
      {currentModal}
      {isLoadingIndicatorModalShowing ? (<LoadingIndicator modal />) : null}
      <div className={classes.content}>
        <Switch>
          {getRoutes(routes)}
          <Route path="*" component={() => (<PublicRoute component={ErrorPage} />)} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default withUserInfoProvider(withDataFilterProvider(() => (<SimpleCreate />)));
