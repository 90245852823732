import React from 'react';

import { getEventTypes } from 'utils/api';
import { isCrmProEventType } from 'utils/lib';

// type FilterCategories = 'price' | 'squareFootage' | 'numBeds' | 'numBaths' | 'soldYearsAgo' | 'builtYearsAgo' | 'numberOfUnits'
type FilterCategories = 'squareFootage' | 'numBeds' | 'numBaths' | 'soldYearsAgo' | 'builtYearsAgo' | 'numberOfUnits';

interface FilterSettings {
  lowerBound: number;
  upperBound: number;
  step: number;
  lowerBoundLessThan: boolean; // If true, uses a less than check when at the lower bound
  upperBoundGreaterThan: boolean; // If true, uses a greater than check when at the upper bound
}

type FiltersModalOptionsType = {
  disableEventTypeSelector: boolean;
  selectAllPropertyTypesByDefault: boolean;
  selectPropertyTypesByDefault: string[];
};

export const filterCategorySettings: { [key in FilterCategories]: FilterSettings } = {
  // price: {
  //   lowerBound: 150000,
  //   upperBound: 10000000,
  //   step: 50000,
  //   lowerBoundLessThan: true,
  //   upperBoundGreaterThan: true,
  // },
  squareFootage: {
    lowerBound: 500,
    upperBound: 10000,
    step: 250,
    lowerBoundLessThan: true,
    upperBoundGreaterThan: true,
  },
  numBeds: {
    lowerBound: 1,
    upperBound: 8,
    step: 1,
    lowerBoundLessThan: true,
    upperBoundGreaterThan: true,
  },
  numBaths: {
    lowerBound: 1,
    upperBound: 6,
    step: 1,
    lowerBoundLessThan: true,
    upperBoundGreaterThan: true,
  },
  soldYearsAgo: {
    lowerBound: 0,
    upperBound: 50,
    step: 1,
    lowerBoundLessThan: true,
    upperBoundGreaterThan: true,
  },
  builtYearsAgo: {
    lowerBound: 0,
    upperBound: 50,
    step: 1,
    lowerBoundLessThan: true,
    upperBoundGreaterThan: true,
  },
  numberOfUnits: {
    lowerBound: 4,
    upperBound: 50,
    step: 1,
    lowerBoundLessThan: false,
    upperBoundGreaterThan: true,
  },
};

export const getAllPropertyTypesFromEventType = (eventType: any) => {
  if (eventType) {
    return eventType.propertyTypeFilters.map(({ propertyType }: { propertyType: string }) => propertyType);
  }

  return [];
};

export const DataFilterContext = React.createContext(null);

const defaultFilters = {
  // Global filters
  propertyTypes: [],
  soldYearsAgoRange: [0, Number.MAX_SAFE_INTEGER],

  // ABS/NODs/Full Farm filters
  // priceRange: [0, Number.MAX_SAFE_INTEGER],
  squareFootageRange: [0, Number.MAX_SAFE_INTEGER],
  numBedsRange: [0, Number.MAX_SAFE_INTEGER],
  numBathsRange: [0, Number.MAX_SAFE_INTEGER],

  // Commercial filters
  builtYearsAgoRange: [0, Number.MAX_SAFE_INTEGER],
  numberOfUnitsRange: [0, Number.MAX_SAFE_INTEGER],
};

const DataFilterProvider = (props: any) => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = React.useState(defaultFilters);
  const [selectedEventType, setSelectedEventType] = React.useState(null);
  const [filtersModalOptions, setFiltersModalOptions] = React.useState({});

  const [availableEventTypes, setAvailableEventsTypes] = React.useState([]);
  const [crmProEventType, setCRMProEventType] = React.useState(null);

  React.useEffect(() => {
    // Prevents setting state on unmounted components due to redirects on the dashboard page
    let isCancelled = false;

    (async () => {
      const { eventTypes } = await getEventTypes(true); // Fetch prices too, required for create campaign page

      if (isCancelled) return;

      // Filter out CRM from the event types
      const filteredEventTypes = eventTypes.filter((e: any) => !isCrmProEventType(e));

      setAvailableEventsTypes(filteredEventTypes);
      setCRMProEventType(eventTypes.filter((e: any) => isCrmProEventType(e))[0]);
    })();

    return (() => { isCancelled = true; });
  }, []);

  const handleSetFiltersModalOptions = (options: FiltersModalOptionsType) => {
    if (options) setFiltersModalOptions(options);
  };

  const handleSetSelectedEventTypeByName = (selectedName: string) => {
    if (!availableEventTypes) return;

    const eventType = availableEventTypes.filter(({ name }: { name: string }) => name === selectedName)[0];
    setSelectedEventType(eventType);
  };

  const dataFilterProviderValue = React.useMemo(() => ({
    appliedFilters,
    setAppliedFilters,
    defaultFilters,
    isFiltersModalOpen,
    filtersModalOptions,
    setIsFiltersModalOpen,
    setFiltersModalOptions: handleSetFiltersModalOptions,
    selectedEventType,
    setSelectedEventType,
    setSelectedEventTypeByName: handleSetSelectedEventTypeByName,
    availableEventTypes,
    crmProEventType,
  }), [appliedFilters, isFiltersModalOpen, selectedEventType, availableEventTypes, crmProEventType, filtersModalOptions]);

  return <DataFilterContext.Provider value={dataFilterProviderValue} {...props} />;
};

export const useDataFilterContext = () => React.useContext(DataFilterContext);

export const withDataFilterProvider = (Component: React.FC) => ({ ...props }) => (
  <DataFilterProvider>
    <Component {...props} />
  </DataFilterProvider>
);

export default DataFilterProvider;
