import React from 'react';
import {
  Formik, Form, Field, useFormikContext,
} from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, Slide, Checkbox, ButtonBase,
} from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { submitAgentLandingConsentForm } from 'utils/api';
import { isAgentLandingPageSample, phoneRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    backdropFilter: 'blur(6px)',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.black.main,
  },
  line: {
    margin: '13px auto 32px',
    width: '100%',
    maxWidth: '163px',
    border: '1px solid rgba(0, 0, 0, 0.44)',
  },
  button: {
    margin: '32px 0 0',
    width: '100%',
  },
  termsCheckbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 'normal',
    textAlign: 'center',
    margin: '10px 0 0',

    '& > span': {
      marginRight: '10px',
    },

    '& > a': {
      marginLeft: '5px',
      textDecoration: 'underline',
      fontStyle: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      color: 'inherit',
    },
  },
}));

const TermsModal = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="sm"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Terms" />

      <p className={classes.termsText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
    </Dialog>
  );
};

const TermsCheckbox = ({ fieldName }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  // const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);

  return (
    <>
      {/* {isTermsModalOpen && <TermsModal onClose={() => setIsTermsModalOpen(false)} />} */}

      <div className={classes.termsCheckbox}>
        <Checkbox
          color="primary"
          checked={values[fieldName]}
          tabIndex={-1}
          onClick={() => setFieldValue(fieldName, !values[fieldName], true)}
        />
        I agree to these
        <a href="https://harvist.com/privacy-terms-and-conditions/" target="_blank" rel="noreferrer">
          terms
        </a>
        {/* <ButtonBase onClick={() => setIsTermsModalOpen(true)}>
                          terms
                        </ButtonBase> */}
      </div>
    </>
  );
};

export default TermsCheckbox;
