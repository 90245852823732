import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

const FilterIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.40006 10.3846L0.623585 2.29748C0.0562689 1.50324 0.624018 0.399994 1.60006 0.399994L18.4001 0.399994C19.3761 0.399994 19.9439 1.50324 19.3765 2.29748L13.6001 10.3846L13.6001 16C13.6001 16.4545 13.3433 16.87 12.9367 17.0733L8.13672 19.4733C7.33884 19.8722 6.40006 19.2921 6.40006 18.4L6.40006 10.3846ZM3.93189 2.79999L8.57654 9.30251C8.72192 9.50603 8.80006 9.74989 8.80006 9.99999L8.80006 16.4584L11.2001 15.2584L11.2001 9.99999C11.2001 9.74989 11.2782 9.50603 11.4236 9.30251L16.0682 2.79999L3.93189 2.79999Z" fill="white" />
    </SvgIcon>

  );
};

export default FilterIcon;
