import React from 'react';

import { SvgIcon } from '@material-ui/core';

const HomeIcon = ({ className }) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99999 22.7522C5.34314 22.7522 3.99999 21.4091 3.99999 19.7522V13.7522H2.99999C2.10909 13.7522 1.66292 12.6751 2.29288 12.0451L11.2929 3.04509C11.6834 2.65457 12.3166 2.65457 12.7071 3.04509L21.7071 12.0451C22.3371 12.6751 21.8909 13.7522 21 13.7522H20V19.7522C20 21.4091 18.6568 22.7522 17 22.7522H6.99999ZM12 5.16641L5.3509 11.8155C5.73009 11.9576 5.99999 12.3234 5.99999 12.7522V19.7522C5.99999 20.3045 6.44771 20.7522 6.99999 20.7522L8.99999 20.7512L8.99999 16.7522C8.99999 15.6476 9.89542 14.7522 11 14.7522H13C14.1046 14.7522 15 15.6476 15 16.7522L15 20.7512L17 20.7522C17.5523 20.7522 18 20.3045 18 19.7522V12.7522C18 12.3234 18.2699 11.9576 18.6491 11.8155L12 5.16641ZM13 16.7522H11L11 20.7512H13L13 16.7522Z" fill="inherit" />
  </SvgIcon>
);

export default HomeIcon;
