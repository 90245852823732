import styles from 'assets/jss/material-dashboard-pro-react/creative-components/customInputStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles(styles);

const MaskedInputField = ({
  inputRef, mask, pipe, onChange, ...other
}) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    guide={false}
    onChange={onChange}
    mask={mask}
    pipe={pipe}
  />
);

export default function CustomInput(props) {
  const classes = useStyles();

  const {
    className,
    formControlProps,
    labelText,
    labelBold,
    labelRequired,
    labelDisabled,
    id,
    inputProps,
    error,
    showFeedbackEndIcon, // Whether or not to show the x or check at the end depending on error/success
    success, // TODO: NOT USED???
    helperText,
    mask,
    pipe,
    feedbackEndIconStyles,
    isDashboard,
    tooltip, // Must be passed along with an end adornment
  } = props;

  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = cx(
      formControlProps.className,
      classes.formControl,
      isDashboard && classes.formControlDashboard,
      className,
    );
  } else {
    formControlClasses = cx(classes.formControl, isDashboard && classes.formControlDashboard, className);
  }

  const newInputProps = {
    style:
      inputProps && inputProps.style ? inputProps.style : undefined,
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
  };

  let startAdornment = null;
  if (inputProps && inputProps.startAdornment) {
    startAdornment = (
      <InputAdornment position="start">
        {inputProps.startAdornment}
      </InputAdornment>
    );
  }

  let feedbackEndIcon = null;
  if (showFeedbackEndIcon) {
    if (error) {
      feedbackEndIcon = (
        <Clear
          className={classes.feedbackError}
          style={feedbackEndIconStyles}
        />
      );
    } else if (success) {
      feedbackEndIcon = (
        <Check
          className={classes.feedbackSuccess}
          style={{
            right: inputProps && inputProps.endAdornment ? '25px' : '0px',
            ...feedbackEndIconStyles,
          }}
        />
      );
    }
  }

  let endAdornment = null;
  if (feedbackEndIcon || (inputProps && inputProps.endAdornment)) {
    endAdornment = (
      <InputAdornment position="end" className={classes.endAdornment}>
        {feedbackEndIcon}
        {tooltip ? (
          <Tooltip
            placement="top"
            disableFocusListener
            disableTouchListener
            title={tooltip}
          >
            {inputProps && inputProps.endAdornment}
          </Tooltip>
        ) : (
          <>
            {inputProps && inputProps.endAdornment}
          </>
        )}
      </InputAdornment>
    );
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses} fullWidth>
      {labelText !== undefined ? (
        // <InputLabel
        //   shrink
        //   classes={{
        //     root: classes.labelRoot,
        //   }}
        //   htmlFor={id}
        // >
        //   {labelText}
        // </InputLabel>
        <div
          htmlFor={id}
          className={cx(classes.label, {
            [classes.labelDashboard]: isDashboard,
            [classes.labelDisabled]: inputProps && inputProps.disabled && (labelDisabled || labelDisabled === undefined),
            [classes.labelBold]: labelBold,
            [classes.labelRequired]: labelRequired,
          })}
        >
          {labelText}
        </div>
      ) : null}

      <InputBase
        classes={{
          root: classes.inputRoot,
          input: cx(classes.input, isDashboard && classes.inputDashboard),
          focused: classes.inputFocused,
          error: classes.inputErrored,
          disabled: classes.inputDisabled,
          adornedStart: classes.inputAdornedStart,
          adornedEnd: classes.inputAdornedEnd,
          inputAdornedEnd: null,
        }}
        className={cx(
          {
            [classes.inputComplete]: inputProps && inputProps.value && !error,
          },
          inputProps.className,
        )}
        id={id}
        {...inputProps}
        inputProps={mask ? { mask, pipe, ...newInputProps } : newInputProps}
        inputComponent={mask && MaskedInputField}
        error={error}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />

      {helperText !== undefined && (
        <FormHelperText
          id={`${id}-text`}
          classes={{
            root: classes.helperText,
            error: classes.helperTextError,
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CustomInput.defaultProps = {
  showFeedbackEndIcon: true,
  feedbackEndIconStyles: {},
  labelBold: false,
  labelRequired: false,
  labelDisabled: true,
  isDashboard: false,
};

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelBold: PropTypes.bool,
  labelRequired: PropTypes.bool,
  labelDisabled: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  showFeedbackEndIcon: PropTypes.bool,
  helperText: PropTypes.node,
  mask: PropTypes.any,
  feedbackEndIconStyles: PropTypes.object,
  isDashboard: PropTypes.bool, // Whether this input is for the dashboard. Different styles apply.
};
