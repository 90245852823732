export enum EEventType {
  CRMPro = 'CRM Pro',
  AbsenteeOwners = 'Absentee Owners',
  NOD = 'NOD',
  Commercial = 'Commercial',
  FullFarm = 'Full Farm',
}

export interface IEventType {
  _id: string;
  name: EEventType;
  subscriptionPriceId: string;
  voicemailTexts: string[];
  propertyTypeFilters: {
    propertyType: string;
    landUseCodes: string[];
  };
  campaignLengthInMonths?: number;
  numberOfMailTouchesPerEvent?: number;
  numberOfTouchesPerEvent?: number;
}
