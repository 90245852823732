import React from 'react';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';

import AdminDashboardPage from 'views/Dashboard/AdminDashboardPage';
import CampaignDashboardPage from 'views/Dashboard/CampaignDashboardPage';

const DashboardPageWrapper = () => {
  const { isAdmin, isAccountManager } = useAuthDataContext();

  if (isAdmin || isAccountManager) {
    return <AdminDashboardPage />;
  }

  return <CampaignDashboardPage />;
};

export default DashboardPageWrapper;
