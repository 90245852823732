/* eslint-disable no-param-reassign */
import { Chart, Plugin } from 'chart.js';

export const addPaddingBelowLegendPlugin: Plugin = {
  id: 'addPaddingBelowLegend',
  beforeInit(chart: Chart) {
    // Get a reference to the original fit function
    const originalFit = (chart as any).legend.fit;

    // Override the fit function
    (chart as any).legend.fit = function fit() {
      // Call the original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      this.height += 20;
    };
  },
};

export const doughnutCenteredTextPlugin: Plugin = {
  id: 'doughnutCenteredTextPlugin',
  beforeDraw: (chart: any) => {
    const { ctx } = chart;
    if (chart.options.elements?.arc?.centeredText !== undefined) {
      const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
      const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
      ctx.save();
      ctx.font = 'bold 24px Montserrat';
      ctx.fillStyle = '#504F5D';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(chart.options.elements.arc.centeredText, xCoor, yCoor);
      ctx.restore();
    }
  },
};

export const doughnutRoundedDataPlugin: any = {
  id: 'doughnutRoundedDataPlugin',
  afterUpdate(chart: any) {
    if (chart.options.elements?.arc?.roundedCornersFor !== undefined) {
      const arcValues = Object.values(chart.options.elements.arc.roundedCornersFor);

      arcValues.forEach((arcs: any) => {
        arcs = Array.isArray(arcs) ? arcs : [arcs];
        arcs.forEach((i: number) => {
          const arc = chart.getDatasetMeta(0).data[i];
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (arc.outerRadius + arc.innerRadius) / 2,
            thickness: (arc.outerRadius - arc.innerRadius) / 2,
            backgroundColor: arc.options.backgroundColor,
          };
        });
      });
    }
  },
  afterDraw: (chart: any) => {
    if (chart.options.elements.arc.roundedCornersFor !== undefined) {
      const {
        ctx,
        canvas,
      } = chart;
      let arc;
      const { roundedCornersFor } = chart.options.elements.arc;
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const position in roundedCornersFor) {
        const values = Array.isArray(roundedCornersFor[position]) ? roundedCornersFor[position] : [roundedCornersFor[position]];
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        values.forEach((p: number) => {
          arc = chart.getDatasetMeta(0).data[p];
          const startAngle = Math.PI / 2 - arc.startAngle;
          const endAngle = Math.PI / 2 - arc.endAngle;
          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.options.backgroundColor;
          ctx.beginPath();
          if (position === 'start') {
            ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
          } else {
            ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
          }
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        });
      }
    }
  },
};
