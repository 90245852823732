import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card, CardHeader, CardContent, ButtonBase,
} from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import CustomInput from 'creative-components/CustomInput/CustomInput';
import Button from 'creative-components/CustomButtons/Button';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ActionModal from 'components/ActionModal/ActionModal';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import { passwordReset } from 'utils/api';

const useStyles = makeStyles(styles);

const StartPasswordResetForm = ({ setStartingPasswordReset }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isSubmittedModalVisible, setIsSubmittedModalVisible] = React.useState(false);

  const onCloseActionModal = () => {
    setIsSubmittedModalVisible(false);
    setStartingPasswordReset(false);
  };

  return (
    <div>
      {isSubmittedModalVisible && (
        <ActionModal
          backgroundColor={theme.palette.offWhite.main}
          textColor={theme.palette.darkGray.main}
          onClose={onCloseActionModal}
          title=""
          message="If that account exists, we will email you a password reset link."
          buttons={[<ButtonBase onClick={onCloseActionModal}>Okay</ButtonBase>]}
        />
      )}

      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={async ({ email }) => {
          try {
            await passwordReset(email);

            setIsSubmittedModalVisible(true);
          } catch (error) {
            console.error('error', error);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting,
          } = props;

          return (
            <Form>
              {isSubmitting ? <LoadingIndicator modal /> : null}
              <Card>
                <CardHeader
                  title="Reset Your Password"
                  classes={{
                    root: classes.cardHeaderRoot,
                    title: classes.cardHeaderTitle,
                  }}
                />
                <div className={classes.cardHeaderLine} />
                <CardContent>
                  <Field
                    name="email"
                  >
                    {({ field }) => (
                      <CustomInput
                        labelText="Email"
                        inputProps={{
                          ...field,
                          autoComplete: 'email',
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                        formControlProps={{
                          classes: {
                            root: classes.customInputDashboard,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Button
                    className={classes.primaryAction}
                    type="submit"
                    color="primary"
                    round
                    block
                    disabled={!isValid || !dirty || isSubmitting}
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </Button>
                  <div className={classes.center}>
                    <ButtonBase
                      disableRipple
                      disableTouchRipple
                      onClick={() => setStartingPasswordReset(false)}
                      className={classes.secondaryAction}
                    >
                      Back to Login
                    </ButtonBase>
                  </div>
                </CardContent>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default StartPasswordResetForm;
