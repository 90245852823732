import React from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, ButtonBase } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ETouchType } from 'types/touch-type';
import { useCampaignDeliveredTouchStats } from 'data/campaign-stats';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({

}));

const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      x: 20,
      top: 40,
      bottom: 14,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      yAlign: 'bottom' as const,
      backgroundColor: '#56917F',
      displayColors: false,
      caretPadding: 8,
      cornerRadius: 8,
      bodyFont: {
        size: 12,
        family: 'Montserrat',
        weight: '500',
      },
      callbacks: {
        title: () => null, // Render no title
        label: (context: any) => {
          // Use the dataset's color for the tooltip background
          context.chart.tooltip.options.backgroundColor = context.dataset.backgroundColor[context.dataIndex];

          // Render only the value, no label
          return context.formattedValue;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      grid: {
        display: false,
        borderColor: 'transparent',
      },
      ticks: {
        display: true,
      },
    },
    y: {
      display: false,
    },
  },
};

export interface Props {
  campaignId: string;
  onViewTouchTimeline: () => void;
}

export default ({ campaignId, onViewTouchTimeline }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { deliveredTouchStats, isLoadingDeliveredTouchStats } = useCampaignDeliveredTouchStats(campaignId);

  const chartData = React.useMemo<ChartData<'bar'> | null>(
    () => {
      if (isLoadingDeliveredTouchStats || !deliveredTouchStats) return null;

      const aggregatedData = deliveredTouchStats.reduce<Record<ETouchType, number>>((result, current) => {
        const { touchType, count } = current;

        // eslint-disable-next-line no-param-reassign
        result[touchType] += count;

        return result;
      }, {
        [ETouchType.Postcard]: 0,
        [ETouchType.Letter]: 0,
        [ETouchType.Email]: 0,
        [ETouchType.Text]: 0,
        [ETouchType.Voicemail]: 0,
      } as { [key in ETouchType]: number });

      return {
        labels: ['Emails', 'Postcards', 'Voicemails', 'Letters'],
        datasets: [{
          data: [
            aggregatedData[ETouchType.Email],
            aggregatedData[ETouchType.Postcard],
            aggregatedData[ETouchType.Voicemail],
            aggregatedData[ETouchType.Letter],
          ],
          backgroundColor: [
            '#56917F',
            '#F04528',
            '#018EDE',
            '#7B57E0',
          ],
          borderWidth: 0,
          barThickness: 29,
        }],
      };
    },
    [deliveredTouchStats],
  );

  return (
    <Box sx={{
      height: 317,
      width: '100%',
      maxWidth: 385,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 8,
      bgcolor: theme.palette.offWhite.main,
    }}
    >
      <Box>
        <Box sx={{
          fontWeight: 500,
          fontSize: 21,
          color: theme.palette.purple.dark,
          marginBottom: 15,
        }}
        >
          Touches Sent
        </Box>

        <ButtonBase onClick={onViewTouchTimeline} style={{ color: theme.palette.primary.main }}>
          View All
          <ChevronRightIcon />
        </ButtonBase>
      </Box>

      <Box sx={{
        flex: 1,
        display: 'flex',
      }}
      >
        {chartData ? (
          <Bar
            options={options}
            data={chartData}
          />
        ) : <LoadingIndicator />}
      </Box>

    </Box>
  );
};
