import React from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { supportEmail, supportPhoneNumber, supportPhoneNumberFormatted } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/creative-components/footerStyle';

const useStyles = makeStyles(styles);

export default function Footer() {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();

  let customFooterColor;

  // Only use a transparent navbar for specific pages
  if (!location.pathname.includes('/dashboard')) {
    customFooterColor = theme.palette.white.main;
  }

  return (
    <footer className={classes.footer} style={{ backgroundColor: customFooterColor }}>
      <div className={classes.containerFluid} style={{ padding: 0 }}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://harvist.com/privacy-terms-and-conditions/" style={{ padding: 0 }}>
                Privacy Policy, Terms & Conditions
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href={`tel:${supportPhoneNumber}`}>
                {supportPhoneNumberFormatted}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              &copy;
              {' '}
              {1900 + new Date().getYear()}
              {' '}
              Harvist
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}
