import React from 'react';

export const LoadingIndicatorContext = React.createContext(null);

const LoadingIndicatorProvider = (props) => {
  const [shown, setShown] = React.useState(null);

  const loadingIndicatorProviderValue = React.useMemo(() => ({
    isLoadingIndicatorModalShowing: shown,
    showLoadingIndicatorModal: () => setShown(true),
    hideLoadingIndicatorModal: () => setShown(false),
  }), [shown]);

  return <LoadingIndicatorContext.Provider value={loadingIndicatorProviderValue} {...props} />;
};

export const useLoadingIndicatorContext = () => React.useContext(LoadingIndicatorContext);

export default LoadingIndicatorProvider;
