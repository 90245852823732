import React from 'react';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { FastField } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import AddressForm from 'components/AddressAutocomplete/AddressForm';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';

const useStyles = makeStyles(styles);

const ContactFields = ({
  valueName, addressDisplayName, values, touched, errors, setFieldValue, showBirthday = true,
}) => {
  const classes = useStyles();

  return (
    <GridContainer justifyContent="space-between">
      <GridItem xs={12} md={showBirthday ? 4 : 6}>
        <FastField
          name={`${valueName}.firstName`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="First Name"
              margin="normal"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...field,
                autoComplete: 'off',
              }}
              error={(touched[valueName] && touched[valueName].firstName) && (errors[valueName] && errors[valueName].firstName) !== undefined}
            />
          )}
        </FastField>
      </GridItem>
      <GridItem xs={12} md={showBirthday ? 4 : 6}>
        <FastField
          name={`${valueName}.lastName`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="Last Name"
              margin="normal"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...field,
                autoComplete: 'off',
              }}
              error={(touched[valueName] && touched[valueName].lastName) && (errors[valueName] && errors[valueName].lastName) !== undefined}
            />
          )}
        </FastField>
      </GridItem>
      {showBirthday ? (
        <GridItem xs={12} md={4}>
          <FastField
            name={`${valueName}.birthday`}
          >
            {({ field }) => (
              <CustomInputDashboard
                labelText="Birthday"
                margin="normal"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...field,
                  autoComplete: 'off',
                }}
                // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                pipe={createAutoCorrectedDatePipe('mm/dd')}
                error={(touched[valueName] && touched[valueName].birthday) && (errors[valueName] && errors[valueName].birthday) !== undefined}
                helperText="Month and day i.e. 05/15"
              />
            )}
          </FastField>
        </GridItem>
      ) : null}
      <GridItem xs={12} md={8}>
        <FastField
          name={`${valueName}.email`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="Email"
              margin="normal"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...field,
                autoComplete: 'off',
              }}
              error={(touched[valueName] && touched[valueName].email) && (errors[valueName] && errors[valueName].email) !== undefined}
            />
          )}
        </FastField>
      </GridItem>
      <GridItem xs={12} md={4}>
        <FastField
          name={`${valueName}.phoneNumber`}
        >
          {({ field }) => (
            <CustomInputDashboard
              labelText="Phone Number"
              margin="normal"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...field,
                type: 'tel',
                autoComplete: 'off',
              }}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              error={(touched[valueName] && touched[valueName].phoneNumber) && (errors[valueName] && errors[valueName].phoneNumber) !== undefined}
            />
          )}
        </FastField>
      </GridItem>
      {/* TODO: Removed below to prevent double grid padding (i.e. eventoverridesmodal. if looks good, remove these comments) */}
      {/* <GridItem xs={12}> */}
      <AddressForm
        classes={classes}
        valueName={`${valueName}.address`}
        addressDisplayName={addressDisplayName}
        initialValue={values[valueName].address.address1}
      />
      {/* </GridItem> */}
    </GridContainer>
  );
};

export default ContactFields;
