/* eslint-disable no-param-reassign */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import DataFilterControls from 'components/DataMap/DataFilterControls';
import EventMarkers from 'components/DataMap/EventMarkers';
import { useMapInfoContext } from 'components/DataMap/MapInfoProvider';
import ZipcodePolygon from 'components/DataMap/ZipcodePolygon';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { getZipcodesByBounds } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    position: 'absolute',
    marginTop: '10px',
    marginLeft: '10px',
    top: '0px',
    left: '0px',
    zIndex: 100,
  },
}));

const zoomThresholdToShowZipcodes = 11;

function ZipcodeAndDataOverlay({
  highlightZipcodes,
  clickToLoadZipcodeEventCounts,
  showOnlyHighlightedZipcodes,
  highlightEventData,
  highlightEventDataShowHoverPopup,
  isDrawingShape,
  initPropertyFilters,
}) {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { mapInstance, mapBounds } = useMapInfoContext();

  const [forceRefetchData, setForceRefetchData] = React.useState(null); // Used to force data fetching
  const [isLoading, setIsLoading] = React.useState(false);

  const [loadedZipcodeMapBounds, setLoadedZipcodeMapBounds] = React.useState(null);

  const [loadedZipcodes, setLoadedZipcodes] = React.useState(null);

  React.useEffect(() => {
    if (!mapInstance) return;

    if (mapInstance?.zoom < zoomThresholdToShowZipcodes) {
      return;
    }

    // Don't update the bounds and requery for zipcodes if the new bounds are within the currently loaded ones
    if (loadedZipcodeMapBounds
        && loadedZipcodeMapBounds.contains(mapInstance.getBounds().getNorthEast())
        && loadedZipcodeMapBounds.contains(mapInstance.getBounds().getSouthWest())) {
      return;
    }

    setLoadedZipcodeMapBounds(mapInstance.getBounds());
    setForceRefetchData(Date.now());
  }, [mapBounds]);

  React.useEffect(() => {
    (async () => {
      if (!mapInstance) return;
      if (!loadedZipcodeMapBounds) return;

      const boundsNe = {
        lat: mapInstance.getBounds().getNorthEast().lat(),
        lng: mapInstance.getBounds().getNorthEast().lng(),
      };
      const boundsSw = {
        lat: mapInstance.getBounds().getSouthWest().lat(),
        lng: mapInstance.getBounds().getSouthWest().lng(),
      };

      try {
        setIsLoading(true);

        const { zipcodes } = await getZipcodesByBounds(boundsNe, boundsSw);

        setLoadedZipcodes(zipcodes);

        setIsLoading(false);
      } catch (err) {
        console.error(err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, [forceRefetchData]);

  if (mapInstance?.zoom < zoomThresholdToShowZipcodes) {
    return null;
  }

  if (!loadedZipcodes) {
    return null;
  }

  return (
    <>
      {isLoading && (
        <div className={classes.loadingIndicator}>
          <LoadingIndicator />
        </div>
      )}
      <DataFilterControls initPropertyFilters={initPropertyFilters} />
      {loadedZipcodes.map(
        ({
          zipcode, zipcodePolygons,
        }) => {
          // Check if we only want to show highlighted zipcodes
          if (showOnlyHighlightedZipcodes && highlightZipcodes && !highlightZipcodes.includes(zipcode)) return null;

          return (
            <ZipcodePolygon
              key={zipcode}
              zipcode={zipcode}
              zipcodePolygons={zipcodePolygons}
              highlight={highlightZipcodes && highlightZipcodes.includes(zipcode)}
              clickToLoadZipcodeEventCounts={clickToLoadZipcodeEventCounts}
              isDrawingShape={isDrawingShape}
            />
          );
        },
      )}
      {/* Render outside-provided & highlighted event data separately and always show it */}
      {highlightEventData && highlightEventData.length > 0 && (
        <EventMarkers
          eventData={highlightEventData}
          ignoreZoomThresholdCulling
          highlightEventDataApns={highlightEventData}
          showEventDataHoverPopup={highlightEventDataShowHoverPopup}
        />
      )}
    </>
  );
}

export default ZipcodeAndDataOverlay;
