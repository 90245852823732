import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import 'utils/yupExtensions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import { addressYupValidationWithoutLocation, initialAddress } from 'components/AddressAutocomplete/AddressForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import ContactFields from 'components/ContactFields/ContactFields';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { updateCampaignEventOverride } from 'utils/api';
import { nameRegExpGeneral, phoneRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...customCheckboxRadioSwitchStyles(theme),
  warningText: {
    fontSize: '14px',
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  contactFieldsContainer: {
    margin: '20px -16px 0',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grayScale9.main,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',

    '& > svg': {
      marginLeft: '10px',
    },
  },
}));

const EditCampaignEventOverrideModal = ({
  campaignId, eventId, defaultOwnerContactInfo, existingEventOverride, onCancel, updateEventOverride,
}) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        newOwnerContact: existingEventOverride && existingEventOverride.newOwnerContact
          ? ({
            ..._.pick(existingEventOverride.newOwnerContact, ['firstName', 'lastName', 'email', 'phoneNumber']),
            address: {
              ..._.pick(existingEventOverride.newOwnerContact.address, ['address1', 'address2', 'city', 'state', 'zipcode']),
              location: {}, // Include this so AddressForm doesn't complain
            },
          })
          : ({
            ..._.pick(defaultOwnerContactInfo, ['firstName', 'lastName', 'email', 'phoneNumber']),
            address: {
              ..._.pick(defaultOwnerContactInfo.address, ['address1', 'address2', 'city', 'state', 'zipcode']),
              location: {}, // Include this so AddressForm doesn't complain
            },
          }),
        farmingStopped: existingEventOverride ? existingEventOverride.farmingStopped : false,
        notes: existingEventOverride ? existingEventOverride.notes : '',
      }}
      onSubmit={async ({ newOwnerContact, farmingStopped, notes }) => {
        try {
          const { eventOverride: updatedEventOverride } = await updateCampaignEventOverride(campaignId, eventId, {
            ...newOwnerContact,
            address: _.omit(newOwnerContact.address, 'location'), // We don't store location with the address for owner contact infos
          }, farmingStopped, notes);
          updateEventOverride(updatedEventOverride);

          setCurrentAlert('success', 'Record has been updated');

          onCancel();
        } catch (error) {
          if (error.response && error.response.data.error) {
            if (error.response.data.error.includes('must be a valid email')) {
              setCurrentAlert('error', 'Please enter a valid email address');
            } else {
              setCurrentAlert('error', error.response.data.error);
            }
          } else {
            setCurrentAlert('error', 'Something went wrong, please try again.');
          }

          console.error('error', error);
        }
      }}
      validationSchema={Yup.object().shape({
        newOwnerContact: Yup.object().shape({
          firstName: Yup.string().required().matches(nameRegExpGeneral),
          lastName: Yup.string().matches(nameRegExpGeneral), // Not required for owner contact info
          email: Yup.string().email(), // Not required for owner contact info
          phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'), // Not required for owner contact info
          address: addressYupValidationWithoutLocation.required(),
        }).required(),
        farmingStopped: Yup.boolean().required(),
        notes: Yup.string().optional(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal,
              }}
              open
              scroll="body"
              maxWidth="md"
              keepMounted
              onClose={() => onCancel()}
            >
              <DialogTitle
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  color="transparent"
                  onClick={() => onCancel()}
                >
                  <CloseIcon className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Edit Owner Contact Info</h4>
              </DialogTitle>
              <DialogContent
                className={classes.modalBody}
              >
                {isSubmitting ? <LoadingIndicator modal /> : null}
                <h5 className={classes.subtitle}>
                  Farming Status for This Lead
                  <Tooltip
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    title={'If farming is disabled, campaign marketing touches won\'t be sent to this lead'}
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </h5>
                <Field
                  name="farmingStopped"
                >
                  {({ field }) => (
                    <>
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={!field.value}
                            onChange={() => setFieldValue(field.name, false, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                            )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                            )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Enabled"
                      />
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={field.value}
                            onChange={() => setFieldValue(field.name, true, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                            )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                            )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Disabled"
                      />
                    </>
                  )}
                </Field>
                <br />
                <br />
                <div className={classes.contactFieldsContainer}>
                  <ContactFields
                    valueName="newOwnerContact"
                    addressDisplayName="Owner Contact Address"
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    showBirthday={false}
                  />
                </div>
                <br />
                <Field
                  name="notes"
                >
                  {({ field }) => (
                    <CustomInputDashboard
                      labelText="Notes"
                      inputProps={{
                        ...field,
                        multiline: true,
                        rows: 5,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
                <br />
              </DialogContent>
              <DialogActions
                className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                <Button
                  color="dark"
                  block
                  round
                  disabled={!isValid || isSubmitting}
                  onClick={() => setFieldValue('newOwnerContact',
                    {
                      ..._.pick(defaultOwnerContactInfo, ['firstName', 'lastName', 'email', 'phoneNumber']),
                      address: {
                        ...initialAddress,
                        ..._.pick(defaultOwnerContactInfo.address, ['address1', 'address2', 'city', 'state', 'zipcode']),
                      },
                    }, true)}
                >
                  Reset Contact Info
                </Button>
                <Button
                  color="primary"
                  block
                  round
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

EditCampaignEventOverrideModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  defaultOwnerContactInfo: PropTypes.object.isRequired,
  existingEventOverride: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  updateEventOverride: PropTypes.func.isRequired,
};

export default EditCampaignEventOverrideModal;
