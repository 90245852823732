import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

export default ({
  component: Component, restricted, requiredRoles, componentProps, ...rest
}) => {
  const {
    isAuthLoaded, user, LOGIN_ROUTE, DASHBOARD_ROUTE,
  } = useAuthDataContext();

  // Scroll to top when navigating to page for the first time
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isAuthLoaded) {
    return (<LoadingIndicator />);
  }

  return (
    <Route
      {...rest}
      render={(location, ...props) => {
        if (user) {
          // Do they have the required roles?
          if (!requiredRoles || requiredRoles.length === 0 || requiredRoles.includes(user.role)) {
            return (<Component {...componentProps} {...props} />);
          }

          return (
            <Redirect to={DASHBOARD_ROUTE} />
          );
        }

        return (
          <Redirect to={LOGIN_ROUTE} />
        );
      }}
    />
  );
};
