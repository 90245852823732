const style = (theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    background: theme.palette.gray.main,
    '& th': {
      borderTop: 'none',
      borderBottom: 'none',
    },
  },
  tableBody: {
    '& tr:first-child td': {
      borderTop: 'none',
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  tableFooter: {
    background: theme.palette.orange.main,
    '& td:first-child': {
      borderBottomLeftRadius: '8px',
    },
    '& td:last-child': {
      borderBottomRightRadius: '8px',
    },
  },
  tableCell: {
    border: `0.2px solid ${theme.palette.grayScale6.main}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableHeaderCell: {
    padding: '14px 16px',
    color: theme.palette.white.main,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  tableDataCell: {
    padding: '16px 16px',
    color: theme.palette.gray.main,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  },
  tableDataCellBold: {
    fontWeight: 'bold',
  },
  footerCell: {
    padding: '16px 16px',
    color: theme.palette.white.main,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  },
});

export default style;
