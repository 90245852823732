import {
  container,
  boxShadow,
  drawerWidth,
  transition,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    width: '100%',
    zIndex: '1029',
    border: '0',
    transition: 'all 150ms ease 0s',
    display: 'block',

    height: '96px',
    padding: '18px 0 0',
  },
  container: {
    ...container,
    maxHeight: '48px',
  },
  logo: {
    marginTop: '4px',
    height: 'auto',
    maxWidth: '245px',

    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
    },
    [theme.breakpoints.only('sm')]: {
      maxHeight: '64px',
      marginLeft: '12px',
    },
    [theme.breakpoints.only('xs')]: {
      height: '32px',
      marginLeft: '12px',
    },
  },
  list: {
    padding: 0,
    listStyle: 'none',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: '22px',
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  listItem: {
    float: 'left',
    position: 'relative',
    display: 'block',
    padding: 0,

    '&:not(:last-child)': {
      [theme.breakpoints.only('md')]: {
        marginRight: '20px',
      },

      [theme.breakpoints.up('lg')]: {
        marginRight: '32px',
      },
    },
    // '&:hover,&:focus': {
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        boxShadow: `0px -4px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical
      },
    },
    '& span': {
      color: theme.palette.white.main,
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      margin: '4px 0',
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 'auto',
    },
  },
  listItemDropdownHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '& svg': {
      marginLeft: '6px',
    },
    '&:hover,&:focus': {
      background: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: '14px 16px',

      '&:hover': {
        backgroundColor: '#5A5F68',
        borderRadius: '6px 0 0 6px',
      },
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  listItemButton: {
    height: 'auto',
  },
  listItemLeftMargin: {
    marginLeft: '26px',
  },
  navLink: {
    color: theme.palette.white.main,
    borderRadius: '0',
    lineHeight: '18px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    transition: 'box-shadow 0.3s',

    [theme.breakpoints.down('sm')]: {
      padding: '14px 16px',
      width: '100%',

      '&:hover': {
        backgroundColor: '#5A5F68',
        borderRadius: '6px 0 0 6px',
      },
    },
  },
  navLinkUnderlined: {
    '& span': {
      color: theme.palette.primary.main,
      boxShadow: `0px -4px ${theme.palette.primary.main} inset`,
    },

    '&:hover span, &:focus span': {
      color: theme.palette.orange.main,
      boxShadow: `0px -4px ${theme.palette.orange.main} inset`,
    },
  },
  navLinkDrawerButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  listItemNoHoverShadow: {
    '&:hover,&:focus': {
      boxShadow: 'none',
    },
    '& span': {
      color: 'inherit',
    },
  },
  listItemIcon: {
    marginTop: '-3px',
    top: '0px',
    position: 'relative',
    marginRight: '3px',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    color: 'inherit',
    display: 'inline-block',
  },
  listItemText: {
    flex: 'none',
    padding: '0',
    minWidth: '0',
    margin: 0,
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  logInButton: {
    // height: '100%',
    // paddingBottom: '0px',
  },
  getItButton: {
    padding: '14px 16px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  listItemActive: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#5A5F68',
      borderRadius: '6px 0 0 6px',

      '& span': {
        color: theme.palette.primary.main,
      },
    },

    [theme.breakpoints.up('md')]: {
      boxShadow: `0px -4px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical
    },
  },
  dropdownCollapse: {
    zIndex: 100,
    top: '53px',
    // left: '-23px',
    left: '-10px', // TODO: TEMP FOR ALIGNMENT. do above
    position: 'absolute',
    backgroundColor: theme.palette.gray.main,
    borderRadius: '6px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.25)',
    // padding: '20px 23px',
    padding: '12px 0',
  },
  dropdownList: {
    width: '100%',
  },
  dropdownListItem: {
    margin: '8px 26px',
    padding: 0,
    width: 'fit-content',
    '& span': {
      color: theme.palette.white.main,
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:hover span, &:focus span': {
        backgroundColor: '#5A5F68',
        borderRadius: '6px 0 0 6px',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:hover span, &:focus span': {
        // color: theme.palette.orange.main, // TODO: WHITE OR ORANGE???
        boxShadow: `0px -4px ${theme.palette.orange.main} inset`,
      },
    },
  },
  dropdownListItemActive: {
    [theme.breakpoints.down('sm')]: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.up('md')]: {
      '& span': {
        boxShadow: `0px -4px ${theme.palette.orange.main} inset`,
      },
    },
  },

  // MOBILE DRAWER STYLES
  sidebarButtonOpen: {
    '&,&:hover,&:focus': {
      color: theme.palette.primary.main,
    },
  },
  sidebarButtonClose: {
    padding: '20px',

    '&,&:hover,&:focus': {
      color: theme.palette.orange.main,
    },
  },
  drawerPaper: {
    position: 'relative',
    border: 'none',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    // width: drawerWidth,
    // ...boxShadow,
    // display: 'block', // TODO: re-enable
    // top: '0',
    // bottom: '0',
    // right: '0',
    // left: '0',
    // height: '100vh',
    width: '100%',
    // visibility: 'visible',
    // overflowY: 'visible',
    textAlign: 'left',
    paddingRight: 0,
    paddingLeft: 0,
    ...transition,
    background: theme.palette.gray.main,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // TODO: Why all this stuff here???
    // '&:before,&:after': {
    //   position: 'absolute',
    //   zIndex: '3',
    //   width: '100%',
    //   height: '100%',
    //   content: '""',
    //   display: 'block',
    //   top: '0',
    // },
    // '&:after': {
    //   background: theme.palette.gray.main,
    //   // opacity: '.8',
    // },
  },
  drawerHeaderContainer: {
    position: 'sticky',
    top: '0px',
    // left: '0px',
    // right: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.gray.main,
    zIndex: 5,
  },
  drawerLogo: {
    [theme.breakpoints.only('xs')]: {
      margin: '14px 16px',
      maxHeight: '32px',
    },
    [theme.breakpoints.only('sm')]: {
      margin: '22px 26px',
      maxHeight: '64px',
    },
  },
  drawerFooterContainer: {
    position: 'sticky',
    bottom: '0px',
    // left: '0px',
    // right: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.gray.main,
    zIndex: 5,
    padding: '0 40px 30px',
    // margin: '0 0 48px',
  },
  drawerGetItButton: {
    width: '204px',
    marginRight: '40px',
  },
});

export default style;
