import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default () => (
  <SvgIcon style={{ fontSize: 44 }} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#F04528" />
    <path d="M30.7832 13.2175C30.682 13.1168 30.5541 13.047 30.4146 13.0165C30.2751 12.9859 30.1298 12.9958 29.9957 13.045L13.4957 19.045C13.3534 19.099 13.2309 19.195 13.1445 19.3202C13.058 19.4455 13.0117 19.5941 13.0117 19.7463C13.0117 19.8984 13.058 20.047 13.1445 20.1723C13.2309 20.2975 13.3534 20.3935 13.4957 20.4475L20.6957 23.3275L23.5757 30.5275C23.6298 30.6636 23.7226 30.7808 23.8425 30.8648C23.9625 30.9487 24.1044 30.9957 24.2507 31C24.4023 30.9969 24.5494 30.9479 24.6725 30.8596C24.7957 30.7712 24.8892 30.6476 24.9407 30.505L30.9407 14.005C30.9918 13.8723 31.0042 13.7278 30.9763 13.5884C30.9484 13.449 30.8814 13.3203 30.7832 13.2175ZM24.2507 28.15L22.1582 22.9L25.222 19.8363C25.514 19.5442 25.514 19.0708 25.222 18.7788C24.93 18.4867 24.4565 18.4867 24.1645 18.7788L21.0707 21.8725L15.8507 19.75L28.9982 15.0025L24.2507 28.15Z" fill="white" />
  </SvgIcon>
);
