import {
  backdropContainer,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

const style = (theme) => ({
  modalCloseButton: {
    ...modalStyle(theme).modalCloseButton,
    float: 'left',
    marginTop: '0px',
  },
  backdropContainer,
  previewContainer: {
    position: 'relative',
    display: 'block',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    border: '2px solid #FFF',

    height: '85vh',
    width: '85vw',
  },

  previewContainerLetter: {
    // 8.5 in. x 11 in. Add extra to remove scrollbars, etc.
    maxWidth: '8.75in',
    maxHeight: '11.1in',

    overflowY: 'auto',
  },
  previewContainerPostcard: {
    // 11.25 in. x 6.25 in. Add extra to remove scrollbars, etc.
    maxWidth: '11.5in',
    maxHeight: '6.25in',

    overflowY: 'auto',
    overflowX: 'auto',
  },

  previewFrame: {
    overflow: 'hidden',
  },

  previewFrameLetter: {
    height: 'calc(11in + 4px)', // Add 4px for border
    width: 'calc(8.5in + 4px)',
  },
  previewFramePostcard: {
    height: 'calc(6.25in + 4px)', // Add 4px for border
    width: 'calc(11.25in + 4px)',
  },

  timeline: {
    minWidth: '600px',

    '& .MuiTimelineItem-missingOppositeContent:before': {
      // Removes the space on the left
      display: 'none',
    },
    '& .MuiTimelineDot-root': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: 'transparent',
    },
    '& .MuiTimelineConnector-root': {
      width: '1px',
      backgroundColor: theme.palette.black.main,
    },
  },
  timelineIconContainerBlink: {
    animation: '$blinker 1.5s linear infinite',
    backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.2)`,
    borderRadius: '100%',

    // Must be same total # pixels as timelineIconContainer margin
    padding: '9px',
    border: `3px solid ${theme.palette.primary.main}`,

    '& > div': {
      margin: 0,
    },
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },
  timelineIconContainer: {
    padding: '14px',
    margin: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
  },
  touchCardContainer: {
    margin: '24px 0 0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '& > h3': {
      fontFamily: 'Overpass Mono',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.darkGray.main,
    },
    '& > h4': {
      fontFamily: 'Overpass Mono',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      color: theme.palette.gray.main,
      '& > span': {
        color: theme.palette.warning.main,
      },
    },
  },
  touchCardRow: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > h4': {
      color: theme.palette.grayScale11.main,
      font: 'inherit',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',

      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
  },
  touchCardRowLeft: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
    overflow: 'auto',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '32px',

    '& > h4': {
      // width: 0,
      color: theme.palette.grayScale11.main,
      font: 'inherit',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',

      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
    '& > h5': {
      // width: 0,
      margin: '6px 0 0',
      color: theme.palette.grayScale11.main,
      font: 'inherit',
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  touchCardRowRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    margin: '0 26px 0',
    '& > button': {
      marginLeft: '17px',
    },
  },
  touchCardRowDivider: {
    borderBottom: '1px solid #D3D3D3',
    margin: '10px 0 16px',
  },
});

export default style;
