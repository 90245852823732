import imageCompression from 'browser-image-compression';
import capitalize from 'capitalize';
import moment from 'moment';
import numeral from 'numeral';
import { EAgentLandingPageType } from 'types/agent-landing-page';
import { ECampaignStatus } from 'types/campaign';
import { EEventType, IEventType } from 'types/event-type';
import { ETouchType } from 'types/touch-type';
import XRegExp from 'xregexp';

import ContactMailIcon from '@material-ui/icons/ContactMail';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import VoicemailIcon from '@material-ui/icons/Voicemail';

const intercomAppId = 'ykyzek5d';

const supportEmail = 'support@harvist.com';

// Use XRegExp to match all unicode letters (like accented ones too)
const nameRegExp = XRegExp(/^[\p{L}\p{N}&+\-. ]+$/u); // Allow letters, numbers, spaces, '&', '-', '.', and '+'
const nameRegExpGeneral = XRegExp(/^[\p{L}0-9&\-() ]+$/u); // Allows way more characters than normal (used for imported event data names usually)
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const birthdayRegExp = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;
const birthdayNoYearRegExp = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/;
const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const httpsRegex = /^https?:\/\//;

// >= 3K APNs across the brokerage or an individual agent account
const totalNumberOfApnsBeingFarmedForDiscount = 3000;
const regularMonthlyRatePerApn = 125; // $1.25
const discountedMonthlyRatePerApn = 110; // $1.10
const nodMonthlyRatePerApn = 500; // $5.00

// 13400 Ventura Blvd, Sherman Oaks, CA 91423
const corporateOfficeLocation = {
  lat: 34.1467852,
  lng: -118.4250829,
};

const supportPhoneNumber = '18183019800';
const supportPhoneNumberFormatted = '+1 (818) 301-9800';

export const agentAdvicePageUrl = 'a8413098-3cbf-448b-97e9-0ac3cea99a34';

export const minPasswordLength = 8;
export const maxPasswordLength = 16;
export const passwordComplexityOptions = {
  min: 8,
  max: 16,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
  symbol: 1,
  requirementCount: 4,
};
export const passwordValidationChecks = [
  {
    description: 'At least 8 characters',
    errorName: 'passwordComplexity.tooShort',
  },
  {
    description: 'One lowercase character',
    errorName: 'passwordComplexity.lowercase',
  },
  {
    description: 'One uppercase character',
    errorName: 'passwordComplexity.uppercase',
  },
  {
    description: 'One number',
    errorName: 'passwordComplexity.numeric',
  },
  {
    description: 'One symbol',
    errorName: 'passwordComplexity.symbol',
  },
];

export const agentLandingPageTypeEnums = [EAgentLandingPageType.ComparableProperties, EAgentLandingPageType.ContactForm]; // Types exist on both frontend and backend

export const isAgentLandingPageSample = (token: string) => ['sample-comparable-properties', 'sample-contact-form'].includes(token);

export const getAgentLandingPageSampleUrlFromType = (agentLandingPageType: EAgentLandingPageType, eventId?: string) => {
  let token = '';
  if (agentLandingPageType === EAgentLandingPageType.ComparableProperties) {
    token = 'sample-comparable-properties';
  } else if (agentLandingPageType === EAgentLandingPageType.ContactForm) {
    token = 'sample-contact-form';
  }

  let url = `${process.env.REACT_APP_AGENT_LANDING_URL}/?token=${token}`;

  if (eventId) url = `${url}&subject=${eventId}`;

  return url;
};

// Types exist on both frontend and backend
export const voicemailTouchBehaviors = {
  ALL_LEADS: 'Send my voicemail to all leads. None of my leads will receive an email.',
  CONSENTED_LEADS: 'Send my voicemail to leads who have scanned my QR code and an email to all others.',
  NON_CONSENTED_LEADS: 'Send my voicemail to leads who have NOT scanned my QR code and an email to all others.',
  NONE: 'Don\'t send any voicemails. All of my leads will receive an email.',
};

export const enumToReadableName = (s: string) => capitalize.words(s.replaceAll('_', ' '));

const clampNumber = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const numberWithCommas = (num: number | string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// 850000 -> $850K, 1234000 -> $1.23M
const numberAsAbbreviatedPrice = (num: number) => numeral(num).format(num >= 1000000 ? '$0.00a' : '$0a').toUpperCase();

export const urlWithoutScheme = (url: string) => url.replace(httpsRegex, '');

const compressImageUpload = async (imageFile: File) => {
  try {
    // TODO: KINDA SUCKS???
    const compressedFile = await imageCompression(imageFile, { maxSizeMB: 1, maxWidthOrHeight: 2048 });

    return await imageCompression.getDataUrlFromFile(compressedFile);
  } catch (err) {
    console.error(err);
  }

  return null;
};

const isCrmProEventType = (eventType: IEventType) => eventType.name === EEventType.CRMPro;
const isNODEventType = (eventType: IEventType) => eventType.name === EEventType.NOD;
const isABSEventType = (eventType: any) => eventType.name === EEventType.AbsenteeOwners;
const isCommercialEventType = (eventType: IEventType) => eventType.name === EEventType.Commercial;
const isFullFarmEventType = (eventType: any) => eventType.name === EEventType.FullFarm;

const filterEventType = (eventTypes: IEventType[], typeChecker: (eventType: IEventType)=> boolean) => {
  const filtered = eventTypes.filter((e) => typeChecker(e));
  return filtered.length === 1 ? filtered[0] : null;
};

/**
 * Converts the event type name to a URL path
 * E.g. 'Absentee Owners' -> 'absentee-owners'
 * @param {string} eventTypeName - Event type name
 */
const eventTypeNameToUrlPath = (eventTypeName: EEventType) => eventTypeName.toLowerCase().replaceAll(' ', '-');

const stringifyAddressObject = (address: any) => {
  if (!address) return '';

  const {
    address1, address2, city, state, zipcode,
  } = address;

  return `${address1}${address2 ? ` ${address2}` : ''}, ${city}, ${state} ${zipcode}`;
};

const stringifyEventSiteAddressObject = (siteAddress: any) => {
  if (!siteAddress) return '';

  const {
    address, city, state, zipcode,
  } = siteAddress;

  return `${address}, ${city}, ${state} ${zipcode}`;
};

const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

const isCampaignDraft = (campaignStatus: ECampaignStatus) => campaignStatus === ECampaignStatus.Draft;
const isCampaignPending = (campaignStatus: ECampaignStatus) => [ECampaignStatus.PendingDocusign, ECampaignStatus.PendingData, ECampaignStatus.PendingBilling].includes(campaignStatus);
const isCampaignActiveOrCompleted = (campaignStatus: ECampaignStatus) => [ECampaignStatus.Active, ECampaignStatus.Completed].includes(campaignStatus);
const isCampaignPaused = (campaignStatus: ECampaignStatus) => campaignStatus === ECampaignStatus.Paused;
const isCampaignCancelled = (campaignStatus: ECampaignStatus) => campaignStatus === ECampaignStatus.Cancelled;

const calculateCampaignDates = (eventType: IEventType) => {
  const { campaignLengthInMonths } = eventType;

  // Calculate the campaign start and end date. Start date is the nearest 1st or 15th of the month,
  // with a buffer of at least some # of days.
  const minimumNumDaysBeforeCampaignStarts = 8;
  const currentDateWithBuffer = moment().utc().add(minimumNumDaysBeforeCampaignStarts, 'days');
  let campaignStartDate;

  if (currentDateWithBuffer.date() >= 15) {
    campaignStartDate = moment(currentDateWithBuffer).utc().add(1, 'M').date(1)
      .hour(20); // ~ 12 PM PST which is 20 UTC
  } else {
    campaignStartDate = moment(currentDateWithBuffer).utc().date(15).hour(20); // ~ 12 PM PST which is 20 UTC
  }

  const campaignEndDate = moment(campaignStartDate).add(campaignLengthInMonths, 'M');

  return { campaignStartDate, campaignEndDate };
};

// TODO: ADD SIMPLE PLAN HERE AND TO BACKEND??? NOD ONLY CHECK!!!
const calculateCampaignPrices = (
  eventType: IEventType,
  numberOfApns: number,
  numberOfExtraRecipients: number,
  numApnsAlreadyBeingFarmed: number,
  applyDiscountedAPNRate: boolean,
  // TODO: MAKE TYPE TOO???
  // simpleFlowSelectedPlan,
) => {
  let discountedMonthlyRate = false;
  let monthlyRatePerApn = regularMonthlyRatePerApn;

  if (eventType.name === 'NOD') {
    // Different rate for NODs and no discount offered
    monthlyRatePerApn = nodMonthlyRatePerApn;
  } else if (numApnsAlreadyBeingFarmed + numberOfApns >= totalNumberOfApnsBeingFarmedForDiscount || applyDiscountedAPNRate) {
    // Discounted rate if the brokerage or individual agent farms a minimum number of APNs
    discountedMonthlyRate = true;
    monthlyRatePerApn = discountedMonthlyRatePerApn;
  }

  const apnsMonthlyCost = Math.ceil(monthlyRatePerApn * numberOfApns);
  const extraRecipientsMonthlyCost = Math.ceil(monthlyRatePerApn * numberOfExtraRecipients);
  const totalMonthlyCost = apnsMonthlyCost + extraRecipientsMonthlyCost;

  return {
    discountedMonthlyRate,
    monthlyRatePerApn,
    apnsMonthlyCost,
    extraRecipientsMonthlyCost,
    totalMonthlyCost,
  };
};

// TODO: Why do we need this? Kinda messy. Can't we just check this on the fly and cache it when we need it or something?
const populateCampaignEventDataEventOverrideData = (campaigns: any[]) => {
  campaigns.forEach((campaign) => {
    campaign.subscribedEvents.forEach((eventData: any) => {
      const { _id, siteAddress: { zipcode } } = eventData;

      const [existingEventOverride] = campaign.eventOverrides.filter((o: any) => o.event === _id);

      if (existingEventOverride) {
        // eslint-disable-next-line no-param-reassign
        eventData.existingEventOverride = existingEventOverride;
      }
    });
  });
};

export const isCampaignUsingCalendarBasedSystem = (eventTypeName: EEventType, campaignCreatedDate: Date) => {
  const dateCalendarSystemLaunched = moment('2023-07-18T22:12:41.922Z');
  return moment(campaignCreatedDate).isAfter(dateCalendarSystemLaunched) && ['Absentee Owners', 'Commercial', 'Full Farm'].includes(eventTypeName);
};

export const sortTouchesForCampaignSteps = (campaign: any) => {
  const { touchTriggers } = campaign;

  // No need to do extra sorting for non-draft and non-pending campaigns
  if (!['DRAFT', 'PENDING_DATA', 'PENDING_BILLING'].includes(campaign.status)) {
    return touchTriggers;
  }

  if (!isCampaignUsingCalendarBasedSystem(campaign.eventType.name, campaign.createdAt)) {
    return touchTriggers;
  }

  // Touches are sorted by sendAt date when fetched, but need to sort it again for draft campaigns

  // First touch shown should always be the intro email touch
  const introEmailTouch = (campaign.touchTriggers as any[]).find((o) => o.description.includes('Email #0'));
  const introEmailTouchIndex = touchTriggers.indexOf(introEmailTouch);

  // We also have to calculate the estimated campaign start date and show the real touches on and after that date
  const { campaignStartDate } = calculateCampaignDates(campaign.eventType);

  const touchesWithoutIntroEmail = (campaign.touchTriggers as any[]).filter((o, i) => i !== introEmailTouchIndex);

  // For calendar-based campaigns, show the next outgoing touch at the top
  const campaignStartDateDOY = campaignStartDate.dayOfYear();

  // Set the year as the campaign start date's year before comparing the day of year. Edge
  // cases like leap years can mess this up!
  const firstTouch = touchesWithoutIntroEmail.reduce((previous: any, touchTrigger: any) => {
    const previousDOY = moment(previous.sendAt).year(campaignStartDate.year()).dayOfYear();
    const thisDOY = moment(touchTrigger.sendAt).year(campaignStartDate.year()).dayOfYear();

    if (thisDOY >= campaignStartDateDOY && thisDOY - campaignStartDateDOY < previousDOY - campaignStartDateDOY) return touchTrigger;

    return previous;
  }, touchesWithoutIntroEmail.find((touchTrigger: any) => moment(touchTrigger.sendAt).year(campaignStartDate.year()).dayOfYear() >= campaignStartDateDOY));

  const firstTouchIndex = touchesWithoutIntroEmail.indexOf(firstTouch);

  // Insert intro email, then touches on or after the campaign start date
  return [
    // Intro email touch may not exist for calendar-based campaign drafts created before this update
    ...introEmailTouch ? [introEmailTouch] : [],

    ...touchesWithoutIntroEmail.slice(firstTouchIndex),
    ...touchesWithoutIntroEmail.slice(0, firstTouchIndex),
  ];
};

// TODO: REMOVE???????
/* const getInitialVoicemailRecordings = (eventType, userInfo) => {
  let initialVoicemailRecordings;

  // Make sure there's an object in the array for each voicemail of this event type. The order of the voicemails array
  // should match the order of the voicemailTexts array in the event type!
  const eventVoicemails = userInfo.agentInfo.voicemailRecordings?.filter((o) => o.eventType === eventType._id);
  if (eventVoicemails && eventVoicemails.length > 0) {
    [initialVoicemailRecordings] = eventVoicemails;

    while (eventVoicemails[0].voicemails.length < eventType.voicemailTexts.length) {
      // We might add more voicemail texts to the event type, so make sure to initialize any
      // missing voicemail recordings as empty strings here
      eventVoicemails[0].voicemails.push('');
    }
  } else {
    initialVoicemailRecordings = {
      eventType: eventType._id,
      voicemails: eventType.voicemailTexts.map(() => ''), // Initialize with empty strings
    };
  }

  return initialVoicemailRecordings;
}; */

const getIconForTouchType = (touchType: ETouchType) => {
  switch (touchType) {
    case ETouchType.Postcard:
      return ContactMailIcon;
    case ETouchType.Letter:
      return DescriptionIcon;
    case ETouchType.Email:
      return EmailIcon;
    case ETouchType.Text:
      return SmsIcon;
    case ETouchType.Voicemail:
      return VoicemailIcon;
    default:
      return null;
  }
};

const getColorForTouchType = (touchType: ETouchType) => {
  switch (touchType) {
    case ETouchType.Postcard:
      return '#F24E1D';
    case ETouchType.Letter:
      return '#A259FF';
    case ETouchType.Email:
      return '#09A366';
    case ETouchType.Text:
      return '#000000';
    case ETouchType.Voicemail:
      return '#018EDE';
    default:
      return null;
  }
};

const getEventDataOwnerInformation = (existingEventOverride: any, eventData: any) => {
  const { ownerContact } = eventData;

  let name;
  let phoneNumber;
  let email;
  let ownerAddress;
  if (existingEventOverride && existingEventOverride.newOwnerContact) {
    name = `${existingEventOverride.newOwnerContact.firstName || ''} ${existingEventOverride.newOwnerContact.lastName || ''}`; // || check here to prevent 'undefined' being concatenated
    phoneNumber = formatPhoneNumber(existingEventOverride.newOwnerContact.phoneNumber);
    email = existingEventOverride.newOwnerContact.email;
    ownerAddress = stringifyAddressObject(existingEventOverride.newOwnerContact.address);
  } else {
    name = `${ownerContact.firstName || ''} ${ownerContact.lastName || ''}`; // || check here to prevent 'undefined' being concatenated
    phoneNumber = formatPhoneNumber(ownerContact.phoneNumber); // Format these since they aren't formatted already when imported
    email = ownerContact.email;
    ownerAddress = stringifyAddressObject(ownerContact.address);
  }

  return {
    name,
    phoneNumber,
    email,
    ownerAddress,
    existingEventOverride,
    alternateEmails: ownerContact.alternateEmails,
    alternatePhoneNumbers: (ownerContact.alternatePhoneNumbers ?? []).map((s: string) => formatPhoneNumber(s)),
  };
};

const showAPIErrorAlert = (setCurrentAlert: any, error: any) => {
  if (error.response && error.response.data.error) {
    setCurrentAlert('error', error.response.data.error);
  } else {
    setCurrentAlert('error', 'Something went wrong, please try again.');
  }
};

// Check all required values, one from each section
const isMarketingProfileDirty = (agentInfo: any) => agentInfo && (agentInfo.agentPictureUrl || agentInfo.agentDreNumber || agentInfo.brokerageDreNumber);

// Check all required values, one from each section on the frontend. If one value in a section is written,
// then that means all required fields were written too.
// NOTE: Update on both frontend and backend if changes are made here!
const isMarketingProfileCompleted = (agentInfo: any) => agentInfo && agentInfo.agentPictureUrl && agentInfo.agentDreNumber && agentInfo.brokerageDreNumber;

const openFileInNewWindow = (file: any, setCurrentAlert: any) => {
  const newWindow = window.open(file, '_blank');

  // Check for blocked pop-ups
  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    setCurrentAlert('warning', 'It looks like our pop-up was blocked. Please allow pop-ups in your browser for Harvist.');
  }
};

const checkNextTouchIsVMWithUnassignedRecording = (touchTriggers: any[]) => {
  const nextTouchToGoOut = touchTriggers.reduce((nextTouch, current) => (!nextTouch && !current.processed ? current : nextTouch), null);
  return nextTouchToGoOut && nextTouchToGoOut.touchType.name === 'VOICEMAIL' && !nextTouchToGoOut.metadata.voicemail;
};

const getNumDaysAutoRenewalCampaignStartsIn = (createdAtDate: Date) => moment(createdAtDate).add(30, 'days').diff(moment(), 'days') + 1; // Add 1 to include current day

const transformObjectKeys = (obj: any, transformFn: (key: any)=> any) => {
  const transformedObject = {} as any;

  Object.keys(obj).forEach((key) => {
    transformedObject[transformFn(key)] = obj[key];
  });

  return transformedObject;
};

const tableFilterPhoneNumbers = (rows: any[], id: string, filterValue: string) => rows.filter((row) => {
  const stripFormatting = (phone: string) => phone.replace(/\D/g, '');

  const formattedPhoneNumbers = row.values[id];
  const rawPhoneNumbers = formattedPhoneNumbers.map((s: string) => stripFormatting(s));
  const filterValueStripped = stripFormatting(filterValue);

  return rawPhoneNumbers.find((s: string) => s.includes(filterValueStripped));
});

export {
  // getInitialVoicemailRecordings,
  intercomAppId, supportEmail, nameRegExp, nameRegExpGeneral, phoneRegExp, birthdayRegExp, birthdayNoYearRegExp, urlRegExp, corporateOfficeLocation,
  compressImageUpload, stringifyAddressObject, stringifyEventSiteAddressObject, formatPhoneNumber,
  numberWithCommas, clampNumber, getIconForTouchType, getEventDataOwnerInformation,
  calculateCampaignDates, calculateCampaignPrices, supportPhoneNumber, supportPhoneNumberFormatted, numberAsAbbreviatedPrice,
  isABSEventType, isNODEventType, isCommercialEventType, isFullFarmEventType, isCrmProEventType, filterEventType, eventTypeNameToUrlPath,
  showAPIErrorAlert, populateCampaignEventDataEventOverrideData, getColorForTouchType, isCampaignDraft, isCampaignActiveOrCompleted, isCampaignPending, isCampaignCancelled,
  isMarketingProfileDirty, isMarketingProfileCompleted, openFileInNewWindow, checkNextTouchIsVMWithUnassignedRecording, isCampaignPaused, getNumDaysAutoRenewalCampaignStartsIn,
  transformObjectKeys, tableFilterPhoneNumbers,
};
