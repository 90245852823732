import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const style = {
  hoverContainer: {
    marginLeft: '20px',
    padding: '0 4px',
    backgroundColor: '#FFF',
    border: '2px solid #FFF',
    borderRadius: '4px',
    zIndex: 100,
  },
  hoverSiteAddress: {
    margin: 0, padding: 0, fontWeight: 600,
  },
  hoverSiteApn: {
    margin: 0,
    padding: 0,
  },
  hoverOwnerHeading: {
    margin: 0,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  hoverOwnerInfoSpan: {
    margin: '6px 0',
    display: 'inline-flex',
    alignItems: 'center',
  },
  hoverOwnerInfoIcon: {
    marginRight: '10px',
  },
  hoverOwnerInfoText: {
    margin: 0,
  },
};

export default style;
