import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    background: theme.palette.grayScale7.main,
  },
  container: {
    ...container,
    position: 'relative',
  },

  biographySection: {
    margin: '-235px 0 113px',
  },
  biographyCard: {
    padding: '70px 80px !important',
    background: theme.palette.white.main,
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',
    borderRadius: '8px',

    [theme.breakpoints.down('sm')]: {
      padding: '35px 40px !important',
    },

    '& > h1': {
      color: theme.palette.black.main,
      fontSize: '36px',
      lineHeight: '42px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '36px',
        lineHeight: '42px',
      },
    },
    '& > p': {
      margin: '40px 0 0',
      color: theme.palette.black.main,
      fontSize: '24px',
      lineHeight: '150%',
      whiteSpace: 'pre-line',

      [theme.breakpoints.down('sm')]: {
        margin: '20px 0 0',
        fontSize: '18px',
      },
    },
  },
});

export default style;
