import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { enumToReadableName } from 'utils/lib';

const useStyles = makeStyles((theme) => ({

}));

export default function BrokerageAdminsTable({ brokerage }) {
  const classes = useStyles();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!brokerage) return;

      const allUsers = brokerage.admins;

      // Sort by createdAt desc
      allUsers.sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1));

      setData(allUsers.map((user) => ({
        _id: user._id,
        name: `${user.firstName} ${user.lastName ?? ''}`,
        email: user.email,
        status: enumToReadableName(user.status),
        createdAt: moment(user.createdAt).toDate(),
      })));
    })();
  }, [brokerage]);

  if (!brokerage) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <ReactTable
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
            sortType: 'datetime',
          },
        ]}
        data={data}
        normalRightColumn
      />
    </>
  );
}
