import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Slide } from '@material-ui/core';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import berkshireHathaway from 'assets/img/brokerage-logos/Berkshire Hathaway.png';
import century21 from 'assets/img/brokerage-logos/Century 21.png';
import coldwellBanker from 'assets/img/brokerage-logos/Coldwell Banker.png';
import compass from 'assets/img/brokerage-logos/Compass.png';
import kellerWilliams from 'assets/img/brokerage-logos/Keller Williams.png';
import remax from 'assets/img/brokerage-logos/REMAX.png';
import rodeoRealty from 'assets/img/brokerage-logos/Rodeo Realty.png';
import sothebys from 'assets/img/brokerage-logos/Sothebys.png';
import equityUnion from 'assets/img/brokerage-logos/Equity Union.png';

const brokerages = [
  { name: 'Berkshire Hathaway', image: berkshireHathaway },
  { name: 'Century 21', image: century21 },
  { name: 'Coldwell Banker', image: coldwellBanker },
  { name: 'Compass', image: compass },
  { name: 'Keller Williams', image: kellerWilliams },
  { name: 'REMAX', image: remax },
  { name: 'Rodeo Realty', image: rodeoRealty },
  { name: 'Sothebys', image: sothebys },
  { name: 'Equity Union', image: equityUnion },
];

const useStyles = makeStyles((theme) => ({
  description: {
    margin: '0 0 31px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grayScale9.main,
  },
  image: {
    padding: '50px',
    margin: 'auto',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    maxHeight: '200px',
  },
}));

const BrokerageLogosModal = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="lg"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Brokerage Logos" />

      <div className={classes.description}>Click on a logo to download.</div>
      <GridContainer alignItems="center">
        {brokerages.map(({ name, image }) => (
          <GridItem xs={12} md={6} lg={4} key={name}>
            <a download={name} href={image} title={name}>
              <img src={image} className={classes.image} alt={name} />
            </a>
          </GridItem>
        ))}
      </GridContainer>
    </Dialog>
  );
};

export default BrokerageLogosModal;
