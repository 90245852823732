import PropTypes from 'prop-types';
import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  grid: {
    // margin: '0 -16px', // NOTE for redesign: why use 15px negative side margin here?
    // width: 'calc(100% + 32px)',
    // '&:before,&:after': {
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after': {
    //   clear: 'both',
    // },

    [theme.breakpoints.only('xs')]: {
      // TODO: Disabled to fix company address on marketing profile page having extra grid container margin.
      //       Make sure it doesn't cause other issues on mobile!
      // width: 'calc(100% - 32px)', // 16px margins
      // margin: '0 16px', // 16px margins

      // width: '100vw',

      // paddingLeft: '16px !important',
      // paddingRight: '16px !important',
      // marginLeft: '16px !important',
      // marginRight: '16px !important',
    },
  },
});

const useStyles = makeStyles(styles);

export default function GridContainer(props: GridProps) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <Grid container {...rest} className={className ? `${classes.grid} ${className}` : classes.grid}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
