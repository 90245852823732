import { saveAs } from 'file-saver';
import React from 'react';

import { Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { createCustomTouchTemplate, getTouchPreview } from 'utils/api';
import { openFileInNewWindow, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  instructions: {
    color: theme.palette.darkGray.main,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    margin: '30px 0 20px',

    '& > span': {
      color: theme.palette.lightGray.main,
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: '30px',

    '& > div': {
      marginTop: '10px', // For better rendering when wrapping
    },
    '& > div > button:first-child': {
      marginRight: '20px',
    },
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    margin: '30px 0 40px',

    '& > span': {
      marginRight: '10px',
    },
    '& > svg': {
      marginLeft: '10px',
    },
  },
  confirmResetButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  viewUploadedDesignButton: {
    float: 'right',
  },
}));

const ReplaceDesign = ({
  onClose, touchTrigger, onReceiveEditedTouchTrigger, setIsConfirmingReset,
}) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const { customTouchTemplate, touchType: { name: touchTypeName } } = touchTrigger;

  const sampleFileNames = [];
  if (touchTypeName === 'POSTCARD') {
    sampleFileNames.push('sample-postcard-6x9.pdf');
    sampleFileNames.push('postcard-guide-6x9.pdf');
  } else if (touchTypeName === 'LETTER') {
    sampleFileNames.push('sample-letter.pdf');
    sampleFileNames.push('letter-guide.pdf');
  }

  const fileInput = React.createRef();
  const [useQRCodeOnUploadedPdf, setUseQRCodeOnUploadedPdf] = React.useState(customTouchTemplate && customTouchTemplate.type === 'ENTIRE_DESIGN' ? customTouchTemplate.extraMergeVariables.useQRCodeOnUploadedPdf : true);

  const onDownloadTemplateSample = () => {
    sampleFileNames.forEach((fileName) => saveAs(`/${fileName}`, fileName));
  };

  const onUploadPdf = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) {
      setCurrentAlert('warning', 'There was an issue uploading your PDF, please try again!');
      return;
    }

    if (file.size > 15 * 1024 * 1024) { // 15 MB
      setCurrentAlert('warning', 'File size should not exceed 15 MB!');
      return;
    }

    // Show a loading indicator during the upload
    showLoadingIndicatorModal();

    try {
      const { touchTrigger: updatedTouchTrigger } = await createCustomTouchTemplate(touchTrigger._id, file, undefined, useQRCodeOnUploadedPdf, undefined);

      // Callback to update the touch trigger locally
      onReceiveEditedTouchTrigger(updatedTouchTrigger);

      onClose();

      setCurrentAlert('success', 'Your custom design has been uploaded and will be used when sending out this touch! Please preview your touch to make sure everything looks great!');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onPreviewReplacedDesign = async () => {
    try {
      showLoadingIndicatorModal();

      const { pdf } = await getTouchPreview(touchTrigger._id);

      openFileInNewWindow(pdf, setCurrentAlert);
    } catch (err) {
      console.error('error', err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  return (
    <>
      <div className={classes.instructions}>
        {`Have your own awesome marketing materials? Upload a PDF of your design and we will send out your ${touchTypeName.toLowerCase()} instead of ours!`}
        <br />
        <br />
        <span>
          Things to Know
          <ul>
            <li>
              {touchTypeName === 'POSTCARD' ? 'PDF must be 9.25” x 6.25” and match the dimensions of the sample template.' : 'PDF must be 8.5" x 11" and match the dimensions of the sample template.'}
            </li>
            {touchTypeName === 'POSTCARD' && (
              <li>
                Postage indicia (stamps, address box) is printed on the bottom right section of the back of the postcard (second page of the PDF). Please refer to the sample template for specific dimensions.
              </li>
            )}
            {touchTypeName === 'LETTER' && (
              <li>
                Make sure there is a space on the top for the mailing address to be printed, similar to the sample.
              </li>
            )}
            <li>
              Your
              {touchTypeName === 'POSTCARD' ? ' postcard ' : ' letter '}
              will be printed as-is. We cannot merge any data (site-address, recipient information, etc) onto your file.
            </li>
            {touchTypeName === 'POSTCARD' && (
              <li>
                Check the check-box below to have our QR Code added to your postcard.
              </li>
            )}
          </ul>
          After uploading your custom
          {touchTypeName === 'POSTCARD' ? ' postcard' : ' letter'}
          , make sure to preview your touch by clicking the eye icon!
        </span>

        <div className={classes.checkbox}>
          <Checkbox
            color="primary"
            checked={useQRCodeOnUploadedPdf}
            tabIndex={-1}
            onClick={() => setUseQRCodeOnUploadedPdf(!useQRCodeOnUploadedPdf)}
          />
          {`Use Agent Landing Page QR Code on my custom ${touchTypeName.toLowerCase()}`}
          <Tooltip
            placement="top"
            disableFocusListener
            disableTouchListener
            title={touchTypeName === 'POSTCARD'
              ? 'The QR code will be on the back (second) page of your uploaded postcard. It is 80px by 80px and will be 30 pixels from the top and right edge of the page. Refer to the Sample Templates for an example.'
              : 'The QR code will be on the bottom right of your uploaded letter. It is 80px by 80px and will be 30 pixels from the bottom and 20 pixels from the right edge of the page. Refer to the Sample Templates for an example.'}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </div>

        <Button
          color="primary"
          simple
          onClick={onDownloadTemplateSample}
          className={classes.viewSamplesButton}
        >
          <FileCopyIcon />
          View Sample Templates
        </Button>

        {customTouchTemplate && customTouchTemplate.type === 'ENTIRE_DESIGN' && (
          <Button
            color="orange"
            simple
            onClick={onPreviewReplacedDesign}
            className={classes.viewUploadedDesignButton}
          >
            <VisibilityRoundedIcon />
            View My Uploaded Design
          </Button>
        )}
      </div>

      <div className={classes.actions}>
        <div>
          {customTouchTemplate && (
            <Button
              color="white"
              round
              onClick={() => setIsConfirmingReset(true)}
            >
              Reset
            </Button>
          )}

          <input
            type="file"
            accept="application/pdf"
            onChange={onUploadPdf}
            ref={fileInput}
            style={{ display: 'none' }}
          />
          <Button
            color="primary"
            round
            onClick={() => fileInput.current.click()}
          >
            <AttachFileIcon />
            Upload PDF
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReplaceDesign;
