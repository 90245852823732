import { BorderRight } from '@material-ui/icons';

const style = (theme) => ({
  mapContainer: {
    [theme.breakpoints.down('sm')]: {
      // TODO: MOBILE sizes

    },
    [theme.breakpoints.up('md')]: {
      height: '389px',
    },
  },
  similarHomeContainer: {
    marginTop: '-56px', // Height of the container
    padding: '8px 10px',
    textAlign: 'center',
    background: theme.palette.orange.main,
    borderRadius: '4px',
    position: 'relative',

    '& > p': {
      color: theme.palette.white.main,
      fontSize: '16px',
      lineHeight: '18px',

      '& > span': {
        display: 'block',
        fontSize: '20px',
        lineHeight: '22px',
        fontWeight: 'bold',
      },
    },
  },
  similarHomeCaret: {
    position: 'absolute',
    left: 'calc(50% - 8px)',
    bottom: '-7px',
    width: 0,
    height: 0,
    borderRadius: '4px',
    borderTop: `8px solid ${theme.palette.orange.main}`,
    borderRight: '8px solid transparent',
    borderLeft: '8px solid transparent',
  },
});

export default style;
