import { useFormikContext } from 'formik';
import Papa from 'papaparse';
import React from 'react';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import TrashIcon from 'components/CustomIcons/TrashIcon';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { checkCampaignAddOnProperties } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '800px',
  },
  title: {
    margin: '30px 0 6px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.gray.main,
    fontWeight: 500,
  },
  container: {
    marginTop: '30px',
    display: 'inline-block',
    '& > span': {
      display: 'inline-block',
      marginLeft: '50px',
      fontWeight: 500,
      color: theme.palette.darkGray.main,
      '& > button': {
        marginLeft: '10px',
      },
    },
  },
}));

const exampleCsvFileName = 'campaign-add-on-properties-example.csv';

const AddOnPropertiesInput = ({
  apnAvailableCounts, setApnAvailableCounts, apnSelectedCounts, setApnSelectedCounts, setApnsTotalSelectedCount, apnsTotalInAreaCount, setApnsTotalInAreaCount,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const {
    values, touched, errors, setFieldValue, setTouched, isValid,
  } = useFormikContext();

  const checkAndSetAddOnProperties = async (propertyData, showSuccessAlert) => {
    showLoadingIndicatorModal();

    try {
      const addOnPropertiesJsonString = JSON.stringify(propertyData);

      const { nonOverlappingAddOnPropertyData } = await checkCampaignAddOnProperties(addOnPropertiesJsonString);

      setFieldValue('addOnPropertiesJsonString', JSON.stringify(nonOverlappingAddOnPropertyData), true);

      const newApnAvailableCounts = [
        ...apnAvailableCounts.filter(({ name }) => name !== 'Add On Properties'),
        {
          name: 'Add On Properties',
          count: nonOverlappingAddOnPropertyData.length,
        },
      ];

      const newApnSelectedCounts = [
        ...apnSelectedCounts.filter(({ name }) => name !== 'Add On Properties'),
        {
          name: 'Add On Properties',
          count: nonOverlappingAddOnPropertyData.length,
        },
      ];

      setApnAvailableCounts(newApnAvailableCounts);
      setApnSelectedCounts(newApnSelectedCounts);
      setApnsTotalSelectedCount(newApnSelectedCounts.reduce((sum, { count }) => count + sum, 0));
      setApnsTotalInAreaCount(apnsTotalInAreaCount + nonOverlappingAddOnPropertyData.length);

      if (propertyData.length !== nonOverlappingAddOnPropertyData.length) {
        setCurrentAlert('warning', `We found ${propertyData.length} extra properties in your uploaded file, but only ${nonOverlappingAddOnPropertyData.length} 
          are available because ${propertyData.length - nonOverlappingAddOnPropertyData.length} are being actively farmed by other agents.`, 15000);
      } else if (showSuccessAlert) {
        setCurrentAlert('success', `We found ${propertyData.length} extra properties in your uploaded file.`, 15000);
      }
    } catch (err) {
      console.error('error', err);
      if (err.response?.data?.error?.includes('ValidationError')) {
        setCurrentAlert('error', 'Please make sure your CSV follows the sample format.');
      } else {
        showAPIErrorAlert(setCurrentAlert, err);
      }
    }

    hideLoadingIndicatorModal();
  };

  // If a draft is being loaded, set the apn available counts based off any existing addOnPropertiesJsonString
  React.useEffect(() => {
    (async () => {
      if (!values.addOnPropertiesJsonString) return;

      const propertyData = JSON.parse(values.addOnPropertiesJsonString);

      await checkAndSetAddOnProperties(propertyData, false);
    })();
  }, []);

  const addOnPropertiesCountArr = apnSelectedCounts.filter(({ name }) => name === 'Add On Properties');

  const fileInput = React.createRef();

  const handleCsvChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    // Show a loading indicator during the upload
    showLoadingIndicatorModal();

    // Count the # of properties in the CSV
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      async complete(results) {
        if (results.errors.length > 0) {
          console.error(results.errors);
          setCurrentAlert('error', 'There was an error reading your CSV file.');
          hideLoadingIndicatorModal();
          return;
        }

        // Make sure CSV file wasn't empty and validate headers (just check the first data row)
        if (results.data.length === 0 || !results.data[0]['Parcel Number'] || !results.data[0]['Zip Code']) {
          setCurrentAlert('error', 'Please make sure your CSV file follows the example format.');
          hideLoadingIndicatorModal();
          return;
        }

        const propertyData = results.data.map((row) => ([row['Parcel Number'], row['Zip Code']]));

        await checkAndSetAddOnProperties(propertyData, true);
      },
    });
  };

  const handleRemove = async () => {
    fileInput.current.value = null; // Allows the same file to be selected again
    setFieldValue('addOnPropertiesJsonString', null, true);

    const newApnAvailableCounts = apnAvailableCounts.filter(({ name }) => name !== 'Add On Properties');
    const newApnSelectedCounts = apnSelectedCounts.filter(({ name }) => name !== 'Add On Properties');

    setApnAvailableCounts(newApnAvailableCounts);
    setApnSelectedCounts(newApnSelectedCounts);
    setApnsTotalSelectedCount(newApnSelectedCounts.reduce((sum, { count }) => count + sum, 0));
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>
        Add extra properties to your farm area by uploading a CSV file of parcel numbers
      </h4>
      <h5 className={classes.subTitle}>
        We will verify the parcel numbers and check for duplicates in your selected farm area when starting your campaign. If any duplicates are found, your actual number of APNs will be lower than estimated. Your file must follow the
        <strong><a download target="_blank" rel="noreferrer" href={`/${exampleCsvFileName}`} style={{ color: theme.palette.primary.main }}> example format </a></strong>
        and all properties must be in the same state.
      </h5>
      <input
        type="file"
        accept="text/csv"
        onChange={handleCsvChange}
        ref={fileInput}
        style={{ display: 'none' }}
      />
      <div className={classes.container}>
        <Button
          round
          color="orange"
          onClick={() => fileInput.current.click()}
        >
          <InsertDriveFileIcon />
          {' '}
          Upload CSV File
        </Button>
        {addOnPropertiesCountArr.length > 0 && (
          <span>
            {`${addOnPropertiesCountArr[0].count} Extra Properties`}
            <Tooltip
              title="Remove"
              placement="top"
            >
              <ButtonBase onClick={handleRemove}>
                <TrashIcon color={theme.palette.error.main} />
              </ButtonBase>
            </Tooltip>
          </span>
        )}
      </div>
    </div>
  );
};

export default AddOnPropertiesInput;
