import React from 'react';
import cx from 'classnames';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, Slide, ButtonBase, Checkbox, Hidden,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';

import { submitAgentLandingAssessmentForm } from 'utils/api';
import { isAgentLandingPageSample, phoneRegExp } from 'utils/lib';

import assessmentFormBgImage from 'assets/img/agent-landing/home-estimation/assessment-form-bg.png';
import { hexToRgb, modalCloseButton } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
  modalCloseButton: {
    ...modalCloseButton,
    background: theme.palette.offWhite.main,
    borderRadius: '100%',
    top: '12px',
    right: '12px',
    padding: '8px',
  },
  root: {
    backgroundColor: theme.palette.offWhite.main,
    position: 'relative',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: '46%',
    right: 0,
    bottom: 0,
    background: `url(${assessmentFormBgImage})`,
    borderRadius: '16px',

    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  title: {
    fontSize: '34px',
    lineHeight: '40px',
    color: theme.palette.black.main,
    margin: '0 0 8px',
  },
  subtitle: {
    fontSize: '24px',
    lineHeight: '30px',
    color: theme.palette.black.main,
    margin: '0 0 40px',
  },
  button: {
    margin: '32px 0 0',
    color: 'black',
    width: '100%',
  },

  interestsContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '0 90px 0 0 !important',
    },
  },
  formContainerParent: {
    position: 'relative',
  },
  formContainer: {
    position: 'relative',
    background: theme.palette.white.main,
    border: `1px solid ${theme.palette.grayScale5.main}`,
    borderRadius: '16px',
    margin: '20px 10px',
    padding: '40px 30px',

    [theme.breakpoints.only('xs')]: {
      margin: 0,
      padding: '12px 10px',
    },

    '& h3': {
      fontSize: '24px',
      lineHeight: '28px',
      color: theme.palette.black.main,
      margin: '0 0 32px',
    },
  },

  interestRoot: {
    margin: '0 0 16px',
    boxShadow: `0 0 0 2px ${theme.palette.grayScale4.main}`,
    borderRadius: '16px',
    padding: '20px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    [theme.breakpoints.only('xs')]: {
      minHeight: '140px',
      margin: '0 auto 16px',
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '140px',
      maxWidth: '80%',
      margin: '0 auto 16px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '140px',
    },
  },
  interestRootSelected: {
    boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.2)`,
  },
  interestTextContainer: {
    textAlign: 'left',
    marginLeft: '23px',

    '& > h4': {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: theme.palette.gray.main,
    },
    '& > p': {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.gray.main,
    },
  },
}));

const interestCardValues = [
  {
    title: 'Agent Assessment',
    description: 'One of the best ways to get a general estimate of value for your home is to provide some basic information about your property.',
  },
  {
    title: 'On Site Assessment',
    description: 'If you would like a professional comparable market analysis by a real estate professional, simply complete the form below.',
  },
  {
    title: 'Get Home Value Alerts',
    description: 'The market is always changing. It\'s difficult to gauge your home value based on home sales from a year ago. Why not sign up for a monthly alert?',
  },
  {
    title: 'Sell Or Buy',
    description: 'Selling or buying a home can be a complex and stressful process. You need an agent who has the experience and knowledge to sell a home in any market.',
  },
];

const bestTimeToCallValues = ['Morning', 'Afternoon', 'Evening'];

const InterestCard = ({
  title, description, selected, onClick,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={cx(classes.interestRoot, {
        [classes.interestRootSelected]: selected,
      })}
      onClick={onClick}
    >
      <Checkbox color="secondary" checked={selected} />
      <div className={classes.interestTextContainer}>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </ButtonBase>
  );
};

const AssessmentForm = ({ onClose, agentLandingPageToken }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const formRef = React.useRef();

  return (
    <Dialog
      classes={{
        paper: classes.root,
      }}
      open
      keepMounted
      maxWidth="lg"
      scroll="body"
      onClose={onClose}
    >
      <Hidden smDown>
        <div className={classes.background} />
      </Hidden>
      <ButtonBase
        className={classes.modalCloseButton}
        aria-label="Close"
        onClick={onClose}
      >
        <CloseIcon />
      </ButtonBase>
      <Formik
        innerRef={formRef}
        initialValues={{
          interests: interestCardValues.map(({ title }) => title),
          phoneNumber: '',
          email: '',
          message: '',
          bestDateToCall: null,
          bestTimeToCall: '',
          recaptchaValue: '',
        }}
        onSubmit={async ({
          interests, phoneNumber, email, message, bestDateToCall, bestTimeToCall, recaptchaValue,
        }) => {
          showLoadingIndicatorModal();

          try {
            if (!isAgentLandingPageSample(agentLandingPageToken)) {
              await submitAgentLandingAssessmentForm(agentLandingPageToken, interests, phoneNumber, email, message, bestDateToCall, bestTimeToCall, recaptchaValue);
            }

            setCurrentAlert('success', 'Thank you for your request, I will be in touch!');

            onClose();
          } catch (error) {
            console.error('error', error);
          }

          hideLoadingIndicatorModal();
        }}
        validationSchema={Yup.object().shape({
          interests: Yup.array().of(Yup.string().required()),
          phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          email: Yup.string().email().required(),
          message: Yup.string().required(),
          bestDateToCall: Yup.string().required(),
          bestTimeToCall: Yup.string().required(),
          recaptchaValue: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          const [showDatePicker, setShowDatePicker] = React.useState(false);

          return (
            <Form>
              {/* <Hidden smDown>
                <div className={classes.background} />
              </Hidden> */}

              <GridContainer>
                <GridItem xs={12} md={6} className={classes.interestsContainer}>
                  <h3 className={classes.title}>What are you interested in?</h3>
                  <p className={classes.subtitle}>You may choose multiple ones!</p>
                  <FieldArray
                    name="interests"
                    render={(arrayHelpers) => {
                      const toggleSelect = (interestInfoTitle) => {
                        const oIndex = values.interests.indexOf(interestInfoTitle);

                        if (oIndex === -1) {
                          arrayHelpers.push(interestInfoTitle);
                        } else {
                          arrayHelpers.remove(oIndex);
                        }
                      };

                      return (
                        <>
                          {interestCardValues.map((interestInfo) => (
                            <InterestCard
                              key={interestInfo.title}
                              title={interestInfo.title}
                              description={interestInfo.description}
                              selected={values.interests.indexOf(interestInfo.title) !== -1}
                              onClick={() => toggleSelect(interestInfo.title)}
                            />
                          ))}
                        </>
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <div className={classes.formContainer}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <h3>Please enter your info:</h3>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="phoneNumber">
                          {({ field }) => (
                            <CustomInput
                              labelText="Phone Number"
                              labelBold
                              labelRequired
                              inputProps={{
                                ...field,
                                type: 'tel',
                                autoComplete: 'tel',
                              }}
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              error={touched[field.name] && errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="email">
                          {({ field }) => (
                            <CustomInput
                              labelText="Email Address"
                              labelBold
                              labelRequired
                              inputProps={{
                                ...field,
                                autoComplete: 'email',
                              }}
                              error={touched[field.name] && errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="message">
                          {({ field }) => (
                            <CustomInput
                              labelText="Message"
                              labelBold
                              labelRequired
                              inputProps={{
                                ...field,
                                multiline: true,
                                rows: 3,
                              }}
                              error={touched[field.name] && errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="bestDateToCall">
                          {({ field }) => (
                            <>
                              <CustomInput
                                labelText="Best Date to Call"
                                labelBold
                                labelRequired
                                inputProps={{
                                  ...field,
                                  value: values[field.name] ? moment(values[field.name]).format('MMMM D') : '',
                                  onClick: () => setShowDatePicker(true),
                                  onChange: null,
                                }}
                                // Don't show error while the picker is open
                                error={!showDatePicker && touched[field.name] && errors[field.name] !== undefined}
                              />
                              {showDatePicker && (
                                <Dialog
                                  open
                                  onClose={() => setShowDatePicker(false)}
                                  maxWidth="xs"
                                >
                                  <DatePicker
                                    label="Best Date to Call"
                                    autoOk
                                    disablePast
                                    disableToolbar
                                    variant="static"
                                    value={values[field.name]}
                                    onChange={(value) => {
                                      setFieldValue(field.name, value, true);
                                      setShowDatePicker(false);
                                    }}
                                  />
                                </Dialog>
                              )}
                            </>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="bestTimeToCall">
                          {({ field }) => (
                            <CustomSelect
                              labelText="Best Time to Call"
                              labelBold
                              labelRequired
                              items={bestTimeToCallValues.map((amount) => ({ value: amount, name: amount }))}
                              inputProps={{
                                ...field,
                              }}
                              error={touched[field.name] && errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="recaptchaValue">
                          {({ field }) => (
                            <ReCAPTCHAInput
                              fieldName={field.name}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Button
                          color="primary"
                          round
                          outlined
                          size="lg"
                          className={classes.button}
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          Let's Get Started!
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AssessmentForm;
