import React from 'react';

import { SvgIcon } from '@material-ui/core';

const LongArrowLeftIcon = () => (
  <SvgIcon width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 5C16 5.55228 15.5523 6 15 6H3.415L5.70711 8.29289C6.06759 8.65338 6.09532 9.22061 5.7903 9.6129L5.70711 9.70711C5.34662 10.0676 4.77939 10.0953 4.3871 9.7903L4.29289 9.70711L0.292893 5.70711L0.251496 5.66315L0.196335 5.59531L0.124671 5.48406L0.0712255 5.37134L0.0358451 5.26599L0.00683422 5.11747L0 5L0.00278786 4.92476L0.0202401 4.79927L0.0497381 4.68786L0.0936734 4.57678L0.145995 4.47929L0.212786 4.38325C0.237669 4.35153 0.264427 4.32136 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893C6.06759 0.653377 6.09532 1.22061 5.7903 1.6129L5.70711 1.70711L3.415 4H15C15.5523 4 16 4.44772 16 5Z" fill="inherit" />
  </SvgIcon>
);

export default LongArrowLeftIcon;
