import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default () => (
  <SvgIcon style={{ fontSize: 44 }} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#56917F" />
    <path d="M19 13C19.7417 13 20.4667 13.2199 21.0834 13.632C21.7001 14.044 22.1807 14.6297 22.4645 15.3149C22.7484 16.0002 22.8226 16.7542 22.6779 17.4816C22.5333 18.209 22.1761 18.8772 21.6517 19.4017C21.1272 19.9261 20.459 20.2833 19.7316 20.4279C19.0042 20.5726 18.2502 20.4984 17.5649 20.2145C16.8797 19.9307 16.294 19.4501 15.882 18.8334C15.4699 18.2167 15.25 17.4917 15.25 16.75C15.25 15.7554 15.6451 14.8016 16.3483 14.0983C17.0516 13.3951 18.0054 13 19 13ZM19 11.5C17.9616 11.5 16.9466 11.8079 16.0833 12.3848C15.2199 12.9617 14.547 13.7816 14.1496 14.7409C13.7523 15.7002 13.6483 16.7558 13.8509 17.7742C14.0534 18.7926 14.5535 19.7281 15.2877 20.4623C16.0219 21.1965 16.9574 21.6965 17.9758 21.8991C18.9942 22.1017 20.0498 21.9977 21.0091 21.6004C21.9684 21.203 22.7883 20.5301 23.3652 19.6667C23.9421 18.8034 24.25 17.7884 24.25 16.75C24.25 15.3576 23.6969 14.0223 22.7123 13.0377C21.7277 12.0531 20.3924 11.5 19 11.5Z" fill="white" />
    <path d="M26.5 31.75C26.5 32.1642 26.1642 32.5 25.75 32.5C25.3358 32.5 25 32.1642 25 31.75V28.75C25 27.7554 24.6049 26.8016 23.9017 26.0983C23.1984 25.3951 22.2446 25 21.25 25H16.75C15.7554 25 14.8016 25.3951 14.0983 26.0983C13.3951 26.8016 13 27.7554 13 28.75V31.75C13 32.1642 12.6642 32.5 12.25 32.5C11.8358 32.5 11.5 32.1642 11.5 31.75V28.75C11.5 27.3576 12.0531 26.0223 13.0377 25.0377C14.0223 24.0531 15.3576 23.5 16.75 23.5H21.25C22.6424 23.5 23.9777 24.0531 24.9623 25.0377C25.9469 26.0223 26.5 27.3576 26.5 28.75V31.75Z" fill="white" />
    <path d="M28.75 22.135L27.3363 20.7213C27.0442 20.4292 26.5708 20.4292 26.2787 20.7213C25.9867 21.0133 25.9867 21.4867 26.2787 21.7787L28.75 24.25L33.4713 19.5287C33.7633 19.2367 33.7633 18.7633 33.4713 18.4713C33.1792 18.1792 32.7058 18.1792 32.4138 18.4712L28.75 22.135Z" fill="white" />
  </SvgIcon>
);
