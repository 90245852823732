import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default () => (
  <SvgIcon style={{ fontSize: 44 }} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#A259FF" />
    <path d="M29.5 20.5H28.75C28.3358 20.5 28 20.8358 28 21.25C28 21.6642 28.3358 22 28.75 22H29.5C30.0965 22.0007 30.6685 22.2379 31.0903 22.6597C31.5121 23.0815 31.7493 23.6535 31.75 24.25V26.5C31.75 26.9142 32.0858 27.25 32.5 27.25C32.9142 27.25 33.25 26.9142 33.25 26.5V24.25C33.2488 23.2558 32.8534 22.3026 32.1504 21.5996C31.4474 20.8966 30.4942 20.5012 29.5 20.5Z" fill="white" />
    <path d="M28 13C28.445 13 28.88 13.132 29.25 13.3792C29.62 13.6264 29.9084 13.9778 30.0787 14.389C30.249 14.8001 30.2936 15.2525 30.2068 15.689C30.12 16.1254 29.9057 16.5263 29.591 16.841C29.2763 17.1557 28.8754 17.37 28.439 17.4568C28.0025 17.5436 27.5501 17.499 27.139 17.3287C26.7278 17.1584 26.3764 16.87 26.1292 16.5C25.882 16.13 25.75 15.695 25.75 15.25C25.75 14.6533 25.9871 14.081 26.409 13.659C26.831 13.2371 27.4033 13 28 13ZM28 11.5C27.2583 11.5 26.5333 11.7199 25.9166 12.132C25.2999 12.544 24.8193 13.1297 24.5355 13.8149C24.2516 14.5002 24.1774 15.2542 24.3221 15.9816C24.4667 16.709 24.8239 17.3772 25.3483 17.9017C25.8728 18.4261 26.541 18.7833 27.2684 18.9279C27.9958 19.0726 28.7498 18.9984 29.4351 18.7145C30.1203 18.4307 30.706 17.9501 31.118 17.3334C31.5301 16.7167 31.75 15.9917 31.75 15.25C31.75 14.2554 31.3549 13.3016 30.6516 12.5983C29.9484 11.8951 28.9946 11.5 28 11.5Z" fill="white" />
    <path d="M27.25 31.75C27.25 32.1642 26.9142 32.5 26.5 32.5C26.0858 32.5 25.75 32.1642 25.75 31.75V31C25.7493 30.4035 25.5121 29.8315 25.0903 29.4097C24.6685 28.9879 24.0965 28.7507 23.5 28.75H20.5C19.9035 28.7507 19.3315 28.9879 18.9097 29.4097C18.4879 29.8315 18.2507 30.4035 18.25 31V31.75C18.25 32.1642 17.9142 32.5 17.5 32.5C17.0858 32.5 16.75 32.1642 16.75 31.75V31C16.7512 30.0058 17.1466 29.0527 17.8496 28.3496C18.5527 27.6466 19.5058 27.2512 20.5 27.25H23.5C24.4942 27.2512 25.4473 27.6466 26.1504 28.3496C26.8534 29.0527 27.2488 30.0058 27.25 31V31.75Z" fill="white" />
    <path d="M22 19.75C22.445 19.75 22.88 19.882 23.25 20.1292C23.62 20.3764 23.9084 20.7278 24.0787 21.139C24.249 21.5501 24.2936 22.0025 24.2068 22.439C24.12 22.8754 23.9057 23.2763 23.591 23.591C23.2763 23.9057 22.8754 24.12 22.439 24.2068C22.0025 24.2936 21.5501 24.249 21.139 24.0787C20.7278 23.9084 20.3764 23.62 20.1292 23.25C19.882 22.88 19.75 22.445 19.75 22C19.75 21.4033 19.9871 20.831 20.409 20.409C20.831 19.9871 21.4033 19.75 22 19.75ZM22 18.25C21.2583 18.25 20.5333 18.4699 19.9166 18.882C19.2999 19.294 18.8193 19.8797 18.5355 20.5649C18.2516 21.2502 18.1774 22.0042 18.3221 22.7316C18.4667 23.459 18.8239 24.1272 19.3483 24.6517C19.8728 25.1761 20.541 25.5333 21.2684 25.6779C21.9958 25.8226 22.7498 25.7484 23.4351 25.4645C24.1203 25.1807 24.706 24.7001 25.118 24.0834C25.5301 23.4667 25.75 22.7417 25.75 22C25.75 21.0054 25.3549 20.0516 24.6517 19.3483C23.9484 18.6451 22.9946 18.25 22 18.25Z" fill="white" />
    <path d="M16 21.25C16 20.8358 15.6642 20.5 15.25 20.5H14.5C13.5058 20.5012 12.5527 20.8966 11.8496 21.5996C11.1466 22.3027 10.7512 23.2558 10.75 24.25V26.5C10.75 26.9142 11.0858 27.25 11.5 27.25C11.9142 27.25 12.25 26.9142 12.25 26.5V24.25C12.2507 23.6535 12.4879 23.0815 12.9097 22.6597C13.3315 22.2379 13.9035 22.0007 14.5 22H15.25C15.6642 22 16 21.6642 16 21.25Z" fill="white" />
    <path d="M16 13C16.445 13 16.88 13.132 17.25 13.3792C17.62 13.6264 17.9084 13.9778 18.0787 14.389C18.249 14.8001 18.2936 15.2525 18.2068 15.689C18.12 16.1254 17.9057 16.5263 17.591 16.841C17.2763 17.1557 16.8754 17.37 16.439 17.4568C16.0025 17.5436 15.5501 17.499 15.139 17.3287C14.7278 17.1584 14.3764 16.87 14.1292 16.5C13.882 16.13 13.75 15.695 13.75 15.25C13.75 14.6533 13.9871 14.081 14.409 13.659C14.831 13.2371 15.4033 13 16 13ZM16 11.5C15.2583 11.5 14.5333 11.7199 13.9166 12.132C13.2999 12.544 12.8193 13.1297 12.5355 13.8149C12.2516 14.5002 12.1774 15.2542 12.3221 15.9816C12.4667 16.709 12.8239 17.3772 13.3483 17.9017C13.8728 18.4261 14.541 18.7833 15.2684 18.9279C15.9958 19.0726 16.7498 18.9984 17.4351 18.7145C18.1203 18.4307 18.706 17.9501 19.118 17.3334C19.5301 16.7167 19.75 15.9917 19.75 15.25C19.75 14.2554 19.3549 13.3016 18.6517 12.5983C17.9484 11.8951 16.9946 11.5 16 11.5Z" fill="white" />
  </SvgIcon>
);
