import styles from 'assets/jss/material-dashboard-pro-react/components/customSelectStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function CustomSelect({
  labelText,
  labelBold,
  labelRequired,
  labelLight,
  id,
  inputProps,
  error,
  helperText,
  items,
  fontSize = 16,
}) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth>
      {labelText !== undefined ? (
        <div
          htmlFor={id}
          className={cx(classes.label, {
            [classes.labelDisabled]: inputProps && inputProps.disabled,
            [classes.labelBold]: labelBold,
            [classes.labelRequired]: labelRequired,
            [classes.labelLight]: labelLight,
          })}
        >
          {labelText}
        </div>
      ) : null}
      <Select
        // classes={{
        //   root: classes.selectRoot,
        // }}
        style={{ fontSize }}
        inputProps={{
          classes: {
            root: classes.input,
          },
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenuPaper,
            // list: classes.selectMenuList,
          },
          // className: classes.selectMenu,
          variant: 'menu', // Prevents jumping around on select, ref: https://v4.mui.com/components/menus/#selected-menus
          getContentAnchorEl: null,
        }}
        id={id}
        {...inputProps}
        variant="outlined"
        error={error}
      >

        {items.map(({ name, value }) => (
          <MenuItem
            style={{ fontSize }}
            classes={{
              root: cx({
                [classes.selectMenuItem]: true,
              }),
              selected: cx({
                [classes.selectMenuItemSelected]: true,
                [classes.selectMenuItemSelectedMultiple]: inputProps.multiple,
              }),
            }}
            key={value}
            value={value}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperText !== undefined ? (
        <FormHelperText
          id={`${id}-text`}
          classes={{
            root: classes.helperText,
            error: classes.helperTextError,
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  labelBold: false,
  labelRequired: false,
  labelLight: false,
};

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelBold: PropTypes.bool,
  labelRequired: PropTypes.bool,
  labelLight: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  items: PropTypes.array, // TODO: For typescript: [name: string, value: string]
};
