import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import AgentLandingHeader from 'components/AgentLanding/AgentLandingHeader/AgentLandingHeader';

import styles from 'assets/jss/material-dashboard-pro-react/views/agent-landing/biographyPageStyle';

const useStyles = makeStyles(styles);

const BiographyPage = ({ agentLandingPageType, agentLandingPageToken, agentData }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AgentLandingHeader agentLandingPageType={agentLandingPageType} agentLandingPageToken={agentLandingPageToken} agentData={agentData} />

      <div className={classes.biographySection}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.biographyCard}>
            <h1>Bio</h1>
            <p>
              {agentData.agentLandingBio ? (
                <>
                  {agentData.agentLandingBio}
                  <br />
                  <br />
                </>
              ) : (
                <>
                  {`${agentData.firstName} ${agentData.lastName} is a dynamic real estate professional, specializing in residential and commercial property sales across Southern California.  With real estate property development, construction and sales experience, ${agentData.firstName} brings a wealth of knowledge and experience to every unique sale and listing they manage.\n
                As a seasoned realtor, ${agentData.firstName} has a proven track record of providing the finest level of exceptional service, expertise and a commitment to helping their clients achieve their real estate dreams.  ${agentData.firstName}'s broad range of real estate services spans residential home sales, condominiums, income properties, commercial, land, and mobile home sales.  They also have expertise in leases.\n
                As an expert negotiator and cutting-edge marketer, ${agentData.firstName} brings professional experience to all of their real estate listings and sales. They provide an unparalleled ability to create the best investment strategies for their clients.\n
                ${agentData.firstName} brings an uncompromising level of service and ethical standards to all of their transactions and their repeat and referral business are a testament of their loyal and dedicated following.
                Their mission is dedicated to creating unique and personal real estate solutions for each real estate client, from first time buyers to savvy investors and sellers.\n
                As a hard worker, ${agentData.firstName} will provide you with exceptional service and results for all of your Real Estate needs. If you are looking for a real estate professional who will exceed your expectations.\n\n`}
                </>
              )}
              <strong>
                {`Contact ${agentData.firstName} ${agentData.lastName} at `}
                <a href={`tel:${agentData.phoneNumber}`}>{agentData.phoneNumber}</a>
              </strong>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default BiographyPage;
