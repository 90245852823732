import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const useOptions = () => {
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const StripeCardElement = () => {
  const options = useOptions();

  return (
    <CardElement options={options} />
  );
};

export default StripeCardElement;
