import { ELeadResponseType } from './lead-response';
import { ETouchType } from './touch-type';

export interface IDeliveredTouchStat {
  touchTriggerId: string;
  touchType: ETouchType;
  date: string;
  count: number;
}

export interface ILeadResponseData {
  _id: string;
  type: ELeadResponseType;
  date: string;
  isCampaignExtraRecipient: boolean;
  eventData?: {
    _id: string;
    apn: string;
    siteAddress: {
      address: string;
      city: string;
      state: string;
      zipcode: string;
    };
    eventDataOverride?: {
      firstName: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      address: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipcode: string;
      };
    };
  };
  recipient: {
    firstName: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    alternateEmails?: string[];
    alternatePhoneNumbers?: string[];
    address?: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zipcode: string;
    };
  };
  metadata: any;
}

// TODO: REFACTOR TO something similar to ILeadResponseData later if needed to show table of event data
export type ILeadResponseStats = {
  [key in ELeadResponseType]: ILeadResponseData[];
};

export interface IEmailDeliverabilityMonthlyRecipients {
  // monthString is in MM-YYYY format (i.e. 04-2024), but it may also be 'Total' for the total stat
  [monthString: string]: {
    count: number;
    percentageChange: number; // Percent change compared to previous month. 0 for first month and 'Total' stat
  };
}

export interface IEmailDeliverabilityStats {
  unsubscribedEmails: IEmailDeliverabilityMonthlyRecipients;
  bouncedEmails: IEmailDeliverabilityMonthlyRecipients;
  clickedEmails: IEmailDeliverabilityMonthlyRecipients;
}

export const hotProspectLeadTypes = [
  ELeadResponseType.EmailReply,
  ELeadResponseType.EmailClick,
  ELeadResponseType.AgentLandingOpen,
  ELeadResponseType.AgentLandingConsentForm,
  ELeadResponseType.AgentLandingDownloadReport,
  ELeadResponseType.AgentLandingEmailAgentForm,
  ELeadResponseType.AgentLandingSellForThisPriceForm,
  ELeadResponseType.AgentLandingPriceTooLowForm,
  ELeadResponseType.AgentLandingAssessmentForm,
  ELeadResponseType.AgentLandingContactForm,
];

export const engagementLeadTypes = [...hotProspectLeadTypes, ELeadResponseType.EmailOpen];
