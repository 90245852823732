import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  footer: {
    bottom: '0',
    zIndex: 4,
    padding: '32px 0',

    color: theme.palette.white.main,
    backgroundColor: theme.palette.darkGray.main,
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',
    '& > div > div': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  logo: {
    width: '173px',
  },
  copyrightText: {
    color: theme.palette.white.main,
    opacity: '0.5',
    fontSize: '14px',
    lineHeight: '20px',

    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
  },
});

export default style;
