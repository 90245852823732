import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import BrokerageAgentsTable from 'components/BrokerageTables/BrokerageAgentsTable';
import BrokerageAdminsTable from 'components/BrokerageTables/BrokerageAdminsTable';
import BrokerageBranchesTable from 'components/BrokerageTables/BrokerageBranchesTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import AddBrokerageAdminModal from 'components/AddBrokerageAdminModal/AddBrokerageAdminModal';
import AddBrokerageAgentModal from 'components/AddBrokerageAgentModal/AddBrokerageAgentModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';

import { useMyBrokerage, useBrokerageQuota } from 'data/brokerage';

import { updateBrokerageName } from 'utils/api';
import { numberWithCommas, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    marginTop: '20px',
  },
  dashboardBubble: {
    margin: '10px auto',
    '& a': {
      color: theme.palette.warning.main,
    },
  },
  quotaText: {
    '& > span': {
      fontWeight: 600,
    },
  },
  buttons: {
    textAlign: 'right',
    marginBottom: '20px',
    '& > button': {
      marginLeft: '20px',
    },
  },
}));

const tabs = ['Branches', 'Agents', 'Admins'];

const MyBrokeragePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const { brokerage, isLoadingBrokerage, mutateBrokerage } = useMyBrokerage();
  const { quota, isLoadingQuota, reloadQuota } = useBrokerageQuota();

  const [showAddAgentModal, setShowAddAgentModal] = React.useState(null);
  const [showAddAdminModal, setShowAddAdminModal] = React.useState(null);

  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  if (isLoadingBrokerage || isLoadingQuota) return (<LoadingIndicator />);

  const { numAgents, numApnsBeingFarmed } = quota;

  const onUpdateBrokerageName = async (name) => {
    showLoadingIndicatorModal();
    try {
      await updateBrokerageName(name);

      mutateBrokerage({
        brokerage: {
          ...brokerage,
          name,
        },
      }, { revalidate: false });

      setCurrentAlert('success', 'Brokerage name updated');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const addNewAgentButton = (
    <Button
      color="primary"
      round
      onClick={() => {
        if (brokerage.branches.length > 0) {
          setShowAddAgentModal(true);
        } else {
          setActiveTab(tabs[0]);
          setCurrentAlert('warning', 'Create your first branch before inviting agents to your brokerage!');
        }
      }}
    >
      + Add New Agent
    </Button>
  );

  let tabContent;
  if (activeTab === tabs[0]) {
    tabContent = (
      <>
        <div className={classes.buttons}>
          <Button
            color="primary"
            round
            onClick={() => history.push({ pathname: '/dashboard/brokerage-branch-marketing-profile' })}
          >
            + Create New Branch
          </Button>
        </div>

        <BrokerageBranchesTable brokerage={brokerage} />
      </>
    );
  } else if (activeTab === tabs[1]) {
    tabContent = (
      <>
        <div className={classes.buttons}>
          {addNewAgentButton}
        </div>

        <BrokerageAgentsTable brokerage={brokerage} mutateBrokerage={mutateBrokerage} reloadQuota={reloadQuota} />
      </>
    );
  } else if (activeTab === tabs[2]) {
    tabContent = (
      <>
        <div className={classes.buttons}>
          <Button
            color="primary"
            round
            onClick={() => setShowAddAdminModal(true)}
          >
            + Add New Admin
          </Button>
        </div>

        <BrokerageAdminsTable brokerage={brokerage} />
      </>
    );
  }

  return (
    <div className={classes.root}>
      {showAddAdminModal && (<AddBrokerageAdminModal onClose={() => setShowAddAdminModal(false)} />)}
      {showAddAgentModal && (<AddBrokerageAgentModal onClose={() => setShowAddAgentModal(false)} />)}

      <DashboardSection
        sectionName={brokerage.name}
        sectionNameEditable
        onSectionEditName={onUpdateBrokerageName}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {/*  TODO: IMPROVE UI */}
      {/* <DashboardBubble
        rootClass={classes.dashboardBubble}
        backgroundColor={theme.palette.primary.light}
        icon={<InfoIcon htmlColor={theme.palette.success.main} />}
        content={(
          <span className={classes.quotaText}>
            <span>Stats:</span>
            <br />
            {`${numberWithCommas(numAgents)} Agents`}
            <br />
            {`${numberWithCommas(numApnsBeingFarmed)} APNs`}
          </span>
          )}
      /> */}

      <div className={classes.spacer} />

      {tabContent}
    </div>
  );
};

export default MyBrokeragePage;
