import React from 'react';

import { ButtonBase, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '350px',
    maxHeight: '350px',
  },

  popupImage: {
    marginTop: '20px',
    position: 'relative',
    display: 'block',
    margin: 'auto',

    // width: '100%',
    height: '100%',
    objectFit: 'contain',
    border: '1px solid gray',

    overflowY: 'auto',
    overflowX: 'auto',
  },
}));

interface Props {
  imageData?: string;
}

export default ({ imageData }: Props) => {
  const classes = useStyles();

  const [popupOpen, setPopupOpen] = React.useState(false);

  return (
    <>
      {popupOpen && (
      <Dialog
        id="preview-modal"
        open
        scroll="paper"
        keepMounted
        maxWidth="lg"
        onClose={() => setPopupOpen(false)}
      >
        <SimpleModalHeader onClose={() => setPopupOpen(false)} title="Ad Preview" />

        <img
          className={classes.popupImage}
          src={imageData}
          alt="Ad Preview"
        />
      </Dialog>
      )}

      {imageData ? (
        <ButtonBase onClick={() => setPopupOpen(true)}>
          <img
            className={classes.image}
            src={imageData}
            alt="Ad Preview"
          />
        </ButtonBase>
      ) : <Skeleton variant="rect" width={350} height={350} />}
    </>
  );
};
