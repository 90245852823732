import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, InputBase, Paper } from '@material-ui/core';

import { newsletterSubscribe } from 'utils/api';

import styles from 'assets/jss/material-dashboard-pro-react/components/emailSignupFieldStyle';

const useStyles = makeStyles(styles);

const EmailSignupField = () => {
  const classes = useStyles();

  const [submitted, setSubmitted] = React.useState(false);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async ({ email }) => {
        try {
          await newsletterSubscribe({
            email,
            utmSource: 'harvist',
            utmMedium: 'website',
            utmCampaign: 'footer',
          });

          setSubmitted(true);
        } catch (error) {
          console.error('error', error);
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        if (submitted) {
          return (<div className={classes.submittedText}>Your email has been added!</div>);
        }

        return (
          <Form className={classes.root}>
            <Field
              name="email"
            >
              {({ field }) => (
                <Paper elevation={0} className={classes.paper}>
                  <InputBase
                    className={classes.inputField}
                    placeholder="Enter Email"
                    inputProps={{
                      ...field,
                      autoComplete: 'email',
                    }}
                  />
                  <ButtonBase
                    type="submit"
                    className={classes.button}
                    disableRipple
                    disableTouchRipple
                  >
                    Signup
                  </ButtonBase>
                </Paper>
              )}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailSignupField;
