import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';

import { updateTouchVoicemail } from 'utils/api';
import { showAPIErrorAlert, voicemailTouchBehaviors } from 'utils/lib';

import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const useStyles = makeStyles((theme) => ({
  ...customCheckboxRadioSwitchStyles(theme),
  root: {
    margin: '40px 0 0',
  },
  description: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: theme.palette.gray.main,
    fontWeight: 500,
  },
  fieldQuestion: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    color: theme.palette.darkGray.main,
    fontWeight: 500,
    margin: '40px 0 10px',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const VoicemailTouchBehaviorForm = ({ touchTrigger, onReceiveEditedTouchTrigger }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  return (
    <Formik
      initialValues={{
        voicemailTouchBehavior: touchTrigger.metadata.voicemailTouchBehavior,
      }}
      onSubmit={async (values, { setStatus, resetForm }) => {
        const { voicemailTouchBehavior } = values;
        try {
          const { touchTrigger: newTouchTrigger } = await updateTouchVoicemail(touchTrigger._id, { voicemailTouchBehavior });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });

          // Callback to update the touch trigger locally
          onReceiveEditedTouchTrigger(newTouchTrigger);
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        voicemailTouchBehavior: Yup.string().required(),
      })}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <GridContainer justifyContent="center" />
          <div className={classes.root}>
            <h4 className={classes.description}>
              Choose who your voicemail is sent to. Anyone who doesn't receive a voicemail will receive an email as a substitute.
            </h4>
            <Field name="voicemailTouchBehavior">
              {({ field }) => (
                <>
                  <h5 className={classes.fieldQuestion}>
                    How do you want this touch sent to your leads?
                    {/* We can only send voicemails to the leads that scan your QR code on letters and postcards to give us consent to call them. */}
                    {/* We recommend using the first option here to send voicemails only to leads who have not scanned your QR codes.
                  You will receive notifications when a lead scans your QR codes on letters and postcards and you have the highest chance of success by contacting them directly soon after! */}
                  </h5>
                  {Object.keys(voicemailTouchBehaviors).map((key) => (
                    <GridItem xs={12} key={key}>
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={field.value === key}
                            onChange={() => setFieldValue(field.name, key, true)}
                            icon={<FiberManualRecordIcon className={classes.radioUnchecked} />}
                            checkedIcon={<FiberManualRecordIcon className={classes.radioChecked} />}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                    )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label={voicemailTouchBehaviors[key]}
                      />
                    </GridItem>
                  ))}
                </>
              )}
            </Field>
          </div>
          <GridItem xs={12}>
            <div className={classes.saveButtonContainer}>
              <SaveButton />
            </div>
          </GridItem>
        </Form>
      )}
    </Formik>
  );
};

export default VoicemailTouchBehaviorForm;
