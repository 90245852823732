import useSWR from 'swr';

import { populateCampaignEventDataEventOverrideData } from 'utils/lib';

// For Admins
export const useAllCampaigns = (userId = undefined, brokerageId = undefined, shouldFetch = true, page = 0, pageSize = 1000000) => {
  const { data, error } = useSWR(shouldFetch ? ['/campaigns/all', {
    userId, brokerageId, page, pageSize,
  }] : null);

  return {
    campaigns: data?.campaigns,
    isLoadingCampaigns: !data,
    isError: error,
  };
};

export const useCampaignDetail = (campaignId: string) => {
  const { data, error, mutate } = useSWR(campaignId ? `/campaigns/${campaignId}` : null);

  const campaign = data?.campaign;

  if (campaign) {
    populateCampaignEventDataEventOverrideData([campaign]);
  }

  return {
    campaign,
    isLoadingCampaign: !data,
    isLoadingCampaignError: error,
    mutateCampaign: mutate,
  };
};

export const useCampaigns = (eventTypeName?: string, statuses?: string[], shouldPopulateCampaignEventDataEventOverrideData = false, shouldFetch = true) => {
  const { data, error, mutate } = useSWR(shouldFetch ? ['/campaigns', { eventTypeName, statuses }] : null);

  const campaigns = data?.campaigns;

  if (shouldPopulateCampaignEventDataEventOverrideData && campaigns) {
    populateCampaignEventDataEventOverrideData(campaigns);
  }

  return {
    campaigns: data?.campaigns,
    isLoadingCampaigns: !data,
    isLoadingCampaignsError: error,
    mutateCampaigns: mutate,
  };
};

export const useCampaignNumApnsBeingFarmed = () => {
  const { data, error } = useSWR('/campaigns/num-apns-farmed');

  return {
    numApnsBeingFarmed: data?.numApnsBeingFarmed,
    isLoadingNumApnsBeingFarmed: !data,
    isError: error,
  };
};

export const useTouchTemplateDesigns = (campaignId: string) => {
  const { data, error } = useSWR(campaignId ? `/campaigns/touch-template-designs?campaignId=${campaignId}` : '/campaigns/touch-template-designs');

  return {
    touchTemplateDesigns: data?.touchTemplateDesigns,
    isLoadingTouchTemplateDesigns: !data,
    isError: error,
  };
};
